import React from 'react';
import config from '../../config';

export default function DicomViewer({ close, file }) {
	let currentUser = localStorage.getItem('currentUser');
	let accessToken = localStorage.getItem(`${currentUser}.accessToken`);

	return (
		<div
			className='threeJsCont'
			style={{
				height: window.innerHeight - 100,
				width: window.innerWidth < 800 ? window.innerWidth - 100 : 1200,
			}}>
			<h2 className='stlHeader' style={{ color: 'white', marginTop: '16px' }}>
				{file?.originalname}
			</h2>
			<button className='closeModel' style={{ marginTop: '16px' }} onClick={close}>
				Close
			</button>
			<iframe
				src={`${config.dicom}${file ? `?filePath=${file.path}` : ''}${
					accessToken ? `&accessToken=${accessToken}` : ''
				}`}
				width='100%'
				height='100%'></iframe>
		</div>
	);
}

import React from 'react';

export default function SupplierSkeleton() {
	return (
		<div className='supplierSkel'>
			<div className='supplierHeader'></div>
			<div className='supplierBody'></div>
			<div className='supplierFooter'></div>
		</div>
	);
}

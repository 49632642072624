import React, { useContext } from 'react';
import Button from '../../Button';
import SelectCustom from '../../Select/SelectCustom';
import { ArrowNarrowRightIcon } from '../../SVGIcons/SVGIcons';
import { parseToOptions } from '../../../helpers';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import useFetch from '../../../hooks/useFetch';
import config from '../../../config';

export default function PackagesFilterBar(props) {
	const { filter, handleFilter, handleSearch, openFilter, search } = props || {};

	const { teamState } = useContext(CurrentTeamContext);

	const { data, fetch, reqLoading } = useFetch(
		`${config.apiv1}/courier/couriers.read/${teamState?.currentTeam?.teamUuid}`
	);

	return (
		<div className='filterBar'>
			<div>
				<h4 className='label'>Search</h4>
				<input
					className='textFieldInput'
					defaultValue={search}
					onChange={(e) => {
						handleSearch(e.target.value);
					}}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Courier</h4>
				<SelectCustom
					options={[{ name: 'All', value: undefined }, ...parseToOptions(data, 'couriers')]}
					placeholder={'Select...'}
					fieldName='courier'
					callback={handleFilter}
					value={filter?.courier}
					width={300}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Date</h4>
				<SelectCustom
					htmlOptions={[
						<option value='date.desc'>Newest to oldest</option>,
						<option value='date.asc'>Oldest to newest</option>,
					]}
					placeholder={'Select...'}
					fieldName='sort'
					callback={(e) => {
						handleFilter(e.value, 'sort');
					}}
					value={filter?.date}
					width={300}
				/>
			</div>
			{/* <Button
				text='All filters'
				size='md'
				style='secondary'
				iconRight={<ArrowNarrowRightIcon />}
				onClick={openFilter}
			/> */}
		</div>
	);
}

import React from 'react';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';

export default function CourierNewForm(props) {
	const { handleFormInput, formData, customErrors } = props || {};

	return (
		<div className='mainFormCont' id='courierForm'>
			<h2>Add new courier</h2>
			<TextInput
				text={'Company name'}
				name={'name'}
				callback={handleFormInput}
				defaultValue={formData.name}
				customError={customErrors?.name}
				required
			/>
			<TextInput
				text={'Email'}
				name={'email'}
				callback={handleFormInput}
				defaultValue={formData.email}
				customError={customErrors?.email}
				required
			/>
			<TextInput
				text={'Telephone'}
				name={'telephone'}
				callback={handleFormInput}
				defaultValue={formData.telephone}
				customError={customErrors?.telephone}
				required
			/>
			<TextInput
				text={'Account'}
				name={'accountNmbr'}
				callback={handleFormInput}
				defaultValue={formData.accountNmbr}
				customError={customErrors?.accountNmbr}
				required
			/>
			<TextareaInput
				text={'Notes'}
				name={'notes'}
				defaultValue={formData?.notes}
				callback={handleFormInput}
				customError={customErrors?.notes}
			/>
		</div>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import { ArrowLeftIcon, InvoiceGBPIcon } from '../../components/SVGIcons/SVGIcons';
import { useLocation, useNavigate } from 'react-router';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { calculatePercentage, getCurrencySymbolFromTeam, splitUserId } from '../../helpers';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import Loading from '../../components/Loading/Loading';
import PageWrapper from '../../components/Invoice/PageWrapper';
import SectionWrapper from '../../components/Invoice/sections/SectionWrapper';
import BuyerInfoSection from '../../components/Invoice/sections/BuyerInfoSection';
import AmountSection from '../../components/Invoice/sections/AmountSection';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import * as yup from 'yup';
import { useForms } from '../../hooks/useForms';
import NotesSection from '../../components/Invoice/sections/NotesSection';
import { taxInfo } from '../../components/TempTaxData/tempTaxData';
import ItemsSection from '../../components/Invoice/sections/ItemsSection';
import InfoSection from '../../components/Invoice/sections/InfoSection';
import Axios from 'axios';

export default function InvoiceCreate() {
	let navigate = useNavigate();
	let location = useLocation();

	let orderUuid = new URLSearchParams(location.search).get('orderUuid');

	const lineItemBase = {
		lineNumber: 1,
		description: '',
		quantity: 1,
		unitAmount: 0,
		taxAmount: 0,
		taxPercent: 20,
		totalAmount: 0,
		unit: 'pcs',
	};

	const [order, setOrder] = useState(null);
	const [products, setProducts] = useState([]);
	const [buyerTeam, setBuyerTeam] = useState({});
	const [sellerTeam, setSellerTeam] = useState({});
	const [invoiceItems, setInvoiceItems] = useState({
		1: lineItemBase,
	});
	const [currencySymbol, setCurrencySymbol] = useState('');
	const [editing, setEditing] = useState(null);

	const formValidationSchema = yup.object().shape({
		privateNote: yup.string(),
		currencyCode: yup.string().required(),
		txnDate: yup.date().required(),
		paymentTerms: yup.number().required(),
		invoiceItems: yup.array().required(),
	});

	let invoiceFieldMap = (formData) => {
		let dueDate = calcDueDate(formData.txnDate, formData.paymentTerms);

		function calcDueDate(txnDate, paymentTerms) {
			let timeMilliseconds = new Date(txnDate)?.getTime() + paymentTerms * 24 * 60 * 60 * 1000;

			return new Date(timeMilliseconds);
		}

		return {
			createdByUserUuid: splitUserId(userState.currUser.sub),
			sellerTeamUuid: order.sellerTeamUuid,
			buyerTeamUuid: order.buyerTeamUuid,
			privateNote: formData.privateNote,
			currencyCode: formData.currencyCode,
			message: formData.message,
			orderUuid: order.orderUuid,
			invoiceItems: formData.invoiceItems,
			txnDate: formData.txnDate,
			totalAmount: formData.totalAmount,
			totalTax: formData.totalTax,
			subject: formData.subject,
			dueDate: dueDate,
			paymentTerms: formData.paymentTerms,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'createInvoice',
		formValidationSchema,
		invoiceFieldMap,
		`${config.apiv1}/invoice/invoice.create`
	);

	const { fetch } = useFetch();

	const { userState } = useContext(CurrentUserContext);
	const { teamState } = useContext(CurrentTeamContext);

	const handleInvoiceItemData = (value, name, lineNumber) => {
		let newInvoiceItems = {};

		if (formData?.invoiceItems) {
			formData?.invoiceItems.forEach((obj) => {
				newInvoiceItems[obj.lineNumber] = obj;
			});
			let lineItem = newInvoiceItems[lineNumber];
			lineItem.lineNumber = lineNumber;
			lineItem[name] = value;
			if (Number(lineItem.unitAmount) && Number(lineItem.quantity)) {
				let subTotal = Number(lineItem.unitAmount) * Number(lineItem.quantity);
				if (lineItem.taxPercent) {
					lineItem.taxAmount = calculatePercentage(lineItem.taxPercent, subTotal);
				} else {
					lineItem.taxAmount = subTotal * taxInfo[teamState.currentTeam?.teamInfo?.tax]?.rate;
				}
				lineItem.totalAmount = lineItem.taxAmount + subTotal;
			}

			newInvoiceItems = {
				...newInvoiceItems,
				[lineNumber]: {
					...lineItem,
					lineNumber: lineNumber,
					[name]: value,
				},
			};
		}

		let invoiceTotal = calcInvoiceTotal({ invoiceItems: Object.values(newInvoiceItems) });
		let invoiceTax = calcInvoiceTax({ invoiceItems: Object.values(newInvoiceItems) });

		setInvoiceItems(newInvoiceItems);

		updateForm({
			...formData,
			totalTax: invoiceTax,
			totalAmount: invoiceTotal,
			invoiceItems: Object.values(newInvoiceItems),
		});
	};

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async () => {
		let invoiceItems = [...formData.invoiceItems];
		let success = await submitForm(null, `Invoice was successfully created`, (e) =>
			createInvoiceItems(e.data, invoiceItems)
		);
	};

	const formatter = new Intl.NumberFormat(teamState?.currentTeam?.teamInfo?.spokenLanguage, {
		style: 'currency',
		currency: formData?.currencyCode || 'GBP',
		currencyDisplay: 'narrowSymbol',
	});

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			fetchInitData();
			setCurrencySymbol(getCurrencySymbolFromTeam(teamState.currentTeam));
			updateForm({
				...formData,
				paymentTerms: 30,
				totalTax: 0,
				invoiceItems: Object.values(invoiceItems),
				currencyCode: teamState?.currentTeam?.teamInfo?.currency,
			});
		}
		return () => (mounted = false);
	}, []);

	return (
		<PageWrapper
			backColor='gray'
			style={window.innerWidth > 768 ? { padding: '0px 24px 32px 24px' } : null}
			header={{
				text: 'New Invoice',
				icon: <InvoiceGBPIcon iconClass='headerIcon' />,
				theme: 'sale',
				button1: {
					text: 'Go back to order',
					cb: goBackToOrder,
					icon: <ArrowLeftIcon />,
					style: 'tertiary-secondary',
					width: 180,
				},
				button2: { text: 'Save invoice', cb: handleSubmit, style: 'primary', width: 164 },
			}}
			innerWrap>
			{order ? (
				<>
					{/* <div className='innerHeader'>
						<h2>{order.buyerTeamName || order.buyerUserName}</h2>
						<h2>{order.sellerTeamName}</h2>
					</div> */}
					<SectionWrapper>
						<BuyerInfoSection order={order} formData={formData} handleFormData={handleFormData} />
					</SectionWrapper>
					<SectionWrapper>
						<InfoSection
							formData={formData}
							handleFormData={handleFormData}
							addInvoiceItem={addInvoiceItem}
							customErrors={customErrors}
							products={products}
						/>
					</SectionWrapper>
					<SectionWrapper>
						<ItemsSection
							handleInvoiceItemData={handleInvoiceItemData}
							removeInvoiceItem={removeInvoiceItem}
							currencySymbol={currencySymbol}
							handleFormData={handleFormData}
							addInvoiceItem={addInvoiceItem}
							invoiceItems={invoiceItems}
							customErrors={customErrors}
							setEditing={setEditing}
							formatter={formatter}
							editing={editing}
						/>
					</SectionWrapper>
					<SectionWrapper>
						<AmountSection
							formData={formData}
							customErrors={customErrors}
							currencySymbol={currencySymbol}
							formatter={formatter}
						/>
					</SectionWrapper>
					<SectionWrapper>
						<NotesSection formData={formData} handleFormData={handleFormData} customErrors={customErrors} />
					</SectionWrapper>
				</>
			) : (
				<Loading type='circle' />
			)}
		</PageWrapper>
	);

	function goBackToOrder() {
		navigate(`/orders/${orderUuid}`);
	}

	function addInvoiceItem(data) {
		let invoiceItemsLength = Object.keys(invoiceItems)?.length;
		let newItems = {
			...invoiceItems,
			[invoiceItemsLength + 1]: {
				...lineItemBase,
				...data,
				lineNumber: invoiceItemsLength + 1,
			},
		};
		setInvoiceItems(newItems);
		updateForm({
			...formData,
			invoiceItems: Object.values(newItems),
		});
		setEditing(invoiceItemsLength + 1);
	}

	function removeInvoiceItem(lineNumber) {
		let newItems = {
			...invoiceItems,
		};
		delete newItems[lineNumber];
		let invoiceTotal = calcInvoiceTotal({ invoiceItems: Object.values(newItems) });
		let invoiceTax = calcInvoiceTax({ invoiceItems: Object.values(newItems) });
		setInvoiceItems(newItems);
		updateForm({
			...formData,
			totalTax: invoiceTax,
			totalAmount: invoiceTotal,
			invoiceItems: Object.values(newItems),
		});
	}

	async function fetchInitData() {
		let order = await fetch(`${config.apiv1}/order/order.read/${orderUuid}`);
		let products = await fetch(`${config.apiv1}/product/products.read/${teamState?.currentTeam?.teamUuid}`);
		let buyerTeam = await fetch(`${config.apiv1}/team/team.read/${order?.buyerTeamUuid}`);
		let sellerTeam = await fetch(`${config.apiv1}/team/team.read/${order?.sellerTeamUuid}`);

		setOrder(order);
		setProducts(products);
		setBuyerTeam(buyerTeam);
		setSellerTeam(sellerTeam);
	}

	function calcInvoiceTotal(invoice) {
		let reduced = invoice?.invoiceItems?.reduce((totalVal, item) => {
			return totalVal + Number(item?.totalAmount);
		}, 0);
		return reduced;
	}

	function calcInvoiceTax(invoice) {
		let reduced = invoice?.invoiceItems?.reduce((totalVal, item) => {
			return totalVal + Number(item?.taxAmount);
		}, 0);
		return reduced;
	}

	async function createInvoiceItems(invoice, invoiceItems) {
		if (invoiceItems?.length === 0) {
			return;
		}
		async function addRequest(url, data) {
			return await Axios({
				url: url,
				method: 'POST',
				data: data,
			});
		}

		let reqs = [];

		for (let i = 0; i < invoiceItems.length; i++) {
			reqs.push(
				addRequest(`${config.apiv1}/invoice/invoice-item.create`, {
					...invoiceItems[i],
					invoiceUuid: invoice?.invoiceUuid,
				})
			);
		}

		if (reqs.length > 0) {
			try {
				await Axios.all(reqs).then(() => {
					navigate(`/invoices/${invoice?.invoiceUuid}`);
				});
			} catch (err) {
				return err;
			}
		}
	}
}

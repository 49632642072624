import React, { useContext, useEffect, useMemo, useState } from 'react';
import KanbanView from './KanbanView';
import useMultiFetch from '../../hooks/useMultiFetch';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import config from '../../config';
import { parseProcessingStatus } from '../../helpers';
import ManageWrap from '../../components/Wrappers/ManageWrap';
import { ProgressIcon } from '../../components/SVGIcons/SVGIcons';
import FilterTabs from '../../components/Listing/FilterTabs';
import Axios from 'axios';

export default function TeamJobs() {
	const { teamState } = useContext(CurrentTeamContext);

	let defaultOptions = [
		{
			key: 'pending',
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=1`,
			status: 1,
		},
		{
			key: 'accepted',
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=2`,
			status: 2,
		},
		{
			key: 'processing',
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=3`,
			status: 3,
		},
		{
			key: 'completed',
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=4`,
			status: 4,
		},
		{
			key: 'dispatched',
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=5`,
			status: 5,
		},
		{
			key: 'delivered',
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=6`,
			status: 6,
		},
		{
			key: 'rejected',
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=9`,
			status: 9,
		},
	];

	const { data, fetch, setData, handleCustomOptions } = useMultiFetch(defaultOptions);

	const [localFilter, setLocalFilter] = useState(null);
	const [selectedTab, setSelectedTab] = useState({ left: null });
	const [parsedOrders, setParsedOrders] = useState(null);
	const [colsOpen, setColsOpen] = useState({
		pending: false,
		accepted: false,
		processing: true,
		completed: true,
		dispatched: true,
		delivered: false,
		rejected: false,
	});

	const [btnLoading, setBtnLoading] = useState({});
	// const [handlingScroll, setHandlingScroll] = useState(false);

	function handleColsOpen(colRef) {
		let colArr = Object.keys(colsOpen);
		let colsCurrOpen = colArr.filter((col) => colsOpen[col] === true);
		let newColsOpen = { ...colsOpen, [colRef]: !colsOpen[colRef] };

		if (colsCurrOpen?.length > 2 && newColsOpen[colRef] === true) {
			let rightmostOpen = colArr.findIndex((col) => colsCurrOpen[colsCurrOpen?.length + 1] === col);
			let leftmostOpen = colArr.findIndex((col) => colsCurrOpen[0] === col);
			let colPos = colArr.findIndex((col) => colRef === col);

			if (colPos < leftmostOpen) {
				newColsOpen[colsCurrOpen[colsCurrOpen?.length - 1]] = false;
			} else if (colPos > rightmostOpen) {
				newColsOpen[colsCurrOpen[0]] = false;
			}
		}

		setColsOpen(() => ({ ...newColsOpen }));
	}

	function handleFilter(tabKey, filter) {
		let newOptions = [];
		for (let option of defaultOptions) {
			newOptions.push({
				key: option?.key,
				url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=${
					option?.status
				}${filter || ''}`,
			});
		}
		handleCustomOptions(newOptions);
		setLocalFilter(null);
		setSelectedTab((selected) => ({ ...selected, left: tabKey }));
	}

	async function loadNextOrders(status) {
		setBtnLoading(() => ({
			[status]: true,
		}));

		let res = await Axios({
			url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&status=${parsedOrders[status]?.options?.status}&skip=${parsedOrders[status]?.items?.length}`,
			method: 'GET',
		});
		if (!parsedOrders[status] || !res?.data?.data) {
			return setBtnLoading(() => ({
				[status]: false,
			}));
		}

		let lists = document.getElementsByClassName('list');
		let newOrders = {};

		newOrders = { ...parsedOrders[status] };
		newOrders.items = [...newOrders?.items, ...res?.data?.data];

		setParsedOrders((orders) => ({
			...orders,
			[status]: { ...newOrders },
		}));

		for (let i = 0; i < lists.length; i++) {
			if (lists[i].id === status) {
				lists[i].scrollTo({
					top: lists[i].scrollHeight,
					left: 0,
					behavior: 'smooth',
				});
			}
		}

		setTimeout(() => {
			if (localFilter) {
				localFilter({
					...parsedOrders,
					[status]: { ...newOrders },
				});
			}
			setBtnLoading(() => ({
				[status]: false,
			}));
		}, 300);
	}

	// async function handleColScroll(e) {
	// 	if (handlingScroll === false) {
	// 		setHandlingScroll(true);
	// 	}
	// 	if (e.target) {

	// 	}
	// 	let res = await Axios({
	// 		url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?teamType=2&limit=4&skip=${
	// 			parsedOrders[e.target.id]?.items?.length + 4
	// 		}&status=${parsedOrders[e.target.id]?.options?.status}`,
	// 		method: 'GET',
	// 	});
	// }

	async function handleLocalFilter(tabKey, filter, orders) {
		let newParsedOrders = { ...orders };
		switch (filter) {
			case 'assignee':
				for (let orderStatus in newParsedOrders) {
					newParsedOrders[orderStatus]?.items?.sort((a, b) => {
						if (a.sellerUserName < b.sellerUserName) {
							return 1;
						} else if (a.sellerUserName > b.sellerUserName) {
							return -1;
						} else if (!a.sellerUserName && b.sellerUserName) {
							return 1;
						} else if (a.sellerUserName && !b.sellerUserName) {
							return -1;
						}
						return 0;
					});
				}
				break;
		}
		setParsedOrders(() => newParsedOrders);
		setSelectedTab((selected) => ({ ...selected, left: tabKey }));
	}

	function filterAndSetOptions(status, orders, statusStr, parsedOrders) {
		let obj = { items: orders?.data, totalDocs: orders?.totalDocuments };

		if (!status) {
			return obj;
		}

		if (parsedOrders && orders.data?.length < parsedOrders[statusStr]?.items?.length) {
			return parsedOrders[statusStr];
		}

		let parsedStatus = parseProcessingStatus(status, 'kanbanIcon');

		obj.options = {
			status: status,
			headerText: parsedStatus?.text,
			icon: parsedStatus?.iconInverted,
			statusColor: parsedStatus?.color,
		};

		return obj;
	}

	useEffect(() => {
		if (data) {
			let newParsedOrders = {
				pending: filterAndSetOptions(1, data?.pending?.data, 'pending', parsedOrders),
				accepted: filterAndSetOptions(2, data?.accepted?.data, 'accepted', parsedOrders),
				processing: filterAndSetOptions(3, data?.processing?.data, 'processing', parsedOrders),
				completed: filterAndSetOptions(4, data?.completed?.data, 'completed', parsedOrders),
				dispatched: filterAndSetOptions(5, data?.dispatched?.data, 'dispatched', parsedOrders),
				delivered: filterAndSetOptions(6, data?.delivered?.data, 'delivered', parsedOrders),
				rejected: filterAndSetOptions(9, data?.rejected?.data, 'rejected', parsedOrders),
			};
			setParsedOrders(newParsedOrders);
			if (localFilter) {
				localFilter(newParsedOrders);
			}
		}
	}, [data, colsOpen]);

	return (
		<>
			<ManageWrap
				backColor='white'
				style={window.innerWidth > 768 ? { padding: '36px 70px' } : null}
				header={{ text: 'Progress board', icon: <ProgressIcon iconClass='headerIcon' /> }}>
				<FilterTabs
					selectedTab={selectedTab}
					leftGroup={[
						{
							text: 'By assignee',
							onClick: () => {
								setLocalFilter(() => (orders) => handleLocalFilter(0, 'assignee', orders));
								handleCustomOptions();
							},
						},
						{
							text: 'By date',
							onClick: () => {
								handleFilter(1, '&sortDispatchDate=desc');
							},
						},
						{
							text: 'My assignments',
							onClick: () => {
								handleFilter(2, '&assignment=sellerAssigned');
							},
						},
					]}
				/>
				<KanbanView
					orders={parsedOrders}
					colsOpen={colsOpen}
					handleColsOpen={handleColsOpen}
					btnLoading={btnLoading}
					loadNextOrders={loadNextOrders}
					// handleColScroll={handleColScroll}
				/>
			</ManageWrap>
		</>
	);
}

import React from 'react';
import { addCurrencySpace } from '../../../helpers';

export default function AmountSection({ formData, formatter }) {
	return (
		<div className='amountSection'>
			<div className='row'>
				<p className='label'>VAT</p>
				<p className='amount'>{addCurrencySpace(formatter.format(formData?.totalTax || 0))}</p>
			</div>
			<div className='row bold'>
				<p className='label'>Total</p>
				<p className='amount'>{addCurrencySpace(formatter.format(formData?.totalAmount || 0))}</p>
			</div>
		</div>
	);
}

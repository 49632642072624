import React from 'react';
import Button from '../../Button';
import { ArrowLeftIcon, ArrowNarrowRightIcon } from '../../SVGIcons/SVGIcons';

export default function StepNav(props) {
	const { next, prev, skip, saveDraft, postForm, settings, disabled } = props || {};
	return (
		<div className='btnCont'>
			{settings?.prev && (
				<Button
					text='Go back'
					style='tertiary-secondary'
					iconLeft={<ArrowLeftIcon />}
					size='md'
					type='button'
					minWidth={164}
					disabled={disabled && disabled[settings.ref]?.prev}
					onClick={prev}
				/>
			)}
			<div className='primaryBtns'>
				{settings?.skip && (
					<Button
						text='Skip'
						style='secondary'
						iconRight={<ArrowNarrowRightIcon />}
						size='md'
						type='button'
						minWidth={164}
						disabled={disabled && disabled[settings.ref]?.skip}
						onClick={skip}
					/>
				)}
				{settings?.next && (
					<Button
						text='Next'
						style='primary'
						iconRight={<ArrowNarrowRightIcon />}
						size='md'
						type='button'
						minWidth={164}
						disabled={disabled && disabled[settings.ref]?.next}
						onClick={() => next(settings.stepNumber)}
					/>
				)}
				{settings?.submit && (
					<>
						{settings?.saveDraft && (
							<Button
								text='Save as draft'
								style='secondary'
								size='md'
								type='button'
								minWidth={164}
								onClick={() => {
									postForm('draft');
								}}
								disabled={disabled && disabled[settings.ref]?.saveDraft}
							/>
						)}
						<Button
							text='Submit'
							style='primary'
							size='md'
							type='button'
							minWidth={164}
							disabled={disabled && disabled[settings.ref]?.submit}
							onClick={postForm}
						/>
					</>
				)}
			</div>
		</div>
	);
}

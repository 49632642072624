import React from 'react';
import { UploadIcon } from '../SVGIcons/SVGIcons';
import AutofillInput from './AutofillInput';

export default function FileInput(props) {
	const { text, name, multiple, callback, disabled, btnText, autofill, handleParentFiles, required, preview, thin } =
		props || {};
	return (
		<>
			{!thin ? (
				<div className='formRowCenter'>
					{required && <div isrequired={name}></div>}
					<div className='fieldTextContainer'>
						<p className='extraBold' htmlFor={name}>
							{text}
						</p>
					</div>
					<div className={`fileInput${preview || disabled ? ' disabled' : ''}`}>
						<input
							className='file'
							id={name}
							type='file'
							name={name}
							onChange={(e) =>
								multiple ? callback(e.target.files, name) : callback(e.target.files[0], name)
							}
							disabled={disabled || preview}
							multiple={multiple}
							value=''
						/>
						<label htmlFor={name}>
							<UploadIcon iconClass='uploadIcon' />
							{btnText}
						</label>
					</div>
					{autofill && (
						<AutofillInput callback={handleParentFiles} customCallbackValue={parentOrder} name={name} />
					)}
				</div>
			) : (
				<>
					{required && <div isrequired={name}></div>}
					<div className={`fileInput${preview ? ' disabled' : ''} thin`}>
						<input
							className='file'
							id={name}
							type='file'
							name={name}
							onChange={(e) =>
								multiple ? callback(e.target.files, name) : callback(e.target.files[0], name)
							}
							disabled={disabled || preview}
							multiple={multiple}
							value=''
						/>
						<label htmlFor={name}>
							<UploadIcon iconClass='uploadIcon' />
						</label>
					</div>
					{autofill && (
						<AutofillInput callback={handleParentFiles} customCallbackValue={parentOrder} name={name} />
					)}
				</>
			)}
		</>
	);
}

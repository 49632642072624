import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { ArrowNarrowRightIcon, KanbanIcon } from '../../components/SVGIcons/SVGIcons';

export default function DashboardWidget(props) {
	const {
		color,
		icon,
		linkBtn,
		linkBtn2,
		header,
		body,
		type,
		disabled,
		url,
		url2,
		linkType,
		callback,
		noFooter,
		disabledBtn,
	} = props || {};

	let navigate = useNavigate();
	return (
		<>
			<div className={`overviewItem${disabled ? ' disabledDiv' : ''}`}>
				<div className={`overviewItemHeader`} style={{ backgroundColor: `var(--${color || 'gray'})` }}>
					<h3>{header || ''}</h3>
					<div className='headerIcon'>{icon || ''}</div>
				</div>
				<div className='overviewItemBody'>
					{body || ''}
					{noFooter ? (
						<></>
					) : (
						<div className='overviewItemFooter'>
							{linkBtn && linkType === 'sidebarRight' && (
								<Button
									size='md'
									text={linkBtn || ''}
									style='secondary'
									onClick={() => {
										callback();
									}}
									iconRight={<ArrowNarrowRightIcon />}
									minWidth={60}
									disabled={disabledBtn}
									fill></Button>
							)}
							{linkBtn && linkType === 'link' && (
								<Button
									size='md'
									onClick={() => navigate(`/${url || ''}${type ? `?type=${type}` : ''}`)}
									text={linkBtn || ''}
									style='secondary'
									iconRight={<ArrowNarrowRightIcon />}
									minWidth={60}
									disabled={disabledBtn}
									fill></Button>
							)}
							{linkBtn && !linkType && (
								<button className='dashboardBtnSeeAll disabledDivWithShadow' disabled={disabledBtn}>
									{linkBtn || ''}
								</button>
							)}
							{linkBtn2 && linkType === 'sidebarRight' && (
								<Button
									onClick={() => {
										callback();
									}}
									size='md'
									text={linkBtn2 || ''}
									style='secondary'
									minWidth={60}
									disabled={disabledBtn}
									fill></Button>
							)}
							{linkBtn2 && linkType === 'link' && (
								<Button
									size='md'
									onClick={() => navigate(`/${url2 || ''}${type ? `?type=${type}` : ''}`)}
									text={linkBtn2 || ''}
									style='secondary'
									iconLeft={<KanbanIcon />}
									minWidth={60}
									disabled={disabledBtn}
									fill></Button>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
}

import React, { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { dateConverter, parseProcessingStatus, timestampConverter } from '../../helpers';
import Loading from '../../components/Loading/Loading';
import EmptyState from '../../components/EmptyState/EmptyState';

export default function History({ orderUuid, teamUuid }) {
	const { fetch } = useFetch();
	const [logs, setLogs] = useState([]);
	const [loading, setLoading] = useState(true);
	const { t, i18n } = useTranslation('log');

	useEffect(async () => {
		let data = await fetch(`${config.apiv1}/log/logs.read/${teamUuid}?orderUuid=${orderUuid}`);
		setLogs(data);
		setLoading(false);
	}, []);

	return (
		<div className='historyLog'>
			{loading ? (
				<Loading type='circle' />
			) : (
				<>
					{logs?.length === 0 ? <EmptyState header='No logs found for order' /> : <></>}
					{logs?.map((log, key) => {
						if (log.logType === 'order.create' || log.logType === 'order.update.changerequest') {
							return (
								<div key={key} className='logItem'>
									<p>{dateConverter(log.createdAt, 'DD/MM/YYYY - hh:mm')}</p>
									<h4 key={log.logUuid}>{t(`${log.logType}`)}</h4>
								</div>
							);
						} else if (log.logType === 'order.update.status') {
							return (
								<div key={key} className='logItem'>
									<p>{dateConverter(log.createdAt, 'DD/MM/YYYY - hh:mm')}</p>
									<h4 key={log.logUuid}>
										{t(`${log.logType}`)
											?.replace(
												'{{fromStatus}}',
												parseProcessingStatus(log?.logData?.fromStatus)?.text
											)
											?.replace(
												'{{toStatus}}',
												parseProcessingStatus(log?.logData?.toStatus)?.text
											)}
									</h4>
								</div>
							);
						} else if (log.logType === 'order.update.assignment') {
							return (
								<div key={key} className='logItem'>
									<p>{dateConverter(log.createdAt, 'DD/MM/YYYY - hh:mm')}</p>
									<h4 key={log.logUuid}>
										{t(`${log.logType}`)?.replace(
											'{{assignmentUserName}}',
											log?.logData?.assignmentUserName
										)}
									</h4>
								</div>
							);
						} else if (log.logType === 'message.create') {
							return (
								<div key={key} className='logItem'>
									<h4 key={log.logUuid}>
										{t(`${log.logType}`)
											?.replace('{{messageSender}}', log.logData?.messageSender)
											?.replace('{{messageBody}}', log.logData?.messageBody)}
									</h4>
								</div>
							);
						} else if (log.logType === 'file.create') {
							return (
								<div key={key} className='logItem'>
									<h4 key={log.logUuid}>
										{t(`${log.logType}`)?.replace('{{filename}}', log.logData?.filename)}
									</h4>
								</div>
							);
						} else if (log.logType === 'comment.create') {
							return (
								<div key={key} className='logItem'>
									<h4 key={log.logUuid}>
										{t(`${log.logType}`)?.replace('{{commentBody}}', log.logData?.commentBody)}
									</h4>
								</div>
							);
						}
					})}
				</>
			)}
		</div>
	);
}

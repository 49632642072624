import React, { useEffect } from 'react';

export default function Terms() {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.async = true;
		document.body.appendChild(script);
	}, []);

	return (
		<div
			className='termlyCont'
			name='termly-embed'
			data-id='d5e305f2-8508-42e6-b3ca-92feee0fa28a'
			data-type='iframe'></div>
	);
}

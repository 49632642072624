import React, { useContext } from 'react';
import Loading from '../../Loading/Loading';
import Button from '../../Button';
import { ArrowNarrowRightIcon } from '../../SVGIcons/SVGIcons';
import { dateConverter, parseProcessingStatus } from '../../../helpers';
import { useNavigate } from 'react-router';
import EmptyState from '../../EmptyState/EmptyState';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';

export default function InvoiceListItems(props) {
	const { listItems, loading, isSeller } = props || {};

	let navigate = useNavigate();

	const { teamState } = useContext(CurrentTeamContext);

	return (
		<div className='listOuterCont'>
			<div className='list'>
				<div className='listHeader'>
					<h4>Date</h4>
					<h4>No</h4>
					<h4>Customer</h4>
					<h4>Amount</h4>
					<h4>Status</h4>
					<h4></h4>
				</div>
				{!loading ? (
					<>
						{listItems && listItems?.length !== 0 ? (
							listItems.map((item, key) => {
								if (teamState?.currentTeam?.teamUuid === item.sellerTeamUuid && !item.buyerTeamName) {
									item.buyerTeamName = 'Personal';
								}
								return (
									<div
										key={key}
										className='item'
										onTouchEnd={() => {
											navigate(`/invoices/${item.invoiceUuid}`);
										}}>
										<p className='data'>{dateConverter(item.dueDate)}</p>
										<p className='data'>{item.docNumber}</p>
										<p className='data'>
											{teamState?.currentTeam?.teamUuid === item.buyerTeamUuid
												? item.sellerTeamName
												: item.buyerTeamName}
										</p>
										<p className='data'>{item.totalAmount?.toFixed(2) || 0.0} £</p>
										<p className='data'>{item.status}</p>
										<p className='data'>
											<Button
												text='See details'
												size='md'
												style='tertiary-secondary'
												iconRight={<ArrowNarrowRightIcon />}
												onClick={() => {
													navigate(`/invoices/${item.invoiceUuid}`);
												}}
											/>
										</p>
									</div>
								);
							})
						) : (
							<EmptyState body='No invoices matched your search filters' header='No invoices found' />
						)}
					</>
				) : (
					<Loading type='circle' />
				)}
			</div>
		</div>
	);
}

import React, { useContext } from 'react';
import NumberSplitInput from '../../Inputs/NumberSplitInput';
import SelectInput from '../../Inputs/SelectInput';
import SelectCustom from '../../Select/SelectCustom';
import currencyOptions from '../../Options/currencyOptions';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';

export default function BuyerInfoSection({ order, formData, handleFormData, details }) {
	const { teamState } = useContext(CurrentTeamContext);

	return (
		<div className='buyerInfoSection'>
			<div>
				<h4>{order.buyerTeamName}</h4>
				<p>{order.shippingAddress?.addressLine1}</p>
				<p>{order.shippingAddress?.postalCode}</p>
				<p>{order.shippingAddress?.city}</p>
			</div>
			{!details ? (
				<div className='col'>
					<div className='invoiceInput'>
						<p>Payment Terms</p>
						<NumberSplitInput
							readOnly='days'
							name='paymentTerms'
							callback={handleFormData}
							defaultValue={formData.paymentTerms || 30}
						/>
					</div>
					<div className='invoiceInput'>
						<p>Currency</p>
						<SelectCustom
							thin
							callback={(e) => {
								handleFormData ? handleFormData(e.children, 'currencyCode') : null;
							}}
							fieldName='currencyCode'
							htmlOptions={currencyOptions}
							value={formData.currencyCode}
						/>
					</div>
				</div>
			) : (
				<div>
					<h4>{order.sellerTeamName}</h4>
					<p>
						{teamState?.currentTeam?.registeredAddress?.shippingAddress?.addressLine1 ||
							teamState?.currentTeam?.teamInfo?.addresses[0]?.addressLine1}
					</p>
					<p>
						{teamState?.currentTeam?.registeredAddress?.shippingAddress?.postalCode ||
							teamState?.currentTeam?.teamInfo?.addresses[0]?.postalCode}
					</p>
					<p>
						{teamState?.currentTeam?.registeredAddress?.shippingAddress?.city ||
							teamState?.currentTeam?.teamInfo?.addresses[0]?.city}
					</p>
				</div>
			)}
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import OrderFilterForm from '../../Forms/OrderFilterForm';
import { useLocation } from 'react-router';

export default function SidebarOrderFilter(props) {
	const { callback, contentReference } = props || {};

	const location = useLocation();

	const assignment = new URLSearchParams(location.search).get('assignment');

	const [filter, setFilter] = useState({
		sort: 'date.desc',
		status: contentReference?.status,
		assignment: assignment || 'all',
	});

	function clearFilter() {
		setFilter(() => ({
			sort: 'date.desc',
			status: undefined,
			assignment: 'all',
		}));
	}

	const handleFilterInput = (value, name) => {
		setFilter((filter) => ({
			...filter,
			[name]: value,
		}));
	};

	const handleFilterSelect = (value, name) => {
		setFilter((filter) => ({
			...filter,
			[name]: value?.value,
		}));
	};

	useEffect(() => {
		setFilter((filter) => ({
			...filter,
			assignment: assignment,
			status: contentReference.status,
		}));
	}, [contentReference.status]);

	return (
		<OrderFilterForm
			handleFilterSelect={handleFilterSelect}
			handleFilterInput={handleFilterInput}
			clearFilter={clearFilter}
			applyFilter={callback}
			filter={filter}
		/>
	);
}

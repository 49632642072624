import React from 'react';
import { TeamCircleIcon, UserCircleIcon } from '../SVGIcons/SVGIcons';
import Overlay from '../Overlay';
import { sliceString } from '../../helpers';

export default function TeamChooseModal(props) {
	const { currentTeamUuid, show, teams, callback } = props || {};
	return (
		<>
			{show && teams?.length > 0 && (
				<>
					<Overlay showOverlay loadOverlay />
					<div className='teamChooseModal'>
						<h2>
							<TeamCircleIcon iconClass='headerIcon' />
							Select your team
						</h2>
						<div className='choices'>
							{teams.map((team, key) => {
								return (
									<div
										className={
											currentTeamUuid === team.teamUuid ? 'teamChoice highlight' : 'teamChoice'
										}
										key={key}
										onClick={() => {
											callback(team.teamUuid);
										}}>
										{team.teamIcon ? (
											<img className='teamImg' src={team.teamIcon} alt='team icon' />
										) : (
											<>
												{team.teamInfo?.teamName ? (
													<TeamCircleIcon iconClass='choiceIcon' />
												) : (
													<UserCircleIcon iconClass='choiceIcon' />
												)}
											</>
										)}
										<h4>{sliceString(team.teamInfo.teamName, 0, 40, true) || 'Personal'}</h4>

										<button className='btn3-secondary hug'>Select</button>
									</div>
								);
							})}
						</div>
					</div>
				</>
			)}
		</>
	);
}

import React, { useContext, useEffect, useState, useCallback } from 'react';
import Listing from '../../components/Listing';
import { useLocation } from 'react-router';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';

export default function HistoryLog() {
	const { teamState } = useContext(CurrentTeamContext);
	const { rightSidebarDispatch } = useContext(RightSidebarContext);
	const { data, fetch, reqLoading } = useFetch();

	let location = useLocation();
	let orderRef = new URLSearchParams(location.search).get('orderRef');

	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState(orderRef);
	const [pages, setPages] = useState(0);
	const [filter, setFilter] = useState({
		limit: 7,
		skip: 0,
		pages: 0,
		sort: 'date.desc',
	});

	const options = {
		rightGroup: [{ text: '12 months' }, { text: '30 days' }, { text: '1 week' }, { text: '24 hours' }],
		leftGroup: [{ text: 'All Trades' }, { text: 'Purchases' }, { text: 'Sales' }],
	};

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * filter?.limit);
		setLoading(true);
		handleFilter(newSkip, 'skip');
	}

	function resetPagination() {
		let paginationBtns = document.getElementsByClassName('paginationPage');
		for (let i = 0; i < paginationBtns.length; i++) {
			paginationBtns[i]?.classList.remove('paginationActive');
		}
		paginationBtns[0]?.classList.add('paginationActive');
	}

	function handleFilter(value, name) {
		if (name === 'status') {
			rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: value } });
		}
		if (name !== 'skip') {
			resetPagination();
		}

		setFilter((filter) => ({
			...filter,
			[name]: value,
		}));
	}

	const updateDebounceSearch = useCallback(
		debounce((searchValue) => {
			setSearch(searchValue);
		}),
		[]
	);

	function openFilter() {
		const contentType = 3;
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: filter?.status || presetStatus } });
		rightSidebarDispatch({
			type: 'SET_CALLBACK',
			payload: (filter) => {
				handleFilter(filter);
				rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
			},
		});
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: contentType });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
	}

	async function buildURLAndFetch(newSearch) {
		setLoading(true);
		const url = new URL(
			`${config.apiv1}/log/logs.read/${teamState?.currentTeam?.teamUuid}?${
				newSearch ? `orderRef=${newSearch}` : ''
			}`
		);
		filter?.sort?.split('.')[0] === 'date' && url.searchParams.set('sortDate', filter?.sort?.split('.')[1]);
		filter?.sort?.split('.')[0] === 'team' && url.searchParams.set('sortAlphabet', filter?.sort?.split('.')[1]);

		filter?.member?.value && url.searchParams.set('userUuid', filter?.member?.value);

		filter?.dateFrom && url.searchParams.set('dateFrom', filter?.dateFrom);
		filter?.dateTo && url.searchParams.set('dateTo', filter?.dateTo);
		search && url.searchParams.set('search', search);
		filter?.limit && url.searchParams.set('limit', filter?.limit);
		filter?.skip && url.searchParams.set('skip', filter?.skip);
		filter?.search && url.searchParams.set('orderRef', filter?.search);

		let res = await fetch(url.href, null, true);

		if (res?.ok) {
			setPages(res?.data && res.data.length > 0 ? Math.ceil(res?.totalDocuments / filter?.limit) : 0);
		} else {
			setPages(1);
		}

		setLoading(false);
	}

	function debounce(cb, delay = 400) {
		let timeout;
		return (...args) => {
			setLoading(true);
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	useEffect(() => {
		buildURLAndFetch(search);
	}, [teamState.reload, filter, search, location]);

	return (
		<Listing
			openFilter={openFilter}
			handlePagination={handlePagination}
			handleSearch={updateDebounceSearch}
			loading={reqLoading || loading}
			handleFilter={handleFilter}
			search={search}
			options={options}
			listItems={data ? data[0]?.data : null}
			filter={filter}
			pages={pages}
			type='logs'
		/>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { TutorialContext } from '../../contexts/TutorialContext';
import useModals from '../../hooks/useModals';
import usePost from '../../hooks/usePost';
import FeedbackForm from '../Forms/FeedbackForm';
import FlexModal from '../Modals/FlexModal';
import FadeOutModal from '../Modals/FadeOutModal';

export default function SupportDropdown(props) {
	const { setOpen, spaceBottom } = props || {};

	const { tutorialDispatch } = useContext(TutorialContext);
	const { userState } = useContext(CurrentUserContext);

	let location = useLocation();
	let navigate = useNavigate();

	const [feedback, setFeedback] = useState(null);
	const [prevLocation, setPrevLocation] = useState(location);

	const { modalsState, handleModal, handleFadeModal, flashFadeModal } = useModals();

	const { data, post, reqLoading, error, customErrors } = usePost(
		`${config.apiv1}/email/feedback`,
		() => {},
		'feedbackForm'
	);

	useEffect(() => {
		if (location && location !== prevLocation) {
			setOpen(false);
		} else {
			setPrevLocation(location);
		}
	}, [location]);

	return (
		<>
			<FadeOutModal
				show={modalsState['modalFading']?.show}
				isFading={modalsState['modalFading']?.isFading}
				message={modalsState['modalFading']?.message}
			/>
			{modalsState['feedbackModal']?.open && (
				<FlexModal
					setShow={handleModal}
					modalName={'feedbackModal'}
					zIndex={99}
					btn1='Send'
					closeBtn={true}
					updateLoading={reqLoading}
					callback={async () => {
						let res = await post({
							senderEmail: userState.currUser.email,
							senderName:
								userState.currUser.name ||
								`${userState.currUser.given_name} ${userState.currUser.family_name}`,
							feedbackBody: feedback,
						});
						flashFadeModal('Thank you for your feedback!');
						handleModal('feedbackModal', { open: false });
					}}
					body={<FeedbackForm callback={setFeedback} feedback={feedback} />}
				/>
			)}
			<div className={`dropdownFixed${spaceBottom ? ' spaceBottom' : ''}`}>
				<div className='supportCol'>
					<div className='section'>
						<Link to='/tutorials/en/getting-started'>
							<p
								onClick={() => {
									setOpen(false);
								}}>
								Getting started
							</p>
						</Link>
						<Link to='/tutorials/en/getting-started'>
							<p
								onClick={() => {
									setOpen(false);
								}}>
								Tutorials
							</p>
						</Link>
						<p
							onClick={() => {
								setOpen(false);
								tutorialDispatch({
									type: 'RESET',
									payload: {
										page: 'dashboard',
										data: {
											open: false,
											prompt: true,
											curr: 1,
										},
									},
								});
								location.pathname !== '/' ? navigate('/') : null;
							}}>
							Take the tour
						</p>
						<p
							onClick={() => {
								setOpen(false);
								tutorialDispatch({
									type: 'SET_SIGNPOSTING',
									payload: {
										page: 'dashboard',
										enabled: true,
									},
								});
							}}>
							Enable signposting
						</p>
					</div>
					<div className='section'>
						<p
							onClick={() => {
								handleModal('feedbackModal', { open: true });
							}}>
							Give feedback
						</p>
						<Link to='/tutorials/en/how-to-contact-support'>
							<p
								onClick={() => {
									setOpen(false);
								}}>
								Contact support
							</p>
						</Link>
					</div>
					<div className='section'>
						<Link to='/orders'>
							<p
								className='halfOpacity'
								onClick={(e) => {
									e.preventDefault();
									// setOpen(false);
								}}>
								Follow us
							</p>
						</Link>
						<Link to='/orders'>
							<p
								className='halfOpacity'
								onClick={(e) => {
									e.preventDefault();
									// setOpen(false);
								}}>
								Refer a friend
							</p>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

import React from 'react';

export default function AutofillInput(props) {
	const { callback, customCallbackValue, defaultValue, name } = props || {};
	return (
		<label className='checkboxLabelAbsolute'>
			<input
				type='checkbox'
				name={name}
				defaultValue={defaultValue || ''}
				onChange={(e) => {
					e.target.checked ? callback(customCallbackValue, name) : callback('', name);
				}}
				checked={customCallbackValue === defaultValue}
			/>
			<div className='customCheckbox'></div>
			Fill from linked order
		</label>
	);
}

import React from 'react';
import { useNavigate } from 'react-router';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';

export default function InviteTeamForm(props) {
	const { formData, handleFormData, customErrors, handleModal, formVis, setFormVis, teamOfOne } = props || {};
	const { recipientName, email, customText } = formData || {};

	let navigate = useNavigate();

	return (
		<div className='invitationContainer' id='inviteForm'>
			{!formVis && !teamOfOne ? (
				<>
					<h4>Who would you like to invite?</h4>
					<div className='btnCont'>
						<button
							className='btn3-secondary'
							onClick={() => {
								navigate('/manage/members');
								handleModal('inviteModal', false);
							}}>
							Team Member
						</button>
						<button
							className='btn3-secondary'
							onClick={() => {
								setFormVis(true);
							}}>
							Supplier
						</button>
						<button
							className='btn3-secondary'
							onClick={() => {
								setFormVis(true);
							}}>
							Customer
						</button>
					</div>
				</>
			) : (
				<>
					<h4>Invite a supplier to Procuur</h4>
					<form>
						<TextInput
							text={'Name of recipient: '}
							name={'recipientName'}
							type={'text'}
							defaultValue={recipientName}
							callback={handleFormData}
							required={true}
							customError={customErrors.recipientName}
						/>
						<TextInput
							text={'Email: '}
							name={'email'}
							type={'email'}
							defaultValue={email}
							callback={handleFormData}
							required={true}
							customError={customErrors.email}
						/>
						<TextareaInput
							text={'Custom message (optional):'}
							name={'customText'}
							defaultValue={customText}
							callback={handleFormData}
						/>
					</form>
				</>
			)}
		</div>
	);
}

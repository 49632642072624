import React from 'react';
import Section from './Section';
import logo from '../../../assets/img/hero_1.png';
import { CheckCircleIcon } from '../../../components/SVGIcons/SVGIcons';

export default function FeaturesList(props) {
	const {
		sectionHeader,
		paragraph1,
		paragraph2,
		icon,
		features,
		listHeader,
		listParagraph,
		border,
		align,
		showHeader,
		img,
	} = props || {};
	return (
		<Section border={border}>
			{showHeader && (
				<div className='col'>
					<h4 className='header marginBottom-20'>{sectionHeader}</h4>
					<p className='paragraph marginBottom-20'>{paragraph1}</p>
					<p className='paragraph'>{paragraph2}</p>
				</div>
			)}
			<div className={`row${align === 'right' ? ' right' : ''}`}>
				{align === 'right' && <img className='screengrab' src={img} alt='screengrab'></img>}
				<div className='featuresList'>
					<div>
						<div className='dualTurqBorderIcon'>{icon}</div>
						<h2 className='header'>{listHeader}</h2>
						<p className='paragraph'>{listParagraph}</p>
					</div>
					<div className='listItems'>
						{features &&
							features.length > 0 &&
							features.map((feature, key) => {
								return (
									<div className='feature' key={key}>
										<CheckCircleIcon iconClass='icon' />
										<p className='paragraph'>{feature}</p>
									</div>
								);
							})}
					</div>
				</div>
				{align === 'left' && <img className='screengrab' src={img} alt='screengrab'></img>}
			</div>
		</Section>
	);
}

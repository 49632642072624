import React from 'react';

export default function Loading(props) {
	return (
		<>
			{props.type === 'facebook' && (
				<div className={`lds-facebook${props.size ? ` ${props.size}` : ''}`}>
					<div></div>
					<div></div>
					<div></div>
				</div>
			)}
			{props.type === 'circle' && (
				<div
					className={`lds-dual-ring${props.size ? ` ${props.size}` : ''}${
						props.size === 'btnLoad' ? ' btnLoad' : ''
					}`}
					style={{ margin: props.center ? 'auto' : '' }}></div>
			)}
		</>
	);
}

import React from 'react';
import ListField from './ListField';
import { Droppable } from 'react-beautiful-dnd';
import Button from '../../../components/Button';

export default function ToolsCol(props) {
	const { handleModal, patchDocket, setSelected, changeMade, selected, column, chosenDocket } = props || {};

	return (
		<div className='fixedToolsCont'>
			<div className='toolTop'>
				<div className='flexRow'>
					<Button
						text={chosenDocket?.published ? 'Save changes' : 'Save as draft'}
						size='lg'
						style='secondary'
						minWidth={200}
						disabled={!changeMade}
						onClick={() => {
							patchDocket();
						}}
					/>
					{!chosenDocket?.published && (
						<Button
							text='Publish this form'
							size='lg'
							style='primary'
							minWidth={200}
							onClick={() => {
								patchDocket({ published: true }, true);
							}}
						/>
					)}
					{/* <button
						className='btn2-medium'
						onClick={() => {
							setIsEditing(false);
						}}>
						Publish this form
					</button> */}
				</div>
				<div className='tabs'>
					<div
						className={`tab${selected === 1 ? ' selected' : ''}`}
						onClick={() => {
							setSelected(1);
						}}>
						<span>Widgets</span>
					</div>
					<div
						className={`tab${selected === 2 ? ' selected' : ''}`}
						onClick={() => {
							setSelected(2);
						}}>
						<span>Blocks</span>
					</div>
				</div>
			</div>
			<div className={`col library`}>
				<Droppable droppableId={column.id} direction='horizontal' isDropDisabled={column.immutable === true}>
					{(provided) => (
						<div className='dropSection' ref={provided.innerRef} {...provided.droppableProps}>
							{column.fields.map((field, key) => {
								return (
									<React.Fragment key={field.id}>
										<ListField
											index={key}
											field={field}
											handleModal={handleModal}
											droppableId={column.id}
										/>
									</React.Fragment>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		</div>
	);
}

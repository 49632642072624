const metaFieldsByPage = {
	order: {
		title: 'Procuur - Order {{ORDER_REF}}',
		description: 'Order details page for viewing a singular Procuur order and any relevant content to that order.',
		path: '/orders/',
	},
	dashboard: {
		title: 'Procuur - Dashboard',
		description: 'Dashboard providing an overview of key metrics and activities within Procuur.',
		path: '/',
	},
	tutorials: {
		title: 'Procuur - Tutorials',
		description: 'Collection of tutorials and guides to help navigate and utilize Procuur efficiently.',
		path: '/tutorials',
	},
	features: {
		title: 'Procuur - Features',
		description: 'Explore the various features and functionalities available in Procuur.',
		path: '/features',
	},
	addresses: {
		title: 'Procuur - Addresses',
		description: 'Manage and view address-related information within Procuur.',
		path: '/addresses',
	},
	orderForm: {
		title: 'Procuur - Order Form',
		description: 'Form used for placing orders and specifying details within Procuur.',
		path: '/order',
	},
	orders: {
		title: 'Procuur - Orders',
		description: 'View all Procuur orders and related content.',
		path: '/orders',
	},
	packageDetails: {
		title: 'Procuur - Package Details',
		description: 'Details page for packages sent and received via Courier.',
		path: '/package',
	},
	apps: {
		title: 'Procuur - Apps',
		description: 'Explore Procuur applications and related content.',
		path: '/manage/apps',
	},
	dispatch: {
		title: 'Procuur - Dispatch',
		description: 'Dispatch page listing packages sent and received via Courier',
		path: '/packages',
	},
	teamManage: {
		title: 'Procuur - Team Management',
		description: 'Manage various aspects of teams in Procuur.',
		path: '/manage',
	},
	teamEdit: {
		title: 'Procuur - Edit Team',
		description: 'Edit Procuur team settings and information.',
		path: '/manage/team',
	},
	teamFiles: {
		title: 'Procuur - Files',
		description: 'Access Procuur team files and related content.',
		path: '/manage/files',
	},
	teamInvoices: {
		title: 'Procuur - Invoices',
		description: 'View Procuur team invoices information',
		path: '/manage/invoices',
	},
	teamJobs: {
		title: 'Procuur - Progress board',
		description: 'Manage job-related activities within Procuur.',
		path: '/progress',
	},
	teamList: {
		title: 'Procuur - Suppliers',
		description: 'List of suppliers and related information within Procuur.',
		path: '/suppliers',
	},
	manageTeamMembers: {
		title: 'Procuur - Manage Team Members',
		description: 'Manage Procuur team members and permissions.',
		path: '/manage/members',
	},
	teamShop: {
		title: 'Procuur - Shop',
		description: 'Access a Procuur team shop, its relevant information and available products.',
		path: '/suppliers/',
	},
	registerTeam: {
		title: 'Procuur - Register Team',
		description: 'Register a new team within Procuur.',
		path: '/team-registration',
	},
	tools: {
		title: 'Procuur - Tools',
		description: 'Access Procuur tools and utilities.',
		path: '/manage/toolbox',
	},
	customForms: {
		title: 'Procuur - Custom Forms',
		description: 'Manage and create custom forms within Procuur.',
		path: '/manage/toolbox/form-builder',
	},
	teamTrustedSuppliers: {
		title: 'Procuur - Trusted Suppliers',
		description: 'View and manage trusted suppliers for Procuur teams.',
		path: '/manage/trusted',
	},
};

module.exports = metaFieldsByPage;

import React, { useContext } from 'react';
import { CaretRightIcon } from '../SVGIcons/SVGIcons';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';

export default function Filter(props) {
	const { filterTypes, callback, presets } = props || {};

	const { rightSidebarDispatch } = useContext(RightSidebarContext);

	function fillAndOpenRightSidebar(type) {
		const contentType = 3;
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: presets });
		rightSidebarDispatch({ type: 'SET_CALLBACK', payload: callback });
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: contentType });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
		rightSidebarDispatch({ type: 'SET_TEXTBOX_OPEN', payload: { boxRef: type, boxOpen: true } });
	}

	return (
		<>
			<div className='filterCont'>
				<div
					className='filterType'
					onClick={() => {
						fillAndOpenRightSidebar();
					}}>
					<h4>Advanced search</h4>
					<CaretRightIcon iconClass='caretRightIconGray' />
				</div>

				{/* {filterTypes.length > 0 ? (
					filterTypes.map((filterType, key) => {
						return (
							<div
								className='filterType'
								key={key}
								onClick={() => {
									fillAndOpenRightSidebar(filterType.type);
								}}>
								<h4>{filterType.text}</h4>
								<CaretRightIcon iconClass='caretRightIconGray' />
							</div>
						);
					})
				) : (
					<></>
				)} */}
			</div>
		</>
	);
}

import React, { useEffect, useState, useContext } from 'react';
import { dateConverter, parseProcessingStatus, parseTrackingStatus, timestampConverter } from '../../helpers';
import { useNavigate } from 'react-router';
import useWindowSize from '../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import Loading from '../../components/Loading/Loading';
import Button from '../../components/Button';
import { ArrowNarrowRightIcon, CloseIcon, CloseXIcon, DownloadIcon } from '../../components/SVGIcons/SVGIcons';
import EmptyState from '../../components/EmptyState/EmptyState';
import { itemLabelTemp } from '../../templates/templates';
import Axios from 'axios';
import config from '../../config';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import { ModalContext } from '../../contexts/ModalContext';

export default function ItemsList(props) {
	const { listItems, pack, loading, reload } = props || {};
	const [lastMousePress, setLastMousePress] = useState(null);
	const [newMousePress, setNewMousePress] = useState(null);
	const [loadingEnrichment, setLoadingEnrichment] = useState(false);
	const [enrichedListItems, setEnrichedListItems] = useState([]);

	let navigate = useNavigate();

	const { showModal, hideModal } = useModalsGlobal();
	const { flashFadeModal } = useContext(ModalContext);

	const { teamState } = useContext(CurrentTeamContext);

	const { width } = useWindowSize();

	function onTouchStart(e) {
		setLastMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	function onTouchMove(e) {
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	function onTouchEnd(e, item) {
		if (newMousePress?.x === lastMousePress?.x && newMousePress?.y === lastMousePress?.y) {
			navigate(`/packages/${item.packageUuid}`);
		}
	}

	function getFilePath(file) {
		let res = Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			return url;
		});
		return res;
	}

	async function removeItem(itemUuid) {
		const data = {
			packageUuid: 'unset',
		};

		try {
			const res = await Axios({
				url: `${config.apiv1}/order/item.update/${itemUuid}`,
				method: 'PATCH',
				data: data,
			});
			if (res.data?.ok) {
				setTimeout(() => {
					flashFadeModal('Order item removed successfully');

					reload(true);
				}, 500);
			}
		} catch (err) {
			setTimeout(() => {
				flashFadeModal('Error when removing item');
			}, 500);
			return err;
		}
	}

	function removeItemPrompt(itemUuid) {
		showModal('confirmChoice', {
			callback: () => removeItem(itemUuid),
			header: 'Are you sure you want to remove this item?',
			open: true,
		});
	}

	useEffect(() => {
		if (listItems) {
			let members = teamState?.currentTeam?.members?.filter((member) => member.firstname && member.lastname);
			let enrichedListItems = [...listItems];
			enrichedListItems.forEach((item) => {
				let matchingMember = members.filter((member) => member.userUuid === item.userUuid);
				item.user = matchingMember[0];
			});
			setEnrichedListItems(enrichedListItems);
		}

		setLoadingEnrichment(false);
	}, [listItems]);

	return (
		<div className='listOuterCont'>
			<div className='list'>
				<div className='listHeader'>
					<h4>Reference</h4>
					{/* <h4>Buyer</h4> */}
					{/* <h4>Description</h4> */}
					<h4>Note</h4>
					<h4>Status</h4>
					<h4>
						<Button
							style='tertiary-secondary'
							maxWidth={width > 1028 ? 140 : 40}
							minWidth={width > 1028 ? 140 : 40}
							disabled
						/>
					</h4>
					<h4></h4>
				</div>
				{!loading ? (
					<>
						{listItems && listItems?.length !== 0 ? (
							<>
								{listItems.map((item, key) => {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}
											onTouchEnd={(e) => onTouchEnd(e, item)}>
											<p className='data'>{item.orderRef || ''}</p>
											{/* <p className='data'>{item.buyerUserName || ''}</p> */}
											{/* <p className='data'>{item.description || ''}</p> */}
											<p className='data'>{item.note || ''}</p>
											<p className='data'>
												{pack?.locations
													? `With ${parseTrackingStatus(
															pack?.locations[pack?.locations?.length - 1]?.status
													  )?.text?.toLocaleLowerCase()}`
													: ''}
											</p>
											<p className='data'>
												<Button
													text={width > 1028 ? 'Label' : null}
													size='md'
													style='tertiary-secondary'
													iconLeft={<DownloadIcon />}
													maxWidth={width > 1028 ? 140 : 40}
													minWidth={width > 1028 ? 140 : 40}
													onClick={() => {
														itemLabelTemp(item, getFilePath, true, { x: 6.5, y: 3.5 });
													}}
												/>
											</p>
											<p className='data'>
												<Button
													size='md'
													style='tertiary-secondary'
													iconLeft={<CloseXIcon />}
													maxWidth={40}
													minWidth={40}
													onClick={() => {
														removeItemPrompt(item.itemUuid);
													}}
												/>
											</p>
										</div>
									);
								})}
							</>
						) : (
							<EmptyState header='No items in package' />
						)}
					</>
				) : (
					<Loading type='circle' />
				)}
			</div>
		</div>
	);
}

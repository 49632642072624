import React from 'react';

export default function OrderLMInfo(props) {
	const { order } = props?.props || {};
	let lmData = order?.integrations?.transactor?.labmanager?.appData;

	return (
		<div className='gridItemOrder labmanager'>
			<div className='row highlight'>
				<h4 className='gridHeader'>LabManager</h4>
			</div>
			{lmData && (
				<div className='col marginLeft-10 marginRight-10'>
					{lmData.invoiceNo ? (
						<div className='row'>
							<p className='text'>Invoice number: </p>
							<div className='data bold'>{lmData?.invoiceNo}</div>
						</div>
					) : (
						<></>
					)}
					{lmData.orderNo ? (
						<div className='row'>
							<p className='text'>Reference: </p>
							<div className='data bold'>{lmData?.orderNo}</div>
						</div>
					) : (
						<></>
					)}
					{lmData.name ? (
						<div className='row'>
							<p className='text'>Name: </p>
							<div className='data bold'>{lmData?.name}</div>
						</div>
					) : (
						<></>
					)}
					{lmData.practice ? (
						<div className='row'>
							<p className='text'>Practice: </p>
							<div className='data bold'>{lmData?.practice}</div>
						</div>
					) : (
						<></>
					)}
					{lmData.netTotal ? (
						<div className='row'>
							<p className='text'>NET Total: </p>
							<div className='data bold'>{lmData?.netTotal}</div>
						</div>
					) : (
						<></>
					)}
					{lmData.taxTotal ? (
						<div className='row'>
							<p className='text'>TAX Total: </p>
							<div className='data bold'>{lmData?.taxTotal}</div>
						</div>
					) : (
						<></>
					)}
					{lmData.balance ? (
						<div className='row'>
							<p className='text'>Balance: </p>
							<div className='data bold'>{lmData?.balance}</div>
						</div>
					) : (
						<></>
					)}
				</div>
			)}
		</div>
	);
}

import React from 'react';
import Button from '../Button';
import { ArrowLeftIcon, FloppyIcon } from '../SVGIcons/SVGIcons';

/**
 * Wraps page content with optional header, background color, custom styles, and a button.
 *
 * @param {Object} props
 * @param {ReactNode} props.children - Content to be wrapped.
 * @param {string} props.backColor - Background color class name for the wrapper.
 * @param {Object} props.style - Inline style object for the wrapper.
 * @param {Object} props.header - Header object containing text, color class, and optional icon.
 * @param {string} props.header.text - Text content of the header.
 * @param {string} [props.header.color] - Color class name for the header text.
 * @param {SVGElement} [props.header.icon] - Optional SVG element for an icon to display in the header.
 * @param {boolean} props.innerWrap - Determines if children should be wrapped in an additional div.
 * @param {number} props.innerWidthMax - Maximum width of the innerWrapper component
 */

function PageWrapper({ children, backColor, style, header, subHeader, innerWrap, innerWidthMax, innerCont }) {
	return (
		<main className={`pageInnerCont${backColor ? ` ${backColor}` : ''}`} style={style || null}>
			{header && (
				<div className={`wrapperHeader${header.theme ? ` ${header.theme}` : ''}`}>
					<div>
						<h1>
							{header.icon}
							{header.text}
						</h1>
						<p className='subHeader'>{subHeader}</p>
					</div>
					<div className='flexRow'>
						{header?.button1 && (
							<Button
								text={header.button1.text}
								style={header.button1.style}
								size='md'
								iconLeft={header.button1.iconLeft}
								iconRight={header.button1.iconRight}
								onClick={header.button1.cb ? header.button1.cb : null}
								maxWidth={header.button1.width}
								minWidth={header.button1.width}
								disabled={header.button1.disabled}
								fill={header.button1.width}
							/>
						)}
						{header?.button2 && (
							<Button
								text={header.button2.text}
								style={header.button2.style}
								size='md'
								iconLeft={header.button2.iconLeft}
								iconRight={header.button2.iconRight}
								onClick={header.button2.cb ? header.button2.cb : null}
								maxWidth={header.button2.width}
								minWidth={header.button2.width}
								disabled={header.button2.disabled}
								fill={header.button2.width}
							/>
						)}
						{header?.button3 && (
							<Button
								text={header.button3.text}
								style={header.button3.style}
								size='md'
								iconLeft={header.button3.iconLeft}
								iconRight={header.button3.iconRight}
								onClick={header.button3.cb ? header.button3.cb : null}
								maxWidth={header.button3.width}
								minWidth={header.button3.width}
								disabled={header.button3.disabled}
								fill={header.button3.width}
							/>
						)}
					</div>
				</div>
			)}
			{innerWrap ? (
				<div className={`wrapperInner`} style={{ maxWidth: innerWidthMax }}>
					{children}
				</div>
			) : (
				<>{children}</>
			)}
			<div className='innerCont'>{innerCont}</div>
		</main>
	);
}

export default PageWrapper;

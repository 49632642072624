import React, { useEffect, useRef, useState } from 'react';
import FileInput from '../Inputs/FileInput';
import SelectCustom from '../Select/SelectCustom';
import useWindowSize from '../../hooks/useWindowSize';
import {
	BroomIcon,
	CircleSolidIcon,
	CloseIcon,
	EraserIcon,
	FileExportIcon,
	ReverseLeftIcon,
	ReverseRightIcon,
	TextTIcon,
	ToolLineMediumIcon,
	ToolLineThickIcon,
	ToolLineThinIcon,
	ToolScribbleMediumIcon,
	ToolScribbleThickIcon,
	ToolScribbleThinIcon,
} from '../SVGIcons/SVGIcons';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import Button from '../Button';
import Overlay from '../Overlay';
import ReactPortal from '../../Portal/ReactPortal';

export default function Canvas(props) {
	const { img, cWidth, cHeight, handleFile, showInsert, name } = props || {};
	const cvsRef = useRef(null);
	const cvsRefPreview = useRef(null);
	const ctxRef = useRef(null);

	let aspectRatio = cHeight / cWidth;
	// const cvsBgRef = useRef(null);
	// const ctxBgRef = useRef(null);

	const textRef = useRef(null);
	const cvsModalRef = useRef(null);

	const { showModal, hideModal } = useModalsGlobal();

	const windowSize = useWindowSize();

	const [loaded, setLoaded] = useState(false);
	const [editing, setEditing] = useState(false);
	const [mobile, setMobile] = useState(false);
	const [fullSize, setFullSize] = useState(false);
	const [dropOpen, setDropOpen] = useState(null);
	const [cvsSize, setCvsSize] = useState({ cWidth: cWidth, cHeight: cHeight });
	const [textboxInit, setTextboxInit] = useState(false);
	const [backImg, setBackImg] = useState({});
	const [isDrawing, setIsDrawing] = useState(false);
	const [history, setHistory] = useState({
		redoList: [],
		undoList: [],
	});

	const [text, setText] = useState({ fontSize: 12 });
	const [lineWidth, setLineWidth] = useState(2);
	const [color, setColor] = useState('red');
	const [lineType, setLineType] = useState(1);
	const [lineRef, setLineRef] = useState({});

	function handleColor(e, color) {
		setDropOpen(null);
		e.stopPropagation();
		setColor(color);
	}

	function handleLineWidth(e, type, thickness) {
		setDropOpen(null);
		e.stopPropagation();
		let widthValue = 2;
		switch (thickness) {
			case 'thin':
				widthValue = 2;
				break;
			case 'medium':
				widthValue = 4;
				break;
			case 'thick':
				widthValue = 6;
				break;
		}
		setLineWidth(widthValue);
		setLineType(type);
	}

	function toggleDrop(type) {
		if (dropOpen === type) {
			setDropOpen(null);
		} else {
			setDropOpen(type);
		}
	}

	function setImgRef(src, scale) {
		if (typeof src === 'object' && src.length !== 0) {
			let fr = new FileReader();
			let backImg = document.createElement('img');
			backImg.hidden = true;
			backImg.id = 'imgRef';
			backImg = document.body.appendChild(backImg);
			fr.onload = (e) => {
				backImg.src = e.target.result;
			};
			fr.readAsDataURL(src);
			return backImg;
		} else {
			let img = null;
			let imgTag = document.getElementById('imgRef');
			if (imgTag) {
				img = imgTag;
			} else {
				img = document.createElement('img');
				img.id = 'imgRef';
			}
			img.hidden = true;
			img.src = src;
			if (scale) {
				img.onload = () => {
					img.width = img.naturalWidth * scale;
					img.height = img.naturalHeight * scale;
				};
			}
			return img;
		}
	}

	function clearCanvas(src, initial, canvasRef, contextRef, applyNew) {
		let cvsImg = setImgRef(src);
		let cvs = canvasRef || cvsRef.current;
		let ctx = contextRef || ctxRef.current;
		cvsImg.onload = () => {
			ctx.clearRect(0, 0, cvs.width, cvs.height);
			ctx.beginPath();
			ctx.drawImage(cvsImg, 0, 0, cvs.width, cvs.height, 0, 0, cvs.width, cvs.height);
			cvsImg.remove();
			if (img && initial) {
				let backImg = setImgRef(img);
				backImg.onload = () => {
					ctx.clearRect(0, 0, cvs.width, cvs.height);
					ctx.beginPath();
					ctx.drawImage(backImg, 0, 0, cvs.width, cvs.height, 0, 0, cvs.width, cvs.height);
					backImg.remove();
					if (applyNew) {
						return applyNew();
					}
				};
			}
			if (applyNew) {
				return applyNew();
			}
		};
	}

	const saveHistory = (cvs, list, type, keep) => {
		let updList = history[list];
		if (type === 'push') {
			updList.push(cvs.toDataURL());
		}
		setHistory({
			...history,
			['redoList']: keep ? history['redoList'] : [],
			[list]: updList,
		});

		if (type === 'pop') {
			let restore;
			if (updList.length > 1) {
				restore = updList.pop();
			} else {
				restore = updList[0];
			}
			clearCanvas(restore);
		}
	};

	const undo = () => {
		saveHistory(cvsRef.current, 'redoList', 'push', true);
		saveHistory(cvsRef.current, 'undoList', 'pop', true);
	};

	const redo = () => {
		saveHistory(cvsRef.current, 'redoList', 'pop', true);
		saveHistory(cvsRef.current, 'undoList', 'push', true);
	};

	const clear = () => {
		let cvsPreview = cvsRefPreview.current;
		let ctxPreview = cvsPreview.getContext('2d');
		let blank = history['undoList'][0];
		clearCanvas(blank);
		clearCanvas(blank, false, cvsPreview, ctxPreview, () =>
			ctxPreview.drawImage(cvsRef.current, 0, 0, 1 * cWidth, 1 * cHeight)
		);

		setHistory({
			redoList: [],
			undoList: [],
		});
	};

	const save = () => {
		let dataURL = cvsRef.current.toDataURL('image/png');
		let newTab = window.open('about:blank', 'image from canvas');
		newTab.document.write("<img src='" + dataURL + "' alt='from canvas'/>");
	};

	function getOffsetVals(e) {
		if (e.touches && e.touches[0]) {
			let top = document.getElementById(`cvsCont_${name}`);
			const rect = top.getBoundingClientRect();
			return { offsetX: e.touches[0].clientX - rect.left, offsetY: e.touches[0].clientY - rect.top };
		} else {
			return { offsetX: e.nativeEvent.offsetX, offsetY: e.nativeEvent.offsetY };
		}
	}

	const dot = (e) => {
		const { offsetX, offsetY } = getOffsetVals(e);
		if (lineType === 1) {
			ctxRef.current.lineTo(offsetX, offsetY);
			ctxRef.current.stroke();
		}
	};

	const startDraw = (e) => {
		const { offsetX, offsetY } = getOffsetVals(e);
		if (lineType === 1) {
			ctxRef.current.beginPath();
			ctxRef.current.moveTo(offsetX, offsetY);
			setIsDrawing(true);
		} else if (lineType === 2) {
			setLineRef({ x: offsetX, y: offsetY, img: cvsRef.current.toDataURL() });
			ctxRef.current.beginPath();
			ctxRef.current.moveTo(offsetX, offsetY);
			setIsDrawing(true);
		} else if (lineType === 3 && !text.writing) {
			setText((text) => ({
				...text,
				writing: true,
				x: offsetX,
				y: offsetY,
			}));
		}
		saveHistory(cvsRef.current, 'undoList', 'push', false);
	};

	const finishDraw = () => {
		if (isDrawing || lineType === 3) {
			// let img = ctxRef.current.canvas;
			// ctxBgRef.current.drawImage(img, 0, 0, 500, 700, 0, 0, 500, 700);
			ctxRef.current.closePath();
			cvsRef.current.toBlob((blob) => {
				blob.name = name && name.replace(' ', '').toLowerCase() + '.png';
				handleFile && handleFile(blob, name);
			});
			setIsDrawing(false);
		}
	};

	const draw = (e) => {
		if (isDrawing) {
			const { offsetX, offsetY } = getOffsetVals(e);
			if (lineType === 1) {
				ctxRef.current.strokeStyle = color;
				ctxRef.current.lineWidth = lineWidth;
				ctxRef.current.lineTo(offsetX, offsetY);
				ctxRef.current.stroke();
				ctxRef.current.beginPath();
				ctxRef.current.moveTo(offsetX, offsetY);
				ctxRef.current.lineCap = 'round';
				ctxRef.current.lineJoin = 'round';
			} else if (lineType === 2) {
				// Do line tool logic
				let backImg = setImgRef(lineRef.img);
				backImg.onload = () => {
					ctxRef.current.clearRect(0, 0, cvsRef.current.width, cvsRef.current.height);
					ctxRef.current.drawImage(
						backImg,
						0,
						0,
						backImg.width,
						backImg.height,
						0,
						0,
						backImg.width,
						backImg.height
					);
					ctxRef.current.strokeStyle = color;
					ctxRef.current.lineWidth = lineWidth;
					ctxRef.current.lineTo(lineRef.x, lineRef.y);
					ctxRef.current.stroke();
					ctxRef.current.beginPath();
					ctxRef.current.moveTo(offsetX, offsetY);
					ctxRef.current.lineCap = 'round';
					ctxRef.current.lineJoin = 'round';
				};
			}
		}
	};

	const handleBlur = (e) => {
		// if (e.target.id === `textToolBox_${name}` && !textboxInit && !text) {
		// 	e.target.focus();
		// 	return setTextboxInit(true);
		// }
		ctxRef.current.fillStyle = color;
		ctxRef.current.font = `${text.fontSize}px sans-serif`;
		ctxRef.current.fillText(text.text || '', text.x, text.y);
		setText((text) => ({
			...text,
			text: '',
			writing: false,
		}));
		finishDraw();
		setTextboxInit(false);
	};

	const handleFontSize = (e) => {
		setText((text) => ({
			...text,
			fontSize: e.value,
		}));
	};

	function handleEditing(editing) {
		let cvsPreview = cvsRefPreview.current;
		let ctxPreview = cvsPreview.getContext('2d');

		setEditing(editing);

		if (editing === true) {
		} else {
			let blank = history['undoList'][0];
			clearCanvas(blank, false, cvsPreview, ctxPreview, () =>
				ctxPreview.drawImage(cvsRef.current, 0, 0, 1 * cvsSize.cWidth, 1 * cvsSize.cHeight)
			);
		}
	}

	useEffect(() => {
		if (text.writing) {
			textRef.current.focus();
		}
	}, [text.writing]);

	useEffect(() => {
		let mounted = true;
		if (mounted && loaded) {
			let cvs = cvsRef.current;
			let cvsPreview = cvsRefPreview.current;
			// let cvsBg = cvsRef.current;
			let ctx = cvs.getContext('2d');
			let ctxPreview = cvsPreview.getContext('2d');
			// let ctxBg = cvsBg.getContext('2d');
			ctx.translate(0.5, 0.5);
			ctxPreview.translate(0.5, 0.5);
			// ctxBgRef.current = ctx;
			let backImg = null;

			if (img?.length !== 0 && img) {
				backImg = setImgRef(img, 2);
				setBackImg(backImg);
				backImg.onload = () => {
					cvs.width = cvsSize?.cWidth;
					cvs.height = cvsSize?.cHeight;
					cvs.style.width = `${cvsSize?.cWidth}px`;
					cvs.style.height = `${cvsSize?.cHeight}px`;

					// cvsBg.width = 500;
					// cvsBg.height = backImg.height + 200;
					// cvsBg.style.width = `${500}px`;
					// cvsBg.style.height = `${backImg.height + 200}px`;
					let scale = Math.min(cvs.width / backImg.width, cvs.height / backImg.height);
					ctx.drawImage(backImg, 0, 0, scale * backImg.width, scale * backImg.height);

					cvsPreview.width = cvsSize?.cWidth;
					cvsPreview.height = cvsSize?.cHeight;
					cvsPreview.style.width = `${cvsSize?.cWidth}px`;
					cvsPreview.style.height = `${cvsSize?.cHeight}px`;

					// cvsBg.width = 500;
					// cvsBg.height = backImg.height + 200;
					// cvsBg.style.width = `${500}px`;
					// cvsBg.style.height = `${backImg.height + 200}px`;
					ctxPreview.drawImage(backImg, 0, 0, scale * backImg.width, scale * backImg.height);

					backImg.remove();
					ctx.beginPath();
					ctx.scale(1, 1);
					// ctxBg.beginPath();
					// ctxBg.scale(1, 1);
				};
			} else {
				cvs.width = cvsSize?.cWidth * 2 || window.innerWidth * 2;
				cvs.height = cvsSize?.cHeight * 2 || window.innerHeight * 2;
				cvs.style.width = `${cvsSize?.cWidth || window.innerWidth}px`;
				cvs.style.height = `${cvsSize?.cHeight || window.innerHeight}px`;

				// cvsBg.width = cWidth * 2 || window.innerWidth * 2;
				// cvsBg.height = cHeight * 2 || window.innerHeight * 2;
				// cvsBg.style.width = `${cWidth || window.innerWidth}px`;
				// cvsBg.style.height = `${cHeight || window.innerHeight}px`;
				ctx.beginPath();
				ctx.scale(2, 2);
				// ctxBg.beginPath();
				// ctxBg.scale(2, 2);
			}
			ctxRef.current = ctx;
		}
		return () => {
			mounted = false;
			if (backImg) {
				backImg.onload = null; // Remove onload handler to prevent memory leaks
			}
		};
	}, [img, cvsSize]);

	useEffect(() => {
		const closeOnEscapeKey = (e) => (e.key === 'Escape' ? handleEditing(false) : null);
		document.body.addEventListener('keydown', closeOnEscapeKey);
		return () => {
			document.body.removeEventListener('keydown', closeOnEscapeKey);
		};
	}, []);

	useEffect(() => {
		let mounted = true;
		let margin = 50;
		if (mounted && loaded) {
			if (cvsModalRef?.current?.offsetWidth <= 500 && cvsModalRef?.current?.offsetWidth !== 0) {
				setCvsSize({
					cWidth: cvsModalRef?.current?.offsetWidth,
					cHeight: cvsModalRef?.current?.offsetWidth * aspectRatio,
				});
			} else if (windowSize.width < 600) {
				setCvsSize({
					cWidth: windowSize.width - margin,
					cHeight: windowSize.height - margin,
				});
			} else {
				setCvsSize({
					cWidth: 500,
					cHeight: 700,
				});
			}
		}
		if (!loaded) {
			setLoaded(true);
		}
		return () => (mounted = false);
	}, [windowSize?.width, loaded, cvsModalRef]);

	return (
		<>
			<ReactPortal wrapperId='canvas-portal'>
				<Overlay loadOverlay={editing} showOverlay={editing} zIndex={96} />
				<div ref={cvsModalRef} className={`canvasModal${!editing ? ' hidden' : ''}`}>
					<div className='cvsHeader'>
						<h3>{name}</h3>
						<Button
							text='Save'
							style='primary'
							size='md'
							onClick={() => {
								handleEditing(false);
							}}
						/>
					</div>
					<div className='cvsToolCont'>
						<div className='separator'>
							<div
								className='tool'
								onClick={(e) => {
									e.preventDefault();
									undo();
								}}>
								<ReverseLeftIcon iconClass='drawToolIcon' />
							</div>
							<div
								className='tool'
								onClick={(e) => {
									e.preventDefault();
									showModal('confirmChoice', {
										callback: () => clear(),
										fixed: true,
										header: 'Are you sure you want to erase your progress on this drawing?',
									});
								}}>
								<BroomIcon iconClass='drawToolIcon' />
							</div>
							<div
								className='tool'
								onClick={(e) => {
									e.preventDefault();
									redo();
								}}>
								<ReverseRightIcon iconClass='drawToolIcon' />
							</div>
						</div>
						<div className='separator'>
							<div
								className={`tool${lineType === 1 ? ' selected' : ''}`}
								onClick={() => {
									toggleDrop('pen');
								}}>
								{lineWidth === 2 && lineType === 1 && <ToolScribbleThinIcon iconClass='drawToolIcon' />}
								{lineWidth === 4 && lineType === 1 && (
									<ToolScribbleMediumIcon iconClass='drawToolIcon' />
								)}
								{lineWidth === 6 && lineType === 1 && (
									<ToolScribbleThickIcon iconClass='drawToolIcon' />
								)}
								{lineType !== 1 && <ToolScribbleMediumIcon iconClass='drawToolIcon' />}
								{dropOpen === 'pen' && (
									<div className='toolDrop'>
										<div
											className={
												lineWidth === 2 && lineType === 1
													? 'circleIconCont selected'
													: 'circleIconCont'
											}
											onClick={(e) => handleLineWidth(e, 1, 'thin')}>
											<ToolScribbleThinIcon />
										</div>
										<div
											className={
												lineWidth === 4 && lineType === 1
													? 'circleIconCont selected'
													: 'circleIconCont'
											}
											onClick={(e) => handleLineWidth(e, 1, 'medium')}>
											<ToolScribbleMediumIcon />
										</div>
										<div
											className={
												lineWidth === 6 && lineType === 1
													? 'circleIconCont selected'
													: 'circleIconCont'
											}
											onClick={(e) => handleLineWidth(e, 1, 'thick')}>
											<ToolScribbleThickIcon />
										</div>
									</div>
								)}
							</div>
							<div
								className={`tool${lineType === 2 ? ' selected' : ''}`}
								onClick={() => {
									toggleDrop('line');
								}}>
								{lineWidth === 2 && lineType === 2 && <ToolLineThinIcon iconClass='drawToolIcon' />}
								{lineWidth === 4 && lineType === 2 && <ToolLineMediumIcon iconClass='drawToolIcon' />}
								{lineWidth === 6 && lineType === 2 && <ToolLineThickIcon iconClass='drawToolIcon' />}
								{lineType !== 2 && <ToolLineMediumIcon iconClass='drawToolIcon' />}
								{dropOpen === 'line' && (
									<>
										<div className='toolDrop'>
											<div
												className={
													lineWidth === 2 && lineType === 2
														? 'circleIconCont selected'
														: 'circleIconCont'
												}
												onClick={(e) => handleLineWidth(e, 2, 'thin')}>
												<ToolLineThinIcon />
											</div>
											<div
												className={
													lineWidth === 4 && lineType === 2
														? 'circleIconCont selected'
														: 'circleIconCont'
												}
												onClick={(e) => handleLineWidth(e, 2, 'medium')}>
												<ToolLineMediumIcon />
											</div>
											<div
												className={
													lineWidth === 6 && lineType === 2
														? 'circleIconCont selected'
														: 'circleIconCont'
												}
												onClick={(e) => handleLineWidth(e, 2, 'thick')}>
												<ToolLineThickIcon />
											</div>
										</div>
									</>
								)}
							</div>
							<div className={`tool${lineType === 3 ? ' selected' : ''}`} onClick={() => setLineType(3)}>
								<TextTIcon iconClass='drawToolIcon' />
							</div>
							<div className='tool' onClick={() => toggleDrop('color')}>
								<div style={{ fill: color }} className='circleIconCont'>
									<CircleSolidIcon />
								</div>
								{dropOpen === 'color' && (
									<div className='toolDrop'>
										<div
											style={{ fill: '#000000' }}
											className='circleIconCont'
											onClick={(e) => handleColor(e, '#000000')}>
											<CircleSolidIcon />
										</div>
										<div
											style={{ fill: '#ff0000' }}
											className='circleIconCont'
											onClick={(e) => handleColor(e, '#ff0000')}>
											<CircleSolidIcon />
										</div>
										<div
											style={{ fill: '#0000ff' }}
											className='circleIconCont'
											onClick={(e) => handleColor(e, '#0000ff')}>
											<CircleSolidIcon />
										</div>
										<div
											style={{ fill: '#00ff00' }}
											className='circleIconCont'
											onClick={(e) => handleColor(e, '#00ff00')}>
											<CircleSolidIcon />
										</div>
									</div>
								)}
							</div>
						</div>
						{/* <div className='tool'>
					<FileExportIcon
						onClick={(e) => {
							e.preventDefault();
							save();
						}}
						iconClass='drawToolIcon'
					/>
				</div> */}
						{showInsert && (
							<div className='tool'>
								<FileInput name={name} callback={handleFile} thin />
							</div>
						)}
					</div>
					<div
						id={`cvsCont_${name}`}
						className='cvsCont'
						style={{
							width: `${cvsSize?.cWidth}px`,
						}}>
						{text.writing && (
							<textarea
								id={`textToolBox_${name}`}
								className='textToolBox'
								style={{
									top: text.y - text.fontSize,
									left: text.x,
									fontSize: Math.floor(text.fontSize),
									color: color,
								}}
								ref={textRef}
								onChange={(e) => {
									setText((text) => ({
										...text,
										text: e.target.value,
									}));
								}}
								onBlur={handleBlur}></textarea>
						)}
						<canvas
							ref={cvsRef}
							className='canvas'
							onMouseDown={(e) => {
								startDraw(e);
								dot(e);
							}}
							onMouseLeave={finishDraw}
							onMouseUp={finishDraw}
							onMouseMove={draw}
							onTouchStart={(e) => {
								if (lineType !== 3) {
									startDraw(e);
									dot(e);
								}
							}}
							onTouchEnd={(e) => {
								if (lineType !== 3) {
									finishDraw(e);
								}
							}}
							onTouchMove={(e) => {
								draw(e);
							}}
							id='canvas'
							style={{ zIndex: 3 }}></canvas>
						{/* <canvas ref={cvsBgRef} className='canvas' id='canvasBg' style={{ zIndex: 1 }}></canvas> */}
					</div>
				</div>
			</ReactPortal>

			<div
				className='canvasPreview'
				onClick={() => {
					handleEditing(true);
				}}>
				<canvas
					ref={cvsRefPreview}
					className='canvas'
					onMouseDown={(e) => {
						startDraw(e);
						dot(e);
					}}
					onMouseLeave={finishDraw}
					onMouseUp={finishDraw}
					onMouseMove={draw}
					onTouchStart={(e) => {
						if (lineType !== 3) {
							startDraw(e);
							dot(e);
						}
					}}
					onTouchEnd={(e) => {
						if (lineType !== 3) {
							finishDraw(e);
						}
					}}
					onTouchMove={(e) => {
						draw(e);
					}}
					id='canvasPreview'
					style={{ zIndex: 3 }}></canvas>
				<div className={`overlay${windowSize.width < 1280 ? ' mobile' : ''}`}>
					<Button
						type='button'
						text={windowSize.width < 1280 ? 'Tap to edit' : 'Click to edit'}
						size='md'
						style='secondary'
					/>
				</div>
			</div>
		</>
	);
}

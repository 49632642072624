import React, { useState } from 'react';
import Button from '../Button';
import PaymentList from './PaymentList';
import Tabs from './Tabs';
import TextareaInput from '../Inputs/TextareaInput';

export default function InvoicePayments({ invoice, setUpdateLoading, updateLoading, updateDebounceSearch }) {
	const [selected, setSelected] = useState('payments');
	return (
		<>
			{invoice?.status !== 'draft' ? (
				<div className='invoicePayments'>
					<Tabs
						tabsList={[
							{ text: 'Payment Balance', name: 'payments', color: 'sale', style: 'tertiary-secondary' },
							{ text: 'Notes', name: 'notes', style: 'tertiary-secondary' },
						]}
						selected={selected}
						setSelected={setSelected}
					/>
					{selected === 'payments' && <PaymentList status={invoice?.status} />}
					{selected === 'notes' && (
						<TextareaInput
							name={'privateNote'}
							defaultValue={invoice?.privateNote}
							callback={(value, name) => {
								setUpdateLoading(true);
								updateDebounceSearch(value, name);
							}}
							loading={updateLoading}
							onlyInput
						/>
					)}
				</div>
			) : (
				<TextareaInput
					name={'privateNote'}
					defaultValue={invoice?.privateNote}
					callback={(value, name) => {
						setUpdateLoading(true);
						updateDebounceSearch(value, name);
					}}
					loading={updateLoading}
					onlyInput
				/>
			)}
		</>
	);
}

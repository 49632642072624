import React, { useContext, useEffect } from 'react';
import CheckboxInput from '../Inputs/CheckboxInput';
import RadioInput from '../Inputs/RadioInput';
import { getRolePermissions, splitUserId } from '../../helpers';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';

export default function PermissionsForm(props) {
	const { role, permissions, handlePermissionsInput, setRole, setPermissions, required } = props || {};

	const { userState } = useContext(CurrentUserContext);
	const { teamState } = useContext(CurrentTeamContext);

	function setPermissionsAndRole(value) {
		setPermissions(getRolePermissions(value));
		setRole(value);
	}

	let isSuperAdmin = splitUserId(userState.currUser?.sub) === teamState.currentTeam?.teamInfo?.admin;

	return (
		<>
			<div className='permissionsCont'>
				<div className='col'>
					<h4>Member role</h4>
					<RadioInput
						text={'Will you automatically accept changes to pricing throughout the manufacturing process?'}
						name={'acceptPricing'}
						defaultValue={role}
						callback={setPermissionsAndRole}
						radios={[
							{ value: 'administrator', text: 'Administrator', disabled: !isSuperAdmin },
							{ value: 'manager', text: 'Manager' },
							{ value: 'buyer', text: 'Buyer' },
							{ value: 'officer', text: 'Officer' },
							{ value: 'member', text: 'Member' },
						]}
						required
						onlyInput
					/>
				</div>
				<div className='col'>
					<h4>Permissions</h4>
					<div className={!isSuperAdmin ? 'disabledDiv' : ''}>
						<CheckboxInput
							checkboxText={'Is Administrator?'}
							name={'isAdmin'}
							checked={permissions.isAdmin}
							callback={handlePermissionsInput}
							required
							onlyInput
							disabled={!isSuperAdmin}
						/>
					</div>
					<CheckboxInput
						checkboxText={'Is a Buyer?'}
						name={'isBuyer'}
						checked={permissions.isBuyer}
						callback={handlePermissionsInput}
						required
						onlyInput
					/>
					<CheckboxInput
						checkboxText={'See and manage all orders?'}
						name={'manageOrders'}
						checked={permissions.manageOrders}
						callback={handlePermissionsInput}
						required
						onlyInput
					/>
					<CheckboxInput
						checkboxText={'Is this person authorized to act on behalf of a buyer?'}
						name={'authorizedOnBehalf'}
						checked={permissions.authorizedOnBehalf}
						callback={handlePermissionsInput}
						required
						onlyInput
					/>
					<CheckboxInput
						checkboxText={'Can they invite people into the team?'}
						name={'canInvite'}
						checked={permissions.canInvite}
						callback={handlePermissionsInput}
						required
						onlyInput
					/>
					<CheckboxInput
						checkboxText={'Can they add Trusted Suppliers?'}
						name={'canAddTrusted'}
						checked={permissions.canAddTrusted}
						callback={handlePermissionsInput}
						required
						onlyInput
					/>
				</div>
			</div>
		</>
	);
}

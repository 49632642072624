import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Canvas from '../../../components/Canvas/Canvas';
import CheckboxInput from '../../../components/Inputs/CheckboxInput';
import DateInput from '../../../components/Inputs/DateInput';
import FileInput from '../../../components/Inputs/FileInput';
import RadioInput from '../../../components/Inputs/RadioInput';
import SelectInput from '../../../components/Inputs/SelectInput';
import TextareaInput from '../../../components/Inputs/TextareaInput';
import TextInput from '../../../components/Inputs/TextInput';
import { DocketDotsVerIcon, DocketImageIcon } from '../../../components/SVGIcons/SVGIcons';
import { getFutureDate } from '../../../helpers';

let topAligned = ['drawingTool', 'paragraph'];

export default function ListFieldPre(props) {
	const { field, index, showTools, editIcon } = props || {};
	return (
		<>
			<Draggable draggableId={field.id} index={index}>
				{(provided) => (
					<div className={'fieldPre'} {...provided.draggableProps} ref={provided.innerRef}>
						{(() => {
							switch (field.type) {
								case 'select':
									let options = [];
									if (field.data?.selectOptions) {
										for (let i = 0; i < field.data?.selectOptions.length; i++) {
											options.push(
												<option value={field.data?.selectOptions[i].value}>
													{field.data?.selectOptions[i].value}
												</option>
											);
										}
									}
									return (
										<SelectInput
											text={field.data?.fieldText || 'Label'}
											htmlOptions={options}
											preview
										/>
									);
								case 'input.text':
									return (
										<TextInput
											type='text'
											text={field.data?.fieldText || 'Label'}
											placeholder={field.data?.placeholder || 'Text'}
										/>
									);
								case 'input.phone':
									return (
										<TextInput
											type='phone'
											text={field.data?.fieldText || 'Label'}
											placeholder={field.data?.placeholder || 'Phone'}
										/>
									);
								case 'textarea':
									return (
										<TextareaInput
											text={field.data?.fieldText || 'Label'}
											placeholder={field.data?.placeholder || 'Long text'}
										/>
									);
								case 'input.date':
									return (
										<DateInput
											text={field.data?.fieldText || 'Label'}
											min={getFutureDate(field.data?.minDays)}
										/>
									);
								case 'input.file':
									return (
										<FileInput btnText={'Choose file'} text={field.data?.fieldText || 'Label'} />
									);
								case 'input.checkbox':
									return <CheckboxInput text={field.data?.fieldText || 'Label'} preview />;
								case 'input.radio':
									let radios = [];
									if (!field?.data) {
										radios = [
											{ value: 1, text: field.data?.radio1 || 'Choice #1' },
											{ value: 2, text: field.data?.radio2 || 'Choice #2' },
										];
									}
									for (let radio in field?.data) {
										if (radio !== 'extraRadios' && radio !== 'fieldName' && radio !== 'fieldText') {
											radios.push({ text: field?.data[radio], value: radios.length + 1 });
										}
									}
									return (
										<RadioInput radios={radios} text={field.data?.fieldText || 'Label'} preview />
									);
								case 'header':
									return <h3 className='header'>{field.data?.header || 'Header'}</h3>;
								case 'paragraph':
									return <p className='paragraph'>{field.data?.paragraph || 'Paragraph'}</p>;
								case 'drawingTool':
									let img = field.data?.drawToolImg?.data;
									fetch(field.data?.drawToolImg?.data)
										.then((res) => res.blob())
										.then((res) => {
											img = URL.createObjectURL(res);
										});
									return (
										<div className='drawTool'>
											<h3 className='header'>{field.data?.fieldText || 'Drawing tool'}</h3>
											<Canvas cWidth={500} cHeight={700} callback={null} img={img} />
										</div>
									);
								case 'graphic':
									if (field.data?.graphic?.data) {
										return (
											<img
												className='graphicImg'
												alt='graphic'
												src={field.data?.graphic?.data}></img>
										);
									} else {
										return <DocketImageIcon iconClass='graphicImg' />;
									}
								default:
									return <div>{field.name}</div>;
							}
						})()}
						<div className={`fieldTools${topAligned.includes(field.type) ? ' top' : ''}`}>
							{showTools && editIcon}
							<div className='toolIcon discIcon' {...provided.dragHandleProps}>
								<DocketDotsVerIcon />
							</div>
						</div>
					</div>
				)}
			</Draggable>
		</>
	);
}

import React from 'react';
import TrustedForm from '../Forms/TrustedForm';
import Loading from '../Loading/Loading';

export default function TrustedEditModal(props) {
	const {
		preferences,
		updateLoading,
		supplierUuid,
		handlePreference,
		setUpdateLoading,
		postForm,
		setPreferences,
		handleModal,
	} = props || {};

	return (
		<div className='mainModal'>
			<TrustedForm handlePreference={handlePreference} preferences={preferences} />
			{!updateLoading ? (
				<div className='addressButtons'>
					<div>
						<input
							type='button'
							className='functionalButtonPrimary'
							onClick={() => {
								postForm(supplierUuid, preferences);
								setUpdateLoading(true);
							}}
							defaultValue='Update preferences'
						/>
					</div>
					<div className='marginTop-10'>
						<input
							type='button'
							className='functionalButtonSecondary'
							onClick={() => {
								handleModal('editModal', { open: false });
								setPreferences(null);
							}}
							defaultValue='Cancel'
						/>
					</div>
				</div>
			) : (
				<>
					<Loading type='circle' />
				</>
			)}
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import img1 from '../../assets/img/news1.png';
import config from '../../config';
import { dateConverter } from '../../helpers';
import useFetch from '../../hooks/useFetch';
import Axios from 'axios';

export default function News(props) {
	const { open } = props || {};

	const { data } = useFetch(`${config.apiv1}/news/news.read`);

	const [news, setNews] = useState([]);

	useEffect(async () => {
		if (data) {
			let newNews = [...data];
			for (let i = 0; i < newNews.length; i++) {
				if (newNews[i].json_news_item_body?.imgUrl) {
					let res = await Axios({
						url: `${config.api}/file?path=${newNews[i].json_news_item_body?.imgUrl}`,
						method: 'GET',
						responseType: 'blob',
					});
					const url = window.URL.createObjectURL(new Blob([res.data]));
					let news = newNews[i];
					news.json_news_item_body.imgUrl = url;
					newNews[i] = news;
				}
			}
			setNews(newNews);
		}
	}, [data]);

	const navigate = useNavigate();

	return (
		<div className='newsCont'>
			<div className='row'>
				<h3>What's New</h3>
				<h3></h3>
			</div>
			{news?.length !== 0 &&
				news.map((news, key) => {
					return (
						<div className='newsItem' key={key}>
							{news.type_id === 1 && (
								<>
									<div className='topBar yellow'>
										<h5>ANNOUNCEMENT</h5>
										<p>{dateConverter(news.publish_date_time)}</p>
									</div>
									<div className='body'>
										<img src={img1} alt='news-related-image' />
										<h3>{news.json_news_item_body?.header}</h3>
										<p>{news.json_news_item_body?.bodyText}</p>
										{news?.json_news_item_body?.buttonText && (
											<button
												className='btn3-primary'
												onClick={() => navigate(news.json_news_item_body?.buttonHref)}>
												{news.json_news_item_body?.buttonText}
											</button>
										)}
									</div>
								</>
							)}
							{news.type_id === 2 && (
								<>
									<div className='topBar red'>
										<h5>NEW FEATURE</h5>
										<p>{dateConverter(news.publish_date_time)}</p>
									</div>
									<div className='body'>
										{news.json_news_item_body?.imgUrl && (
											<img src={news.json_news_item_body?.imgUrl} alt='news-related-image' />
										)}
										<h3>{news.json_news_item_body?.header}</h3>
										<p>{news.json_news_item_body?.bodyText}</p>
										{news?.json_news_item_body?.buttonText && (
											<button
												className='btn3-primary'
												onClick={() =>
													(window.location.href = news.json_news_item_body?.buttonHref)
												}>
												{news.json_news_item_body?.buttonText}
											</button>
										)}
									</div>
								</>
							)}
							{news.type_id === 3 && (
								<>
									<div className='topBar turq'>
										<h5>UPDATED FEATURE</h5>
										<p>{dateConverter(news.publish_date_time)}</p>
									</div>
									<div className='body'>
										<h3>{news.json_news_item_body?.header}</h3>
										<p>{news.json_news_item_body?.bodyText}</p>
										{news?.json_news_item_body?.buttonText && (
											<button
												className='btn3-primary'
												onClick={() => navigate(news.json_news_item_body?.buttonHref)}>
												{news.json_news_item_body?.buttonText}
											</button>
										)}
									</div>
								</>
							)}
							{news.type_id === 4 && (
								<>
									<div className='topBar purp'>
										<h5>DID YOU KNOW?</h5>
										<p>{dateConverter(news.publish_date_time)}</p>
									</div>
									<div className='body'>
										<h3>{news.json_news_item_body?.header}</h3>
										<p>{news.json_news_item_body?.bodyText}</p>
										{news?.json_news_item_body?.buttonText && (
											<button
												className='btn3-primary'
												onClick={() => navigate(news.json_news_item_body?.buttonHref)}>
												{news.json_news_item_body?.buttonText}
											</button>
										)}
									</div>
								</>
							)}
						</div>
					);
				})}
			{/* <div className='row'>
				<h3>What's New</h3>
				<h3></h3>
			</div>
			<div className='newsItem'>
				<div className='topBar yellow'>
					<h5>ANNOUNCEMENT</h5>
					<p>14:26</p>
				</div>
				<div className='body'>
					<h3>Learn Procuur's Tips and Tricks</h3>
					<img src={img1} alt='news-related-image' />
					<p>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
						dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
						sit amet.
					</p>
					<button className='btn3-primary'>Learn more</button>
				</div>
			</div>
			<div className='newsItem'>
				<div className='topBar turq'>
					<h5>UPDATED FEATURE</h5>
					<p>14:36</p>
				</div>
				<div className='body'>
					<h3>Magna aliquyam erat</h3>
					<p>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
						dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
						sit amet.
					</p>
					<button className='btn3-primary'>Learn more</button>
				</div>
			</div>
			<div className='newsItem'>
				<div className='topBar red'>
					<h5>NEW FEATURE</h5>
					<p>14:36</p>
				</div>
				<div className='body'>
					<h3>At vero eos et accusam</h3>
					<p>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
						ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
						dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
						sit amet.
					</p>
					<button className='btn3-primary'>Learn more</button>
				</div>
			</div> */}
		</div>
	);
}

import React from 'react';
import TextareaInput from '../../Inputs/TextareaInput';

export default function NotesSection({ formData, handleFormData, customErrors, details }) {
	const handleKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			const start = event.target.selectionStart;
			const end = event.target.selectionEnd;

			setValue(value.substring(0, start) + '\t' + value.substring(end));

			setTimeout(() => {
				event.target.selectionStart = event.target.selectionEnd = start + 1;
			}, 0);
		}
	};
	return (
		<div className='notesSection'>
			{!details ? (
				<>
					<div className='invoiceInput hiddenTextarea'>
						<p>Message on invoice</p>
						<TextareaInput
							name={'message'}
							customError={customErrors?.message}
							placeholder='Here you can write some nice words to your client.'
							defaultValue={formData?.message}
							callback={handleFormData}
							onKeyDown={handleKeyDown}
							onlyInput
						/>
					</div>
					<div className='invoiceInput'>
						<p>Notes</p>
						<TextareaInput
							name={'privateNote'}
							customError={customErrors?.privateNote}
							defaultValue={formData?.privateNote}
							callback={handleFormData}
							onKeyDown={handleKeyDown}
							onlyInput
						/>
						<p className='infoText'>This field is not visible on the invoice.</p>
					</div>
				</>
			) : (
				<>
					{formData?.message?.split('\n').map((value, key) => (
						<p key={key}>{value}</p>
					))}
				</>
			)}
		</div>
	);
}

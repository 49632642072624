import React, { useEffect, useRef } from 'react';
import 'react-phone-number-input/style.css';
import TooltipIcon from '../TooltipIcon';

const TextSplitInput = React.forwardRef((props, ref) => {
	const {
		focusRightOnNewVal,
		defaultValue,
		placeholder,
		autoFocus,
		maxLength,
		onKeyDown,
		callback,
		disabled,
		controlled,
		required,
		noSpace,
		readOnly,
		preview,
		tooltip,
		onBlur,
		value,
		type,
		name,
	} = props || {};

	let inputRef = useRef(null);

	useEffect(() => {
		if (inputRef.current && focusRightOnNewVal) {
			inputRef.current.focus();
			inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
		}
	}, [value]);

	return (
		<div className='splitInputContainer'>
			{readOnly && <div className='textFieldReadOnly'>{readOnly}</div>}
			{controlled ? (
				<input
					name={name}
					type={type || 'text'}
					className={`textFieldInput${preview ? ' cursor-text' : ''}`}
					onChange={(e) => (callback ? callback(e.target.value, e.target.name, noSpace) : null)}
					value={value || ''}
					placeholder={placeholder}
					onKeyDown={onKeyDown}
					maxLength={maxLength}
					autoFocus={autoFocus}
					required={required}
					disabled={disabled}
					onBlur={onBlur}
					ref={inputRef}
					id={name}
					min={0}
				/>
			) : (
				<input
					name={name}
					type={type || 'text'}
					className={`textFieldInput${preview ? ' cursor-text' : ''}`}
					onChange={(e) => (callback ? callback(e.target.value, e.target.name, noSpace) : null)}
					defaultValue={defaultValue || ''}
					placeholder={placeholder}
					onKeyDown={onKeyDown}
					maxLength={maxLength}
					autoFocus={autoFocus}
					required={required}
					disabled={disabled}
					onBlur={onBlur}
					ref={inputRef}
					id={name}
					min={0}
				/>
			)}
			{tooltip && <TooltipIcon id={name} tooltip={tooltip} delay={500} />}
		</div>
	);
});

export default TextSplitInput;

import React from 'react';
import Toggle from '../Toggle';

export default function NotificationSettings({ stepWidth, updateSetting, formData }) {
	return (
		<div className='notificationSettings' style={{ width: `${stepWidth}px` }}>
			<h3>Notification settings</h3>
			<h4 className='subHeader'>Notify me about:</h4>
			<Toggle
				name='notifyNewMessages'
				onClick={updateSetting}
				value={formData?.notifyNewMessages}
				label='New messages'
			/>
			<h4 className='categoryHeader'>ORDERS</h4>
			<Toggle
				name='notifyNewOrders'
				onClick={updateSetting}
				value={formData?.notifyNewOrders}
				label='New orders'
			/>
			<Toggle
				name='notifyOrderUpdates'
				onClick={updateSetting}
				value={formData?.notifyOrderUpdates}
				label='Order updates'
			/>
			<Toggle
				name='notifyOrderStatusChange'
				onClick={updateSetting}
				value={formData?.notifyOrderStatusChange}
				label='Order status change'
			/>
			<h4 className='categoryHeader'>PRICING</h4>
			<Toggle
				name='notifyPricingUpdates'
				onClick={updateSetting}
				value={formData?.notifyPricingUpdates}
				label='Pricing updates'
			/>
			<Toggle
				name='notifyQuotationUpdates'
				onClick={updateSetting}
				value={formData?.notifyQuotationUpdates}
				label='Quotation updates'
			/>
			<h4 className='categoryHeader'>PARCELS</h4>
			<Toggle
				name='notifyParcelsDispatched'
				onClick={updateSetting}
				value={formData?.notifyParcelsDispatched}
				label='Parcels dispatched'
				disabled
			/>
			<Toggle
				name='notifyParcelsReceived'
				onClick={updateSetting}
				value={formData?.notifyParcelsReceived}
				label='Parcels received'
				disabled
			/>
			<Toggle
				name='notifyParcelTrackingStatusChange'
				onClick={updateSetting}
				value={formData?.notifyParcelTrackingStatusChange}
				label='Parcel tracking status change'
				disabled
			/>
			<h4 className='categoryHeader'>TEAM</h4>
			<Toggle
				name='notifyNewTeamMember'
				onClick={updateSetting}
				value={formData?.notifyNewTeamMember}
				label='New Team members'
			/>
			<Toggle
				name='notifyTrustedRequest'
				onClick={updateSetting}
				value={formData?.notifyTrustedRequest}
				label='Request to add a Trusted Supplier'
			/>
			<h4 className='categoryHeader'>GENERAL</h4>
			<Toggle
				name='mobileSettings'
				onClick={updateSetting}
				value={formData?.mobileSettings}
				label='Use different settings for mobile phone'
				disabled
			/>
			<Toggle
				name='notifyViaEmail'
				onClick={updateSetting}
				value={formData?.notifyViaEmail}
				label='Notify via email when offline'
			/>
			{/* <h4 className='categoryHeader'>NOTIFICATION SCHEDULE</h4> */}
		</div>
	);
}

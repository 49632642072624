import React from 'react';
import { FileLinesIcon, WrenchIcon } from '../../components/SVGIcons/SVGIcons';
import { useNavigate } from 'react-router';
import ManageWrap from '../../components/Wrappers/ManageWrap';

export default function TeamToolbox() {
	let navigate = useNavigate();

	return (
		<ManageWrap backColor='white' style={window.innerWidth > 768 ? { padding: '70px' } : null}>
			<div className='manageCont'>
				<div className='manageHeader'>
					<h2>
						<WrenchIcon iconClass='manageIcon' />
						Toolbox
					</h2>
					<p></p>
				</div>
				<div className='manageRow'>
					<div className='leftDesc'>
						<h4 className='smallHeader'>Tools</h4>
						<p>This is a toolbox filled with powerful tools for your team.</p>
					</div>
					<div className='rowItems'>
						<div
							className='item'
							onClick={() => {
								navigate(`/manage/toolbox/form-builder`);
							}}>
							<FileLinesIcon iconClass='manageIconLarge' />
							<h4 className='smallHeader'>Custom Form Tool</h4>
							{/* <button
								className={`btn3-secondary hug${
									app.appName === 'labmanager' ? ' disabledDivWithShadow' : ''
								}`}
								onClick={() => {
									navigate(`/manage/apps/settings?appName=${app.appName}`);
								}}>
								<GearIcon iconClass='btnIconLeft' /> Manage
							</button> */}
						</div>
					</div>
				</div>
			</div>
		</ManageWrap>
	);
}

import React, { useContext } from 'react';
import Button from '../../Button';
import SelectCustom from '../../Select/SelectCustom';
import { ArrowNarrowRightIcon } from '../../SVGIcons/SVGIcons';
import { parseToOptions } from '../../../helpers';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';

export default function LogsFilterBar(props) {
	const { filter, handleFilter, handleSearch, openFilter, search } = props || {};

	const { teamState } = useContext(CurrentTeamContext);

	return (
		<div className='filterBar'>
			<div>
				<h4 className='label'>Search for order logs</h4>
				<input
					className='textFieldInput'
					defaultValue={search}
					onChange={(e) => {
						handleSearch(e.target.value);
					}}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Member</h4>
				<SelectCustom
					options={[
						{ name: 'All', value: undefined },
						...parseToOptions(
							teamState?.currentTeam?.members?.filter((member) => member.firstname && member.lastname),
							'members'
						),
					]}
					placeholder={'Select...'}
					fieldName='member'
					callback={handleFilter}
					value={filter?.member}
					width={300}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Date</h4>
				<SelectCustom
					htmlOptions={[
						<option value='date.desc'>Newest to oldest</option>,
						<option value='date.asc'>Oldest to newest</option>,
					]}
					placeholder={'Select...'}
					fieldName='sort'
					callback={(e) => {
						handleFilter(e.value, 'sort');
					}}
					value={filter?.date}
					width={300}
				/>
			</div>
			{/* <Button
				text='All filters'
				size='md'
				style='secondary'
				iconRight={<ArrowNarrowRightIcon />}
				onClick={openFilter}
			/> */}
		</div>
	);
}

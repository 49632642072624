import React from 'react';

/**
 *
 * @param {{header: string, children: any, backColor: string, style: string}} props
 */

export default function FormWrap({ children, backColor, style, header, innerWrap }) {
	return (
		<main className={`pageInnerCont${backColor ? ` ${backColor}` : ''}`} style={style || null}>
			{header && (
				<h1 className='wrapperHeader'>
					{header.icon}
					{header.text}
				</h1>
			)}
			{innerWrap ? <div className='wrapperInner form'>{children}</div> : <>{children}</>}
		</main>
	);
}

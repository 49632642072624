import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CircleSolidIcon, ManageTeamIcon, TeamGearIcon, TopnavTeamCircleIcon } from '../SVGIcons/SVGIcons';
import useCompVis from '../../hooks/useCompVis';

const TeamDropdown = React.forwardRef((props) => {
	let { teamState, teamDispatch } = useContext(CurrentTeamContext);

	const { ref } = useCompVis(true, () => {
		setTimeout(() => {
			props?.handleOpen(false);
		}, 100);
	});

	const [position, setPosition] = useState({ top: 0, left: 0, flipped: null });

	const updatePosition = () => {
		if (ref?.current) {
			const rect = ref.current.getBoundingClientRect();
			const overflowRight = rect.right > window.innerWidth;
			const overflowBottom = rect.bottom > window.innerHeight;

			let dropdownStyle = 'dropdown';

			if (overflowBottom && overflowRight) {
				dropdownStyle = 'dropdownFlippedVertHor';
			} else if (overflowBottom && !overflowRight) {
				dropdownStyle = 'dropdownFlippedVert';
			} else if (!overflowBottom && overflowRight) {
				dropdownStyle = 'dropdownFlippedHor';
			}

			setPosition((prev) => ({
				...prev,
				flipped: overflowBottom || overflowRight,
				style: dropdownStyle,
			}));
		}
	};

	function setTeam(team) {
		teamDispatch({ type: 'SET_TEAM', payload: { teamUuid: team.teamUuid, reload: true } });
	}

	useEffect(() => {
		window.addEventListener('resize', updatePosition);

		updatePosition();

		return () => {
			window.removeEventListener('resize', updatePosition);
		};
	}, []);

	return (
		<div
			className={position.style || 'dropdown'}
			ref={ref}
			style={{ visibility: position?.flipped === null ? 'hidden' : null }}>
			{teamState.userPermissions['isAdmin'] && (
				<Link to='/manage'>
					<div className='navSection'>
						<TeamGearIcon iconClass='manageTeamIcon' />
						<p>Manage</p>
					</div>
				</Link>
			)}
			<h4>Switch Team</h4>
			{teamState.userTeams
				?.reduce((teams, team) => {
					if (
						team.teamUuid === teamState.currentTeam.teamUuid ||
						team.isTeamOfOne === true ||
						team.teamInitials === 'ME'
					) {
						return [team, ...teams];
					}
					return [...teams, team];
				}, [])
				.map((team, key) => {
					return (
						<div
							key={key}
							className={`teamSwitchItem${
								team.teamUuid === teamState.currentTeam.teamUuid ? ' selected' : ''
							}`}
							onClick={() => {
								setTeam(team);
							}}>
							{team.teamInfo?.teamName ? (
								<>
									{team.teamIcon ? (
										<img src={team.teamIcon} alt='teamIcon' />
									) : (
										<div
											className={`teamChoicesIcon marginRight-10${
												team.avatarColor ? ` ${team.avatarColor}` : ''
											}`}>
											{team.teamInitials?.slice(0, 2)?.toUpperCase()}
										</div>
									)}
									<p>{team.teamInfo?.teamName}</p>
								</>
							) : (
								<>
									<TopnavTeamCircleIcon iconClass='icon marginRight-10' />
									<p
										onClick={() => {
											setTeam(team);
										}}>
										{team.teamInfo.teamName || 'Personal'}
									</p>
								</>
							)}
							{team.teamUuid === teamState.currentTeam.teamUuid && <CircleSolidIcon iconClass='circle' />}
						</div>
					);
				})}
		</div>
	);
});

export default TeamDropdown;

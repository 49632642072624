import React, { useEffect, useState } from 'react';
import {
	AtIcon,
	CalendarIcon,
	FileIcon,
	MapPinIcon,
	PrintIcon,
	TagIcon,
	TargetIcon,
	ThreeDotsHorizontalIcon,
	TruckRightIcon,
} from '../../components/SVGIcons/SVGIcons';
import { dateConverter, parseTrackingStatus } from '../../helpers';
import { itemLabelTemp, packLabelTemp } from '../../templates/templates';
import Button from '../../components/Button';
import PackageListItems from '../../components/Listing/ListItemTypes/PackageListItems';
import PageWrapper from '../../components/Invoice/PageWrapper';
import { useLocation, useNavigate, useParams } from 'react-router';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import StopwatchCont from '../../components/ModalListing/StopwatchCont';
import ItemsList from './ItemsList';
import MemberInfo from '../../components/MemberInfo';
import Axios from 'axios';
import Loading from '../../components/Loading/Loading';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';

export default function PackageDetails() {
	const [pack, setPack] = useState({});
	const [loading, setLoading] = useState(true);
	const [reload, setReload] = useState(false);

	let location = useLocation();
	let params = useParams();

	const { showModal } = useModalsGlobal();

	let navigate = useNavigate();

	const { data, fetch, reqLoading } = useFetch(`${config.apiv1}/package/package.read/${params?.packageUuid}`);

	let addressString = pack?.address?.addressLine1
		? `${pack?.address?.name ? pack?.address?.name : ''}${
				pack?.address?.addressLine1 ? `, ${pack?.address?.addressLine1}` : ''
		  }${pack?.address?.county ? `, ${pack?.address?.county}` : ''}${
				pack?.address?.postalCode ? `, ${pack?.address?.postalCode}` : ''
		  }`
		: '';

	function getFilePath(file) {
		let res = Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			return url;
		});
		return res;
	}

	useEffect(async () => {
		let mounted = true;
		if (mounted && reload === false) {
			let pack = await fetch(`${config.apiv1}/package/package.read/${params?.packageUuid}`);
			setPack(pack);
			setLoading(false);
		} else {
			setLoading(true);
			setReload(false);
		}
		return () => (mounted = false);
	}, [reload]);

	return (
		<PageWrapper
			header={{
				icon: <TagIcon />,
				text: 'Package details',
				theme: 'sale',
				button1: {
					text: 'Edit package',
					cb: () =>
						showModal('editPackage', {
							callback: ({ data }) => {
								setPack({ ...pack, ...data });
							},
							data: pack,
							submitUrl: `${config.apiv1}/package/package.update/${params?.packageUuid}`,
							method: 'PATCH',
						}),
					style: 'secondary',
					width: 140,
				},
				button2: {
					text: 'Change status',
					cb: () => navigate(`/package?uuid=${params?.packageUuid}`),
					style: 'secondary',
					width: 140,
				},
				button3: {
					cb: () => packLabelTemp(pack, getFilePath, { x: 20.8, y: 7.5 }),
					style: 'secondary',
					iconRight: <PrintIcon />,
					width: 40,
				},
				// button3: {
				// 	text: 'More',
				// 	cb: null,
				// 	style: 'secondary',
				// 	iconRight: <ThreeDotsHorizontalIcon />,
				// 	width: 140,
				// 	disabled: true,
				// },
			}}>
			<div className='packageDetails'>
				{!loading ? (
					<>
						<div className='row borderBottom'>
							<h3 className='marginRight-auto'>{pack?.packageRef}</h3>
							{pack?.courier ? <h3>{pack?.courier[0]?.companyName}</h3> : ''}
							<StopwatchCont pack={pack} />
							{pack?.user ? <MemberInfo user={pack?.user} /> : <></>}
						</div>
						<div className='row'>
							<div className='col'>
								{addressString ? (
									<p>
										<TargetIcon iconClass='dataIcon' />
										{addressString}
									</p>
								) : (
									<></>
								)}
								{pack?.recipient ? (
									<p>
										<AtIcon iconClass='dataIcon' />
										{pack?.recipient}
									</p>
								) : (
									<></>
								)}
							</div>
							<div className='col'>
								<p>
									<MapPinIcon iconClass='dataIcon' />
									{pack?.locations
										? `With ${parseTrackingStatus(
												pack?.locations[pack?.locations?.length - 1]?.status
										  )?.text?.toLocaleLowerCase()}`
										: ''}
								</p>
							</div>
							<div className='col'>
								<p>
									<CalendarIcon iconClass='dataIcon' />
									{dateConverter(pack?.quotedCollectionDateTime)}
								</p>
								<p>
									<TruckRightIcon iconClass='dataIcon' />
									{dateConverter(pack?.quotedDeliveryDateTime)}
								</p>
							</div>
						</div>
						<ItemsList listItems={pack?.items} pack={pack} reload={setReload} />
					</>
				) : (
					<Loading type='circle' size='medium' />
				)}
			</div>
		</PageWrapper>
	);
}

{
	/* {pack && (
				<div className='packageDetailsCont'>
					<div className='dataCont'>
						<div className='row'>
							<h4>Location: </h4>
							{pack.locations ? (
								<p>{parseTrackingStatus(currLocation.status).icon}</p>
							) : (
								<p>{parseTrackingStatus(0).icon}</p>
							)}
						</div>
						<div className='row'>
							<h4>Courier: </h4>
							<p>{filterCourier()?.companyName || 'None'}</p>
						</div>
						<div className='row'>
							<h4>Pickup: </h4>
							<p>{dateConverter(pack.quotedCollectionDateTime)}</p>
						</div>
						{currLocation.note === 'Delivered' && (
							<div className='row'>
								<h4>Status: </h4>
								<p>Delivered</p>
							</div>
						)}
						<div className='row marginTop-20'>
							{currLocation.note !== 'Delivered' && (
								<Button
									text='Remove package'
									size='sm'
									style='secondary'
									minWidth={140}
									onClick={() => {
										removePackPrompt(pack);
									}}
								/>
							)}
						</div>
						<div className='row marginTop-20'>
							<Button
								text='PDF'
								size='sm'
								style='secondary'
								minWidth={140}
								iconLeft={<FileIcon />}
								onClick={() => {
									packLabelTemp(pack, getFilePath, { x: 20.8, y: 7.5 });
								}}
							/>
						</div>
					</div>
					<h2>Package List</h2>
					<div className='cols'>
						<h4>Description</h4>
						<h4>Order Ref</h4>
						<h4>Note</h4>
						<h4></h4>
					</div>
					<div className='orderItems'>
						{pack?.items?.length > 0 ? (
							<>
								{pack.items.map((item, key) => {
									return (
										<div className='row' key={key}>
											<p>{item.itemDesc}</p>
											<p>{item.orderRef}</p>
											<p>{item.note}</p>
											<div className='btnRow'>
												<FileIcon
													onClick={() => {
														itemLabelTemp(item, getFilePath, true, { x: 6.5, y: 3.5 });
													}}
													iconClass='orderPageDiscIcon'
												/>
												{!currLocation.note === 'Delivered' && (
													<button
														className='btn3-secondary marginLeft-20'
														onClick={() => {
															removeItemPrompt(item.itemUuid);
														}}>
														Remove
													</button>
												)}
											</div>
										</div>
									);
								})}
							</>
						) : (
							<div className='message'>
								This package has no items. If you want to send something you have to do it from the
								Order Details page through one of your active orders.
							</div>
						)}
					</div>
				</div>
			)} */
}

import React from 'react';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';
import procuurLogo from '../../assets/img/procuur-logo-2.png';

export default function ApplyRegisterForm(props) {
	const { formData, handleFormData, customErrors, setApplied, applied } = props || {};
	return (
		<>
			{!applied ? (
				<div className='mainFormCont wide' id='applyRegisterForm'>
					<h2>Apply for registration</h2>
					<TextInput
						text={'Name'}
						name={'name'}
						callback={handleFormData}
						value={formData?.name}
						customError={customErrors?.name}
						controlled
						required
					/>
					<TextInput
						text={'Email'}
						name={'email'}
						callback={handleFormData}
						value={formData?.email}
						customError={customErrors?.email}
						controlled
						required
					/>
					<TextInput
						text={'Profession'}
						name={'profession'}
						callback={handleFormData}
						value={formData?.profession}
						customError={customErrors?.profession}
						controlled
						required
					/>
					<TextInput
						text={'Business name'}
						name={'business'}
						callback={handleFormData}
						value={formData?.business}
						customError={customErrors?.business}
						controlled
						required
					/>
					<TextInput
						text={'GDC Number'}
						name={'gdc_number'}
						callback={handleFormData}
						value={formData?.gdc_number}
						customError={customErrors?.gdc_number}
						controlled
					/>
					<TextareaInput
						text={'Comments'}
						name={'comments'}
						callback={handleFormData}
						value={formData?.comments}
						customError={customErrors?.comments}
						controlled
					/>
				</div>
			) : (
				<div className='centerOnPage'>
					<h2>Thank you for your application! We will get back to you when we have processed it.</h2>
					<h3 className='marginTop-40'>from the team at</h3>
					<img className='marginTop-40' src={procuurLogo} alt='Procuur logo' />
				</div>
			)}
		</>
	);
}

import React from 'react';

export default function FormStep(props) {
	const { children, alignCenter, style, closed } = props || {};
	return (
		<div className={`formStep${alignCenter ? ' alignCenter' : ''}${closed ? ' closed' : ''}`} style={style}>
			{!closed && children}
		</div>
	);
}

import React from 'react';
import SelectInput from '../Inputs/SelectInput';
import TextareaInput from '../Inputs/TextareaInput';
import holdReasonOptions from '../Options/holdReasonOptions';

export default function HoldOrderForm(props) {
	const { handleData, formData, handleFormSelectData } = props || {};

	return (
		<>
			<TextareaInput
				text={'Description'}
				name={'holdDescription'}
				callback={handleData}
				defaultValue={formData?.holdDescription}
				tooltip='Describe what the other party needs to do to further the ordering process and release the hold status'
			/>
			<SelectInput
				htmlOptions={holdReasonOptions}
				text={'Reason'}
				name={'reason'}
				value={formData?.reason}
				callback={handleFormSelectData}
			/>
		</>
	);
}

import React, { useContext, useState } from 'react';
import { SupplierPlusIcon, TrustedHollowIcon } from '../../SVGIcons/SVGIcons';
import usePost from '../../../hooks/usePost';
import config from '../../../config';
import useModals from '../../../hooks/useModals';
import FlexModal from '../../Modals/FlexModal';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import InviteTeamForm from '../../Forms/InviteTeamForm';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';
import FadeOutModal from '../../Modals/FadeOutModal';
import useFetch from '../../../hooks/useFetch';

export default function TrustedSettingsHeader() {
	const [formData, setFormData] = useState({});
	const [formVis, setFormVis] = useState(false);

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	const { handleModal, handleFadeModal, modalsState } = useModals();

	const { fetch } = useFetch();

	const { data, post, setCustomUrl, setCustomFormId, reqLoading, error, setCustomErrors, customErrors } = usePost(
		`${config.apiv1}/email/team.invite`,
		(data) => {
			if (data.ok && Object.keys(data?.customErrors).length === 0) {
				setFormData({});
				handleModal('');
				handleFadeModal('modalFading', {
					show: true,
					isFading: false,
					message: `Invite was sent to ${formData.email}`,
				});
			} else {
				handleModal('');
				handleFadeModal('modalFading', {
					show: true,
					isFading: false,
					message:
						data?.error?.request?.status === 403
							? 'Insufficient permissions'
							: `There was an error sending the email`,
				});
			}
		},
		'inviteForm'
	);

	const handleFormData = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	async function handlePost() {
		let userExists = await fetch(`${config.auth}/v1/user/user.read/email/${formData.email}`, false, true);

		if (!userExists?.user) {
			post({
				senderName: `${
					userState.currUser.name || `${userState.currUser.given_name} ${userState.currUser.family_name}`
				}${
					teamState.currentTeam?.teamInfo?.teamName
						? ` from ${teamState.currentTeam?.teamInfo?.teamName}`
						: ''
				}`,
				senderTeamUuid: teamState.currentTeam.teamUuid,
				invitations: [
					{
						recipientEmail: formData.email,
						recipientName: formData.recipientName,
						customText: formData.customText,
					},
				],
			});
			setFormVis(false);
		} else {
			handleFadeModal('modalFading', {
				show: true,
				isFading: false,
				message: 'A user with that email address is already a part of Procuur',
			});
		}
	}

	return (
		<>
			<FadeOutModal
				show={modalsState['modalFading']?.show}
				isFading={modalsState['modalFading']?.isFading}
				message={modalsState['modalFading']?.message}
			/>
			{modalsState['inviteModal']?.open && (
				<FlexModal
					setShow={handleModal}
					modalName={'inviteModal'}
					zIndex={99}
					btn1={formVis === true || teamState.isTeamOfOne ? 'Send' : null}
					closeBtn={true}
					closeBtnCallback={() => {
						setFormVis(false);
					}}
					updateLoading={reqLoading}
					callback={handlePost}
					body={
						<InviteTeamForm
							teamOfOne={teamState.isTeamOfOne}
							handleFormData={handleFormData}
							customErrors={customErrors}
							handleModal={handleModal}
							setFormVis={setFormVis}
							formData={formData}
							formVis={formVis}
						/>
					}
				/>
			)}
			<div className='carouselHeader short'>
				<div>
					<div className='flexRow'>
						<TrustedHollowIcon iconClass='supplierTrustedIcon' />
						<h2>Suppliers</h2>
					</div>
				</div>
				<div className='carouselHeaderBtnCont'>
					<button
						className='dashboardBtnSeeAll'
						onClick={() => {
							handleModal('inviteModal', { open: true });
						}}>
						<SupplierPlusIcon iconClass='btnIcon' />
						Invite a supplier
					</button>
				</div>
			</div>
		</>
	);
}

import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import ListFieldPre from './ListFieldPre';
import { ArrowNarrowLeftIcon, PenIcon } from '../../../components/SVGIcons/SVGIcons';
import ContainerField from './ContainerField';
import EditBox from './EditBox';
import Button from '../../../components/Button';

export default function StagingCol(props) {
	const {
		checkIfDupeBehaviorAndCorrect,
		column,
		handleModal,
		dropsDisabled,
		showDropZones,
		setEditingFields,
		editingFields,
		remove,
		handleFormInput,
		handleFile,
		handleSelectField,
		addSelectField,
		formData,
		customErrors,
		popOption,
		addRadio,
		setFormData,
		setChangeMade,
		setIsEditing,
		save,
	} = props || {};

	return (
		<div className='col docket'>
			<div className='backBtn'>
				<Button
					text='Back to library'
					size='lg'
					style='tertiary-primary'
					iconLeft={<ArrowNarrowLeftIcon />}
					onClick={() => {
						setChangeMade(false);
						setIsEditing(false);
					}}
				/>
			</div>
			<Droppable droppableId={column.id} isDropDisabled={column.immutable === true}>
				{(provided) => (
					<div
						className={`dropSection docket${showDropZones === true ? ' dropZone' : ''}`}
						ref={provided.innerRef}
						{...provided.droppableProps}>
						{column.fields.map((field, key) => {
							if (field.droppable) {
								return (
									<React.Fragment key={field.id}>
										<ContainerField
											field={field}
											column={column}
											handleModal={handleModal}
											fieldIndex={key}
											dropsDisabled={dropsDisabled}
											showDropZones={showDropZones}
										/>
									</React.Fragment>
								);
							} else {
								return (
									<React.Fragment key={field.id}>
										{editingFields?.field?.id === field?.id ? (
											<EditBox
												checkIfDupeBehaviorAndCorrect={checkIfDupeBehaviorAndCorrect}
												handleSelectField={handleSelectField}
												handleFormInput={handleFormInput}
												addSelectField={addSelectField}
												customErrors={customErrors}
												handleModal={handleModal}
												handleFile={handleFile}
												popOption={popOption}
												addRadio={addRadio}
												formData={formData}
												editingFields={editingFields}
												colId={column.id}
												remove={remove}
												close={() => {
													setEditingFields(null);
													setFormData({});
												}}
												field={field}
												index={key}
												save={save}
											/>
										) : (
											<ListFieldPre
												index={key}
												field={field}
												handleModal={handleModal}
												droppableId={column.id}
												showTools={true}
												editIcon={
													<div
														className='toolIcon discIcon'
														onClick={() => {
															setEditingFields({
																field: field,
																colId: column.id,
															});
															setFormData(field.data || {});
														}}>
														<PenIcon />
													</div>
												}
											/>
										)}
									</React.Fragment>
								);
							}
						})}
						{column.fields.length === 0 && (
							<>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
							</>
						)}
						{column.fields.length === 1 && (
							<>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
							</>
						)}
						{column.fields.length === 2 && (
							<>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
							</>
						)}
						{column.fields.length === 3 && (
							<>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
							</>
						)}
						{column.fields.length === 4 && (
							<>
								<div className='placeholder'></div>
								<div className='placeholder'></div>
							</>
						)}
						{column.fields.length === 5 && (
							<>
								<div className='placeholder'></div>
							</>
						)}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
	);
}

import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import { CircleSolidIcon } from '../SVGIcons/SVGIcons';

export default function HeroCarousel(props) {
	const { items } = props || {};

	const [slideOffset, setSlideOffset] = useState(-window.innerWidth);
	const [slideWidth, setSlideWidth] = useState(window.innerWidth);
	const [totalWidth, setTotalWidth] = useState(0);

	const innerRef = useRef(null);

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	function next() {
		function getNewOffset(slideOffset) {
			if (slideOffset !== totalWidth) {
				return slideOffset - slideWidth;
			} else {
				return -slideWidth;
			}
		}
		setSlideOffset((slideOffset) => getNewOffset(slideOffset));
	}

	function prev() {
		setSlideOffset(slideOffset + slideWidth);
	}

	useEffect(() => {
		const recalibrateSlide = () => {
			setTotalWidth(-innerRef.current.offsetWidth * items?.length);
			setSlideWidth(innerRef.current.offsetWidth);
			setSlideOffset(-innerRef.current.offsetWidth);
		};

		recalibrateSlide();
	}, [windowSize]);

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		let mounted = true;
		let intervalId;
		if (mounted && items?.length > 1) {
			intervalId = setInterval(() => {
				next(slideOffset, slideWidth);
			}, 7000);
		}

		return () => {
			mounted = false;
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [windowSize, slideOffset]);

	return (
		<div className='heroCarousel'>
			<div
				ref={innerRef}
				className='innerCont'
				style={{
					transform: `translateX(${slideOffset + slideWidth}px)`,
					transition: 'transform 0.6s ease-in-out',
				}}>
				{items &&
					items.length > 0 &&
					items.map((item, key) => {
						return (
							<div
								className='card'
								key={key}
								style={{
									minWidth: `${slideWidth}px`,
								}}>
								{item.imgSrc && item.flip && <img src={item.imgSrc} alt='Hero image' />}
								<div
									className='col'
									style={{ backgroundColor: `${item.background ? item.background : ''}` }}>
									<h1 className='header'>{item.header}</h1>
									<p className='paragraph'>{item.paragraph}</p>
									<Button size='xxl' style='primary' text={item.btnText} onClick={item.btnCallback} />
								</div>
								{item.imgSrc && !item.flip && <img src={item.imgSrc} alt='Hero image' />}
							</div>
						);
					})}
			</div>
			<div className='carouselNav'>
				<div
					className={slideOffset === -slideWidth * 1 ? 'dot selected' : 'dot'}
					onClick={() => setSlideOffset(-slideWidth * 1)}>
					<CircleSolidIcon />
				</div>
				<div
					className={slideOffset === -slideWidth * 2 ? 'dot selected' : 'dot'}
					onClick={() => setSlideOffset(-slideWidth * 2)}>
					<CircleSolidIcon />
				</div>
				<div
					className={slideOffset === -slideWidth * 3 ? 'dot selected' : 'dot'}
					onClick={() => setSlideOffset(-slideWidth * 3)}>
					<CircleSolidIcon />
				</div>
			</div>
		</div>
	);
}

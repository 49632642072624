import React, { useContext, useRef, useState } from 'react';
import {
	AppIcon,
	CloseIcon,
	CommandIcon,
	FileLinesIcon,
	ListEditIcon,
	MessageChatSquareIcon,
	ProgressIcon,
} from '../../components/SVGIcons/SVGIcons';
import FeaturesIcons from './components/FeaturesIcons';
import FeaturesList from './components/FeaturesList';
import Section from './components/Section';
import Button from '../../components/Button';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { useNavigate, useParams } from 'react-router';
import hero_1 from '../../assets/img/hero_1.png';
import hero_2 from '../../assets/img/hero_2.png';
import hero_3 from '../../assets/img/hero_3.png';
import featureImg1 from '../../assets/photos/PUBLIC_pages/app_drawing-tool.jpg';
import featureImg2 from '../../assets/photos/PUBLIC_pages/app_tracking.jpg';
import featureImg3 from '../../assets/photos/PUBLIC_pages/app_manage.jpg';
import HeroHeader from './components/HeroHeader';
import ReactPlayer from 'react-player';
import Overlay from '../../components/Overlay';
import Loading from '../../components/Loading/Loading';

export default function Features() {
	let params = useParams();
	let navigate = useNavigate();

	let channel = params?.channel;

	const [loadingVideo, setLoadingVideo] = useState(true);
	const [showTour, setShowTour] = useState(false);

	const [items, setItems] = useState({
		dentists: {
			header: 'Discover Procuur for Dentists',
			paragraph:
				'Procuur is a procurement platform from which you can place and track your prescriptions. Start using it with one of your Dental Laboratories and then invite your others; all for FREE*.',
			btnText: 'Discover Procuur for Dentists',
			imgSrc: hero_1,
			background: '#D5D4CE',
			btnCallback: toggleTour,
		},
		dental_labs: {
			header: 'Discover Procuur for Dental Laboratories',
			paragraph:
				'Procuur is a procurement platform from which you can create customised dockets for your customers to fill in online sending you Prescriptions online from their mobile, tablet or desktop computer; all for FREE* to use at its basic subscription level.',
			btnText: 'Discover Procuur for Dental Laboratories',
			imgSrc: hero_2,
			flip: true,
			btnCallback: toggleTour,
		},
		dental_practices: {
			header: 'Discover Procuur for Dental Practices',
			paragraph:
				'Procuur is a procurement platform for Dentists to place and manage Dental Laboratory Prescriptions. We are in the process of developing features specifically for Dental Practices and Dental Groups. It’s easy to use and FREE* to use at its basic subscription level.',
			btnText: 'Discover Procuur for Practices',
			imgSrc: hero_3,
			background: '#E8E8E6',
			btnCallback: toggleTour,
		},
	});

	const [sectionHeaders, setSectionHeaders] = useState({
		dentists: {
			custom_forms: {
				listHeader: 'Real-time ordering',
				listParagraph:
					'Add and manage your Dental Laboratories, then create a prescription and fill in their docket online at the touch of a button.',
			},
			tracking: {
				listHeader: 'Order tracking and messaging',
				listParagraph:
					'Track the progress of your prescription, see inbound and outbound prescriptions over time and manage you business around the deliveries you require.',
			},
			customers: {
				listHeader: 'On the go and time saving',
				listParagraph:
					'Time is money. Procuur allows you to track your prescriptions on the go with our mobile application. You can message labs quickly and conveniently and free up time.',
			},
			gettingStarted: {
				listHeader: 'Getting Started',
				listParagraph1:
					'You can download Procuur as a mobile app or use it from a browser (on your mobile, tablet or desktop).',
				listParagraph2: (
					<>
						At this time Procuur is exclusive to Dentists globally, therefore please{' '}
						<span className='spanLinkText' onClick={() => navigate('/features/apply')}>
							Apply for Registration
						</span>
						. If you have received an invitation from your Dental Laboratory then please follow the link in
						that email.
					</>
				),
			},
		},
		dental_labs: {
			custom_forms: {
				listParagraph1: (
					<>
						Please note that if you are a Transactor Systems (labManager) customer you will receive
						Professional level subscription for FREE with your labManager license. Please contact{' '}
						<a
							className='spanLinkText'
							target='_new'
							href='https://transactorsystems.com/labmanager-meets-procuur/'>
							Transactor Systems
						</a>{' '}
						to learn more.
					</>
				),
				listHeader: 'Real-time ordering and customised forms',
				listParagraph:
					'See your jobs arrive in real-time, track those jobs and any associated files such as pictures or Intra-oral scans AND message your customer pertaining to that job',
			},
			tracking: {
				listHeader: 'Job tracking and messaging',
				listParagraph:
					'Track the progress of received jobs, see inbound and outbound jobs over time and manage you business around your production schedule.',
			},
			customers: {
				listHeader: 'What you can now offer your customers',
				listParagraph:
					'Your customers can send their prescriptions to you by filling in your online docket. They can do this simply from their mobile, tablet or desktop computer. It’s FREE* for them to use and you can invite them at the touch of a button',
			},
			gettingStarted: {
				listHeader: 'Getting Started',
				listParagraph1:
					'You can download Procuur as a mobile app or use it from a browser (on your mobile, tablet or desktop).',
				listParagraph2: (
					<>
						At this time Procuur is exclusive to Dental Laboratories globally, therefore please{' '}
						<span className='spanLinkText' onClick={() => navigate('/features/apply')}>
							Apply for Registration
						</span>
						. If you have received an invitation from your Dental Practice then please follow the link in
						that email.
					</>
				),
			},
		},
		dental_practices: {
			custom_forms: {
				listHeader: 'Team and Group Management',
				listParagraph:
					'Join all the disparate systems from practice management and lab management to accounting software, thus digitising prescriptions across your Dental Practice and Group.',
			},
			tracking: {
				listHeader: 'Business Intelligence',
				listParagraph:
					'Gain insight into Practice and Group Dental Laboratory expenditure and frequency of make and remake.',
			},
			customers: {
				listHeader: 'What you can now offer your  Dental Practitioners',
				listParagraph:
					'Offer your Dental Practitioners, Nurses and Receptionists a way to oversee their Dental Laboratory Prescription business. ',
			},
			gettingStarted: {
				listHeader: 'Getting Started',
				listParagraph1: (
					<>
						If you are interested in being involved in testing and feedback, or you would like more
						information please contact us.
					</>
				),
				listParagraph2: (
					<>
						If, as a Practice owner you are also a practicing Dentist you are more than welcome to{' '}
						<span className='spanLinkText' onClick={() => navigate('/features/apply')}>
							Apply for Registration
						</span>
						. If you would like to stay in the loop then please sign up for our newsletter.
					</>
				),
			},
		},
	});

	const [features, setFeatures] = useState({
		dentists: [
			{
				header: 'Digital Ordering',
				paragraph:
					'Create prescriptions at the touch of a button OR ask your nurse to create them on your behalf.',
				icon: <ListEditIcon iconClass='icon' />,
			},
			{
				header: 'Order tracking',
				paragraph:
					'See your prescriptions progress along with the tracking of associated files AND deliveries of staged work.',
				icon: <ProgressIcon iconClass='icon' />,
			},
			{
				header: 'Buyer to Seller Communications',
				paragraph:
					'Message with your Lab and retain the conversation with the prescription in an easy-to-use chat app.',
				icon: <MessageChatSquareIcon iconClass='icon' />,
			},
			{
				header: 'Third-party software plug-ins',
				paragraph:
					'Integrate Dentally and have the Patient data you choose flow seamlessly through to your Lab.',
				icon: <AppIcon iconClass='icon' />,
			},
			{
				header: 'Ubiquitous – mobile, desktop and tablet',
				paragraph: 'See your jobs, chat with your lab from a mobile, tablet or desktop computer.',
				icon: <CommandIcon iconClass='icon' />,
			},
			{
				header: 'File management',
				paragraph: 'Receive or send files and photos to share with your Lab.',
				icon: <FileLinesIcon iconClass='icon' />,
			},
		],
		dental_practices: [
			{
				header: 'Digitize Prescriptions',
				paragraph:
					'Streamline your Dental Practice by digitising your Dental Laboratory Prescriptions and free up your Dentists and Dental Nurses time.',
				icon: <ListEditIcon iconClass='icon' />,
			},
			{
				header: 'Prescription tracking',
				paragraph:
					'Whether it be your receptionist, Dental nurses and Dental Practitioners Prescription tracking frees them of the worry of Prescriptions being lost of arriving too late.',
				icon: <ProgressIcon iconClass='icon' />,
			},
			{
				header: 'Business Intelligence',
				paragraph:
					'Gain insight into your Practices Prescription frequency and costs through graphs and analytical data.',
				icon: <MessageChatSquareIcon iconClass='icon' />,
			},
			{
				header: 'Third party software plug-ins',
				paragraph: 'Have Dental Laboratory invoices flow through to your accounting software automatically.',
				icon: <AppIcon iconClass='icon' />,
			},
			{
				header: 'Ubiquitous – mobile, desktop and tablet',
				paragraph: 'Gain insight of your Dental Laboratory prescription usage on a mobile, tablet or desktop.',
				icon: <CommandIcon iconClass='icon' />,
			},
			{
				header: 'Team and Group Management',
				paragraph:
					'Create multiple Practices and gain insight into Dental Laboratory prescription habits across your Group',
				icon: <FileLinesIcon iconClass='icon' />,
			},
		],
		dental_labs: [
			{
				header: 'Digitize Prescriptions',
				paragraph:
					'Create customised forms for your customers to complete. Invite your customers and receive jobs in real-time.',
				icon: <ListEditIcon iconClass='icon' />,
			},
			{
				header: 'Prescription tracking',
				paragraph:
					'Give your customers tracking – they can see their jobs‘ progress along with the tracking of associated files AND deliveries of staged work.',
				icon: <ProgressIcon iconClass='icon' />,
			},
			{
				header: 'Buyer to seller Communications',
				paragraph:
					'Message with your customers and retain the conversation with the job in an easy-to-use chat app.',
				icon: <MessageChatSquareIcon iconClass='icon' />,
			},
			{
				header: 'Third-party software plug-ins',
				paragraph:
					'Send your customers invoices and have your job invoices flow through to your accounting software.',
				icon: <AppIcon iconClass='icon' />,
			},
			{
				header: 'Ubiquitous – mobile, desktop and tablet',
				paragraph: 'See your jobs, chat with your customers from a mobile, tablet or desktop.',
				icon: <CommandIcon iconClass='icon' />,
			},
			{
				header: 'Manage outsourcing',
				paragraph:
					'Outsource your Jobs or a part of the job to a 3rd party supplier, then track that part within the main job.',
				icon: <FileLinesIcon iconClass='icon' />,
			},
		],
	});

	const [featureLists, setFeatureLists] = useState({
		dentists: {
			videoTourUrl: 'https://vimeo.com/870658023',
			custom_forms: {
				img: featureImg1,
				features: [
					'Invite your Dental Laboratories at the touch of a button',
					'Create, send and track your prescriptions on mobile, tablet and desktop computers.',
					'Send files and photos with your prescriptions.',
					'View the history of all your prescriptions over time.',
					'Give your nurse the ability to raise prescriptions on your behalf.',
				],
			},
			tracking: {
				img: featureImg2,
				features: [
					'Send and answer messages from you Dental Laboratories online.',
					'Track your prescriptions',
					'Send Dental Impressions with your prescription',
					'Receive files such as your Invoice and your Patient Statement electronically',
					'View the history of all prescriptions over time',
				],
			},
			customers: {
				img: featureImg3,
				features: [
					'Send messages to you about the order',
					'Track your payments for easy accountancy.',
					'If your Dental Practice uses Dentally then add your Patient details to your docket at the touch of a button.',
					'Plan for late deliveries ahead of time. Give your Practice time to re-schedule appointments and free up your calendar for alternative appointments.',
				],
			},
		},
		dental_labs: {
			videoTourUrl: 'https://vimeo.com/870664218',
			custom_forms: {
				img: featureImg1,
				features: [
					'Create, send and track your Jobs on mobile, tablet and desktop computers',
					'Send files and photos with your jobs.',
					'View the history of all your jobs over time.',
					'Assign jobs to technicians',
				],
			},
			tracking: {
				img: featureImg2,
				features: [
					'Track your jobs',
					'Track receipt of Dental Impressions',
					'Send files such as your Invoice and Patient Statement electronically.',
					'View the history of all jobs over time.',
				],
			},
			customers: {
				img: featureImg3,
				features: [
					'Send messages to you about the job',
					'See their jobs online Track the progress of those jobs online',
					'Add photos or files to their jobs',
					'Get their Invoice and Patient Statement online',
				],
			},
		},
		dental_practices: {
			videoTourUrl: 'https://vimeo.com/870668746',
			custom_forms: {
				img: featureImg1,
				features: [
					'Create multiple Practices under one or several groups',
					'Manage multiple Practices with Group management features.',
					'Give Practice Managers oversight of their Practice. Give Group managers oversight across the whole group.',
					'Manage data coming from Dental Management software such as Dentally, though to accounting software such as Xero, Sage or Quickbooks',
				],
			},
			tracking: {
				img: featureImg2,
				features: [
					'Gain Dental Laboratory Prescription insight and business intelligence with prescription analytics',
					'Compare pricing across your business',
					'Ensure Dental freedom',
					'Help ensure you  stay compliant (CQC, GDPR)',
				],
			},
			customers: {
				img: featureImg3,
				features: [
					'Give your Dental Practitioners the ability to create and track prescriptions with ease and on the go.',
					'Give practitioners, nurses and receptionists the ability to track prescriptions and move appointments when deliveries are delayed.',
					'Log all communications so that the nurse or Practice Manager can see the status and location of the Prescription.',
					'Get Invoices and Patient Statements electronically.',
				],
			},
		},
	});

	const { userState } = useContext(CurrentUserContext);

	if (userState.isAuthenticated) {
		navigate('/');
	}

	const videoRef = useRef(null);

	function toggleTour() {
		setShowTour(!showTour);
		setLoadingVideo(true);
	}

	return (
		<div className='frontPage'>
			<HeroHeader item={items[channel]} />
			<div className='hidden' ref={videoRef}></div>
			{showTour && (
				<>
					<Overlay showOverlay={true} loadOverlay={true} />
					<div className='videoModal'>
						<div className='loadingCont' style={{ display: `${loadingVideo ? 'block' : 'none'}` }}>
							<CloseIcon iconClass='closeIcon' onClick={toggleTour} />
							<Loading type='circle' />
						</div>
						<div className='videoCont' style={{ display: `${loadingVideo ? 'none' : 'block'}` }}>
							<CloseIcon iconClass='closeIcon' onClick={toggleTour} />
							<ReactPlayer
								style={{ maxWidth: '100%' }}
								fallback={<div>Hello</div>}
								onReady={() => setLoadingVideo(false)}
								width={1050}
								height={window.innerWidth < 600 ? 300 : 600}
								url={featureLists[channel].videoTourUrl}
								controls></ReactPlayer>
						</div>
					</div>
				</>
			)}
			<FeaturesIcons
				sectionHeader={sectionHeaders[channel]?.icons?.sectionHeader}
				paragraph1={sectionHeaders[channel]?.icons?.paragraph1}
				paragraph2={sectionHeaders[channel]?.icons?.paragraph2}
				features={features[channel]}
				border
			/>
			<FeaturesList
				sectionHeader={sectionHeaders[channel]?.custom_forms?.listSectionHeader}
				paragraph1={sectionHeaders[channel]?.custom_forms?.listParagraph1}
				listHeader={sectionHeaders[channel]?.custom_forms?.listHeader}
				listParagraph={sectionHeaders[channel]?.custom_forms?.listParagraph}
				icon={<ListEditIcon iconClass='icon' />}
				features={featureLists[channel]?.custom_forms?.features}
				align='left'
				img={featureLists[channel]?.custom_forms?.img}
			/>
			<FeaturesList
				listHeader={sectionHeaders[channel]?.tracking?.listHeader}
				listParagraph={sectionHeaders[channel]?.tracking?.listParagraph}
				icon={<ProgressIcon iconClass='icon' />}
				features={featureLists[channel]?.tracking?.features}
				img={featureLists[channel]?.tracking?.img}
				align='right'
			/>
			<FeaturesList
				listHeader={sectionHeaders[channel]?.customers?.listHeader}
				listParagraph={sectionHeaders[channel]?.customers?.listParagraph}
				icon={<MessageChatSquareIcon iconClass='icon' />}
				features={featureLists[channel]?.customers?.features}
				img={featureLists[channel]?.customers?.img}
				align='left'
				border
			/>
			<Section>
				<div className='col'>
					<h4 className='header marginBottom-20'>{sectionHeaders[channel]?.gettingStarted?.listHeader}</h4>
					<p className='paragraph marginBottom-20'>
						{sectionHeaders[channel]?.gettingStarted?.listParagraph1}
					</p>
					<p className='paragraph'>{sectionHeaders[channel]?.gettingStarted?.listParagraph2}</p>
					<div className='col marginTop-20'>
						<Button
							size='xxl'
							text='Apply for registration'
							style='primary'
							onClick={() => navigate('/features/apply')}
						/>
						<p className='paragraph italic marginTop-40'>
							*Please note that Procuur will charge you a small fee for file storage.
						</p>
					</div>
				</div>
			</Section>
			{channel !== 'dental_practices' && (
				<Section>
					<div className='col'>
						<h4 className='header marginBottom-20'>Are you also a Dental Practice Owner?</h4>
						<p className='paragraph marginBottom-20'>
							Gain overview of your Dental Laboratory prescriptions and give your Dental Nurses the
							ability to track incoming Dental Laboratory prescriptions at the touch of a button.
						</p>
						<div className='col marginTop-20'>
							<Button
								size='xxl'
								text='Discover Procuur for Practices'
								style='primary'
								onClick={() => navigate('/features/dental_practices')}
							/>
						</div>
					</div>
				</Section>
			)}
			{channel !== 'dental_labs' && (
				<Section>
					<div className='col'>
						<h4 className='header marginBottom-20'>Are you a Dental Laboratory?</h4>
						<p className='paragraph marginBottom-20'>
							Receive prescriptions electronically, customize your dockets and communicate with your
							customers from one simple-to-use platform.
						</p>
						<div className='col marginTop-20'>
							<Button
								size='xxl'
								text=' Discover Procuur for Dental Laboratories'
								style='primary'
								onClick={() => navigate('/features/dental_labs')}
							/>
						</div>
					</div>
				</Section>
			)}
			{channel !== 'dentists' && (
				<Section>
					<div className='col'>
						<h4 className='header marginBottom-20'>Are you a Dental Practitioner?</h4>
						<p className='paragraph marginBottom-20'>
							Place, view and track all your Dental Laboratory prescriptions simply on your mobile device.
						</p>
						<div className='col marginTop-20'>
							<Button
								size='xxl'
								text='Discover Procuur for Dentists'
								style='primary'
								onClick={() => navigate('/features/dentists')}
							/>
						</div>
					</div>
				</Section>
			)}
		</div>
	);
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '../../../components/SVGIcons/SVGIcons';
import { checkStatusIfDisable, parseProcessingStatus } from '../../../helpers';
import config from '../../../config';
import useMultiFetch from '../../../hooks/useMultiFetch';

export default function OrderSuborders(props) {
	const { parentOrder, isSeller, order } = props?.props || {};

	const { data, fetch, handleCustomOptions, reqLoading, error, setReload, setCustomOptions } = useMultiFetch([]);

	useEffect(() => {
		if (order.suborders && order.suborders.length > 0) {
			let reqList = [];
			for (let i = 0; i < order.suborders.length; i++) {
				reqList.push({
					key: order.suborders[i],
					url: `${config.apiv1}/order/order.read/${order.suborders[i]}`,
				});
			}
			setCustomOptions(reqList);
			setReload(true);
		}
	}, [order?.orderUuid]);

	return (
		<>
			{!reqLoading && (
				<>
					{order?.parentUuid || isSeller ? (
						<div className='gridItemOrder suborders'>
							<div className='row highlight'>
								<h4 className='gridHeader'>Linked orders</h4>
								{isSeller && !order?.parentUuid && (
									<>
										{!checkStatusIfDisable(order.orderProcessingStatus, 'suborder') ? (
											<Link
												className='plus discIcon'
												to={`/order?parentUuid=${order?.orderUuid}`}>
												<PlusIcon />
											</Link>
										) : (
											<div className='plus discIcon disabledSVG'>
												<PlusIcon />
											</div>
										)}
									</>
								)}
							</div>
							<div className='colCont'>
								<div className='row'>
									<div className='rowItem'>
										<h4 className='subHeader'>ORDER TYPE</h4>
									</div>
									<div className='rowItem'>
										<h4 className='subHeader'>SUPPLIER</h4>
									</div>
									<div className='rowItem'>
										<h4 className='subHeader'>ORDER REF.</h4>
									</div>
									<div className='rowItem'>
										<h4 className='subHeader'>PROGRESS</h4>
									</div>
								</div>
								{parentOrder?.orderUuid && !isSeller && (
									<div className='row'>
										<div className='rowItem'>
											<Link
												to={`/orders/${parentOrder?.orderUuid}?orderRef=${parentOrder?.orderRef}`}>
												Parent order
											</Link>
										</div>
										<div className='rowItem'>
											<p>{parentOrder?.sellerTeamName}</p>
										</div>
										<div className='rowItem'>
											<p>{parentOrder?.orderRef}</p>
										</div>
										<div className='rowItem'>
											<p>{parseProcessingStatus(parentOrder?.orderProcessingStatus).icon}</p>
										</div>
									</div>
								)}
								{order?.suborders && !parentOrder?.parentUuid && isSeller ? (
									order?.suborders.length > 0 ? (
										order?.suborders?.map((order, key) => {
											return (
												<div key={key} className='row'>
													<div className='rowItem'>
														<Link to={`/orders/${order}?orderRef=${order}`}>Suborder</Link>
													</div>
													<div className='rowItem'>
														<p>{data[order]?.data?.data?.sellerTeamName}</p>
													</div>
													<div className='rowItem'>
														<p>{data[order]?.data?.data?.orderRef}</p>
													</div>
													<div className='rowItem'>
														<p>
															{
																parseProcessingStatus(
																	data[order]?.data?.data?.orderProcessingStatus
																).icon
															}
														</p>
													</div>
												</div>
											);
										})
									) : (
										<>No suborders to track</>
									)
								) : (
									<></>
								)}
							</div>
						</div>
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
}

import React from 'react';
import { docketDataConverter, integrationDataLabels, sliceString } from '../../../helpers';
import { PlusIcon } from '../../../components/SVGIcons/SVGIcons';
import { useNavigate } from 'react-router';

export default function OrderItems(props) {
	const { order, addons, isSeller, changeReqUuid, docketFields, changeReqFields } = props?.props || {};

	let navigate = useNavigate();

	let includedField = 'included_field_';

	let docketDataFilter = ['docketName', 'docketUuid', 'integrationMaps', 'requiredDateTime'];

	return (
		<div className='gridItemOrder items'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Items</h4>
				{!isSeller && (
					<div
						className={`gridIconRegular discIcon${!changeReqUuid ? ' disabledSVG' : ''}`}
						onClick={() => {
							changeReqUuid &&
								navigate(
									`/order?changeReqUuid=${changeReqUuid}&uuid=${order.sellerTeamUuid}&name=${
										order.sellerTeamName
									}&changeReqOrderUuid=${order.orderUuid}&returnUri=${encodeURI(
										`/orders/${order.orderUuid}?orderRef=${order.orderRef}`
									)}`
								);
						}}>
						<PlusIcon />
					</div>
				)}
			</div>
			<div className='colCont'>
				<div className='row header'>
					<div className='rowItem'>
						<h4 className='subHeader'>PRESCRIPTION</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>PROPERTIES</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>PRICE</h4>
					</div>
				</div>
				<div className='col'>
					<div className='row data'>
						<div className='rowItem'>
							<h4 className='subHeader'>PRESCRIPTION</h4>
							<div className='segmentText'>
								{order?.customFields?.docketName &&
								typeof order?.customFields?.docketName === 'string' ? (
									<p className='bodyText'>{order?.customFields?.docketName}</p>
								) : (
									<p className='bodyText'>None</p>
								)}
							</div>
						</div>
						<div className='rowItem'>
							<h4 className='subHeader'>PROPERTIES</h4>
							{order.customFields ? (
								<>
									{Object.keys(order.customFields).map((key, index) => {
										if (!docketDataFilter.includes(key)) {
											return (
												<div className='row marginBottom-6' key={index}>
													{!key.includes(includedField) && (
														<p className='text'>
															{integrationDataLabels('dentally')[key] ||
																docketFields[key]?.fieldText ||
																key}
															:{' '}
														</p>
													)}
													<div
														className='data bold'
														style={
															key.includes(includedField)
																? { width: '100%', marginTop: '12px' }
																: {}
														}>
														{docketDataConverter(
															order.customFields[key],
															docketFields[key]?.timeSelect
														)}
													</div>
												</div>
											);
										} else {
											return <React.Fragment key={index}></React.Fragment>;
										}
									})}
								</>
							) : (
								<p>No docket data</p>
							)}
						</div>
						<div className='rowItem'>
							<h4 className='subHeader'>PRICE</h4>
							<div className='segmentText'>
								<div className='data bold'>
									{order?.totalPrice ? `${Number(order?.totalPrice)?.toFixed(2)}£` : '0.00£'}
								</div>
							</div>
						</div>
					</div>
					{order.changeReqHistory &&
						order.changeReqHistory.map((change, key) => {
							return (
								<div className='row data borderTop' key={key}>
									<div className='rowItem'>
										<h4 className='subHeader'>PRESCRIPTION</h4>
										<div className='segmentText'>
											{change?.docketName && typeof change?.docketName === 'string' ? (
												<p className='bodyText'>{change?.docketName}</p>
											) : (
												<p className='bodyText'>None</p>
											)}
										</div>
									</div>
									<div className='rowItem'>
										<h4 className='subHeader'>PROPERTIES</h4>
										{Object.keys(change).map((key, index) => {
											if (!docketDataFilter.includes(key)) {
												return (
													<div className='row' key={index}>
														{!key.includes(includedField) && (
															<p className='text'>
																{changeReqFields[key]?.fieldText || key}:{' '}
															</p>
														)}
														<div className='data bold'>
															{docketDataConverter(change[key])}
														</div>
													</div>
												);
											} else {
												return <React.Fragment key={index}></React.Fragment>;
											}
										})}

										<div className='row' key={key}>
											<p className='text'>Date submitted: </p>
											<div className='data bold'>{docketDataConverter(change.createdAt)}</div>
										</div>
									</div>
									<div className='rowItem'>
										<h4 className='subHeader'>PRICE</h4>
										<div className='segmentText'>
											<div className='data bold'>
												{order?.totalPrice
													? `${Number(order?.totalPrice)?.toFixed(2)}£`
													: '0.00£'}
											</div>
										</div>
									</div>
								</div>
							);
						})}
					{addons && (
						<div className='row'>
							<div className='rowItem'>
								<h4 className='subHeader'>ADD-ONS</h4>
							</div>
							<div className='rowItem'>
								<h4 className='subHeader'>QUANTITY</h4>
							</div>
							<div className='rowItem'></div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

import './styles/main.css';
import './styles/carousel.css';
import './styles/dashboard.css';
import './styles/form.css';
import './styles/grid.css';
import './styles/icon.css';
import './styles/messaging.css';
import './styles/orders.css';
import './styles/products.css';
import './styles/sidebar.css';
import './styles/team.css';
import 'react-tooltip/dist/react-tooltip.css';

import { Route, Routes, useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import SidebarLeft from './components/SidebarLeft';
import Menu from './components/NavigationTop/Menu';
import SidebarRight from './components/SidebarRight';

import config from './config';
import { splitUserId } from './helpers';

import Dashboard from './containers/Dashboard';
import Breadcrumbs from './components/NavigationTop/Breadcrumb';
import Order from './containers/Order';
import OrderList from './containers/OrderList';
import OrderCreate from './containers/OrderCreate';
import OrderConfirm from './containers/OrderConfirm';

import TeamManageGrid from './containers/TeamManageGrid';
import TeamApps from './containers/TeamApps';
import TeamAppDetails from './containers/TeamApps/TeamAppDetails';
import TeamMembers from './containers/TeamMembers';
import TeamAddresses from './containers/TeamAddresses';
import TeamEdit from './containers/TeamEdit';
import TeamTrusted from './containers/TeamTrusted';
import TeamProfile from './containers/TeamProfile';
import TeamList from './containers/TeamList';
import TeamToolbox from './containers/TeamToolbox';
import TeamPurchases from './containers/TeamPurchases';
import MemberConfirm from './containers/MemberConfirm';

import TutorialsPage from './containers/Editorials';
import Topbar from './components/NavigationTop/Topbar';

import Error from './components/Error/Error';
import SupportIcon from './components/Tutorial/SupportIcon';

import { CurrentUserContext } from './contexts/CurrentUserContext';
import { CurrentTeamContext } from './contexts/CurrentTeamContext';
import { NotificationContextProvider } from './contexts/NotificationContext';
import TeamDispatch from './containers/TeamDispatch';
import Package from './containers/Package';
import Privacy from './components/Legal/Privacy';
import Policy from './components/Legal/Policy';
import Terms from './components/Legal/Terms';
import TeamFiles from './containers/TeamFiles';
import { ErrorBoundary } from 'react-error-boundary';
import RedirectDentally from './containers/RedirectDentally';
import TeamAppManage from './containers/TeamApps/TeamAppManage';
import TeamRegister from './containers/TeamRegister';
import FrontPage from './containers/FrontPage';
import Features from './containers/FrontPage/Features';
import TeamJobs from './containers/TeamJobs';
import ApplyRegister from './containers/FrontPage/ApplyRegister';
import Loading from './components/Loading/Loading';
import TeamInvoices from './containers/TeamInvoices';
import InvoiceCreate from './containers/InvoiceCreate';
import DocketBuilder from './containers/TeamToolbox/CustomFormTool/DocketBuilder';
import metaFieldsByPage from './helmetMeta';

import { Helmet } from 'react-helmet';
import InvoiceDetails from './containers/InvoiceDetails';
import useWindowSize from './hooks/useWindowSize';
import MobileMenu from './components/NavigationBottom/MobileMenu';
import { ModalContextProvider } from './contexts/ModalContext';
import Modal from './components/Modal';
import { FormContextProvider } from './contexts/FormContext';
import FadeOutModal from './components/Modals/FadeOutModal';
import FadeOutModalNEW from './components/Modals/FadeOutModalNEW';
import InvoiceEdit from './containers/InvoiceEdit';
import TeamChooseModal from './components/Modals/TeamChooseModal';
import Cookies from 'js-cookie';
import TeamInventory from './containers/TeamInventory';
import AddDocket from './admin/AddDocket';
import HistoryLog from './containers/HistoryLog';
import PackageDetails from './containers/TeamDispatch/PackageDetails';

export const LoadedContext = React.createContext();
export const SocketContext = React.createContext();

export default function App() {
	const [socket, setSocket] = useState(null);
	const [checked, setChecked] = useState(false);
	const [metadata, setMetadata] = useState(null);
	const [extendLeftSidebar, setExtendLeftSidebar] = useState(null);
	const [displayMode, setDisplayMode] = useState('desktop');
	const [subNavChoice, setSubNavChoice] = useState(null);

	let location = useLocation();
	let navigate = useNavigate();
	let { width } = useWindowSize();

	let { userState, userDispatch } = useContext(CurrentUserContext);
	let { teamState, teamDispatch } = useContext(CurrentTeamContext);

	const [recentLogin, setRecentLogin] = useState(
		teamState?.userTeams?.length > 1 ? Cookies.get('recentLogin') : false
	);

	const NoMatch = () => {
		return <Navigate to='/' />;
	};

	const checkProtected = () => {
		// Point of function is to avoid strange bug caused by traditional <ProtectedRoutes> type component
		// which means that if bug is solved this function becomes obsolete.
		// Specifics of the bug is that the <Outlet /> React router component causes any dispatch to re-render
		// pages for no apparent reason.
		let authRoutes = [
			'/order',
			'/orders',
			'/progress',
			'/packages',
			'/package',
			'/suppliers',
			'/manage',
			'/redirects',
			'/confirm-order-received',
			'/team-registration',
			'/confirm-member',
		];
		let adminRoutes = ['/manage'];
		if (userState.isAuthenticated === false) {
			if (location.pathname === '/') {
				navigate('/features');
			}
			authRoutes.forEach((route) => {
				if (location.pathname.includes(route)) {
					return navigate('/features');
				}
			});
		} else if (!teamState?.userPermissions['isAdmin']) {
			adminRoutes.forEach((route) => {
				if (location.pathname.includes(route)) {
					return navigate('/');
				}
			});
		}
		setChecked(true);
	};

	useEffect(() => {
		// Console message for peekers
		console.log(
			`%cIf you're here because something broke... it wasn't me!\n\nYours sincerely, \nRobin Neuman\n`,
			`font-family: 'Proza Libre', sans-serif; font-weight: bold; font-size: 30px;color: rgb(212, 70, 23);`
		);
		if (teamState?.currentTeam?.teamUuid) {
			console.log(
				`%cTeamUuid: ${teamState?.currentTeam?.teamUuid}`,
				`font-family: 'Proza Libre', sans-serif; font-weight: bold; font-size: 30px;color: rgb(255, 255, 0);`
			);
		}
	}, []);

	function ErrorFallback({ error, resetErrorBoundary }) {
		return <Error reset={resetErrorBoundary} error={error} />;
	}

	const handleExtendLeftSidebar = (openStatus, storeVal, isNavigationItem) => {
		setExtendLeftSidebar(openStatus);
		if (storeVal < 2 && !isNavigationItem) {
			localStorage.setItem('extendLeftSidebar', openStatus);
		}
	};

	const handleTeam = (teamUuid) => {
		if (teamUuid !== teamState.currentTeam.teamUuid) {
			teamDispatch({ type: 'SET_TEAM', payload: { teamUuid: teamUuid, reload: true } });
		}
		Cookies.set('recentLogin', false, { expires: 1 });
		setRecentLogin(false);
	};

	useEffect(() => {
		const connSocket = () => {
			setSocket(
				io.connect(config.api, {
					query: `userUuid=${splitUserId(userState.currUser?.sub)}&teamUuid=${
						teamState?.currentTeam?.teamUuid
					}`,
				})
			);
		};
		if (socket === null) {
			connSocket();
		}
	}, [socket, teamState?.currentTeam?.teamUuid, userState?.currUser?.sub]);

	useEffect(() => {
		// Cleans up threejs, will find way to do this inside threejs module
		let elem = document.querySelectorAll('.threeJsModel');
		for (let i = 0; i < elem.length; i++) {
			elem[i].remove();
		}

		// Scroll to top if page changes
		window.scrollTo({ top: 0 });
	}, [location]);

	useEffect(() => {
		if (checked === false) {
			checkProtected();
		}
	}, []);

	useEffect(() => {
		for (let field in metaFieldsByPage) {
			if (location.pathname.includes(metaFieldsByPage[field].path)) {
				setMetadata(metaFieldsByPage[field]);
			}
		}
	}, [location]);

	useEffect(() => {
		if (displayMode === 'desktop' && extendLeftSidebar && width < 1312) {
			setExtendLeftSidebar(0);
			setDisplayMode('tablet');
		} else if (displayMode === 'tablet' && width > 1312) {
			setDisplayMode('desktop');
			setExtendLeftSidebar(2);
		}
	}, [width]);

	useEffect(() => {
		let storedVal = localStorage.getItem('extendLeftSidebar');
		storedVal = Number(storedVal);

		if (storedVal !== null && width > 1312) {
			setExtendLeftSidebar(storedVal);
		} else {
			setExtendLeftSidebar(0);
		}
		setSubNavChoice(null);
	}, [location]);

	return (
		<>
			{metadata && (
				<Helmet>
					<title>{metadata.title}</title>
					<meta name='description' content={metadata.description} />
					<meta property='url' content={metadata.url || `${config.host}${metadata.path}`} />
					<meta property='site_name' content='Procuur' />
					<meta property='og:title' content={metadata.title} />
					<meta property='og:description' content={metadata.description} />
					<meta property='og:type' content='saas' />
					<meta property='og:url' content={metadata.url || `${config.host}${metadata.path}`} />
					<meta property='og:site_name' content='Procuur' />
				</Helmet>
			)}
			{checked ? (
				<SocketContext.Provider value={socket}>
					<NotificationContextProvider>
						<ModalContextProvider>
							<FormContextProvider>
								<Modal />
								<TeamChooseModal
									currentTeamUuid={teamState.currentTeam.teamUuid}
									show={recentLogin === 'true'}
									teams={teamState?.userTeams}
									callback={handleTeam}
								/>
								<div
									id='pageContainer'
									className={`pageContainer${
										extendLeftSidebar !== 0 && width > 1312 && userState?.isAuthenticated
											? ' leftExpandedMargin'
											: ''
									}${!userState?.isAuthenticated ? ' noMargin' : ''}`}>
									<SidebarLeft
										handleExtendLeftSidebar={handleExtendLeftSidebar}
										extendLeftSidebar={extendLeftSidebar}
										setSubNavChoice={setSubNavChoice}
										subNavChoice={subNavChoice}
										displayMode={displayMode}
										windowWidth={width}
									/>
									<div className='centerContainer'>
										{!teamState?.loading && !userState?.loading && (
											<div className='navigation'>
												<Menu
													handleExtendLeftSidebar={handleExtendLeftSidebar}
													extendLeftSidebar={extendLeftSidebar}
													setSubNavChoice={setSubNavChoice}
													width={width}
												/>
											</div>
										)}
										{userState?.isAuthenticated && <Breadcrumbs />}
										<ErrorBoundary
											FallbackComponent={ErrorFallback}
											onReset={() => {
												// reset the state of your app so the error doesn't happen again
											}}>
											<Routes>
												<Route exact path='/features' element={<FrontPage />} />
												<Route exact path='/features/:channel' element={<Features />} />
												<Route exact path='/features/apply' element={<ApplyRegister />} />
												<Route exact path='/privacy' element={<Privacy />} />
												<Route exact path='/terms' element={<Terms />} />
												<Route exact path='/policy' element={<Policy />} />
												<Route exact path='/tutorials/:lang/:uri' element={<TutorialsPage />} />

												{userState.isAuthenticated ? (
													<>
														<Route exact path='/' element={<Dashboard />} />
														<Route exact path='/order' element={<OrderCreate />} />
														<Route exact path='/orders' element={<OrderList />} />
														<Route exact path='/orders/:orderUuid' element={<Order />} />
														<Route
															exact
															path='/confirm-order-received'
															element={<OrderConfirm />}
														/>
														<Route
															exact
															path='/confirm-member'
															element={<MemberConfirm />}
														/>
														<Route exact path='/packages' element={<TeamDispatch />} />
														<Route
															exact
															path='/packages/:packageUuid'
															element={<PackageDetails />}
														/>
														<Route exact path='/package' element={<Package />} />
														<Route exact path='/progress' element={<TeamJobs />} />
														<Route
															exact
															path='/team-registration'
															element={<TeamRegister />}
														/>
														<Route exact path='/invoices' element={<TeamInvoices />} />
														<Route
															exact
															path='/invoices/:invoiceUuid'
															element={<InvoiceDetails />}
														/>
														<Route
															exact
															path='/invoice-create'
															element={<InvoiceCreate />}
														/>
														<Route exact path='/invoice-edit' element={<InvoiceEdit />} />
														<Route exact path='/manage' element={<TeamManageGrid />} />
														<Route exact path='/manage/apps' element={<TeamApps />} />
														<Route
															exact
															path='/manage/apps/:app'
															element={<TeamAppDetails />}
														/>
														<Route
															exact
															path='/manage/apps/settings'
															element={<TeamAppManage />}
														/>
														<Route exact path='/manage/team' element={<TeamEdit />} />
														<Route
															exact
															path='/manage/addresses'
															element={<TeamAddresses />}
														/>
														<Route
															exact
															path='/manage/inventory'
															element={<TeamInventory />}
														/>
														<Route exact path='/manage/members' element={<TeamMembers />} />
														<Route exact path='/manage/trusted' element={<TeamTrusted />} />
														<Route exact path='/manage/toolbox' element={<TeamToolbox />} />
														<Route exact path='/admin/add-docket' element={<AddDocket />} />
														<Route
															exact
															path='/manage/toolbox/form-builder'
															element={<DocketBuilder />}
														/>
														<Route
															exact
															path='/manage/purchases'
															element={<TeamPurchases />}
														/>
														<Route exact path='/manage/files' element={<TeamFiles />} />
														<Route exact path='/history' element={<HistoryLog />} />
													</>
												) : (
													<Route path='/' element={<Loading type='circle' />}></Route>
												)}

												<Route exact path='/suppliers' element={<TeamList />} />
												<Route exact path='/suppliers/:uuid' element={<TeamProfile />} />
												<Route
													exact
													path='/redirects/dentally'
													element={<RedirectDentally />}
												/>

												<Route exact path='/error' element={<Error />} />
												<Route path='*' element={<NoMatch />} />
											</Routes>
										</ErrorBoundary>
										{config?.pjson && <h4 className='versionText'>{config.pjson}</h4>}
										<FadeOutModalNEW />
									</div>
									<SidebarRight />
									<SupportIcon spaceBottom={width < 680} />
									{width < 680 && userState?.isAuthenticated && <MobileMenu />}
								</div>
							</FormContextProvider>
						</ModalContextProvider>
					</NotificationContextProvider>
				</SocketContext.Provider>
			) : (
				<>Nothing</>
			)}
		</>
	);
}

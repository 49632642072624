import React, { useState } from 'react';
import Upload from '../Upload';

export default function UploadModal(props) {
	const [dragActive, setDragActive] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');

	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files?.length !== 0) {
			props?.handleNewFiles(e.dataTransfer.files);
		}
	};

	const handleError = (message) => {
		setErrorMessage(message);
	};

	return (
		<>
			<div
				className={dragActive ? 'uploadModal upload dragActive' : 'uploadModal upload'}
				onDragEnter={handleDrag}
				onDragLeave={handleDrag}
				onDragOver={handleDrag}
				onDrop={handleDrop}>
				<Upload props={props} errorMessage={errorMessage} handleError={handleError} />
			</div>
		</>
	);
}

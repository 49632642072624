import React from 'react';
import KanbanCol from './KanbanCol';
import Loading from '../../components/Loading/Loading';

export default function KanbanView(props) {
	const { orders } = props || {};

	return (
		<div className='kanbanCont'>
			{!orders ? (
				<Loading type='circle' />
			) : (
				<>
					<KanbanCol status='pending' props={props} />
					<KanbanCol status='accepted' props={props} />
					<KanbanCol status='processing' props={props} />
					<KanbanCol status='completed' props={props} />
					<KanbanCol status='dispatched' props={props} />
					<KanbanCol status='delivered' props={props} />
					<KanbanCol status='rejected' props={props} />
				</>
			)}
		</div>
	);
}

import React from 'react';
import Chat from '../../Chat';
import { CaretDownIcon, CaretUpIcon } from '../../SVGIcons/SVGIcons';

export default function ChatBox(props) {
	const { contentReference, textboxesOpen, openTextbox, open } = props || {};
	return (
		<div className='rightSidebarChatContainer'>
			<div className='sidebarTextHeader' onClick={() => openTextbox('chat')}>
				<h4 className='bold'>Messages</h4>
				{textboxesOpen['chat'] ? (
					<CaretUpIcon iconClass={'caretUpIcon'} />
				) : (
					<CaretDownIcon iconClass={'caretDownIcon'} />
				)}
			</div>
			{textboxesOpen['chat'] && open && <Chat order={contentReference} open={open} />}
		</div>
	);
}

import React from 'react';
import TextInput from '../../Inputs/TextInput';
import DateInput from '../../Inputs/DateInput';
import { dateConverter, parseToOptions } from '../../../helpers';
import SelectInput from '../../Inputs/SelectInput';

export default function InfoSection({ formData, handleFormData, details, products, addInvoiceItem }) {
	return (
		<div className='infoSection'>
			<div className='invoiceInput'>
				{!details ? (
					<>
						<p>Subject</p>
						<TextInput
							name='subject'
							callback={handleFormData}
							value={formData?.subject}
							onlyInput
							controlled
						/>
					</>
				) : (
					<h4>{dateConverter(formData?.subject)}</h4>
				)}
			</div>
			<div className='invoiceInput'>
				{products ? (
					<>
						<p>Products</p>
						<SelectInput
							name='products'
							callback={(e) => {
								addInvoiceItem({ description: e?.name, productUuid: e?.value });
							}}
							value={formData?.subject}
							options={parseToOptions(products, 'products')}
							onlyInput
						/>
					</>
				) : (
					<h4>{dateConverter(formData?.subject)}</h4>
				)}
			</div>
			<div className='flexRow'>
				<div className='invoiceInput'>
					<p>Invoice No.</p>
					<h4>{formData?.docNumber || 'Draft'}</h4>
				</div>
				<div className='invoiceInput'>
					<p>Date</p>
					{!details ? (
						<DateInput name='txnDate' callback={handleFormData} value={formData?.txnDate} onlyInput />
					) : (
						<h4>{dateConverter(formData?.txnDate)}</h4>
					)}
				</div>
			</div>
		</div>
	);
}

import Axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function useMultiFetch(reqOptions) {
	const [data, setData] = useState(null);
	const [customOptions, setCustomOptions] = useState(null);
	const [reqLoading, setReqLoading] = useState(true);
	const [reload, setReload] = useState(true);
	const [error, setError] = useState('');

	const handleCustomOptions = (newOptions) => {
		let exists = false;
		let options = [];

		if (!customOptions) {
			options = [...reqOptions];
		} else {
			options = [...customOptions];
		}

		if (!newOptions || !Array.isArray(newOptions)) {
			setCustomOptions([...reqOptions]);
			return setReload(true);
		}

		let mapped = [...options].map((option, key) => {
			let match = newOptions.filter((newOption) => newOption.key === option?.key);
			if (match.length > 0) {
				exists = true;
				return match[0];
			} else {
				return option;
			}
		});
		if (exists) {
			setCustomOptions(mapped);
		} else {
			setCustomOptions([...reqOptions, ...newOptions]);
		}
		return setReload(true);
	};

	async function fetch(controller) {
		let usedOptions = reqOptions;
		if (customOptions) {
			usedOptions = customOptions;
		}
		async function addFetchReq(url, headers) {
			return await Axios.get(url, {
				signal: controller?.signal,
				headers: headers ? headers : {},
			});
		}

		let reqs = [];
		let options = [];
		let parsedResponses = {};

		for (let i = 0; i < usedOptions.length; i++) {
			if (usedOptions[i]) {
				reqs.push(addFetchReq(usedOptions[i].url, usedOptions[i].headers));
				options.push(usedOptions[i].key);
			}
		}

		if (reqs.length > 0) {
			await Axios.all(reqs).then(
				Axios.spread((...responses) => {
					for (let i = 0; i < responses.length; i++) {
						if (responses[i].data?.message?.toLowerCase()?.includes('package')) {
							const packages = responses[i]?.data?.data?.reduce(
								(arr, value) => ({
									...arr,
									[value.packageUuid]: value,
								}),
								{}
							);
							parsedResponses[options[i]] = packages;
						} else {
							parsedResponses[options[i]] = responses[i];
						}
					}
				})
			);
		}
		setData(parsedResponses);
		setReqLoading(false);
		setReload(false);
		return parsedResponses;
	}

	useEffect(() => {
		let mounted = true;
		const controller = new AbortController();
		if (mounted && reload === true) {
			fetch(controller);
		}
		return () => {
			controller.abort();
			mounted = false;
		};
	}, [reload, customOptions]);

	return { data, setData, handleCustomOptions, fetch, reqLoading, error, setReload, setCustomOptions };
}

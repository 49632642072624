import React from 'react';
import { FileIcon, FileInfoIcon, PlusIcon } from '../../../components/SVGIcons/SVGIcons';
import { checkFilenameLength, checkStatusIfDisable } from '../../../helpers';
import { useNavigate } from 'react-router';

export default function OrderFiles(props) {
	const {
		setShowOverlay,
		generalFiles,
		toggleHidden,
		downloadFile,
		handleModal,
		handleFiles,
		changeMade,
		removeFile,
		patchOrder,
		newFiles,
		order,
		imgs,
	} = props?.props || {};

	let navigate = useNavigate();

	return (
		<div className='gridItemOrder files' id='filesContainer'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Images and Files</h4>
				<div className='plus discIcon marginRight-10 marginLeft-auto'>
					<FileInfoIcon
						onClick={() =>
							navigate(
								`/manage/files?search=${order.orderUuid}&returnUri=${encodeURI(
									`/orders/${order.orderUuid}?orderRef=${order.orderRef}`
								)}`
							)
						}
					/>
				</div>
				<label htmlFor='uploadFile' onClick={() => handleModal('uploadModal', { open: true })}>
					<div className='plus discIcon'>
						<PlusIcon />
					</div>
				</label>
			</div>
			<div className='col marginLeft-10'>
				{/* {changeMade && <h4>New Files: </h4>}
				{changeMade && (
					<div className='marginTop-20 marginBottom-20'>
						<button className='btn3-primary' onClick={() => patchOrder(null, 1)}>
							Save changes
						</button>
					</div>
				)} */}
				{/* {newFiles[0] &&
					newFiles.map((file, key) => {
						return (
							<div className='newFile' key={key}>
								<div key={key} className='flexRow'>
									<p>{file.name}</p>
									<button className='btn3-secondary' onClick={() => removeFile(file)}>
										Remove
									</button>
								</div>
							</div>
						);
					})} */}
				<div className='imgContainer' id='fileImages'>
					{imgs?.map((img, key) => {
						return (
							<div className='imgItem' key={key}>
								<img
									className='imgThumbnail'
									src={img.url}
									alt='Thumbnail image'
									onClick={() => {
										handleModal('imgModal', { open: true, file: img });
										setShowOverlay(true);
									}}
								/>
								<div className='fileName'>
									{checkFilenameLength(img?.caption, 40) ||
										checkFilenameLength(img?.originalname, 40)}
								</div>
							</div>
						);
					})}
				</div>
				<div className='imgContainer marginTop-20' id='generalFiles'>
					{generalFiles?.map((file, key) => {
						if (file && typeof file === 'object') {
							return (
								<div className='imgItem' key={key}>
									<FileIcon iconClass='imgThumbnail' onClick={() => downloadFile(file)} />
									<p className='fileName'>
										{file?.caption
											? checkFilenameLength(file?.caption ? file.caption : file?.filename, 40)
											: checkFilenameLength(file?.originalname, 10)}
									</p>
									{file?.filename?.split('.')[1] === 'stl' ? (
										<button
											className='btn3-primary small'
											onClick={() => toggleHidden(file, 'stl')}>
											View STL
										</button>
									) : (
										<></>
									)}
									{file?.filename?.split('.')[1] === 'dcm' ? (
										<button
											className='btn3-primary small'
											onClick={() => toggleHidden(file, 'dcm')}>
											View DICOM
										</button>
									) : (
										<></>
									)}
								</div>
							);
						} else {
							return <React.Fragment key={key}></React.Fragment>;
						}
					})}
				</div>
			</div>
		</div>
	);
}

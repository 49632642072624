import React from 'react';
import Loading from '../Loading/Loading';
import OrderEditForm from '../Forms/OrderEditForm';
import Button from '../Button';

export default function OrderEditModal(props) {
	const {
		updateLoading,
		setUpdateLoading,
		setShowOverlay,
		handleSelectData,
		setNewOrderData,
		handleData,
		patchOrder,
		orderData,
		formData,
		setShow,
		type,
	} = props || {};

	return (
		<div className='flexModal'>
			{!updateLoading ? (
				<>
					<OrderEditForm
						sellerUserUuid={orderData.sellerUserUuid}
						requiredDate={orderData.requiredDateTime}
						status={orderData.orderProcessingStatus}
						dueDate={orderData.dispatchDueDateTime}
						handleSelectData={handleSelectData}
						hint={orderData.sellerHint}
						handleData={handleData}
						formData={formData}
						type={type}
					/>
					<div className='btnCont modal reverse'>
						<Button
							text='Update order'
							style='primary'
							type='lg'
							onClick={() => {
								patchOrder();
								setUpdateLoading(true);
							}}
						/>
						<Button
							text='Close'
							style='secondary'
							type='lg'
							onClick={() => {
								setShow('editModal', false);
								setShowOverlay(false);
								setNewOrderData({});
							}}
						/>
					</div>
				</>
			) : (
				<>
					<Loading type='circle' />
				</>
			)}
		</div>
	);
}

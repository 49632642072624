import React from 'react';
import PackageChoiceItem from './PackageChoiceItem';
import Button from '../Button';
import { PlusIcon } from '../SVGIcons/SVGIcons';
import EmptyState from '../EmptyState/EmptyState';

export default function PackageChoiceListing({ options, hideModal }) {
	return (
		<div className='packageChoiceList'>
			<Button
				text={'Create package'}
				iconLeft={<PlusIcon />}
				size='lg'
				style='accent'
				maxWidth={204}
				onClick={() => {
					hideModal();
					options?.openPackageNewModal();
				}}
			/>
			{options?.packages?.map((pack, key) => {
				return (
					<PackageChoiceItem
						pack={pack}
						hideModal={hideModal}
						callback={(e) => {
							options?.callback(e);
							hideModal();
						}}
						key={key}
						choice
					/>
				);
			})}
			{options?.packages?.length === 0 ? (
				<div className='emptyStateOuterCont'>
					<EmptyState header={'No packages found'} />
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

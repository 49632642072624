import React from 'react';
import KanbanItem from './KanbanItem';
import { ArrowsInIcon, ArrowsOutIcon } from '../../components/SVGIcons/SVGIcons';
import Button from '../../components/Button';
import Loading from '../../components/Loading/Loading';

export default function KanbanCol(props) {
	const { orders, colsOpen, handleColsOpen, loadNextOrders, btnLoading } = props?.props || {};
	const { status } = props || {};

	let items = orders[status];

	return (
		<div id='status' className={`col${!colsOpen[status] ? ` closed` : ''}`}>
			<div
				className={`header${items?.options?.statusColor ? ` ${items?.options?.statusColor}` : ''}`}
				style={{ backgroundColor: items?.options?.statusColor ? items?.options?.statusColor : '' }}>
				{items?.options?.icon}
				<h2 className={`text${!colsOpen[status] ? ` closed` : ''}`}>{items?.options?.headerText}</h2>
			</div>
			<div
				className='handle'
				onClick={() => {
					handleColsOpen(status);
				}}>
				{colsOpen[status] ? <ArrowsInIcon /> : <ArrowsOutIcon />}
			</div>
			<div className={`list${!colsOpen[status] ? ` closed` : ''}`} id={status}>
				{items?.items &&
					items?.items.length > 0 &&
					items?.items.map((item, key) => (
						<React.Fragment key={key}>
							<KanbanItem item={item} />
						</React.Fragment>
					))}
			</div>
			{items?.totalDocs > 4 && colsOpen[status] && items?.totalDocs !== items?.items.length && (
				<div className='btnCont'>
					{!btnLoading[status] ? (
						<Button
							onClick={(e) => loadNextOrders(status)}
							size='md'
							style='tertiary-secondary'
							text='Load more'
							fill
						/>
					) : (
						<Loading type='circle' />
					)}
				</div>
			)}
		</div>
	);
}

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import FlexModal from '../../components/Modals/FlexModal';
import { BillIcon } from '../../components/SVGIcons/SVGIcons';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { dateConverter } from '../../helpers';
import useFetch from '../../hooks/useFetch';
import Filter from '../../components/Filter';

export default function TeamPurchases() {
	const { teamState } = useContext(CurrentTeamContext);
	const { data, loading, error } = useFetch(
		`${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?skip=0&search=&sortDate=desc&teamType=1`
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [selected, setSelected] = useState(null);

	let navigate = useNavigate();

	return (
		<div className='purchaseOrdersCont'>
			{modalOpen && (
				<FlexModal
					setShow={() => {
						setModalOpen(false);
					}}
					modalName={'imgCanvasModal'}
					zIndex={99}
					closeBtn={true}
					body={
						<div className='invoicesCont'>
							<div className='flexRow marginBottom-10'>
								<h4>Status</h4>
								<h4>Amount</h4>
							</div>
							{data[selected]?.invoices?.map((invoice, key) => {
								return (
									<div className='flexCol borderBottom' key={key}>
										<div className='flexRow'>
											<p>Open</p>
											<p>{invoice.amount}</p>
										</div>
									</div>
								);
							})}
							{data[selected]?.invoices && data[selected]?.invoices.length === 0 && <>No invoices</>}
						</div>
					}
				/>
			)}
			<Filter filterTypes={[{ text: 'Date', type: 'date' }]} />
			<div className='purchaseOrdersList'>
				<div className='colRow'>
					<div className='rowItem'>
						<h4>Date</h4>
					</div>
					<div className='rowItem'>
						<h4>Type</h4>
					</div>
					<div className='rowItem'>
						<h4>Payer</h4>
					</div>
					<div className='rowItem'>
						<h4>Payee</h4>
					</div>
					<div className='rowItem'>
						<h4>Reference</h4>
					</div>
					<div className='rowItem'>
						<h4>Hint</h4>
					</div>
					<div className='rowItem'>
						<h4>Total</h4>
					</div>
				</div>
				{!loading && (
					<>
						{data?.map((order, key) => {
							return (
								<div className='orderItem' key={key}>
									<div className='orderData'>
										<p>{dateConverter(order.createdAt)}</p>
									</div>
									<div className='orderData'>
										<p>Bill</p>
									</div>
									<div className='orderData'>
										<p>{order.behalfOf.name}</p>
									</div>
									<div className='orderData'>
										<p
											className='underline'
											onClick={(e) => {
												e.stopPropagation();
												navigate(`/suppliers/${order.sellerTeamUuid}`);
											}}>
											{order.sellerTeamName}
										</p>
									</div>
									<div className='orderData'>
										<p
											className='underline'
											onClick={(e) => {
												e.stopPropagation();
												navigate(`/orders/${order.orderUuid}?orderRef=${order.orderRef}`);
											}}>
											{order.orderRef}
										</p>
									</div>
									<div className='orderData'>
										<p>{order.buyerHint}</p>
									</div>
									<div className='orderData'>
										<p>{Number(order.totalPrice)?.toFixed(2) || '0.00'}</p>
									</div>
									<BillIcon
										iconClass='billIcon'
										onClick={() => {
											setModalOpen(true);
											setSelected(key);
										}}
									/>
								</div>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
}

import React, { useContext, useState } from 'react';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import MemberNotification from './MemberNotification';
import MessageNotification from './MessageNotification';
import OrderNotification from './OrderNotification';
import CustomNotification from './CustomNotification';

export default function NotificationSwitch(props) {
	const {
		rightSidebarDispatch,
		deleteNotification,
		handleDropdowns,
		dropdownsOpen,
		notification,
		checkHref,
		setRead,
	} = props || {};

	const [isFading, setIsFading] = useState({});

	const { teamState } = useContext(CurrentTeamContext);

	function returnNotificationStyle() {
		switch (notification.notificationType) {
			case 'message.created':
				return 'message';
			case 'member.added':
				return 'team';
			case 'team.trustedSuggested':
				return 'team';
			case 'order.priceSuggested':
				return 'sale';
			case 'order.created':
				return 'sale';
			case 'custom':
				return 'custom';
			case 'order.updated':
				if (teamState.currentTeam.teamUuid === notification.notificationData.sellerTeamUuid) {
					return 'sale';
				} else {
					return 'purchase';
				}
			default:
				return 'default';
		}
	}

	const handleDelete = (notification, cb) => {
		setIsFading({
			...isFading,
			[notification.notificationUuid]: true,
		});
		deleteNotification(notification, cb);
	};

	return (
		<>
			{(() => {
				switch (notification.notificationType) {
					case 'message.created':
						return (
							<div
								className={`notificationCont${
									isFading[notification.notificationUuid] ? ' fadeOutSquish' : ''
								}`}>
								<MessageNotification
									rightSidebarDispatch={rightSidebarDispatch}
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									style={returnNotificationStyle()}
									handleDropdowns={handleDropdowns}
									dropdownsOpen={dropdownsOpen}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</div>
						);
					case 'order.created':
						return (
							<div
								className={`notificationCont${
									isFading[notification.notificationUuid] ? ' fadeOutSquish' : ''
								}`}>
								<OrderNotification
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									rightSidebarDispatch={rightSidebarDispatch}
									style={returnNotificationStyle()}
									handleDropdowns={handleDropdowns}
									dropdownsOpen={dropdownsOpen}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</div>
						);
					case 'order.updated':
						return (
							<div
								className={`notificationCont${
									isFading[notification.notificationUuid] ? ' fadeOutSquish' : ''
								}`}>
								<OrderNotification
									rightSidebarDispatch={rightSidebarDispatch}
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									style={returnNotificationStyle()}
									handleDropdowns={handleDropdowns}
									dropdownsOpen={dropdownsOpen}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</div>
						);
					case 'custom':
						return (
							<div
								className={`notificationCont${
									isFading[notification.notificationUuid] ? ' fadeOutSquish' : ''
								}`}>
								<CustomNotification
									rightSidebarDispatch={rightSidebarDispatch}
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									style={returnNotificationStyle()}
									handleDropdowns={handleDropdowns}
									dropdownsOpen={dropdownsOpen}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</div>
						);
					case 'order.priceSuggested':
						return (
							<div
								className={`notificationCont${
									isFading[notification.notificationUuid] ? ' fadeOutSquish' : ''
								}`}>
								<OrderNotification
									rightSidebarDispatch={rightSidebarDispatch}
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									style={returnNotificationStyle()}
									handleDropdowns={handleDropdowns}
									dropdownsOpen={dropdownsOpen}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</div>
						);
					case 'member.added':
						return (
							<div
								className={`notificationCont${
									isFading[notification.notificationUuid] ? ' fadeOutSquish' : ''
								}`}>
								<MemberNotification
									rightSidebarDispatch={rightSidebarDispatch}
									deleteNotification={() => {
										handleDelete(notification, setIsFading);
									}}
									style={returnNotificationStyle()}
									handleDropdowns={handleDropdowns}
									dropdownsOpen={dropdownsOpen}
									notification={notification}
									checkHref={checkHref}
									setRead={setRead}
								/>
							</div>
						);
					case 'team.trustedSuggested':
						if (teamState.userPermissions['canAddTrusted']) {
							return (
								<div
									className={`notificationCont${
										isFading[notification.notificationUuid] ? ' fadeOutSquish' : ''
									}`}>
									<MemberNotification
										rightSidebarDispatch={rightSidebarDispatch}
										deleteNotification={() => {
											handleDelete(notification, setIsFading);
										}}
										style={returnNotificationStyle()}
										handleDropdowns={handleDropdowns}
										dropdownsOpen={dropdownsOpen}
										notification={notification}
										checkHref={checkHref}
										setRead={setRead}
									/>
								</div>
							);
						} else {
							return <></>;
						}
					default:
						return <></>;
				}
			})()}
		</>
	);
}

import React from 'react';
import TemplateItem from './TemplateItem';

export default function ColorPicker(props) {
	const { colors, template, setColor, color } = props || {};
	return (
		<div className='colorPickCont'>
			{colors.map((col, key) => {
				return (
					<TemplateItem template={{ ...template, color: col }} setColor={setColor} color={color} key={key} />
				);
			})}
		</div>
	);
}

import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '../Button';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

export default function Error(props) {
	const { reset, error } = props || {};

	const { userDispatch } = useContext(CurrentUserContext);

	let location = useLocation();
	let message = new URLSearchParams(location.search).get('message');
	let login = new URLSearchParams(location.search).get('login');

	return (
		<div className='errorPageCont'>
			<h3>Error when loading page: {message || error.message}</h3>
			{login ? (
				<Button
					text='Go to login'
					style='primary'
					onClick={() => userDispatch({ type: 'LOGIN', payload: true })}
				/>
			) : (
				<Link to='/' onClick={reset} className='btn3-primary'>
					Return to dashboard
				</Link>
			)}
		</div>
	);
}

import React from 'react';
import OrderItemNewForm from '../Forms/OrderItemNewForm';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay';
import Button from '../Button';

export default function OrderItemNewModal(props) {
	const {
		handleModal,
		updateLoading,
		setUpdateLoading,
		setShowOverlay,
		packages,
		handleData,
		postItem,
		formData,
		handleFormSelectData,
		missingOptions,
		zIndex,
	} = props || {};

	return (
		<>
			<div className='flexModal' style={{ zIndex: zIndex }}>
				{!updateLoading ? (
					<div className='mainFormCont'>
						<OrderItemNewForm
							packages={packages}
							handleData={handleData}
							formData={formData}
							handleModal={handleModal}
							handleFormSelectData={handleFormSelectData}
							missingOptions={missingOptions}
						/>
						<div className='btnCont modal reverse'>
							<Button
								text='Save'
								style='primary'
								type='lg'
								onClick={() => {
									setUpdateLoading(true);
									postItem();
								}}
							/>
							<Button
								text='Close'
								style='secondary'
								type='lg'
								onClick={() => {
									handleModal('orderItemNewModal', false);
									setShowOverlay(false);
								}}
							/>
						</div>
					</div>
				) : (
					<>
						<Loading type='circle' />
					</>
				)}
			</div>
			<Overlay loadOverlay={true} showOverlay={true} zIndex={zIndex} />
		</>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import CheckboxInput from '../Inputs/CheckboxInput';
import TextInput from '../Inputs/TextInput';
import { defaultDocketTemp, integrationDataLabels, parseToOptions } from '../../helpers';
import SelectInput from '../Inputs/SelectInput';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import Button from '../Button';

export default function ManageAppForm(props) {
	const {
		handleFormInput,
		handleFormSelect,
		formData,
		checkAll,
		toggleAllCheck,
		integration,
		appName,
		post,
		reFetchIntegration,
		setFormData,
	} = props || {};

	const [dockets, setDockets] = useState(null);

	let labels = integrationDataLabels('dentally');

	const { teamState } = useContext(CurrentTeamContext);

	const { fetch } = useFetch();

	function checkPrefill(fieldName) {
		if (formData[fieldName] !== undefined && formData[fieldName] !== null) {
			return formData[fieldName];
		} else if (integration?.settings && integration?.settings[fieldName]) {
			return integration?.settings[fieldName];
		} else {
			return false;
		}
	}

	function checkHintFormat(label) {
		if (
			formData?.hintFormat !== null &&
			formData?.hintFormat !== undefined &&
			formData?.hintFormat !== 'undefined'
		) {
			return `${formData?.hintFormat} {{${label}}}`;
		} else if (
			integration?.settings?.hintFormat !== null &&
			integration?.settings?.hintFormat !== undefined &&
			integration?.settings?.hintFormat !== 'undefined'
		) {
			return `${integration?.settings?.hintFormat} {{${label}}}`;
		} else {
			return `{{${label}}}`;
		}
	}

	async function postForm() {
		let existingSettings = integration?.settings?.dockets[formData.docketUuid];
		await post({
			settings: {
				...integration?.settings,
				dockets: {
					...integration?.settings?.dockets,
					[formData.docketUuid]: {
						...existingSettings,
						...formData,
					},
				},
			},
		});
		await reFetchIntegration();
	}

	useEffect(() => {
		async function fetchDockets() {
			let res = await fetch(`${config.apiv1}/docket/dockets.read/${teamState.currentTeam?.teamUuid}`);

			if (res && res?.length !== 0) {
				let published = res.filter((docket) => docket.published);
				prefillForm(published);
			} else {
				let dockets = [defaultDocketTemp(teamState.currentTeam)];
				prefillForm(dockets);
			}
		}

		function prefillForm(dockets) {
			let docketSettings = {};
			for (let i = 0; i < dockets.length; i++) {
				let prefillDocket = {
					...dockets[i],
					title: integration?.settings?.dockets[dockets[i].docketUuid]?.title,
					firstname: integration?.settings?.dockets[dockets[i].docketUuid]?.firstname,
					surname: integration?.settings?.dockets[dockets[i].docketUuid]?.surname,
				};
				docketSettings[dockets[i].docketUuid] = prefillDocket;
			}
			setDockets(docketSettings);
		}

		if (appName === 'labmanager') {
			fetchDockets();
		}
	}, [integration]);

	return (
		<>
			{integration && (
				<>
					{appName === 'labmanager' && (
						<>
							<div className='manageForm'>
								<div className='section'>
									<SelectInput
										options={dockets ? parseToOptions(Object.values(dockets), 'dockets') : []}
										callback={(e) => {
											setFormData(null);
											setTimeout(() => {
												handleFormSelect(e.value, 'docketUuid');
											}, 100);
										}}
										value={formData?.docketUuid || null}
										name={'docketUuid'}
										required
									/>
									<h4 className='smallHeader'>Setting your Patient Name in LabManager</h4>
									<p className='suggestDesc'>
										Yout Patient name is set using three fields, Surname, Firstname and Title. In
										your Custom Form please create three fields that represent these, then map them
										below to have those fields populate the Patient Name in LabManager
									</p>
								</div>
								{formData?.docketUuid && (
									<>
										<div className='section paddingBottom-20'>
											<SelectInput
												text='Title'
												options={parseToOptions(
													dockets[formData?.docketUuid]?.fields,
													'docketFields'
												)}
												callback={(e) => {
													handleFormSelect(e.value, 'title');
												}}
												value={formData?.title || dockets[formData.docketUuid]?.title || null}
												name={'title'}
												required
											/>
											<SelectInput
												text='Firstname'
												options={parseToOptions(
													dockets[formData?.docketUuid]?.fields,
													'docketFields'
												)}
												callback={(e) => {
													handleFormSelect(e.value, 'firstname');
												}}
												value={
													formData.firstname ||
													dockets[formData.docketUuid]?.firstname ||
													null
												}
												name={'firstname'}
												required
											/>
											<SelectInput
												text='Surname'
												options={parseToOptions(
													dockets[formData?.docketUuid]?.fields,
													'docketFields'
												)}
												callback={(e) => {
													handleFormSelect(e.value, 'surname');
												}}
												value={
													formData.surname || dockets[formData.docketUuid]?.surname || null
												}
												name={'surname'}
												required
											/>
										</div>
										<div className='btnCont'>
											<Button size='md' style='primary' text='Save changes' onClick={postForm} />
										</div>
									</>
								)}
							</div>
						</>
					)}
					{appName === 'dentally' && (
						<>
							<h4 className='smallHeader'>Patient data settings</h4>
							<div className='manageForm'>
								<div className='marginBottom-40'>
									<CheckboxInput
										checkboxText={'Check / uncheck all'}
										name={'checkAll'}
										checked={checkAll}
										callback={toggleAllCheck}
										onlyInput
									/>
								</div>
								<div className='section borderBottom paddingBottom-20'>
									<div className='col'>
										<CheckboxInput
											checkboxText={'Title'}
											name={'title'}
											checked={checkPrefill('title')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'First Name'}
											name={'first_name'}
											checked={checkPrefill('first_name')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Middle Name'}
											name={'middle_name'}
											checked={checkPrefill('middle_name')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Last Name'}
											name={'last_name'}
											checked={checkPrefill('last_name')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'DOB'}
											name={'date_of_birth'}
											checked={checkPrefill('date_of_birth')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Email'}
											name={'email_address'}
											checked={checkPrefill('email_address')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'NHS Number'}
											name={'nhs_number'}
											checked={checkPrefill('nhs_number')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'National Insurance Number'}
											name={'ni_number'}
											checked={checkPrefill('ni_number')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Address Line 1'}
											name={'address_line_1'}
											checked={checkPrefill('address_line_1')}
											callback={handleFormInput}
											required
											onlyInput
										/>
									</div>
									<div className='col'>
										<CheckboxInput
											checkboxText={'Address Line 2'}
											name={'address_line_2'}
											checked={checkPrefill('address_line_2')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Address Line 3'}
											name={'address_line_3'}
											checked={checkPrefill('address_line_3')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Town'}
											name={'town'}
											checked={checkPrefill('town')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'County/State'}
											name={'county'}
											checked={checkPrefill('county')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Postcode/Zip'}
											name={'postcode'}
											checked={checkPrefill('postcode')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Home Phone'}
											name={'home_phone'}
											checked={checkPrefill('home_phone')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Mobile Phone'}
											name={'mobile_phone'}
											checked={checkPrefill('mobile_phone')}
											callback={handleFormInput}
											required
											onlyInput
										/>
										<CheckboxInput
											checkboxText={'Work Phone'}
											name={'work_phone'}
											checked={checkPrefill('work_phone')}
											callback={handleFormInput}
											required
											onlyInput
										/>
									</div>
								</div>
								<div className='section'>
									<TextInput
										type='text'
										name='hintFormat'
										text='Hint format'
										placeholder='{{title}}, {{first_name}}, {{last_name}}'
										callback={handleFormInput}
										value={formData?.hintFormat}
										controlled
									/>
								</div>
								<p className='suggestDesc'>Suggested hint data fields (click to add to hint format)</p>
								<div className='section borderBottom paddingBottom-20'>
									{Object.keys(labels).map((label, key) => (
										<div
											className='suggestion'
											key={key}
											onClick={() => {
												handleFormInput(checkHintFormat(label), 'hintFormat');
											}}>
											{labels[label]}
										</div>
									))}
								</div>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}

import React from 'react';
import { useNavigate } from 'react-router';
import { ClipboardArrowDownIcon, ClipboardArrowsClockwiseIcon, ThreeDotsIcon } from '../SVGIcons/SVGIcons';
import { dateConverter, parseExactDate } from '../../helpers';

export default function CustomNotification(props) {
	const {
		notification,
		setRead,
		checkHref,
		deleteNotification,
		style,
		handleDropdowns,
		dropdownsOpen,
		rightSidebarDispatch,
	} = props || {};
	const { notificationData } = notification;

	let navigate = useNavigate();
	return (
		<>
			<div
				className={`mainRow ${notification.isRead ? 'opacity-50' : ''}`}
				onClick={() => {
					setRead(notification?.notificationUuid);
					checkHref(`/orders/${notificationData.orderUuid}?orderRef=${notificationData?.orderRef}`);
					navigate(`/orders/${notificationData.orderUuid}?orderRef=${notificationData?.orderRef}`);
					rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
				}}>
				<div className={`iconCol ${style}`}>
					{style === 'sale' ? (
						<ClipboardArrowDownIcon iconClass='notificationIcon' />
					) : (
						<ClipboardArrowsClockwiseIcon iconClass='notificationIcon' />
					)}
				</div>
				<div className='bodyCol'>
					<div className='colRowTop'>
						<h4 className={`notificationHeader ${style}`}>{notificationData?.title}</h4>
						<h4 className='notificationDate'>{parseExactDate(notification.createdAt || new Date())}</h4>
					</div>
					<div className='colRowBottom'>
						<p className='bodyText'>{notificationData?.description}</p>
						<ThreeDotsIcon
							iconClass='notificationSubIcon'
							onClick={(e) => {
								e.stopPropagation();
								handleDropdowns(notification?.notificationUuid);
							}}
						/>
					</div>
				</div>
			</div>
			{dropdownsOpen && (
				<>
					{dropdownsOpen[notification?.notificationUuid] && (
						<div className='notificationDropdown'>
							<p
								onClick={() => {
									deleteNotification(notification);
								}}>
								Delete
							</p>
							<p
								onClick={() => {
									setRead(notification?.notificationUuid);
								}}>
								Mark as read
							</p>
						</div>
					)}
				</>
			)}
		</>
	);
}

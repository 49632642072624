import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
	const [modals, setModals] = useState([]);
	const [fadeModal, setFadeModal] = useState({});

	const flashFadeModal = (message) => {
		const second = 1000;
		setFadeModal({ show: true, isFading: false, message: message });
		setTimeout(() => {
			setFadeModal({ show: true, isFading: true, message: message });
		}, second * 3);
	};

	return (
		<ModalContext.Provider value={{ modals, setModals, fadeModal, flashFadeModal }}>
			{children}
		</ModalContext.Provider>
	);
};

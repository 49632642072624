import React from 'react';
import Button from '../Button';
import { ArrowNarrowUpRightIcon, CartBuyIcon } from '../SVGIcons/SVGIcons';

export default function SignpostItem({ type, icon, onClick, title, paragraph }) {
	return (
		<div className={`signpostItem${type ? ` ${type}` : ''}`} onClick={onClick} tabIndex={0}>
			<Button size='icon' iconLeft={icon} noTab={true} />
			<div className='centerTextCol'>
				<h4>{title}</h4>
				<p>{paragraph}</p>
			</div>
			<ArrowNarrowUpRightIcon iconClass='arrowIcon' />
		</div>
	);
}

import React, { useContext } from 'react';
import { ModalContext } from '../../contexts/ModalContext';

export default function FadeOutModalNEW() {
	const { fadeModal } = useContext(ModalContext);

	return (
		<>
			{fadeModal?.show && (
				<div className={`${fadeModal?.isFading ? 'fadeOutModal' : 'fadeInModal'}`}>{fadeModal?.message}</div>
			)}
		</>
	);
}

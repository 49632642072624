import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import labmanager from '../../assets/img/labManager-logo-green-005B00.svg';
import lmDash from '../../assets/img/LM-dash.jpg';
import { ArrowPrevLeftIcon } from '../../components/SVGIcons/SVGIcons';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config';
import Cookies from 'js-cookie';

export default function TeamAppDetails() {
	const { userState } = useContext(CurrentUserContext);
	const { teamState } = useContext(CurrentTeamContext);

	let location = useLocation();

	function integrate(url) {
		if (!url) {
			return;
		}
		const lastState = uuidv4();
		Cookies.set(`lastState`, lastState, { expires: 0.2 });
		let newUrl = new URL(url);
		newUrl.searchParams.set('state', lastState);
		window.location.href = newUrl.href;
	}

	return (
		<div className='pageInnerCont gray'>
			<div className='teamAppsCont appDetails'>
				{location.pathname.split('/').slice(-1)[0] === 'labmanager' && (
					<>
						<div className='thinCol'>
							<Link to='/manage/apps'>
								<h4 className='backLink'>
									<ArrowPrevLeftIcon iconClass='backIcon' />
									Back to apps
								</h4>
							</Link>
							<img src={labmanager} className='logoImg' alt='Transactor Lite' />
							<a
								className='addBtn'
								href={
									`${config.pconHUB}/Identity/Account` +
									`/RegisterLink?returnUrl=${window.location.href}&email=${userState.currUser.email}` +
									`&sellerteamuuid=${teamState.currentTeam.teamUuid}`
								}>
								<button className='btn3-primary'>Install</button>
							</a>
						</div>
						<div className='wideCol'>
							<img src={lmDash} className='largeImg' alt='App Large Image' />
							<p>
								Transactor's LabManager is the UK's Leading Dental Laboratory Software. Add your orders
								to LabManager automatically as they come in and sync your messages.
							</p>
						</div>
					</>
				)}
				{location.pathname.split('/').slice(-1) === 'dentally' && (
					<>
						<div className='thinCol'>
							<Link to='/manage/apps'>
								<h4 className='backLink'>
									<ArrowPrevLeftIcon iconClass='backIcon' />
									Back to apps
								</h4>
							</Link>
							<img src={labmanager} className='logoImg' alt='Transactor Lite' />
							<a className='addBtn' onClick={() => integrate()} href='#'>
								<button className='btn3-primary'>Add</button>
							</a>
						</div>
						<div className='wideCol'>
							<h2>Labmanager</h2>
							<img src={lmDash} className='largeImg' alt='App Large Image' />
							<p>
								Transactor's LabManager is the UK's Leading Dental Laboratory Software. Add your orders
								to LabManager automatically as they come in and sync your messages.
							</p>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

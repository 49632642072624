import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Listing from '../../components/Listing';
import useFetch from '../../hooks/useFetch';
import { useLocation } from 'react-router';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import config from '../../config';

export default function TeamInvoices() {
	let location = useLocation();

	const options = {
		rightGroup: [{ text: '12 months' }, { text: '30 days' }, { text: '1 week' }, { text: '24 hours' }],
		leftGroup: [{ text: 'All Invoices' }],
	};

	const presetStatus = new URLSearchParams(location.search).get('status');

	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState({
		limit: 7,
		skip: 0,
		pages: 0,
		sort: 'date.desc',
	});
	const [search, setSearch] = useState(null);
	const [pages, setPages] = useState(0);

	const topOfPage = useRef(null);

	const { rightSidebarDispatch } = useContext(RightSidebarContext);
	const { teamState } = useContext(CurrentTeamContext);

	const { data, fetch, reqLoading } = useFetch();

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * filter?.limit);
		setLoading(true);
		handleFilter(newSkip, 'skip');
	}

	function resetPagination() {
		let paginationBtns = document.getElementsByClassName('paginationPage');
		for (let i = 0; i < paginationBtns.length; i++) {
			paginationBtns[i]?.classList.remove('paginationActive');
		}
		paginationBtns[0]?.classList.add('paginationActive');
	}

	function handleFilter(value, name) {
		if (name === 'status') {
			rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: value } });
		}
		if (name !== 'skip') {
			resetPagination();
		}

		if (typeof value === 'object') {
			return setFilter((filter) => ({
				...filter,
				...value,
				status: null,
			}));
		}
		setFilter((filter) => ({
			...filter,
			[name]: value,
		}));
	}

	const updateDebounceSearch = useCallback(
		debounce((searchValue) => {
			setSearch(searchValue);
		}),
		[]
	);

	function openFilter() {
		// ! For filter, set content type to new one created for invoices
		const contentType = 6;
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: filter?.status || presetStatus } });
		rightSidebarDispatch({
			type: 'SET_CALLBACK',
			payload: (filter) => {
				handleFilter(filter);
				rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
			},
		});
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: contentType });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
	}

	async function buildURLAndFetch() {
		const url = new URL(`${config.apiv1}/invoice/invoices.read/team/${teamState.currentTeam.teamUuid}`);
		filter?.sort?.split('.')[0] === 'dueDate' && url.searchParams.set('sortDueDate', filter?.sort?.split('.')[1]);
		filter?.sort?.split('.')[0] === 'date' && url.searchParams.set('sortDate', filter?.sort?.split('.')[1]);
		filter?.status && filter?.status !== 'all' && url.searchParams.set('status', filter?.status);
		filter?.limit && url.searchParams.set('limit', filter?.limit);
		filter?.skip && url.searchParams.set('skip', filter?.skip);
		search && url.searchParams.set('search', search);

		if (!filter?.status) {
			filter?.accepted && url.searchParams.set('refunded', filter?.refunded);
			filter?.accepted && url.searchParams.set('disputed', filter?.disputed);
			filter?.completed && url.searchParams.set('paid', filter?.paid);
			filter?.awaiting && url.searchParams.set('sent', filter?.sent);
			filter?.draft && url.searchParams.set('draft', filter?.draft);
		}

		let res = await fetch(url.href, null, true);
		if (res?.ok) {
			setPages(res?.data && res.data.length > 0 ? Math.ceil(res?.totalDocuments / filter?.limit) : 0);
		} else {
			setPages(1);
		}
		setLoading(false);
	}

	function debounce(cb, delay = 400) {
		let timeout;
		return (...args) => {
			setLoading(true);
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	useEffect(() => {
		buildURLAndFetch();
	}, [teamState.reload, filter, search, location]);

	return (
		<>
			<div ref={topOfPage}></div>
			<Listing
				openFilter={openFilter}
				handlePagination={handlePagination}
				handleSearch={updateDebounceSearch}
				loading={reqLoading || loading}
				handleFilter={handleFilter}
				options={options}
				listItems={data}
				filter={filter}
				pages={pages}
				type='invoices'
			/>
		</>
	);
}

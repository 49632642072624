import React, { useEffect, useState } from 'react';
import config from '../../config';
import Axios from 'axios';

export default function MemberInfo({ user, type }) {
	const [avatarUrl, setAvatarUrl] = useState('');

	let fullName = `${user?.firstname ? `${user?.firstname} ` : ''}${user?.lastname ? `${user?.lastname}` : ''}`;

	async function getImgUrl(path) {
		try {
			const res = await Axios({
				url: `${config.auth}/file?path=${path}`,
				method: 'GET',
				responseType: 'blob',
			});
			if (res.data) {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				return url;
			} else {
				return null;
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(async () => {
		setAvatarUrl(await getImgUrl(user?.avatar?.path));
	}, [user]);

	return (
		<div className={`memberCont${type ? ` ${type}` : ''}`}>
			<div className='memberRow'>
				{avatarUrl ? <img className='profileImg' src={avatarUrl}></img> : null}
				<div className='memberCol'>
					<p className='userName'>{fullName ? fullName : ''}</p>
					<p className='userEmail'>{user?.email}</p>
				</div>
			</div>
		</div>
	);
}

import React from 'react';

export default function TabGroup(props) {
	const { tabs, selectedTab } = props || {};
	return (
		<div className='tabGroup'>
			{tabs &&
				tabs.map((tab, key) => {
					return (
						<div
							key={key}
							className={`tab${selectedTab === key ? ' selected' : ''}`}
							onClick={tab?.onClick}>
							{tab?.text}
						</div>
					);
				})}
		</div>
	);
}

import React, { useState } from 'react';
import ApplyRegisterForm from '../../components/Forms/ApplyRegisterForm';
import FormWrap from '../../components/Wrappers/FormWrap';
import Button from '../../components/Button';
import usePost from '../../hooks/usePost';
import config from '../../config';
import Loading from '../../components/Loading/Loading';

export default function ApplyRegister() {
	const [applied, setApplied] = useState(false);

	const { data, post, reqLoading, error, customErrors, handleFormData, formData } = usePost(
		`${config.apiv1}/application/application.create`,
		(data) => {
			if (data?.ok) {
				setApplied(true);
			}
		},
		'applyRegisterForm'
	);

	return (
		<FormWrap backColor='white'>
			{!reqLoading ? (
				<ApplyRegisterForm
					handleFormData={handleFormData}
					customErrors={customErrors}
					setApplied={setApplied}
					formData={formData}
					applied={applied}
				/>
			) : (
				<Loading type='circle' />
			)}
			{!applied && (
				<div className='btnCont'>
					<Button onClick={() => post()} style='primary' size='lg' maxWidth={100} text='Apply' />
				</div>
			)}
		</FormWrap>
	);
}

import React from 'react';
import Section from './Section';
import Button from '../../../components/Button';
import { PlayCircleIcon } from '../../../components/SVGIcons/SVGIcons';

export default function FeaturesIcons(props) {
	const { sectionHeader, paragraph1, paragraph2, features, border, showHeader } = props || {};
	return (
		<Section border={border}>
			{showHeader && (
				<div className='col'>
					<h4 className='header marginBottom-20'>{sectionHeader}</h4>
					<p className='paragraph marginBottom-20'>{paragraph1}</p>
					<p className='paragraph'>{paragraph2}</p>
				</div>
			)}
			<div className='featuresIcons'>
				{features &&
					features.length > 0 &&
					features.map((feature, key) => {
						return (
							<div className='feature' key={key}>
								<div className='dualTurqBorderIcon'>{feature.icon}</div>
								<div className='col'>
									<h4>{feature.header}</h4>
									<p>{feature.paragraph}</p>
								</div>
							</div>
						);
					})}
			</div>
			{/* <Button size='xxl' text='Play video tour' style='primary' iconLeft={<PlayCircleIcon />} /> */}
		</Section>
	);
}

import { useState, useEffect } from 'react';

export default function useScroll() {
	const [scrollY, setScrollY] = useState(0);

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		document.addEventListener('scroll', handleScroll, true);
		return () => {
			document.removeEventListener('scroll', handleScroll, true);
		};
	}, []);

	return { scrollY, setScrollY };
}

import React, { useContext, useEffect, useState } from 'react';
import DashboardNotifications from './DashboardNotifications';
import Carousel from '../../components/Carousel';
import TutorialPrompt from '../../components/Tutorial/TutorialPrompt';
import Axios from 'axios';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import config from '../../config';
import Loading from '../../components/Loading/Loading';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import Cookies from 'js-cookie';
import TeamChooseModal from '../../components/Modals/TeamChooseModal';
import Overlay from '../../components/Overlay';
import useFetch from '../../hooks/useFetch';
import Signposts from '../../components/Signposts';

export default function Dashboard() {
	const [loaded, setLoaded] = useState(false);
	const [suppliers, setSuppliers] = useState([]);
	const [ordersCount, setOrdersCount] = useState();
	const [notificationsCount, setNotificationsCount] = useState();
	let { getNewTokens } = useContext(CurrentUserContext);
	let { teamState, teamDispatch } = useContext(CurrentTeamContext);
	let { notificationsState } = useContext(NotificationContext);

	const { data, reqLoading } = useFetch(`${config.apiv1}/team/team.read/trusted/${teamState.currentTeam.teamUuid}`);

	const countOrdersByStatus = (data) => {
		const orderTypeCounts = {
			drafts: 0,
			purchasesPending: 0,
			purchasesAccepted: 0,
			purchasesProcessing: 0,
			purchasesDispatched: 0,
			purchasesHeld: 0,
			salesPending: 0,
			salesAccepted: 0,
			salesProcessing: 0,
			awaitingSuborder: 0,
			awaitingDispatch: 0,
			salesHeld: 0,
		};

		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i]?.hold) {
					if (
						data[i].buyerTeamUuid === teamState.currentTeam.teamUuid &&
						data[i].sellerTeamUuid === teamState.currentTeam.teamUuid
					) {
						orderTypeCounts.purchasesHeld++;
						orderTypeCounts.salesHeld++;
					} else if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
						orderTypeCounts.purchasesHeld++;
					} else {
						orderTypeCounts.salesHeld++;
					}
				} else {
					switch (data[i].orderProcessingStatus) {
						case 0:
							if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
								orderTypeCounts.drafts++;
							}
							break;
						case 1:
							if (
								data[i].buyerTeamUuid === teamState.currentTeam.teamUuid &&
								data[i].sellerTeamUuid === teamState.currentTeam.teamUuid
							) {
								orderTypeCounts.purchasesPending++;
								orderTypeCounts.salesPending++;
							} else if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
								orderTypeCounts.purchasesPending++;
							} else {
								orderTypeCounts.salesPending++;
							}
							break;
						case 2:
							if (
								data[i].buyerTeamUuid === teamState.currentTeam.teamUuid &&
								data[i].sellerTeamUuid === teamState.currentTeam.teamUuid
							) {
								orderTypeCounts.purchasesAccepted++;
								orderTypeCounts.salesAccepted++;
							} else if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
								orderTypeCounts.purchasesAccepted++;
							} else {
								orderTypeCounts.salesAccepted++;
							}
							break;
						case 3:
							if (
								data[i].buyerTeamUuid === teamState.currentTeam.teamUuid &&
								data[i].sellerTeamUuid === teamState.currentTeam.teamUuid
							) {
								orderTypeCounts.purchasesProcessing++;
								orderTypeCounts.salesProcessing++;
							} else if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
								orderTypeCounts.purchasesProcessing++;
							} else {
								orderTypeCounts.salesProcessing++;
							}
							break;
						case 4:
							if (data[i].buyerTeamUuid !== teamState.currentTeam.teamUuid) {
								orderTypeCounts.awaitingDispatch++;
							}
							break;
						case 5:
							if (
								data[i].buyerTeamUuid === teamState.currentTeam.teamUuid &&
								data[i].sellerTeamUuid === teamState.currentTeam.teamUuid
							) {
								orderTypeCounts.purchasesDispatched++;
								orderTypeCounts.salesDispatched++;
							} else if (data[i].buyerTeamUuid === teamState.currentTeam.teamUuid) {
								orderTypeCounts.purchasesDispatched++;
							} else {
								orderTypeCounts.salesDispatched++;
							}
							break;
					}
				}
			}
		}
		return orderTypeCounts;
	};

	const countNotificationsByType = (data) => {
		const notificationCounts = {
			messages: 0,
			pricingReviews: 0,
			unreadNotifications: 0,
		};

		if (data) {
			for (let i = 0; i < data.length; i++) {
				switch (data[i].notificationType) {
					case 'message.created':
						notificationCounts.messages++;
						if (!data[i].isRead) {
							notificationCounts.unreadNotifications++;
						}
						break;
					case 'order.priceSuggested':
						notificationCounts.pricingReviews++;
						if (!data[i].isRead) {
							notificationCounts.unreadNotifications++;
						}
						break;
					default:
						if (!data[i].isRead) {
							notificationCounts.unreadNotifications++;
						}
						break;
				}
			}
		}
		return notificationCounts;
	};

	useEffect(async () => {
		let mounted = true;
		if (mounted && teamState.currentTeam.teamUuid) {
			function handleRes(data, loaded) {
				setOrdersCount(countOrdersByStatus(data?.data));
				setLoaded(loaded);
			}
			try {
				let res = await Axios({
					url: `${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}?skip=0&search=&sortDate=desc&teamType=&outputType=minimal`,
					method: 'GET',
				});
				if (res?.data?.data) {
					handleRes(res?.data, true);
				} else {
					handleRes(null, true);
				}
			} catch (err) {
				if (err.response.status === 401) {
					getNewTokens();
				}
				handleRes(null, true);
				return err;
			}
		}
		return () => (mounted = false);
	}, []);

	useEffect(() => {
		setNotificationsCount(() => countNotificationsByType(notificationsState?.notifications));
	}, [notificationsState]);

	return (
		<>
			{loaded ? (
				<>
					<Signposts />
					<div className='mainOverview'>
						<DashboardNotifications ordersCount={ordersCount} notificationsCount={notificationsCount} />
					</div>
					<Carousel
						headerType={'suppliers'}
						backColor={'gray-200'}
						type={'suppliers'}
						loaded={!reqLoading}
						customList={data}
						mode={'light'}
					/>
					<Carousel
						backColor={'learningZoneBack'}
						type={'learningZone'}
						headerType={'learningZone'}
						mode={'dark'}
					/>
					<TutorialPrompt page='dashboard' />
				</>
			) : (
				<Loading type={'circle'} />
			)}
		</>
	);
}

import React from 'react';
import { useNavigate } from 'react-router';
import { TeamCheckIcon, ThreeDotsIcon } from '../SVGIcons/SVGIcons';
import { parseExactDate } from '../../helpers';

export default function MemberNotification(props) {
	const {
		notification,
		setRead,
		checkHref,
		deleteNotification,
		style,
		handleDropdowns,
		dropdownsOpen,
		rightSidebarDispatch,
	} = props || {};
	const { notificationData } = notification;

	let navigate = useNavigate();

	return (
		<>
			<div
				className={`mainRow ${notification.isRead ? 'opacity-50' : ''}`}
				onClick={() => {
					setRead(notification?.notificationUuid);
					checkHref(notification.notificationType === 'member.added' ? `/manage/members` : '/manage/trusted');
					navigate(notification.notificationType === 'member.added' ? `/manage/members` : '/manage/trusted');
					rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
				}}>
				<div className={`iconCol ${style}`}>
					{notification.notificationType === 'member.added' && <TeamCheckIcon iconClass='notificationIcon' />}
					{notification.notificationType === 'team.trustedSuggested' && (
						<TeamCheckIcon iconClass='notificationIcon' />
					)}
				</div>
				<div className='bodyCol'>
					{notification.notificationType === 'member.added' && (
						<>
							<div className='colRowTop'>
								<h4 className={`notificationHeader ${style}`}>{`MEMBER JOINED THE TEAM`}</h4>
								<h4 className='notificationDate'>{parseExactDate(notification.createdAt)}</h4>
							</div>
							<div className='colRowBottom'>
								<p className='bodyText'>{`${notificationData.name} joined the team`}</p>
								<ThreeDotsIcon
									iconClass='notificationSubIcon'
									onClick={(e) => {
										e.stopPropagation();
										handleDropdowns(notification?.notificationUuid);
									}}
								/>
							</div>
						</>
					)}
					{notification.notificationType === 'team.trustedSuggested' && (
						<>
							<div className='colRowTop'>
								<h4 className={`notificationHeader ${style}`}>{`TRUSTED SUPPLIER SUGGESTED`}</h4>
								<h4 className='notificationDate'>{parseExactDate(notification.createdAt)}</h4>
							</div>
							<div className='colRowBottom'>
								<p className='bodyText'>{`${notificationData.suggestedBy.name} suggested adding '${notificationData.teamName}' as a trusted supplier`}</p>
								<ThreeDotsIcon
									iconClass='notificationSubIcon'
									onClick={(e) => {
										e.stopPropagation();
										handleDropdowns(notification?.notificationUuid);
									}}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			{dropdownsOpen && (
				<>
					{dropdownsOpen[notification?.notificationUuid] && (
						<div className='notificationDropdown'>
							<p
								onClick={() => {
									deleteNotification(notification);
								}}>
								Delete
							</p>
							<p
								onClick={() => {
									setRead(notification?.notificationUuid);
								}}>
								Mark as read
							</p>
						</div>
					)}
				</>
			)}
		</>
	);
}

import React from 'react';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import InviteTeamForm from '../Forms/InviteTeamForm';
import { TopnavCloseIcon } from '../SVGIcons/SVGIcons';
import InviteTeamFormNEW from '../Forms/InviteTeamFormNEW';
import Overlay from '../Overlay';
import ConfirmModalForm from '../Forms/ConfirmModalForm';
import InvoiceItemForm from '../Forms/InvoiceItemForm';
import ProductForm from '../Forms/ProductForm';
import PackageChoiceList from '../ModalListing/PackageChoiceList';
import PackageNewModal from '../Modals/PackageNewModal';
import PackageEditForm from '../Forms/PackageEditForm';

const Modal = () => {
	const { modals, hideModal } = useModalsGlobal();

	return (
		<>
			{modals?.length !== 0 && (
				<>
					{modals.map((modal, index) => (
						<div
							key={index}
							className='flexModal'
							style={{
								zIndex: modal.zIndex,
								position: modal?.options?.fixed === true ? 'fixed' : '',
								backgroundColor: modal?.options?.hideBackground ? 'transparent' : '',
								boxShadow: modal?.options?.hideBackground ? 'none' : '',
							}}>
							<TopnavCloseIcon
								iconClass='closeIcon'
								onClick={() => {
									hideModal(index);
								}}
							/>
							{modal.type === 'noPermissions' && (
								<ConfirmModalForm
									hideModal={() => hideModal(index)}
									options={modal?.options}
									onlyOk={modal?.options?.onlyOk}
								/>
							)}
							{modal.type === 'inviteTeam' && (
								<InviteTeamFormNEW hideModal={() => hideModal(index)} options={modal?.options} />
							)}
							{modal.type === 'addInvoiceItem' && (
								<InvoiceItemForm hideModal={() => hideModal(index)} options={modal?.options} />
							)}
							{modal.type === 'editInvoiceItem' && (
								<InvoiceItemForm hideModal={() => hideModal(index)} options={modal?.options} />
							)}
							{modal.type === 'editPackage' && (
								<PackageEditForm hideModal={() => hideModal(index)} options={modal?.options} />
							)}
							{modal.type === 'product' && (
								<ProductForm hideModal={() => hideModal(index)} options={modal?.options} />
							)}
							{modal.type === 'packageChoiceList' && (
								<PackageChoiceList options={modal?.options} hideModal={() => hideModal(index)} />
							)}
							{modal.type === 'packageNewModal' && (
								<PackageNewModal options={modal?.options} hideModal={() => hideModal(index)} />
							)}
							{modal.type === 'confirmChoice' && (
								<ConfirmModalForm options={modal?.options} hideModal={() => hideModal(index)} />
							)}
						</div>
					))}
					<Overlay
						loadOverlay={true}
						showOverlay={true}
						setShowOverlay={() => hideModal(modals?.length)}
						zIndex={99}
					/>
				</>
			)}
		</>
	);
};

export default Modal;

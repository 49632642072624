import React from 'react';
import SelectCustom from '../Select/SelectCustom';
import AutofillInput from './AutofillInput';
import TooltipIcon from '../TooltipIcon';

export default function SelectInput(props) {
	const {
		text,
		name,
		thin,
		onlyInput,
		callback,
		options,
		placeholder,
		warning,
		htmlOptions,
		value,
		customError,
		required,
		reverse,
		missingOptions,
		extraField,
		preview,
		autofill,
		defaultOption,
		customCallbackValue,
		tooltip,
		center,
	} = props || {};

	return (
		<div className={`${onlyInput ? 'inputContainer' : 'formRowCenter'}${center ? ' center' : ''}`} id={name}>
			{(required && value === null) || (required && value === undefined && <div isrequired={name}></div>)}
			{!onlyInput && (
				<div className='fieldTextContainer'>
					{thin ? (
						<p className='inputLabel' htmlFor={name}>
							{text}
						</p>
					) : (
						<p className='extraBold' htmlFor={name}>
							{text}
						</p>
					)}

					{tooltip && <TooltipIcon id={name} tooltip={tooltip} delay={500} />}
				</div>
			)}
			<SelectCustom
				options={options}
				callback={callback}
				placeholder={placeholder || 'Select...'}
				warning={warning}
				htmlOptions={htmlOptions}
				fieldName={name}
				value={value}
				customError={customError}
				reverse={reverse}
				missingOptions={missingOptions}
				extraField={extraField}
				preview={preview}
				defaultOption={defaultOption}
			/>
			{autofill && customCallbackValue && (
				<AutofillInput
					callback={callback}
					customCallbackValue={customCallbackValue}
					name={name}
					defaultValue={value || ''}
				/>
			)}
		</div>
	);
}

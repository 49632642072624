import React from 'react';
import { ChevronLeftIcon } from '../SVGIcons/SVGIcons';

export default function ArrowLeft(props) {
	const { disabled, color, previous } = props || {};
	return (
		<div className='arrowLeft' style={{ fill: color }} onClick={disabled ? null : previous}>
			<ChevronLeftIcon iconClass={`arrow${disabled ? ' disabledSVG' : ''}`} />
		</div>
	);
}

import React, { useContext, useEffect } from 'react';
import TextInput from '../Inputs/TextInput';
import TextareaInput from '../Inputs/TextareaInput';
import * as yup from 'yup';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useForms } from '../../hooks/useForms';
import Button from '../Button';
import config from '../../config';

export default function InvoiceItemForm({ hideModal, options }) {
	const { invoiceUuid, callback, submitUrl, method, data } = options || {};
	const formValidationSchema = yup.object().shape({
		description: yup.string().required(),
		quantity: yup.number().required(),
		unitAmount: yup.number().required(),
	});

	const { teamState } = useContext(CurrentTeamContext);

	let addInvoiceItemMap = (formData) => {
		return {
			invoiceUuid: invoiceUuid,
			recipientTeamUuid: teamState?.currentTeam?.teamUuid,
			quantity: formData.quantity,
			description: formData.description,
			unitAmount: formData.unitAmount,
			discountAmount: formData.discountAmount,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'addInvoiceItem',
		formValidationSchema,
		addInvoiceItemMap,
		submitUrl,
		method
	);

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async () => {
		let success = await submitForm(null, `Line item added successfully`, callback);
		if (success === true) {
			hideModal();
		}
	};

	useEffect(() => {
		if (data) {
			updateForm({ ...data });
		}
	}, []);

	return (
		<>
			<form id='addInvoiceItem'>
				<h4>Add invoice line item</h4>
				<TextareaInput
					text='Description'
					name={'description'}
					value={formData?.description}
					controlled
					callback={handleFormData}
					required={true}
					customError={customErrors?.description}
				/>
				<TextInput
					text='Quantity'
					type='number'
					name={'quantity'}
					value={formData?.quantity}
					controlled
					callback={handleFormData}
					customError={customErrors?.quantity}
				/>
				<TextInput
					text='Amount'
					type='number'
					name={'unitAmount'}
					value={formData?.unitAmount}
					controlled
					callback={handleFormData}
					customError={customErrors?.unitAmount}
				/>
				<TextInput
					text='Discount'
					type='number'
					name={'discountAmount'}
					value={formData?.discountAmount}
					controlled
					callback={handleFormData}
					customError={customErrors?.discountAmount}
				/>
			</form>
			<div className='btnCont'>
				<Button
					size='md'
					style='primary'
					text='Submit'
					onClick={handleSubmit}
					loading={reqLoading}
					minWidth={135}
				/>
			</div>
		</>
	);
}

import { useState, useEffect, useRef, useContext } from 'react';
import { ModalContext } from '../contexts/ModalContext';

export default function useCompVis(initVis, cb) {
	const [compVis, setCompVis] = useState(initVis);
	const ref = useRef(null);

	const { modals } = useContext(ModalContext);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target) && modals?.length === 0) {
			setCompVis(false);
			cb();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [cb, modals]);

	return { ref, compVis, setCompVis };
}

import React from 'react';
import { ReactComponent as EmptyStateSuppliersSVG } from '../../assets/figma_design_doc/empty_state_cloud_suppliers.svg';
import { ReactComponent as EmptyStateFilesSVG } from '../../assets/figma_design_doc/empty_state_cloud_files.svg';
import { ReactComponent as EmptyStateBlankSVG } from '../../assets/figma_design_doc/empty_state_cloud_blank.svg';

export default function EmptyState(props) {
	const { type, header, body, btn1, btn2, thin } = props || {};
	return (
		<div className={`emptyStateCont${thin ? ' thin' : ''}`}>
			{type === 'suppliers' && <EmptyStateSuppliersSVG />}
			{type === 'files' && <EmptyStateFilesSVG />}
			{!type && <EmptyStateBlankSVG />}
			<h4 className='emptyStateHeader'>{header}</h4>
			{body && <p>{body}</p>}
			{btn1 || btn2 ? (
				<div className='btnCont'>
					{btn1 && (
						<button
							className='btn3-secondary thick'
							onClick={(e) => {
								btn1.callback(e);
							}}>
							{btn1.icon}
							{btn1.text}
						</button>
					)}
					{btn2 && (
						<button
							className='btn3-primary wide thick'
							onClick={(e) => {
								btn2.callback(e);
							}}>
							{btn2.icon}
							{btn2.text}
						</button>
					)}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

import React from 'react';
import Button from '../Button';

export default function Tabs({ tabsList, selected, setSelected }) {
	return (
		<div className='tabsList'>
			{tabsList?.length > 0 &&
				tabsList.map((tab, key) => {
					return (
						<React.Fragment key={key}>
							<Button
								style={tab.style}
								onClick={() => setSelected(tab.name)}
								size='md'
								text={tab.text}
								textThickness='thick'
								color={tab.color}
								active={selected === tab.name}
							/>
						</React.Fragment>
					);
				})}
		</div>
	);
}

import React, { useContext, useEffect, useRef } from 'react';
import { PlusIcon } from '../../../components/SVGIcons/SVGIcons';
import { checkStatusIfDisable, dateConverter, splitUserId } from '../../../helpers';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';

export default function OrderComments(props) {
	const { order, handleModal, setShowOverlay } = props?.props || {};

	const { userState } = useContext(CurrentUserContext);

	let commentsRef = useRef(null);

	useEffect(() => {
		commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
	}, []);

	return (
		<div className='gridItemOrder comments'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Private notes</h4>
				<div className='colHeaderShort'>
					<div className={`fileIcon marginLeft-auto`}>
						<div
							className='plus discIcon'
							onClick={() => {
								handleModal('commentModal', true);
								setShowOverlay(true);
							}}>
							<PlusIcon />
						</div>
					</div>
				</div>
			</div>
			<div className='chatBox' ref={commentsRef}>
				{order?.comments?.length > 0 ? (
					order?.comments?.map((comment, key) => {
						if (comment) {
							return (
								<div
									className={
										comment.userUuid === splitUserId(userState.currUser.sub)
											? 'chatMessageUser fullWidth'
											: 'chatMessage fullWidth'
									}
									key={key}>
									<div className='messageTopCont'>
										<p className='messageUserName'>
											{comment.name}
											{comment.userUuid === splitUserId(userState.currUser.sub) ? ' (You)' : ''}
										</p>
										<p className='chatTimestamp'>
											{dateConverter(comment?.createdAt, 'hh:mm - DD/MM/YYYY')}
										</p>
									</div>
									<p className='messageContent'>{comment?.comment}</p>
								</div>
							);
						} else {
							return <React.Fragment key={key}></React.Fragment>;
						}
					})
				) : (
					<>No private notes</>
				)}
			</div>
		</div>
	);
}

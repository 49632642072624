import React, { useEffect } from 'react';

export default function Privacy() {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.async = true;
		document.body.appendChild(script);
	}, []);

	return (
		<div
			className='termlyCont'
			name='termly-embed'
			data-id='1a0387cb-fa4c-46f5-9fb6-94bb8199c373'
			data-type='iframe'></div>
	);
}

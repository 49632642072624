import React from 'react';
import AutofillInput from './AutofillInput';
import { v4 as uuidv4 } from 'uuid';

export default function RadioInput(props) {
	const {
		text,
		name,
		defaultValue,
		callback,
		disabled,
		radios,
		required,
		customError,
		onlyInput,
		preview,
		horizontal,
		autofill,
		customCallbackValue,
	} = props || {};

	let previewName = uuidv4();

	return (
		<>
			{!onlyInput ? (
				<div className='formRowTop'>
					{(required && defaultValue === null) ||
						(required && defaultValue === undefined && <div isrequired={name}></div>)}
					<div className='fieldTextContainer radio'>
						<p className='extraBold' htmlFor={name}>
							{text}
						</p>
					</div>
					<div className='formChoices' id={name}>
						{radios.map((radio, key) => {
							if (preview) {
								let id = uuidv4();
								return (
									<label className='radioLabel' htmlFor={id} key={key}>
										<input
											id={id}
											type='radio'
											name={previewName}
											value={radio.value}
											disabled={disabled || radio.disabled}
										/>
										<div className='customRadio'></div>
										<span>{radio.text}</span>
									</label>
								);
							} else {
								return (
									<label className='radioLabel' key={key}>
										<input
											type='radio'
											name={name}
											value={radio.value}
											checked={defaultValue?.toString() === radio?.value?.toString()}
											onChange={() => {
												callback(radio.value, name);
												radio.extraFunction ? radio.extraFunction() : null;
											}}
											disabled={disabled || radio.disabled}
										/>
										<div className='customRadio'></div>
										<span>{radio.text}</span>
									</label>
								);
							}
						})}
						{customError && <h4 className='tooltipError'>{customError}</h4>}
						{autofill && customCallbackValue && (
							<AutofillInput
								callback={callback}
								customCallbackValue={customCallbackValue}
								name={name}
								defaultValue={defaultValue || ''}
							/>
						)}
					</div>
				</div>
			) : (
				<>
					{!horizontal ? (
						<>
							{radios.map((radio, key) => {
								return (
									<label className={`radioLabel${radio.disabled ? ' disabledDiv' : ''}`} key={key}>
										<input
											type='radio'
											name={name}
											value={radio.value}
											checked={defaultValue?.toString() === radio?.value?.toString()}
											onChange={() => {
												callback(radio.value, name);
												radio.extraFunction ? radio.extraFunction() : null;
											}}
											disabled={disabled || radio.disabled}
										/>
										<div className='customRadio'></div>
										<span>{radio.text}</span>
									</label>
								);
							})}
						</>
					) : (
						<div className='horizontalRadios'>
							{radios.map((radio, key) => {
								return (
									<label className={`radioLabel${radio.disabled ? ' disabledDiv' : ''}`} key={key}>
										<input
											type='radio'
											name={name}
											value={radio.value}
											checked={defaultValue?.toString() === radio?.value?.toString()}
											onChange={() => {
												callback(radio.value, name);
												radio.extraFunction ? radio.extraFunction() : null;
											}}
											disabled={disabled || radio.disabled}
										/>
										<div className='customRadio'></div>
										<span>{radio.text}</span>
									</label>
								);
							})}
						</div>
					)}
				</>
			)}
		</>
	);
}

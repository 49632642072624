import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DocketDotsHorIcon } from '../../../components/SVGIcons/SVGIcons';
import { parseFieldIcon } from '../../../helpers';

export default function ListField(props) {
	const { field, index } = props || {};

	return (
		<Draggable draggableId={field.id} index={index}>
			{(provided) => (
				<div className={field.class || 'field'} {...provided.draggableProps} ref={provided.innerRef}>
					<span className='dragHandle' {...provided.dragHandleProps}>
						<DocketDotsHorIcon iconClass='docketIcon' />
					</span>
					{parseFieldIcon(field.type)}
					<span className='fieldText'>{field.name}</span>
				</div>
			)}
		</Draggable>
	);
}

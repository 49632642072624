import React from 'react';
import RadioInput from '../Inputs/RadioInput';

export default function TrustedForm(props) {
	const { preferences, handlePreference } = props || {};
	return (
		<div className='mainFormCont'>
			<h2>Order preferences</h2>
			<h4>(choices will be pre-set for all orders towards supplier)</h4>
			<RadioInput
				text={'Accept estimate automatically'}
				name={'acceptEstimate'}
				defaultValue={preferences?.acceptEstimate}
				callback={handlePreference}
				radios={[
					{
						value: true,
						text: 'Yes',
					},
					{
						value: false,
						text: 'No, authorization required',
					},
				]}
				required
			/>
			<RadioInput
				text={'Will you automatically accept changes to pricing throughout the manufacturing process?'}
				name={'acceptPricing'}
				defaultValue={preferences?.acceptPricing}
				callback={handlePreference}
				radios={[
					{
						value: true,
						text: 'Yes',
					},
					{
						value: false,
						text: 'No, each pricing change needs approval',
					},
				]}
				required
			/>
			<h2>Permissions</h2>
			<RadioInput
				text={'Allow Seller to create an Order on this Teams behalf?'}
				name={'allowOnBehalf'}
				defaultValue={preferences?.allowOnBehalf || false}
				callback={handlePreference}
				radios={[
					{
						value: true,
						text: 'Yes',
					},
					{
						value: false,
						text: 'No',
					},
				]}
				required
			/>
		</div>
	);
}

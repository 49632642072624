import React from 'react';
import { integrationDataLabels } from '../../../helpers';

export default function OrderDtlyInfo(props) {
	const { order } = props?.props || {};
	let dentallyData = order?.integrations?.soe?.dentally?.appData;
	let dentallyLabels = integrationDataLabels('dentally');

	return (
		<div className='gridItemOrder dentally'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Dentally</h4>
			</div>
			<div className='colCont'>
				{dentallyData &&
					Object.keys(dentallyData).map((key, index) => {
						if (dentallyLabels[key]) {
							return (
								<div className='row' key={index}>
									<p className='text'>{dentallyLabels[key]}: </p>
									<div className='data bold'>{dentallyData[key]}</div>
								</div>
							);
						} else {
							return <React.Fragment key={index}></React.Fragment>;
						}
					})}
			</div>
		</div>
	);
}

import React, { useState } from 'react';

export default function useModals() {
	const [modalsState, setModalsState] = useState({});

	const handleModal = (name, state) => {
		if (!name) {
			setModalsState({});
		} else {
			setModalsState((modalsState) => ({
				...modalsState,
				[name]: state,
			}));
		}
	};

	const handleFadeModal = (name, state) => {
		const second = 1000;
		setModalsState((modalsState) => ({
			...modalsState,
			[name]: state,
		}));
		setTimeout(() => {
			setModalsState((modalsState) => ({
				...modalsState,
				[name]: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	};

	const flashFadeModal = (message) => {
		handleFadeModal('modalFading', {
			show: true,
			isFading: false,
			message: message,
		});
	};

	return { handleModal, handleFadeModal, modalsState, flashFadeModal };
}

import React from 'react';
import { ChevronRightIcon } from '../SVGIcons/SVGIcons';

export default function ArrowRight(props) {
	const { disabled, color, next } = props || {};
	return (
		<div className='arrowRight' style={{ fill: color }} onClick={disabled ? null : next}>
			<ChevronRightIcon iconClass={`arrow${disabled ? ' disabledSVG' : ''}`} />
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import config from '../../config';
import { ChatIcon, PhoneOutIcon, ShopIcon, VerifiedSmallIcon } from '../../components/SVGIcons/SVGIcons';
import ListingPagination from '../../components/Pagination/ListingPagination';
import Loading from '../../components/Loading/Loading';

export default function TeamList() {
	const [pages, setPages] = useState(0);
	const [skip, setSkip] = useState(0);
	const [suppliers, setSuppliers] = useState([]);
	const [sortingAlphabet, setSortingAlphabet] = useState('asc');
	const [loaded, setLoaded] = useState(false);

	let limit = 25;

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * limit);
		setSkip(newSkip);
		setReload(true);
		topOfPage.current.scrollIntoView({ behavior: 'smooth' });
	}

	async function fetchSuppliers() {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.read?sort=${sortingAlphabet}&skip=${skip}&limit=${limit}&isPublic=true`,
				method: 'GET',
			});
			if (response.data?.data) {
				setPages(Math.ceil(response.data.totalDocuments / limit));
				setSuppliers(response.data.data);
			}
			setLoaded(true);
		} catch (err) {
			console.log(err);
			return err;
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			fetchSuppliers();
		}
		return () => (mounted = false);
	}, [sortingAlphabet, skip]);

	return (
		<div className='supplierListCont'>
			{loaded ? (
				<div className='supplierList'>
					<div className='filterHeader'>
						<p>COMPANY NAME</p>
						<p>SHORT DESCRIPTION</p>
						<p>CERTIFIED</p>
						{/* <p>Trusted</p>
                    <p>Awards</p>
                    <p>Rating</p> */}
						<p></p>
					</div>
					{suppliers ? (
						suppliers.map((supplier, key) => {
							if (supplier) {
								return (
									<div className='supplier' key={key}>
										<div className='bodyRow'>
											<Link className='linkText' to={supplier?.teamUuid || '/'}>
												{supplier.teamInfo?.teamName}
											</Link>
											<p>
												{supplier.teamInfo?.companyDescription?.substr(0, 60)}
												{supplier.teamInfo?.companyDescription?.length > 60 && <span>...</span>}
											</p>
											{supplier.procuurVerified === true ? (
												<div className='certRow'>
													<VerifiedSmallIcon iconClass='listingIcon' />
												</div>
											) : (
												<div className='certRow'></div>
											)}
											<div className='iconRow'>
												<div className='iconCont phone disabledDiv'>
													<PhoneOutIcon iconClass='listingIcon blue' />
												</div>
												<div className='iconCont chat  disabledDiv'>
													<ChatIcon iconClass='listingIcon blue' />
												</div>
												<Link
													to={`/suppliers/${supplier?.teamUuid}?name=${supplier?.teamInfo?.teamName}`}>
													<div className='iconCont'>
														<ShopIcon iconClass='listingIcon turq' />
													</div>
												</Link>
											</div>
										</div>
									</div>
								);
							}
						})
					) : (
						<></>
					)}
				</div>
			) : (
				<Loading type='circle' />
			)}
			<ListingPagination handleClick={handlePagination} pages={pages} />
		</div>
	);
}

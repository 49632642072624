export const handleResourceError = (err, navigate, showModal) => {
	if (err?.response?.status === 403 && err?.response?.data?.message === 'NOT_RESOURCE_OWNER') {
		navigate('/');
	} else if (err?.response?.status === 403 && err?.response?.data?.message === 'PERMISSIONS_INSUFFICIENT') {
		showModal('noPermissions', {
			header: 'You do not have permission to view this resource',
			body: ' Please contact your team administrator.',
			onlyOk: true,
			callback: () => navigate('/'),
		});
	}
};

export const handleCreationError = (err, navigate, showModal) => {
	if (err?.response?.status === 403 && err?.response?.data?.message === 'NOT_RESOURCE_OWNER') {
		navigate('/');
	} else if (err?.response?.status === 403 && err?.response?.data?.message === 'PERMISSIONS_INSUFFICIENT') {
		showModal('noPermissions', {
			header: 'You do not have permission to create this resource',
			body: ' Please contact your team administrator.',
			onlyOk: true,
		});
	}
};

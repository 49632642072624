import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { handleIconClass } from '../../helpers';
import { Checkmark, ErrorIcon } from '../SVGIcons/SVGIcons';
import AutofillInput from './AutofillInput';
import 'react-datepicker/dist/react-datepicker.css';

export default function DateInput(props) {
	const {
		text,
		name,
		defaultValue,
		callback,
		autofill,
		handleFocused,
		focused,
		disabled,
		customCallbackValue,
		required,
		customError,
		timeSelect,
		dateFormat,
		onlyInput,
		preview,
		value,
		min,
		max,
	} = props || {};

	function checkValue() {
		if (value) {
			return value;
		} else if (defaultValue) {
			return defaultValue;
		} else {
			return false;
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			let datePicker = document.getElementsByClassName('react-datepicker');
			if (datePicker.length > 0) {
				datePicker[0]?.classList?.add('flexRow');
			}
		}
		return () => (mounted = false);
	}, [focused]);

	return (
		<>
			{!onlyInput ? (
				<div className='formRowCenter'>
					{required && defaultValue === undefined && <div isrequired={name}></div>}
					{required && defaultValue === null && <div isrequired={name}></div>}
					{required && defaultValue === '' && <div isrequired={name}></div>}
					<div className='fieldTextContainer'>
						<p className='extraBold' htmlFor={name}>
							{text}
						</p>
					</div>
					<div className='inputContainer'>
						<DatePicker
							selected={checkValue() ? new Date(checkValue()) : undefined}
							name={name}
							id={name}
							className='textFieldInput'
							onBlur={(e) => (handleFocused ? handleFocused(e) : null)}
							onFocus={(e) => {
								handleFocused ? handleFocused(e) : null;
								e.target.readOnly = true;
							}}
							onChange={(newDate) => {
								if (!timeSelect) {
									newDate?.setHours(12, 0, 0, 0);
								}
								callback(newDate?.toISOString(), name);
							}}
							minDate={min || null}
							maxDate={max || null}
							showTimeSelect={timeSelect}
							required
							dateFormat={dateFormat}
							disabled={disabled || preview}
						/>
						{!preview && focused && (
							<>
								{defaultValue !== '' && defaultValue !== null && defaultValue !== undefined ? (
									<Checkmark
										iconClass={handleIconClass(defaultValue ? true : false, focused[name])}
									/>
								) : (
									<ErrorIcon
										iconClass={handleIconClass(defaultValue ? true : false, focused[name])}
									/>
								)}
							</>
						)}
					</div>
					{autofill && customCallbackValue && (
						<AutofillInput
							callback={callback}
							customCallbackValue={customCallbackValue}
							name={name}
							defaultValue={defaultValue}
						/>
					)}
					{customError && <h4 className='tooltipError'>{customError}</h4>}
				</div>
			) : (
				<DatePicker
					selected={checkValue() ? new Date(checkValue()) : undefined}
					name={name}
					id={name}
					className='textFieldInput'
					onBlur={(e) => (handleFocused ? handleFocused(e) : null)}
					onFocus={(e) => {
						handleFocused ? handleFocused(e) : null;
						e.target.readOnly = true;
					}}
					onChange={(newDate) => {
						if (!timeSelect) {
							newDate?.setHours(12, 0, 0, 0);
						}
						callback(newDate?.toISOString(), name);
					}}
					minDate={min || null}
					maxDate={max || null}
					showTimeSelect={timeSelect}
					required
					dateFormat={dateFormat}
					disabled={disabled || preview}
				/>
			)}
		</>
	);
}

import React from 'react';
import { dateConverter } from '../../../helpers';
import { CaretDownIcon, CaretUpIcon } from '../../SVGIcons/SVGIcons';

export default function OrderDetails(props) {
	const { order, textboxesOpen, openTextbox } = props || {};
	return (
		<>
			<div className='rightSidebarTextContainer'>
				<div className='sidebarTextHeader' onClick={() => openTextbox('order')}>
					<h4 className='bold'>Order details</h4>
					{textboxesOpen['order'] ? (
						<CaretUpIcon iconClass={'caretUpIcon'} />
					) : (
						<CaretDownIcon iconClass={'caretDownIcon'} />
					)}
				</div>
				{order && textboxesOpen['order'] && (
					<div className='sidebarTextBody'>
						<p className='sidebarTextRow'>
							<span>Date ordered: </span> {dateConverter(order.createdAt)}
						</p>
						<p className='sidebarTextRow'>
							<span>Date required: </span> {dateConverter(order.dispatchDueDateTime)}
						</p>
					</div>
				)}
			</div>
		</>
	);
}

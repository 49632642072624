import React from 'react';
import { useLocation } from 'react-router';
import CheckboxInput from '../Inputs/CheckboxInput';
import DateInput from '../Inputs/DateInput';
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import buyerOptions from '../Options/buyerOptions';
import sellerOptions from '../Options/sellerOptions';
import sortOptions from '../Options/sortOptions';
import Button from '../Button';

export default function OrderFilterForm(props) {
	const { filter, handleFilterInput, handleFilterSelect, applyFilter, clearFilter } = props || {};

	let location = useLocation();
	const teamType = new URLSearchParams(location.search).get('type');

	return (
		<div className='orderFilter'>
			<h3>Status</h3>
			<h4>Only show:</h4>
			<CheckboxInput
				checkboxText={'Draft'}
				name={'draft'}
				checked={filter?.draft === undefined && filter.status === '0' ? true : filter?.draft}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Pending'}
				name={'pending'}
				checked={filter?.pending === undefined && filter.status === '1' ? true : filter?.pending}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Accepted'}
				name={'accepted'}
				checked={filter?.accepted === undefined && filter.status === '2' ? true : filter?.accepted}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Processing'}
				name={'processing'}
				checked={filter?.processing === undefined && filter.status === '3' ? true : filter?.processing}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Completed'}
				name={'completed'}
				checked={filter?.completed === undefined && filter.status === '4' ? true : filter?.completed}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Dispatched'}
				name={'dispatched'}
				checked={filter?.dispatched === undefined && filter.status === '5' ? true : filter?.dispatched}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Delivered'}
				name={'delivered'}
				checked={filter?.delivered === undefined && filter.status === '6' ? true : filter?.delivered}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Disputed'}
				name={'disputed'}
				checked={filter?.disputed === undefined && filter.status === '7' ? true : filter?.disputed}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Rejected'}
				name={'rejected'}
				checked={filter?.rejected === undefined && filter.status === '9' ? true : filter?.rejected}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Held'}
				name={'hold'}
				checked={filter?.hold}
				callback={handleFilterInput}
				onlyInput
			/>
			<h3>Date</h3>
			<DateInput
				text={'Date from'}
				name={'dateFrom'}
				callback={handleFilterInput}
				value={filter.dateFrom}
				focused={false}
				dateFormat='Pp'
				timeSelect
				max={new Date()}
			/>
			<DateInput
				text={'Date to'}
				name={'dateTo'}
				callback={handleFilterInput}
				value={filter.dateTo}
				focused={false}
				dateFormat='Pp'
				timeSelect
				min={new Date()}
			/>
			<h3>Assignment</h3>
			<SelectInput
				htmlOptions={parseInt(teamType) === 1 ? buyerOptions : sellerOptions}
				callback={handleFilterSelect}
				value={filter?.assignment}
				name={'assignment'}
			/>
			<h3>Sort</h3>
			<SelectInput
				htmlOptions={sortOptions}
				callback={handleFilterSelect}
				value={filter?.sort}
				text={'Sort by'}
				name={'sort'}
				reverse
			/>

			<h3>LabManager</h3>
			<TextInput
				text={'Reference'}
				name={'reference'}
				value={filter?.reference}
				callback={handleFilterInput}
				controlled
			/>
			<TextInput
				text={'Dentist'}
				name={'dentist'}
				value={filter?.dentist}
				callback={handleFilterInput}
				controlled
			/>
			<TextInput
				text={'Practice'}
				name={'practice'}
				value={filter?.practice}
				callback={handleFilterInput}
				controlled
			/>
			<h3>Dentally</h3>
			<TextInput
				text={'Patient ID'}
				name={'patientId'}
				value={filter?.patientId}
				callback={handleFilterInput}
				controlled
			/>
			<TextInput
				text={'First name'}
				name={'patient_first_name'}
				value={filter?.patient_first_name}
				callback={handleFilterInput}
				controlled
			/>
			<TextInput
				text={'Last name'}
				name={'patient_last_name'}
				value={filter?.patient_last_name}
				callback={handleFilterInput}
				controlled
			/>
			<div className='btnCont'>
				<Button text='Clear filter' size='sm' style='secondary' onClick={clearFilter} minWidth={135} />
				<Button
					text='Apply filter'
					size='sm'
					style='primary'
					onClick={() => {
						applyFilter(filter);
					}}
					minWidth={135}
				/>
			</div>
		</div>
	);
}

import React from 'react';
import TextInput from '../Inputs/TextInput';
import TextareaInput from '../Inputs/TextareaInput';
import SelectInput from '../Inputs/SelectInput';
import countryOptions from '../Options/countryOptions';

export default function AddressForm(props) {
	const { address, handleAddressInput, required, customErrors } = props || {};
	return (
		<>
			<TextInput
				text={'Name:'}
				name={'name'}
				defaultValue={address?.name}
				callback={handleAddressInput}
				required
				customError={customErrors ? customErrors['name'] : null}
				thin
			/>
			<TextInput
				text={'Mobile number:'}
				name={'mobile'}
				type={'phone'}
				defaultValue={address?.mobile}
				callback={handleAddressInput}
				required={required}
				customError={customErrors ? customErrors['mobile'] : null}
				thin
			/>
			<TextInput
				text={'Address line 1:'}
				name={'addressLine1'}
				defaultValue={address?.addressLine1}
				callback={handleAddressInput}
				required
				customError={customErrors ? customErrors['addressLine1'] : null}
				thin
			/>
			<TextInput
				text={'Address line 2 (optional):'}
				name={'addressLine2'}
				defaultValue={address?.addressLine2}
				callback={handleAddressInput}
				required={required}
				customError={customErrors ? customErrors['addressLine2'] : null}
				thin
			/>
			<TextInput
				text={'Address line 3 (optional):'}
				name={'addressLine3'}
				defaultValue={address?.addressLine3}
				callback={handleAddressInput}
				required={required}
				customError={customErrors ? customErrors['addressLine3'] : null}
				thin
			/>
			<TextInput
				text={'City:'}
				name={'city'}
				defaultValue={address?.city}
				callback={handleAddressInput}
				required
				customError={customErrors ? customErrors['city'] : null}
				thin
			/>
			<TextInput
				text={'Postcode:'}
				name={'postalCode'}
				defaultValue={address?.postalCode}
				callback={handleAddressInput}
				required
				customError={customErrors ? customErrors['postalCode'] : null}
				thin
			/>
			<TextInput
				text={'County / State / Province:'}
				name={'county'}
				defaultValue={address?.county}
				callback={handleAddressInput}
				required
				customError={customErrors ? customErrors['county'] : null}
				thin
			/>
			<SelectInput
				htmlOptions={countryOptions}
				callback={(e) => {
					handleAddressInput(e.value, 'country');
				}}
				text={'Country:'}
				name={'country'}
				required
				customError={customErrors ? customErrors['country'] : null}
				value={address?.country}
				thin
			/>
			<TextInput
				text={'Collection hours'}
				name={'collectionHours'}
				callback={handleAddressInput}
				defaultValue={address?.collectionHours}
				customError={customErrors?.collectionHours}
			/>
			<TextInput
				name={'collectionPoint'}
				text={'Collection point:'}
				callback={handleAddressInput}
				defaultValue={address?.collectionPoint}
				customError={customErrors?.collectionPoint}
			/>
			<div className='formRowCenter'>
				<div className='fieldTextContainer'>
					<p className='inputLabel' htmlFor='country'>
						Delivery instructions:
					</p>
				</div>
				<div className='formRowCenter'>
					<TextareaInput
						name={'instructions'}
						callback={handleAddressInput}
						defaultValue={address?.instructions}
						customError={customErrors?.instructions}
						onlyInput
					/>
				</div>
			</div>
		</>
	);
}

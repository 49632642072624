import React, { useState, useEffect } from 'react';
import {
	AtIcon,
	CaretDownIcon,
	ClipboardPlusIcon,
	FileIcon,
	GlobeIcon,
	MapPinIcon,
	PhoneIcon,
	ShareIcon,
	VerifiedIcon,
} from '../../components/SVGIcons/SVGIcons';
import { useNavigate, useParams } from 'react-router';
import Loading from '../../components/Loading/Loading';
import config from '../../config';
import Axios from 'axios';
import { checkFileType, dateConverter } from '../../helpers';
import FlexModal from '../../components/Modals/FlexModal';
import Overlay from '../../components/Overlay';
import TrustedAddBtn from '../../components/TrustedAddBtn';
import GoogleMap from './GoogleMap';
import useMultiFetch from '../../hooks/useMultiFetch';
import ImgModal from '../../components/Modals/ImgModal';

export default function TeamProfile() {
	const [supplier, setSupplier] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [dispAddress, setDispAddress] = useState({});
	const [modalsOpen, setModalsOpen] = useState({});
	const [paragraphLength, setParagraphLength] = useState(800);

	const [galleryFiles, setGalleryFiles] = useState([]);

	let navigate = useNavigate();
	let params = useParams();

	const { data, fetch, reqLoading, error, setReload } = useMultiFetch([
		{ key: 'downloads', url: `${config.apiv1}/file/files.read/${params.uuid}?type=3` },
		{ key: 'gallery', url: `${config.apiv1}/file/files.read/${params.uuid}?type=4` },
	]);

	function handleModal(name, value) {
		setModalsOpen((modalsOpen) => ({
			...modalsOpen,
			[name]: value,
		}));
	}

	async function createImgUrls(filesArr) {
		if (filesArr?.length > 0) {
			let urls = [];
			for (let i = 0; i < filesArr.length; i++) {
				if (checkFileType(filesArr[i]?.filename?.split('.')[1]) === 1) {
					if (filesArr[i].fileAttachmentType !== 2) {
						try {
							const res = await Axios({
								url: `${config.api}/file?path=${filesArr[i].path}`,
								method: 'GET',
								responseType: 'blob',
							});
							if (res.data) {
								const url = window.URL.createObjectURL(new Blob([res.data]));
								let file = filesArr[i];
								file.url = url;
								urls.push(file);
							}
						} catch (err) {
							console.log(err);
						}
					}
				}
			}
			setGalleryFiles(urls);
		}
		setLoaded(true);
	}

	function downloadFile(file) {
		Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file.originalname);
			document.body.appendChild(link);
			link.click();
			link.remove();
		});
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			async function fetchSupplier() {
				try {
					const response = await Axios({
						url: `${config.apiv1}/team/team.read/${params.uuid}?isPublic=true`,
						method: 'GET',
					});
					if (response?.data?.data) {
						let supplier = response.data.data;
						setSupplier(supplier);
						if (supplier.registeredAddress) {
							setDispAddress(supplier.registeredAddress);
						} else if (supplier.teamInfo?.addresses?.length > 0) {
							setDispAddress(supplier.teamInfo.addresses[0]);
						} else {
							setDispAddress(null);
						}
						setLoaded(true);
					} else {
						navigate('/');
					}
				} catch (err) {
					return err;
				}
			}
			fetchSupplier();
		}
		return () => (mounted = false);
	}, [navigate]);

	useEffect(() => {
		if (!reqLoading && data?.gallery?.data?.data) {
			createImgUrls(data.gallery?.data?.data);
		}
	}, [reqLoading]);

	if (!loaded) {
		return <Loading type={'circle'} />;
	}

	return (
		<>
			{loaded && supplier.teamInfo && (
				<>
					{modalsOpen['descModal'] && (
						<>
							<FlexModal
								setShow={handleModal}
								modalName={'descModal'}
								closeBtn={true}
								width={600}
								zIndex={99}
								body={supplier.teamInfo?.companyDescription}
							/>
							<Overlay loadOverlay={true} showOverlay={true} />
						</>
					)}
					{modalsOpen.imgModal?.open === true && (
						<>
							<ImgModal
								setShow={handleModal}
								src={modalsOpen['imgModal']?.file?.url}
								downloadImg={downloadFile}
								file={modalsOpen['imgModal']?.file}
							/>
							<Overlay loadOverlay={true} showOverlay={true} />
						</>
					)}
					<div className='teamProfileCont'>
						<div className='suppIcon halfOpacity'>
							<ShareIcon />
						</div>
						{/* <div className='topRow'>
							<img src={bannerImg} alt='team banner' />
						</div> */}
						<div className='midRow'>
							<div className='thinCard'>
								<div className='cardRow'>
									<div className='teamIcon'>{supplier.teamInitials}</div>
									<div className='cardCol'>
										<h4 className='teamName'>{supplier.teamInfo.teamName}</h4>
										<h4 className='teamSub'>
											{supplier.createdAt
												? `Member since ${dateConverter(supplier.createdAt)}`
												: ''}
										</h4>
									</div>
									<TrustedAddBtn
										supplierUuid={supplier?.teamUuid}
										supplierName={supplier?.teamInfo?.teamName}
										supplierDesc={supplier?.teamInfo?.companyDescriptionHeader}
									/>
								</div>
								{supplier.procuurVerified && (
									<>
										<div className='badgeCont'>
											<div className='badge'>
												<VerifiedIcon iconClass='verifiedIcon' />
												<p>Verified by Procuur</p>
											</div>
										</div>
										<p className='lmParagraph'>
											This Team is verified by Procuur because it is a Transactor LabManager
											subscriber. Discover more about Transactor Systems Ltd and their Dental
											Laboratory Management software at{' '}
											<a className='underline' href='https://transactorsystems.com'>
												transactorsystems.com
											</a>
										</p>
									</>
								)}
								<div className='galleryCont'>
									{!reqLoading &&
										galleryFiles?.map((file, key) => {
											if (!file.orderUuid) {
												return (
													<React.Fragment key={key}>
														<img
															className='galleryImg'
															alt='Gallery image'
															src={file.url}
															onClick={() => {
																handleModal('imgModal', { open: true, file: file });
															}}
														/>
													</React.Fragment>
												);
											} else {
												return <React.Fragment key={key}></React.Fragment>;
											}
										})}
								</div>
							</div>
							<div className='thinCard'>
								<h3 className='cardTitle'>What we do</h3>
								<p className='teamHead'>{supplier?.teamInfo?.companyDescriptionHeader}</p>

								{supplier.teamInfo.companyDescription?.length > paragraphLength ? (
									<>
										<p className='teamDesc hideTextOverflow'>
											{supplier.teamInfo.companyDescription}
										</p>
										<h5 className='marginBottom-20' onClick={() => handleModal('descModal', true)}>
											Read more
										</h5>
									</>
								) : (
									<>
										<p className='teamDesc'>{supplier.teamInfo.companyDescription}</p>
									</>
								)}
								<div className='btnCont'>
									<button
										className='btn4-withIcon marginTop-40'
										onClick={() => {
											navigate(
												`/order?uuid=${supplier?.teamUuid}&name=${supplier?.teamInfo?.teamName}`
											);
										}}>
										<div className='clipboardIcon'>
											<ClipboardPlusIcon />
										</div>
										Place Order
									</button>
								</div>
							</div>
							<div className='thinCard'>
								<div className='cardRow marginBottom-10'>
									<div className='cardCol'>
										<h3 className='cardTitle'>Contact</h3>
										{supplier.teamInfo?.phoneNumber && (
											<a className='flexRow' href={`tel:${supplier.teamInfo.phoneNumber}`}>
												<PhoneIcon iconClass='suppIconReg' />
												<h4 className='teamData'>{supplier.teamInfo.phoneNumber}</h4>
											</a>
										)}
										{supplier.teamInfo?.businessEmail && (
											<a className='flexRow' href={`mailto:${supplier.teamInfo.businessEmail}`}>
												<AtIcon iconClass='suppIconReg' />
												<h4 className='teamData'>{supplier.teamInfo.businessEmail}</h4>
											</a>
										)}
										{supplier.teamInfo?.websiteUrl && (
											<a className='flexRow' href={supplier.teamInfo.websiteUrl}>
												<GlobeIcon iconClass='suppIconReg' />
												<h4 className='teamData'>{supplier.teamInfo.websiteUrl}</h4>
											</a>
										)}
										<div className='flexRow'>
											<MapPinIcon iconClass='suppIconReg' />
											<h4 className='teamData'>
												{dispAddress?.name &&
													`
												${dispAddress?.name ? `${dispAddress.name},` : ''} 
												${dispAddress?.addressLine1 ? `${dispAddress.addressLine1},` : ''}
												${dispAddress?.city ? `${dispAddress.city},` : ''}
												${dispAddress?.postalCode ? `${dispAddress.postalCode},` : ''}
												${dispAddress?.country ? `${dispAddress.country}` : ''}
										`}
											</h4>
										</div>
									</div>
								</div>
								{supplier.teamInfo.addresses &&
									supplier.teamInfo.addresses.length > 0 &&
									dispAddress?.name && (
										<div className='mapCont'>
											<GoogleMap
												parsedAddress={`
												${dispAddress?.name}, 
												${dispAddress?.addressLine1}, 
												${dispAddress?.city},
												${dispAddress?.postalCode},
												${dispAddress?.country}
										`}
												address={supplier.teamInfo.addresses[0]}
											/>
										</div>
									)}
							</div>
						</div>
						<div className='botRow'>
							<div className='thickCard'>
								<div className='cardRow disabledDiv'>
									<h4>Imprint </h4>
									<CaretDownIcon iconClass='caretDownIcon' />
								</div>
								<div className='cardRow disabledDiv'>
									<h4>Terms and Conditions </h4>
									<CaretDownIcon iconClass='caretDownIcon' />
								</div>
								<div className='cardRow disabledDiv'>
									<h4>Shipping Terms </h4>
									<CaretDownIcon iconClass='caretDownIcon' />
								</div>
								<div className='cardRow disabledDiv'>
									<h4>Privacy Policy </h4>
									<CaretDownIcon iconClass='caretDownIcon' />
								</div>
								<div className='cardRow disabledDiv'>
									<h4>Order History </h4>
									<CaretDownIcon iconClass='caretDownIcon' />
								</div>
							</div>
							<div className='thinCard'>
								<h3 className='cardTitle'>Downloads</h3>
								<div className='filesCont'>
									{!reqLoading &&
										data?.downloads?.data?.data &&
										data?.downloads?.data?.data.map((file, key) => {
											if (!file.orderUuid) {
												return (
													<div className='fileRow' key={key}>
														<FileIcon iconClass='orderPageFileIcon' />
														<p className='fileLink' onClick={() => downloadFile(file)}>
															{file.originalname}
														</p>
														{file?.filename?.split('.')[1] === 'stl' ? (
															<button
																className='openBtn'
																onClick={() => toggleHidden(file, !modelHidden)}>
																Open in viewer
															</button>
														) : (
															<></>
														)}
													</div>
												);
											} else {
												return <React.Fragment key={key}></React.Fragment>;
											}
										})}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import { parseToOptions } from '../../helpers';
import DateInput from '../Inputs/DateInput';
import SelectInput from '../Inputs/SelectInput';
import TextareaInput from '../Inputs/TextareaInput';
import AddressForm from './AddressForm';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import config from '../../config';
import Axios from 'axios';
import TextInput from '../Inputs/TextInput';

export default function PackageNewForm(props) {
	const {
		handleFormInput,
		handleAddressInput,
		handleFormSelectData,
		formData,
		focused,
		handleFocused,
		address,
		couriers,
		addressForm,
		sellerAddresses,
		missingOptions,
		customErrors,
	} = props || {};

	const { teamState } = useContext(CurrentTeamContext);

	const [trustedAddresses, setTrustedAddresses] = useState([]);
	const [addressFormOpen, setAddressFormOpen] = useState(false);

	async function getTrustedAddresses() {
		async function addRequest(url) {
			return await Axios({
				url: url,
				method: 'GET',
			});
		}

		let trusted = teamState.currentTeam?.trustedSuppliers;
		let fetchedTrust = [];
		let reqs = [];
		for (let i = 0; i < trusted?.length; i++) {
			reqs.push(addRequest(`${config.apiv1}/team/team.read/${trusted[i].teamUuid}`));
		}
		if (reqs.length > 0) {
			try {
				await Axios.all(reqs).then(
					Axios.spread((...responses) => {
						for (let i = 0; i < responses.length; i++) {
							if (responses[i].data) {
								fetchedTrust.push({
									supplierName: responses[i]?.data?.data?.teamInfo?.teamName,
									...responses[i]?.data?.data?.teamInfo?.addresses[0],
								});
							}
						}
					})
				);
			} catch (err) {
				return err;
			}
		}
		setTrustedAddresses(() => {
			return fetchedTrust;
		});
	}

	useEffect(() => {
		getTrustedAddresses();
	}, []);

	return (
		<>
			<h2>Add new package</h2>
			<SelectInput
				options={[...parseToOptions(couriers, 'couriers'), { name: 'Other', value: 'other' }]}
				text={'Courier'}
				name={'courierUuid'}
				callback={(data, fieldname) => {
					data !== 'other' ? handleFormInput('', 'courierName') : null;
					handleFormSelectData(data, fieldname);
				}}
				missingOptions={missingOptions}
				customError={customErrors['courierUuid']}
			/>
			{formData.courierUuid === 'other' && (
				<TextInput
					text={'Courier name'}
					name={'courierName'}
					value={formData?.courierName}
					callback={handleFormInput}
					controlled
				/>
			)}
			<DateInput
				text={'Pickup'}
				name={'quotedCollectionDateTime'}
				callback={handleFormInput}
				defaultValue={formData?.quotedCollectionDateTime}
				focused={focused}
				handleFocused={handleFocused}
				dateFormat='Pp'
				min={new Date()}
				timeSelect
				required
			/>
			<DateInput
				text={'Delivery'}
				name={'quotedDeliveryDateTime'}
				callback={handleFormInput}
				defaultValue={formData?.quotedDeliveryDateTime}
				focused={focused}
				handleFocused={handleFocused}
				dateFormat='Pp'
				min={new Date()}
				timeSelect
			/>
			<h3 className='margin-10'>Destination</h3>
			<TextInput
				text={'Recipient'}
				name={'recipient'}
				value={formData?.recipient}
				callback={handleFormInput}
				controlled
			/>
			{addressFormOpen === true || addressForm === true ? (
				<>
					<AddressForm
						address={address}
						handleAddressInput={handleAddressInput}
						customErrors={customErrors}
					/>
					<button
						className='btn3-secondary marginTop-20'
						onClick={() => {
							setAddressFormOpen(false);
						}}>
						Choose from dropdown
					</button>
				</>
			) : (
				<>
					<SelectInput
						options={
							sellerAddresses?.length > 0
								? parseToOptions(sellerAddresses, 'addresses')
								: parseToOptions(trustedAddresses, 'supplierAddresses')
						}
						callback={handleFormSelectData}
						customError={customErrors?.address}
						text={'Supplier addresses'}
						name={'address'}
						required
					/>
					{formData?.address && (
						<div className='chosenAddress'>
							{formData?.address?.name}, {formData?.address?.addressLine1}, {formData?.address?.city},{' '}
							{formData?.address?.postalCode}, {formData?.address?.country}
						</div>
					)}
					<button
						className='btn3-secondary marginTop-20'
						onClick={() => {
							setAddressFormOpen(true);
						}}>
						Add custom address
					</button>
				</>
			)}

			<TextareaInput text={'Note'} name={'note'} defaultValue={formData?.note} callback={handleFormInput} />
		</>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import config from '../../config';
import Axios from 'axios';
import Overlay from '../../components/Overlay';
import TrustedEditModal from '../../components/Modals/TrustedEditModal';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import { ArrowNarrowLeftIcon, SearchIcon } from '../../components/SVGIcons/SVGIcons';
import Carousel from '../../components/Carousel';
import EmptyState from '../../components/EmptyState/EmptyState';
import { useNavigate } from 'react-router';
import TrustedSettingsHeader from '../../components/Carousel/carouselHeaders/TrustedSettingsHeader';
import useModals from '../../hooks/useModals';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import Loading from '../../components/Loading/Loading';

export default function TeamTrusted() {
	const [suppliers, setSuppliers] = useState([]);
	const [trustedSuggested, setTrustedSuggested] = useState([]);
	const [preferences, setPreferences] = useState({});
	const [loadOverlay, setLoadOverlay] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [supplierUuid, setSupplierUuid] = useState(null);
	const [loaded, setLoaded] = useState(false);

	const { userState } = useContext(CurrentUserContext);
	const { teamState, teamDispatch } = useContext(CurrentTeamContext);

	const { modalsState, handleModal, handleFadeModal, flashFadeModal } = useModals();

	let navigate = useNavigate();

	function handleOverlay() {
		if (!loadOverlay) {
			setLoadOverlay(true);
		}
	}

	function getSupplierPreferences(teamUuid) {
		for (let i = 0; i < teamState.currentTeam.trustedSuppliers.length; i++) {
			if (teamState.currentTeam.trustedSuppliers[i].teamUuid === teamUuid) {
				return teamState.currentTeam.trustedSuppliers[i].preferences || {};
			}
		}
	}

	function handlePreference(value, name) {
		setPreferences((preferences) => ({
			...preferences,
			[name]: value,
		}));
	}

	async function patchTrusted(teamUuid, preferences) {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.update/trusted.update/${teamState.currentTeam.teamUuid}`,
				method: 'PATCH',
				data: {
					trustedSupplier: {
						teamUuid: teamUuid,
						preferences: preferences,
					},
				},
			});
			if (response.data.ok) {
				setUpdateLoading(false);
				handleModal('editModal', { open: false });
				let index = teamState?.currentTeam.trustedSuppliers
					.map((supplier) => supplier.teamUuid)
					.indexOf(teamUuid);
				let suppliers = teamState?.currentTeam.trustedSuppliers;
				suppliers[index].preferences = preferences;

				teamDispatch({ type: 'RE-FETCH_TEAM' });
				flashFadeModal('Trusted options successfully updated!');
			}
		} catch (err) {
			return err;
		}
	}

	async function deleteTrusted(teamUuid) {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.update/trusted.delete/${teamState.currentTeam.teamUuid}`,
				method: 'PATCH',
				data: {
					trustedSupplier: {
						teamUuid: teamUuid,
					},
				},
			});
			if (response?.data?.ok) {
				let filtered = suppliers.filter((supplier) => supplier.teamUuid !== teamUuid);
				setSuppliers(() => filtered);
				handleModal('deleteModal', { open: false });
				teamDispatch({ type: 'RE-FETCH_TEAM' });
			}
		} catch (err) {
			return err;
		}
	}

	function fetchSuggestedSuppliers() {
		try {
			Axios({
				url: `${config.apiv1}/notification/notifications.read/${
					teamState.currentTeam.teamUuid
				}?limit=&sortDate=desc&type=${'team.trustedSuggested'}`,
				method: 'GET',
			}).then((response) => {
				if (response?.data?.data) {
					let parsed = response?.data?.data.filter(
						(suggested) => suggested?.notificationData?.isAccepted === 'pending'
					);
					setTrustedSuggested(parsed);
				}
			});
		} catch (err) {
			return err;
		}
	}

	async function addTrusted(teamUuid) {
		if (teamState.userPermissions['canAddTrusted']) {
			const response = await Axios({
				url: `${config.apiv1}/team/team.update/trusted.create/${teamState.currentTeam.teamUuid}`,
				method: 'PATCH',
				data: {
					trustedSupplier: {
						teamUuid: teamUuid,
					},
				},
			});
			if (!response?.data?.ok) {
				console.log(response?.data?.error);
			} else {
				if (response?.data?.data) {
					setSuppliers((suppliers) => [...suppliers, response?.data?.data]);
					teamDispatch({ type: 'RE-FETCH_TEAM' });
					flashFadeModal('Supplier successfully added as trusted!');
				}
			}
		}
	}

	async function patchSuggested(notification, bool) {
		if (notification) {
			try {
				const response = await Axios({
					url: `${config.apiv1}/notification/notification.update/${notification.notificationUuid}`,
					method: 'PATCH',
					data: {
						notificationData: {
							teamUuid: notification.notificationData.teamUuid,
							teamName: notification.notificationData.teamName,
							suggestedBy: notification.notificationData.suggestedBy,
							isAccepted: bool,
						},
					},
				});
				let filtered = trustedSuggested.filter(
					(suggested) => suggested.notificationData.teamUuid !== notification.notificationData.teamUuid
				);
				setTrustedSuggested(() => filtered);
				if (response?.data?.ok && bool === 'true') {
					addTrusted(notification.notificationData.teamUuid);
				}
				flashFadeModal('Trusted options successfully updated!');
			} catch (err) {
				return err;
			}
		} else {
			console.error('Notification not set');
		}
	}

	useEffect(() => {
		let mounted = true;
		if (userState.isAuthenticated && !updateLoading && mounted) {
			async function fetchSuppliers() {
				try {
					const response = await Axios({
						url: `${config.apiv1}/team/team.read/trusted/${teamState.currentTeam.teamUuid}`,
						method: 'GET',
					});
					if (response?.data?.data?.length > 0) {
						setSuppliers(response?.data?.data);
					}
					setLoaded(true);
				} catch (err) {
					setLoaded(true);
					return err;
				}
			}
			fetchSuggestedSuppliers();
			fetchSuppliers();
		}
		return () => (mounted = false);
	}, [updateLoading]);

	return (
		<>
			<Overlay
				loadOverlay={loadOverlay}
				showOverlay={modalsState['deleteModal']?.open || modalsState['editModal']?.open}
				zIndex={96}
			/>
			<div className='pageInnerCont white'>
				{loaded ? (
					<>
						<FadeOutModal
							show={modalsState['modalFading']?.show}
							isFading={modalsState['modalFading']?.isFading}
							message={modalsState['modalFading']?.message}
						/>
						{modalsState['editModal']?.open && (
							<TrustedEditModal
								preferences={preferences}
								updateLoading={updateLoading}
								supplierUuid={supplierUuid}
								handlePreference={handlePreference}
								postForm={patchTrusted}
								handleModal={handleModal}
								setUpdateLoading={setUpdateLoading}
								setPreferences={setPreferences}
							/>
						)}
						{modalsState['deleteModal']?.open && (
							<>
								<ConfirmModal
									value={modalsState['deleteModal'].value}
									header={modalsState['deleteModal'].header}
									callback={modalsState['deleteModal'].callback}
									handleModal={handleModal}
									modalType={'deleteModal'}
									loading={updateLoading}
									hideOverlay
									zIndex={999}
								/>
							</>
						)}
						<TrustedSettingsHeader />
						{suppliers.length === 0 && trustedSuggested.length === 0 ? (
							<div className='pageInnerCont gray'>
								<EmptyState
									type='suppliers'
									body='A Trusted Supplier is a business that you trust and would regularly purchase from. Start by adding suppliers to have quick access to their products.'
									btn1={{
										text: 'Go Back',
										icon: <ArrowNarrowLeftIcon iconClass='emptyStateIcon' />,
										callback: () => {
											navigate(-1);
										},
									}}
									btn2={{
										text: 'Search suppliers',
										icon: <SearchIcon iconClass='emptyStateIcon white' />,
										callback: () => {
											navigate('/suppliers');
										},
									}}
									header='No Trusted Suppliers found'
								/>
							</div>
						) : (
							<>
								<Carousel
									backColor={'gray-200'}
									type={'suppliers'}
									headerType={'trustedSettings'}
									mode={'light'}
									loaded={loaded}
									customList={suppliers}
									cardOptions={{
										type: 2,
										btn1: {
											callback: (teamUuid) => {
												handleModal('deleteModal', {
													open: true,
													callback: () => {
														deleteTrusted(teamUuid);
													},
													header: 'Are you sure you want to remove this trusted supplier?',
												});
												handleOverlay();
											},
										},
										btn2: {
											callback: (teamUuid) => {
												handleModal('editModal', { open: true });
												setPreferences(getSupplierPreferences(teamUuid));
												setSupplierUuid(teamUuid);
												handleOverlay();
											},
										},
									}}
									leftDescription
									noHeader
								/>
								<Carousel
									backColor={'gray-200'}
									type={'suggested'}
									headerType={'trustedSettings'}
									mode={'light'}
									loaded={loaded}
									patchSuggested={patchSuggested}
									customList={trustedSuggested}
									leftDescription
									noHeader
								/>
							</>
						)}
					</>
				) : (
					<Loading type='circle' />
				)}
			</div>
		</>
	);
}

import React, { useContext } from 'react';
import ChatBox from './functionalComponents/ChatBox';
import DeliveryDetails from './textComponents/DeliveryDetails';
import OrderDetails from './textComponents/OrderDetails';
import PriceDetails from './textComponents/PriceDetails';
import SellerContact from './textComponents/SellerContact';

export default function SidebarOrderListing(props) {
	const { contentReference, textboxesOpen, openTextbox } = props || {};

	return (
		<>
			<OrderDetails order={contentReference} textboxesOpen={textboxesOpen} openTextbox={openTextbox} />
			<PriceDetails order={contentReference} textboxesOpen={textboxesOpen} openTextbox={openTextbox} />
			<DeliveryDetails order={contentReference} textboxesOpen={textboxesOpen} openTextbox={openTextbox} />
			<ChatBox contentReference={contentReference} textboxesOpen={textboxesOpen} openTextbox={openTextbox} />
			<SellerContact order={contentReference} textboxesOpen={textboxesOpen} openTextbox={openTextbox} />
		</>
	);
}

import React from 'react';
import Loading from '../Loading/Loading';
import PropTypes from 'prop-types';

/**
 * @param {Object} props
 * @param {string} props.size - The size (sm, md, lg, xl, xxl) of the component
 * @param {string} props.style - Style preset (primary, secondary, tertiary) for the component
 * @param {string} props.text - Text content of the component
 * @param {SVGElement} props.iconLeft - SVG element for the left icon
 * @param {SVGElement} props.iconRight - SVG element for the right icon
 * @param {boolean} props.disabled - Indicates if the component is disabled
 * @param {Function} props.onClick - Function to handle click events
 * @param {string} props.type - Type of the component
 * @param {boolean} props.loading - Indicates if the component is in a loading state
 * @param {number} props.minWidth - Minimum width of the component
 * @param {number} props.maxWidth - Maximum width of the component
 * @param {boolean} props.fill - Indicates if the component should fill the available space
 * @param {string} props.color - Decides the color of the button
 * @param {boolean} props.noTab - Decides the color of the button
 */

function Button(props) {
	const {
		size,
		style,
		text,
		iconLeft,
		iconRight,
		disabled,
		onClick,
		type,
		loading,
		minWidth,
		maxWidth,
		active,
		fill,
		color,
		noFill,
		noTab,
	} = props || {};

	return (
		<button
			disabled={disabled || loading}
			onClick={onClick}
			tabIndex={noTab ? -1 : 0}
			type={type}
			style={{ minWidth: minWidth, width: fill ? '100%' : '', maxWidth: maxWidth }}
			className={`btn${size ? ` ${size}` : ' md'}${style ? ` ${style}` : ''}${color ? ` ${color}` : ''}${
				active ? ` active` : ''
			}${noFill ? ` noFill` : ''}`}>
			{!loading ? (
				<>
					{iconLeft}
					{text}
					{iconRight}
				</>
			) : (
				<Loading type='circle' size='btnLoad' />
			)}
		</button>
	);
}

export default Button;

import React from 'react';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay';
import Button from '../Button';

export default function ConfirmModal(props) {
	const { header, callback, value, handleModal, modalType, loading, zIndex, hideOverlay, onlyOk } = props || {};
	return (
		<>
			<div className='fixedModal' style={{ zIndex: zIndex }}>
				{loading ? (
					<Loading type='circle' />
				) : (
					<>
						<h4>{header}</h4>
						{onlyOk ? (
							<div className='btnCont'>
								<Button
									size='md'
									text='OK'
									style='primary'
									onClick={() => {
										handleModal(modalType, false);
									}}
								/>
							</div>
						) : (
							<>
								<div className='btnCont reverse'>
									<Button
										text='Yes'
										style='primary'
										type='lg'
										onClick={() => {
											callback(value);
										}}
									/>
									<Button
										text='No'
										style='secondary'
										type='lg'
										onClick={() => {
											handleModal(modalType, false);
										}}
									/>
								</div>
							</>
						)}
					</>
				)}
			</div>
			<Overlay
				loadOverlay={!hideOverlay ? true : false}
				showOverlay={!hideOverlay ? true : false}
				zIndex={zIndex}
			/>
		</>
	);
}

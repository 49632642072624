import React from 'react';

export default function GridItemSkeleton() {
	return (
		<div className='gridItemSkel'>
			<div className='mainCont'>
				<div className='gridHeader'></div>
				<div className='gridBody'></div>
			</div>
			<div className='iconColumn skel'></div>
		</div>
	);
}

import React from 'react';
import Button from '../../Button';
import { PlusIcon, TrashIcon } from '../../SVGIcons/SVGIcons';
import SelectInput from '../../Inputs/SelectInput';
import SelectCustom from '../../Select/SelectCustom';
import { addCurrencySpace } from '../../../helpers';

export default function ItemsSection({
	handleInvoiceItemData,
	removeInvoiceItem,
	currencySymbol,
	addInvoiceItem,
	invoiceItems,
	setEditing,
	formatter,
	editing,
	details,
}) {
	return (
		<div className='invoiceItemsSection'>
			<table>
				<thead className='topBar'>
					<tr>
						<th className='thin'></th>
						<th className='thin'></th>
						<th className='wide'>
							<h4 className='header'>Description</h4>
						</th>
						<th>
							<h4 className='header'>Number</h4>
						</th>
						<th>
							<h4 className='header'>Unit </h4>
						</th>
						<th>
							<h4 className='header'>Unit price</h4>
						</th>
						<th>
							<h4 className='header'>VAT</h4>
						</th>
						<th>
							<h4 className='header'>Subtotal</h4>
						</th>
						<th>
							<h4 className='header'>Total price</h4>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{invoiceItems &&
						Object.values(invoiceItems)?.map((item, key) => {
							let subTotal = item.unitAmount * item.quantity;
							if (!details) {
								return (
									<tr
										key={key}
										className={`${editing === item.lineNumber ? 'editing' : ''}`}
										onClick={() => setEditing(item.lineNumber)}>
										<td></td>
										<td>{item.lineNumber}.</td>
										<td className='editable'>
											<input
												name={'description'}
												type={'text'}
												onChange={(e) => {
													handleInvoiceItemData
														? handleInvoiceItemData(
																e.target.value,
																e.target.name,
																item.lineNumber
														  )
														: null;
												}}
												value={item.description}
												placeholder={'Description...'}
											/>
										</td>
										<td className='editable'>
											<input
												name={'quantity'}
												type={'number'}
												onChange={(e) =>
													handleInvoiceItemData
														? handleInvoiceItemData(
																Number(e.target.value),
																e.target.name,
																item.lineNumber
														  )
														: null
												}
												value={item.quantity}
												onClick={(e) => e.target.select()}
											/>
										</td>
										<td className='editable'>
											<SelectCustom
												thin
												callback={(e) => {
													handleInvoiceItemData
														? handleInvoiceItemData(e.children, 'unit', item.lineNumber)
														: null;
												}}
												fieldName='unit'
												htmlOptions={[
													<option value='pcs'>pcs</option>,
													<option value='hrs'>hrs</option>,
												]}
												value={item.unit}
											/>
										</td>
										<td className='editable'>
											<input
												name={'unitAmount'}
												type={'number'}
												onChange={(e) =>
													handleInvoiceItemData
														? handleInvoiceItemData(
																Number(e.target.value),
																e.target.name,
																item.lineNumber
														  )
														: null
												}
												value={item.unitAmount}
												onClick={(e) => e.target.select()}
											/>
										</td>
										<td className='editable'>
											<input
												name={'taxPercent'}
												type={'number'}
												onChange={(e) =>
													handleInvoiceItemData
														? handleInvoiceItemData(
																Number(e.target.value),
																e.target.name,
																item.lineNumber
														  )
														: null
												}
												value={item.taxPercent}
												onClick={(e) => e.target.select()}
											/>
										</td>
										<td>
											<p>{addCurrencySpace(formatter.format(subTotal || 0))}</p>
										</td>
										<td>
											<p>{addCurrencySpace(formatter.format(item?.totalAmount || 0))}</p>
										</td>
										<td>
											<div>
												<TrashIcon
													iconClass='trashIcon'
													onClick={() => removeInvoiceItem(item.lineNumber)}
													focusable
												/>
											</div>
										</td>
									</tr>
								);
							} else {
								return (
									<tr key={key}>
										<td></td>
										<td>{key + 1}.</td>
										<td className='editable'>
											<p>{item?.description ? `${item?.description}` : ''}</p>
										</td>
										<td className='editable'>
											<p>{item?.quantity ? `${item?.quantity}` : ''}</p>
										</td>
										<td className='editable'>
											<p>{item?.unit ? `${item?.unit}` : ''}</p>
										</td>
										<td className='editable'>
											<p>{addCurrencySpace(formatter.format(item?.unitAmount || 0))}</p>
										</td>
										<td>
											<p>{item?.taxPercent ? `${item?.taxPercent} %` : ''}</p>
										</td>
										<td>
											<p>{addCurrencySpace(formatter.format(subTotal || 0))}</p>
										</td>
										<td>
											<p>{addCurrencySpace(formatter.format(item?.totalAmount || 0))}</p>
										</td>
										<td></td>
									</tr>
								);
							}
						})}
				</tbody>
			</table>
			{details && invoiceItems?.length === 0 && <p className='margin-20'>No items</p>}
			{!details && (
				<div className='btnAddCont'>
					<Button
						text='New item line'
						style='secondary'
						size='md'
						iconLeft={<PlusIcon />}
						color='sale'
						onClick={addInvoiceItem}
					/>
				</div>
			)}
		</div>
	);
}

import EditorJS from '@editorjs/editorjs';
import { useEffect, useRef, useState } from 'react';
import { EDITOR_JS_TOOLS } from './EditorJsTools';
import { useParams } from 'react-router-dom';
import config from '../../config';
import useMultiFetch from '../../hooks/useMultiFetch';
import useFetch from '../../hooks/useFetch';

export default function Editorials() {
	const params = useParams();

	const [uri, setUri] = useState(params.uri);
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState([]);

	let editor = { isReady: false };

	const { fetch } = useFetch();
	const { data, handleCustomOptions, reqLoading, error, setReload } = useMultiFetch([
		{ key: 'tutorial', url: `${config.apiv1}/tutorial/tutorial.read/${uri || params.uri}?lang=${params.lang}` },
		{ key: 'listings', url: `${config.apiv1}/tutorial/listing.read?lang=en&sort=ASC` },
	]);

	async function getPage(uri) {
		let instanceExists = document.getElementById('editorjs');
		if (instanceExists) {
			instanceExists.innerHTML = '';
		}
		let ctList = document.getElementsByClassName('ct');
		if (ctList) {
			for (let i = 0; i < ctList.length; i++) {
				ctList[i].remove();
			}
		}

		if (editor?.isReady) {
			return;
		}

		let tutorial = await fetch(`${config.apiv1}/tutorial/tutorial.read/${uri || params.uri}?lang=${params.lang}`);

		if (!tutorial?.content) {
			return;
		}

		editor = new EditorJS({
			holder: 'editorjs',
			readOnly: true,
			tools: { ...EDITOR_JS_TOOLS },
			data: JSON.parse(`{"time": ${Date.now()},
					"blocks": ${tutorial?.content}}`),
		});
	}

	function handleSortList() {
		let categories = {};
		const listings = [];
		if (data?.listings?.data?.data) {
			data?.listings?.data?.data?.forEach((listing) => {
				if (listing?.parent_id === 0 || !listing?.parent_id) {
					listing.listings = [];
					categories[listing.id] = listing;
				} else {
					listings.push(listing);
				}
			});
			listings.sort((a, b) => {
				if (a.sequence > b.sequence) {
					return 1;
				} else if (a.sequence < b.sequence) {
					return -1;
				}
			});
			listings.forEach((listing) => {
				if (categories[listing?.parent_id]) {
					categories[listing?.parent_id].listings?.push(listing);
				}
			});
			categories = Object.values(categories);
			categories.sort((a, b) => {
				if (a.sequence > b.sequence) {
					return 1;
				} else if (a.sequence < b.sequence) {
					return -1;
				}
			});

			setCategories(categories);

			setLoading(false);
		}
	}

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			getPage(uri);
		}
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			handleSortList();
		}
		return () => {
			mounted = false;
		};
	}, [data]);

	return (
		<div className='tutorialsPage'>
			{!reqLoading && !loading && (
				<>
					<div className='tutorialList'>
						{categories?.map((listing, key) => {
							return (
								<>
									<h4 className={`category`} key={key}>
										{listing.title?.charAt(0)?.toUpperCase() + listing?.title?.slice(1)}
									</h4>
									{listing?.listings?.map((listing) => {
										return (
											<h4
												className={`item${listing.uri === uri ? ' selected' : ''}`}
												key={key}
												onClick={() => {
													listing.uri ? setUri(listing.uri) : setUri('none');
													getPage(listing.uri);
													window.history.replaceState(null, 'Editorial', listing.uri);
												}}>
												{listing.title.charAt(0).toUpperCase() + listing.title.slice(1)}
											</h4>
										);
									})}
								</>
							);
						})}
					</div>
					<div className='editorJSCont' id='editorjs'></div>
				</>
			)}
		</div>
	);
}

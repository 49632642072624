import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import Listing from '../../components/Listing';
import useFetch from '../../hooks/useFetch';
import { makeFirstCharUpper, parseProcessingStatus } from '../../helpers';

export default function OrderList() {
	let location = useLocation();

	let filterStatuses = [
		'draft',
		'pending',
		'accepted',
		'processing',
		'completed',
		'dispatched',
		'delivered',
		'disputed',
		'rejected',
	];

	const options = {
		rightGroup: [{ text: '12 months' }, { text: '30 days' }, { text: '1 week' }, { text: '24 hours' }],
		leftGroup: [{ text: 'All Trades' }, { text: 'Purchases' }, { text: 'Sales' }],
	};

	const assignment = new URLSearchParams(location.search).get('assignment');
	const presetStatus = new URLSearchParams(location.search).get('status');
	const presetType = new URLSearchParams(location.search).get('type');
	const presetHold = new URLSearchParams(location.search).get('hold');

	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState({
		limit: 7,
		skip: 0,
		pages: 0,
		sort: 'date.desc',
		status: presetStatus || null,
		hold: presetHold || null,
	});
	const [search, setSearch] = useState(null);
	const [pages, setPages] = useState(0);

	const topOfPage = useRef(null);

	const { rightSidebarDispatch } = useContext(RightSidebarContext);
	const { teamState } = useContext(CurrentTeamContext);

	const { data, fetch, reqLoading } = useFetch();

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * filter?.limit);
		setLoading(true);
		handleFilter(newSkip, 'skip');
	}

	function resetPagination() {
		let paginationBtns = document.getElementsByClassName('paginationPage');
		for (let i = 0; i < paginationBtns.length; i++) {
			paginationBtns[i]?.classList.remove('paginationActive');
		}
		paginationBtns[0]?.classList.add('paginationActive');
	}

	function handleFilter(value, name) {
		if (name === 'status') {
			rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: value } });
		}
		if (name !== 'skip') {
			resetPagination();
		}

		if (typeof value === 'object') {
			let multiStatus = '';

			for (let field in value) {
				let statusText = field;
				if (value[field] === true && filterStatuses.includes(statusText)) {
					multiStatus = multiStatus + makeFirstCharUpper(statusText) + ', ';
				}
			}

			if (multiStatus.length > 0) {
				multiStatus = multiStatus.slice(0, -2);
				return setFilter((filter) => ({
					...filter,
					...value,
					multiStatus: multiStatus,
					status: null,
				}));
			} else {
				return setFilter((filter) => ({
					...filter,
					...value,
					status: null,
				}));
			}
		}
		setFilter((filter) => ({
			...filter,
			[name]: value,
		}));
	}

	const updateDebounceSearch = useCallback(
		debounce((searchValue) => {
			setSearch(searchValue);
		}),
		[]
	);

	function checkPreset() {
		if (presetStatus) {
			return `${presetStatus}`;
		} else {
			return '';
		}
	}

	function openFilter() {
		const contentType = 3;
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: { status: filter?.status || presetStatus } });
		rightSidebarDispatch({
			type: 'SET_CALLBACK',
			payload: (filter) => {
				handleFilter(filter);
				rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
			},
		});
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: contentType });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
	}

	function checkStatuses(presetStatus, filter) {
		switch (presetStatus) {
			case '0':
				if (filter.draft !== undefined) {
					return true;
				}
				break;
			case '1':
				if (filter.pending !== undefined) {
					return true;
				}
				break;
			case '2':
				if (filter.accepted !== undefined) {
					return true;
				}
				break;
			case '4':
				if (filter.awaiting !== undefined) {
					return true;
				}
				break;
			case '6':
				if (filter.delivered !== undefined) {
					return true;
				}
				break;
			default:
				return false;
		}
	}

	async function buildURLAndFetch() {
		const url = new URL(`${config.apiv1}/order/orders.read/${teamState.currentTeam.teamUuid}`);
		assignment && url.searchParams.set('assignment', assignment);
		filter?.sort?.split('.')[0] === 'date' && url.searchParams.set('sortDate', filter?.sort?.split('.')[1]);
		filter?.sort?.split('.')[0] === 'team' && url.searchParams.set('sortAlphabet', filter?.sort?.split('.')[1]);
		!checkStatuses(presetStatus, filter) &&
			checkPreset() &&
			!filter?.status &&
			url.searchParams.set('status', `${checkPreset()}`);
		filter?.status && filter?.status !== 'all' && url.searchParams.set('status', filter?.status);
		filter?.assignment && url.searchParams.set('assignment', filter?.assignment);
		filter?.hold && url.searchParams.set('hold', filter?.hold);
		if (!filter?.status) {
			filter?.rejected && url.searchParams.set('rejected', filter?.disputed);
			filter?.disputed && url.searchParams.set('disputed', filter?.disputed);
			filter?.completed && url.searchParams.set('completed', filter?.completed);
			filter?.processing && url.searchParams.set('processing', filter?.processing);
			filter?.delivered && url.searchParams.set('delivered', filter?.delivered);
			filter?.dispatched && url.searchParams.set('dispatched', filter?.dispatched);
			filter?.accepted && url.searchParams.set('accepted', filter?.accepted);
			filter?.pending && url.searchParams.set('pending', filter?.pending);
			filter?.draft && url.searchParams.set('draft', filter?.draft);
		}

		// LabManager
		filter?.reference && url.searchParams.set('reference', filter?.reference);
		filter?.practice && url.searchParams.set('practice', filter?.practice);
		filter?.dentist && url.searchParams.set('dentist', filter?.dentist);
		// Dentally
		filter?.patient_first_name && url.searchParams.set('patient_first_name', filter?.patient_first_name);
		filter?.patient_last_name && url.searchParams.set('patient_last_name', filter?.patient_last_name);
		filter?.patientId && url.searchParams.set('patientId', filter?.patientId);

		filter?.dateFrom && url.searchParams.set('dateFrom', filter?.dateFrom);
		filter?.dateTo && url.searchParams.set('dateTo', filter?.dateTo);
		filter?.buyer && url.searchParams.set('buyer', filter?.buyer);
		presetType && url.searchParams.set('teamType', presetType);
		search && url.searchParams.set('search', search);
		filter?.limit && url.searchParams.set('limit', filter?.limit);
		filter?.skip && url.searchParams.set('skip', filter?.skip);

		let res = await fetch(url.href, null, true);

		if (res?.ok) {
			setPages(res?.data && res.data.length > 0 ? Math.ceil(res?.totalDocuments / filter?.limit) : 0);
		} else {
			setPages(1);
		}
		setLoading(false);
	}

	function debounce(cb, delay = 400) {
		let timeout;
		return (...args) => {
			setLoading(true);
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	useEffect(() => {
		buildURLAndFetch();
	}, [teamState.reload, filter, search, location]);

	return (
		<>
			<div ref={topOfPage}></div>
			{/* {teamState?.userPermissions['manageOrders'] ? ( */}
			<Listing
				openFilter={openFilter}
				handlePagination={handlePagination}
				handleSearch={updateDebounceSearch}
				loading={reqLoading || loading}
				handleFilter={handleFilter}
				options={options}
				listItems={data}
				filter={filter}
				pages={pages}
				type='orders'
			/>
			{/* ) : (
				<>
					<h4>You do not have permission to see or manage orders.</h4>
					<h4 className='marginTop-20'>Contact your administrator for more information.</h4>
				</>
			)} */}
		</>
	);
}

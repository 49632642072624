import React, { useCallback, useContext, useEffect, useState } from 'react';
import SidebarOrder from './SidebarOrder';
import SidebarOrderListing from './SidebarOrderListing';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import { useLocation } from 'react-router';
import Overlay from '../Overlay';
import { TopnavCloseIcon } from '../SVGIcons/SVGIcons';
import SidebarOrderFilter from './filters/SidebarOrderFilter';
import Notifications from '../Notifications';
import News from '../News';
import SidebarInvoiceFilter from './filters/SidebarInvoiceFilter';
import useCompVis from '../../hooks/useCompVis';

export default function SidebarRight() {
	const { rightSidebarState, rightSidebarDispatch } = useContext(RightSidebarContext);
	const [lastLocation, setLastLocation] = useState({});
	let location = useLocation();

	const handleClickOutside = useCallback(() => {
		if (rightSidebarState.sidebarOpen === true) {
			rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
		}
	}, [rightSidebarState]);

	const { ref } = useCompVis(true, handleClickOutside);

	function openSwitch() {
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: !rightSidebarState.sidebarOpen });
	}

	function openTextbox(boxRef) {
		rightSidebarDispatch({
			type: 'SET_TEXTBOX_OPEN',
			payload: {
				boxRef: boxRef,
				boxOpen: rightSidebarState.textboxesOpen[boxRef]
					? rightSidebarState.textboxesOpen[boxRef]?.boxOpen
					: true,
			},
		});
	}

	useEffect(() => {
		let mounted = true;
		if (mounted && lastLocation.pathname !== location.pathname) {
			setLastLocation(location);
			rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false });
		}
		return () => (mounted = false);
	}, [location]); // eslint-disable-next-line

	return (
		<div className='rightSidebar'>
			{/* <Overlay
				loadOverlay={rightSidebarState.show}
				showOverlay={rightSidebarState.sidebarOpen}
				setShowOverlay={openSwitch}
			/> */}
			{rightSidebarState.show ? (
				<div
					ref={ref}
					className={
						rightSidebarState.sidebarOpen
							? 'rightSidebarList slideInRight'
							: 'rightSidebarList slideOutRight'
					}>
					<div className='sidebarContentWrapper'>
						<div className='rightSidebarTopRow'>
							<TopnavCloseIcon
								iconClass='closeIcon'
								onClick={() => {
									openSwitch();
									rightSidebarState.exitCallback ? rightSidebarState.exitCallback() : null;
								}}
							/>
						</div>
						{rightSidebarState.contentType === 1 && (
							<SidebarOrder
								contentReference={rightSidebarState.contentReference}
								textboxesOpen={rightSidebarState.textboxesOpen}
								open={rightSidebarState.sidebarOpen}
								openTextbox={openTextbox}
							/>
						)}
						{rightSidebarState.contentType === 2 && (
							<SidebarOrderListing
								contentReference={rightSidebarState.contentReference}
								textboxesOpen={rightSidebarState.textboxesOpen}
								openTextbox={openTextbox}
							/>
						)}
						{rightSidebarState.contentType === 3 && (
							<SidebarOrderFilter
								contentReference={rightSidebarState.contentReference}
								textboxesOpen={rightSidebarState.textboxesOpen}
								callback={rightSidebarState.callback}
								openTextbox={openTextbox}
							/>
						)}
						{rightSidebarState.contentType === 4 && <Notifications open={rightSidebarState.sidebarOpen} />}
						{rightSidebarState.contentType === 5 && <News open={rightSidebarState.sidebarOpen} />}
						{rightSidebarState.contentType === 6 && (
							<SidebarInvoiceFilter
								contentReference={rightSidebarState.contentReference}
								textboxesOpen={rightSidebarState.textboxesOpen}
								callback={rightSidebarState.callback}
								openTextbox={openTextbox}
							/>
						)}
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

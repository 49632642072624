import React from 'react';

let LeftSidebarContext = React.createContext();

function LeftSidebarContextProvider(props) {

    let initialState = {
        loading: true,
        reload: false,
        sidebarOpen: false,
        textboxesOpen: {},
        show: false
    };

    const reducer = (leftSidebarState, action) => {
        switch (action.type) {
            case "SET_TEXTBOX_OPEN":
                leftSidebarState.textboxesOpen[action.payload.boxRef] = action.payload.boxOpen;
                return {
                    ...leftSidebarState,
                    loading: false,
                    reload: false
                };
            case "SET_TEXTBOXES_CLOSED":
                return {
                    ...leftSidebarState,
                    textboxesOpen: {},
                    loading: false,
                    reload: false
                };
            case "SET_SIDEBAR_OPEN":
                return {
                    ...leftSidebarState,
                    sidebarOpen: action.payload,
                    show: true,
                    loading: false,
                    reload: false
                };
            default:
                break;
        }
    };

    let [leftSidebarState, leftSidebarDispatch] = React.useReducer(reducer, initialState);
    let value = { leftSidebarState, leftSidebarDispatch };

    return (
        <LeftSidebarContext.Provider value={value}>{props.children}</LeftSidebarContext.Provider>
    );
}

let LeftSidebarContextConsumer = LeftSidebarContext.Consumer;

export { LeftSidebarContext, LeftSidebarContextProvider, LeftSidebarContextConsumer };
import React from 'react';
import Button from '../../../components/Button';
import { PlayCircleIcon } from '../../../components/SVGIcons/SVGIcons';

export default function HeroHeader(props) {
	const { item } = props || {};
	return (
		<div className='heroCarousel'>
			<div className='innerCont'>
				<div className='card'>
					{item.imgSrc && item.flip && <img src={item.imgSrc} alt='Hero image' />}
					<div className='col' style={{ backgroundColor: `${item.background ? item.background : ''}` }}>
						<h1 className='header'>{item.header}</h1>
						<p className='paragraph'>{item.paragraph}</p>
						<Button
							size='xxl'
							style='primary'
							text='Play video tour'
							onClick={item.btnCallback}
							iconLeft={<PlayCircleIcon />}
							noFill
						/>
					</div>
					{item.imgSrc && !item.flip && <img src={item.imgSrc} alt='Hero image' />}
				</div>
			</div>
		</div>
	);
}

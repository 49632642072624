import React, { useEffect } from 'react';

export default function Policy() {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.async = true;
		document.body.appendChild(script);
	}, []);

	return (
		<div
			className='termlyCont'
			name='termly-embed'
			data-id='b41c931d-80be-435c-8d6d-24eb3c16a7e0'
			data-type='iframe'></div>
	);
}

import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay';

export default function ProcessingModal(props) {
	const { loading, show, message, uri, setShow, reloadBtnTxt, canReload, goToDash } = props || {};
	return (
		<>
			{show && (
				<>
					<div className='fixedModal'>
						{!loading ? (
							<>
								<h3>{message}</h3>
								<div className='btnCont'>
									{goToDash && (
										<Link to={'/'}>
											<button className='functionalButtonSecondary'>Go to dashboard</button>
										</Link>
									)}
									{uri && (
										<Link to={uri}>
											<button className='functionalButtonSecondary'>Go to order</button>
										</Link>
									)}
									{canReload && (
										<button
											className='functionalButtonPrimary'
											onClick={() => {
												window.location.reload();
											}}>
											{reloadBtnTxt}
										</button>
									)}
									{setShow && (
										<button
											className='functionalButtonSecondary'
											onClick={() => {
												setShow(false);
											}}>
											Close
										</button>
									)}
								</div>
							</>
						) : (
							<>
								<Loading type='circle' />
							</>
						)}
					</div>
					<Overlay loadOverlay={true} showOverlay={true} zIndex={93} />
				</>
			)}
		</>
	);
}

import React from 'react';

export default function TemplateItemExt(props) {
	const { postDocket, template } = props || {};

	return (
		<>
			{template && (
				<div
					className={`templateItem${template.color ? ` ${template.color}` : ''}`}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						postDocket({
							name: template.name,
							docketType: template?.docketType,
							color: template?.color,
							description: template?.description,
							fields: template?.fields,
						});
					}}>
					<h4 className='templateName'>{template.name}</h4>
					<div className={`backImg${template.jobType ? ` ${template.jobType}` : ''}`}></div>
					{/* <p className='templateDescription'>{template.description || ''}</p> */}
					<div className='templateFooter'>
						<p>Template</p>
					</div>
				</div>
			)}
		</>
	);
}

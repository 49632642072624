import React from 'react';
import { makeFirstCharUpper } from '../../helpers';

export default function Badge({ theme, type, fill, message, badgeText, buttons }) {
	return (
		<div className={`badgeCont${theme ? ` ${theme}` : ''}${type ? ` ${type}` : ''}${fill ? ` fill` : ''}`}>
			<div className='badge'>{badgeText ? makeFirstCharUpper(badgeText) : 'Info'}</div>
			<p className='message'>{message}</p>
		</div>
	);
}

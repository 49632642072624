import React from 'react';
import { parseToOptions } from '../../helpers';
import SelectInput from '../Inputs/SelectInput';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';

export default function OrderItemNewForm(props) {
	const { handleData, packages, formData, handleModal, handleFormSelectData, missingOptions } = props || {};

	return (
		<>
			<TextInput
				text={'What would you like to send?'}
				name={'itemDesc'}
				callback={handleData}
				defaultValue={formData?.itemDesc}
			/>
			<TextareaInput text={'Note'} name={'note'} callback={handleData} defaultValue={formData?.note} />
			<SelectInput
				options={parseToOptions(Object.values(packages), 'packages')}
				text={'For package'}
				name={'packageUuid'}
				value={formData?.packageUuid}
				callback={handleFormSelectData}
				missingOptions={missingOptions}
				reverse
			/>
			<button
				className='btn3-secondary marginBottom-40 marginTop-10'
				onClick={() => {
					handleModal('packageNewModal', true);
				}}>
				Add package
			</button>
		</>
	);
}

import React from 'react';
import PermissionsForm from '../Forms/PermissionsForm';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay';
import Button from '../Button';

export default function MemberEditModal(props) {
	const {
		handlePermissionsInput,
		handleModal,
		setUpdateLoading,
		patchMember,
		setRole,
		setPermissions,
		role,
		permissions,
		updateLoading,
		member,
		zIndex,
	} = props || {};

	return (
		<>
			<div className='wideModal'>
				<h3 className='marginBottom-20'>Set user permissions</h3>
				<PermissionsForm
					role={role}
					permissions={permissions}
					setRole={setRole}
					setPermissions={setPermissions}
					handlePermissionsInput={handlePermissionsInput}
					required={true}
				/>
				{!updateLoading ? (
					<div className='btnCont modal reverse'>
						<Button
							text='Update permissions'
							style='primary'
							type='lg'
							onClick={() => {
								patchMember(member, permissions, role);
								setUpdateLoading(true);
							}}
						/>
						<Button
							text='Close'
							style='secondary'
							type='lg'
							onClick={() => {
								handleModal('editModal', false);
								setRole(null);
								setPermissions(null);
							}}
						/>
					</div>
				) : (
					<>
						<Loading type='circle' />
					</>
				)}
			</div>
			<Overlay loadOverlay={true} showOverlay={true} zIndex={zIndex} />
		</>
	);
}

import Axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import config from '../../config';
import MemberEditModal from '../../components/Modals/MemberEditModal';
import {
	ArchiveIcon,
	AtIcon,
	PenIcon,
	PhoneIcon,
	SlackIcon,
	ToggleOnIcon,
	UserCircleIcon,
	UserPlusIcon,
} from '../../components/SVGIcons/SVGIcons';
import placeholderImg from '../../assets/img/whatsapp_app_icon.png';
import FlexModal from '../../components/Modals/FlexModal';
import Invite from './Invite';
import InvitesList from './InvitesList';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { splitUserId } from '../../helpers';
import { Navigate } from 'react-router-dom';

export default function TeamMembers() {
	const [updateLoading, setUpdateLoading] = useState(false);
	const [permissions, setPermissions] = useState({});
	const [role, setRole] = useState(null);
	const [member, setMember] = useState(null);
	const [invitations, setInvitations] = useState([]);

	const [modalsOpen, setModalsOpen] = useState({});

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	function handleModal(modal, value) {
		setModalsOpen({
			...modalsOpen,
			[modal]: value,
		});
	}

	function fetchInvitations() {
		Axios({
			url: `${config.apiv1}/invitation/invitations.read/${teamState.currentTeam.teamUuid}`,
			method: 'GET',
		}).then((res) => {
			if (res?.data?.data) {
				let filtered = res.data.data.filter((invitation) => invitation.status !== 'accepted');
				setInvitations(filtered.reverse());
				setUpdateLoading(false);
			}
		});
	}

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalsOpen((modalOpen) => ({
			...modalOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalsOpen((modalOpen) => ({
				...modalOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	function patchMember(member, permissions, role) {
		Axios({
			url: `${config.apiv1}/team/team.update/member.update`,
			method: 'PATCH',
			data: {
				userUuid: member.userUuid,
				permissions: permissions,
				role: role,
				teamUuid: teamState.currentTeam.teamUuid,
			},
		})
			.catch((err) => {
				return err;
			})
			.then((res) => {
				window.location.reload();
			});
	}

	const handlePermissionsInput = (checked, name) => {
		setPermissions((permissions) => ({
			...permissions,
			[name]: checked,
		}));
	};

	if (teamState.isTeamOfOne) {
		return <Navigate to='/' />;
	}

	return (
		<div className='memberManagementContainer'>
			<FadeOutModal
				show={modalsOpen['modalFading']?.show}
				isFading={modalsOpen['modalFading']?.isFading}
				message={modalsOpen['modalFading']?.message}
			/>
			{modalsOpen['editModal'] && (
				<MemberEditModal
					role={role || null}
					permissions={permissions || {}}
					updateLoading={updateLoading}
					member={member}
					handleModal={handleModal}
					setRole={setRole}
					setPermissions={setPermissions}
					handlePermissionsInput={handlePermissionsInput}
					patchMember={patchMember}
					setUpdateLoading={setUpdateLoading}
				/>
			)}
			{modalsOpen['inviteModal'] && (
				<FlexModal
					setShow={handleModal}
					modalName={'inviteModal'}
					closeBtn={true}
					body={
						<Invite
							handleFadeOutModal={handleFadeOutModal}
							handleModal={handleModal}
							setUpdateLoading={setUpdateLoading}
							fetchInvitations={fetchInvitations}
							members={teamState.currentTeam.members}
						/>
					}
					zIndex={99}
				/>
			)}
			{modalsOpen['inviteListModal'] && (
				<FlexModal
					setShow={handleModal}
					modalName={'inviteListModal'}
					closeBtn={true}
					body={
						<Invite
							handleFadeOutModal={handleFadeOutModal}
							handleModal={handleModal}
							setUpdateLoading={setUpdateLoading}
							fetchInvitations={fetchInvitations}
						/>
					}
					btn2={'Close'}
				/>
			)}
			<div className='header'>
				<h2 className='team'>{teamState.currentTeam.teamInfo.teamName}</h2>
				<div className='btnsRight'>
					<button
						className='btn2-medium team'
						onClick={() => {
							// handleModal('inviteListModal', true);
						}}
						disabled>
						Create invite list
					</button>
					<UserPlusIcon
						iconClass={`headerIcon${!teamState.userPermissions['canInvite'] ? ' disabledDiv' : ''}`}
						onClick={() => {
							handleModal('inviteModal', true);
						}}
					/>
				</div>
			</div>
			<div className='memberList'>
				{teamState.currentTeam.members.map((member, key) => {
					let teamAdmin = teamState.currentTeam.teamInfo?.admin;
					let isMemberAdmin = member.userUuid === teamAdmin || member.role === 'administrator';
					let isUserSuperAdmin =
						splitUserId(userState.currUser.sub) === teamAdmin && member.userUuid !== teamAdmin;
					if (member.firstname) {
						return (
							<React.Fragment key={key}>
								<div className='memberListItem'>
									<div className='leftCol'>
										{member?.avatar?.url ? (
											<img src={member?.avatar?.url} alt='avatar' />
										) : (
											<div>
												<UserCircleIcon iconClass='placeholder' />
												<div className='backColor'></div>
											</div>
										)}

										{!isMemberAdmin || isUserSuperAdmin ? (
											<>
												<div className='row'>
													<ToggleOnIcon iconClass='teamMemberPageIconLarge disabledDiv' />
												</div>
												<div className='row'>
													<div
														className='teamMemberPageIconSmall'
														onClick={() => {
															handleModal('editModal', true);
															setMember(member);
															setRole(member.role);
															setPermissions(member.permissions);
														}}>
														<PenIcon />
													</div>
													<ArchiveIcon iconClass='teamMemberPageIconSmall disabledDiv' />
												</div>
											</>
										) : (
											<></>
										)}
									</div>
									<div className='rightCol'>
										<div className='topRow'>
											<h2>{`${member.firstname} ${member.lastname}`}</h2>
											<p>
												{member.userUuid === teamState.currentTeam.teamInfo?.admin
													? 'superadmin'
													: member.role}
											</p>
										</div>
										<div className='bottomRow'>
											{member.email && (
												<p className='semiBold'>
													<AtIcon iconClass='supplierTrustedIcon marginRight-10' />
													{member.email}
												</p>
											)}
											{member.phone && (
												<p className='semiBold'>
													<PhoneIcon iconClass='supplierTrustedIcon marginRight-10' />
													{member.phone}
												</p>
											)}
											{member.slack && (
												<p className='semiBold'>
													<SlackIcon iconClass='supplierTrustedIcon marginRight-10' />
													{member.slack}
												</p>
											)}
										</div>
									</div>
								</div>
							</React.Fragment>
						);
					}
				})}
				<InvitesList
					handleFadeOutModal={handleFadeOutModal}
					setInvitations={setInvitations}
					loading={updateLoading}
					fetchInvitations={fetchInvitations}
					invitations={invitations}
				/>
			</div>
		</div>
	);
}

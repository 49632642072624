import React, { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../../App';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { splitUserId } from '../../helpers';
import config from '../../config';
import Axios from 'axios';
import FadeOutModal from '../Modals/FadeOutModal';
import { SupplierPlusIcon, SupplierTrustedIcon } from '../SVGIcons/SVGIcons';

export default function TrustedAddBtn(props) {
	const { supplierUuid, supplierName, supplierDesc, iconClass } = props;

	const [trustedSuggested, setTrustedSuggested] = useState(false);
	const [modalsOpen, setModalsOpen] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const { teamState, teamDispatch } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);
	const socket = useContext(SocketContext);

	async function checkSuggestedSupplier(teamUuid) {
		try {
			const res = await Axios({
				url: `${config.apiv1}/notification/notifications.read/${
					teamState.currentTeam.teamUuid
				}?limit=1&sortDate=desc&type=${'team.trustedSuggested'}&teamUuid=${teamUuid}`,
				method: 'GET',
			});

			if (res.data?.data?.notificationData?.isAccepted === 'pending') {
				setTrustedSuggested(true);
			}
			setLoaded(true);
		} catch (err) {
			console.log(err);
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
			return err;
		}
	}

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalsOpen((modalsOpen) => ({
			...modalsOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalsOpen((modalsOpen) => ({
				...modalsOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	async function addTrusted(teamUuid) {
		if (teamState.userPermissions['canAddTrusted']) {
			try {
				const res = await Axios({
					url: `${config.apiv1}/team/team.update/trusted.create/${teamState.currentTeam.teamUuid}`,
					method: 'PATCH',
					data: {
						trustedSupplier: {
							teamUuid: teamUuid,
						},
					},
				});
				if (!res?.data?.ok) {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'Error adding supplier as trusted supplier',
					});
				} else {
					if (res?.data?.data) {
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'Supplier successfully added as trusted supplier',
						});
						teamDispatch({ type: 'RE-FETCH_TEAM' });
					} else {
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'Error adding supplier as trusted supplier',
						});
					}
				}
			} catch (err) {
				// if (err.response.status === 401) {
				// 	window.location.reload();
				// }
			}
		} else {
			socket.emit('suggest-trusted', {
				userUuid: splitUserId(userState.currUser.sub),
				name: userState.currUser.name,
				trustedSuggestedUuid: teamUuid,
				trustedSuggestedName: supplierName,
				trustedSuggestedDesc: supplierDesc,
				recipientUuid: teamState.currentTeam.teamUuid,
			});
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Suggestion sent to Admin',
			});
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			checkSuggestedSupplier(supplierUuid);
		}
		return () => (mounted = false);
	}, []);

	return (
		<>
			<FadeOutModal
				show={modalsOpen['modalFading']?.show}
				isFading={modalsOpen['modalFading']?.isFading}
				message={modalsOpen['modalFading']?.message}
			/>
			{loaded && (
				<>
					{!teamState?.currentTeam?.trustedSuppliers &&
					supplierUuid !== teamState.currentTeam.teamUuid &&
					!trustedSuggested ? (
						<div className={iconClass || 'suppIcon'}>
							<SupplierPlusIcon
								onClick={() => {
									addTrusted(supplierUuid);
								}}
							/>
						</div>
					) : (
						<>
							{teamState?.currentTeam?.trustedSuppliers?.filter(
								(trustedSupplier) => trustedSupplier.teamUuid === supplierUuid
							).length === 0 &&
							supplierUuid !== teamState.currentTeam.teamUuid &&
							!trustedSuggested ? (
								<div className={iconClass || 'suppIcon'}>
									<SupplierPlusIcon
										onClick={() => {
											addTrusted(supplierUuid);
										}}
									/>
								</div>
							) : (
								<div className={iconClass ? `${iconClass} disabled` : `suppIcon disabled`}>
									<SupplierTrustedIcon />
								</div>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}

import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CircleSolidIcon, MenuMainIcon, TopnavBellIcon, TopnavMenuIcon, TopnavRocketIcon } from '../SVGIcons/SVGIcons';
import DirectedTutorialModal from '../Tutorial/DirectedTutorialModal';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import useScroll from '../../hooks/useScroll';
import Button from '../Button';

export default function Menu({ extendLeftSidebar, handleExtendLeftSidebar, setSubNavChoice, width }) {
	let { notificationsState } = useContext(NotificationContext);
	let { rightSidebarState, rightSidebarDispatch } = useContext(RightSidebarContext);
	let { teamState } = useContext(CurrentTeamContext);
	let { userState, userDispatch } = useContext(CurrentUserContext);

	const { scrollY } = useScroll();

	let navigate = useNavigate();

	const handleLeftSidebar = ({ openState, subNavChoice, isNavigationItem }) => {
		setSubNavChoice(subNavChoice);
		handleExtendLeftSidebar(openState, true, isNavigationItem);
	};

	function openSwitchRight(type) {
		if (type === 'new') {
			rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: !rightSidebarState.sidebarOpen });
			rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: 5 });
		} else {
			rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
			rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: !rightSidebarState.sidebarOpen });
			rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: 4 });
		}
	}

	return (
		<div className={`navbarOuter${scrollY !== 0 ? ' scroll' : ''}`}>
			{width < 680 && (
				<div className='hamburgerIcon'>
					<DirectedTutorialModal
						page='dashboard'
						order={1}
						header='Menu'
						body="Click to extend the main menu. In it you'll find everything you need whether you are buying or selling on Procuur."
					/>
					<MenuMainIcon
						iconClass='menuIcon'
						onClick={() =>
							handleLeftSidebar({
								openState: extendLeftSidebar === 0 ? 1 : 0,
								subNavChoice: null,
								isNavigationItem: true,
							})
						}
						focusable
					/>
				</div>
			)}
			<div className={`navbar${extendLeftSidebar > 0 ? ' leftExpandedMargin' : ''}`}>
				<Link to={userState?.isAuthenticated ? '/' : '/features'}>
					<div className={width < 680 ? 'logoSmall' : 'logo'}></div>
				</Link>
				{/* <div className="searchBar">
                <div className="searchDropdownBtn">
                <form>
                <select>
                <option value="">All</option>
                </select>
                </form>
                </div>
                <div className="searchIcon icon">
                <FontAwesomeIcon icon={["fas", getIcon("search")]} />
                </div>
                <input type="text" className="searchInput" />
            </div> */}
				<div className='navItems'>
					{userState?.isAuthenticated && (
						<div
							className='navItem'
							tabIndex={0}
							onClick={() => {
								openSwitchRight('new');
							}}>
							<div className='navIcon'>
								<TopnavRocketIcon iconClass='menuIcon' />
							</div>
						</div>
					)}
					{userState?.isAuthenticated && teamState.userTeams && (
						<>
							<div
								className='navItem'
								tabIndex={0}
								onClick={() => {
									openSwitchRight();
								}}>
								<DirectedTutorialModal
									page='dashboard'
									order={2}
									flip='flipLeft'
									onClick={(e) => {
										e.stopPropagation();
										handleLeftSidebar({ openState: 1, subNavChoice: null, isNavigationItem: true });
									}}
									header='Notifications'
									body='When stuff happens we notify you here. Examples could be a delivery update or a message that you have received.'
								/>
								<div className='navIcon'>
									<TopnavBellIcon iconClass='menuIcon' />
									{notificationsState.unreadNotifications && (
										<CircleSolidIcon iconClass='unreadCircleIconTurq' />
									)}
								</div>
							</div>
						</>
					)}
					{!userState?.isAuthenticated && (
						<div className='btnCont'>
							<Button
								text='Login'
								size={width < 680 ? 'sm' : 'lg'}
								style='tertiary-secondary'
								onClick={() => userDispatch({ type: 'LOGIN' })}
								minWidth={width < 680 ? 80 : 135}
							/>
							<Button
								text={width < 680 ? 'Apply' : 'Apply for registration'}
								size={width < 680 ? 'sm' : 'lg'}
								style='primary'
								onClick={() => navigate('/features/apply')}
								minWidth={width < 680 ? 80 : 135}
							/>
							{/* <Button
								text='Contact us'
								size='lg'
								style='tertiary-secondary'
								onClick={() => userDispatch({ type: 'LOGIN' })}
								minWidth={135}
							/> */}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

import React from 'react';
import { FileIcon, FileThinIcon } from './SVGIcons';

export default function FileMimeIcon({ mimetype }) {
	return (
		<div className={`fileMimeIcon${mimetype ? ` ${mimetype}` : ''}`}>
			<h4>{mimetype?.toUpperCase()}</h4>
			<FileIcon />
		</div>
	);
}

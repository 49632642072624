import React from 'react';
import Loading from '../Loading/Loading';
import Button from '../Button';

export default function ConfirmModalForm({ loading, onlyOk, hideModal, options }) {
	return (
		<>
			{loading ? (
				<Loading type='circle' />
			) : (
				<>
					{options?.header && <h4>{options?.header}</h4>}
					{options?.body && <p className='margin-20'>{options?.body}</p>}
					{onlyOk ? (
						<div className='btnCont'>
							<Button
								size='md'
								text='OK'
								style='primary'
								onClick={() => {
									options?.callback ? options?.callback() : null;
									hideModal();
								}}
							/>
						</div>
					) : (
						<>
							<div className='btnCont reverse'>
								<Button
									text='Yes'
									style='primary'
									type='lg'
									onClick={() => {
										options?.callback ? options?.callback() : null;
										hideModal();
									}}
								/>
								<Button text='No' style='secondary' type='lg' onClick={hideModal} />
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}

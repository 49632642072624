import React from 'react';
import TextareaInput from '../Inputs/TextareaInput';
import { CloseIcon } from '../SVGIcons/SVGIcons';

export default function OrderCommentForm(props) {
	const { handleData, formData, customErrors, fillAndOpenRightSidebar, handleModal } = props || {};

	return (
		<div className='mainFormCont' id='commentForm'>
			<TextareaInput
				text={'Private note'}
				name={'comment'}
				tooltip={
					'Private notes are not shared with the other party. If you would like to contact them please write a message instead.'
				}
				tooltipExtendedText={
					<>
						Private notes are not shared with the other party. If you would like to contact them please{' '}
						<span
							className='spanLinkText'
							onClick={() => {
								fillAndOpenRightSidebar('chat');
								handleModal('commentModal', false);
							}}>
							write a message
						</span>{' '}
						instead.
					</>
				}
				defaultValue={formData?.comment}
				customError={customErrors?.comment}
				callback={handleData}
				required
			/>
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { makeFirstCharUpper } from '../../helpers';
import { HomeSimpleIcon, PipeDividerIcon } from '../SVGIcons/SVGIcons';

function Breadcrumbs() {
	const [loaded, setLoaded] = useState(false);

	const location = useLocation();

	const SupplierCrumb = ({ match }) => {
		let name = new URLSearchParams(location.search).get('name');
		return <span>{name}</span>;
	};

	const OrderCrumb = () => {
		let orderRef = new URLSearchParams(location.search).get('orderRef');
		return <span>{orderRef !== 'undefined' ? orderRef : ''}</span>;
	};

	const InvoiceCrumb = () => {
		let docNumber = new URLSearchParams(location.search).get('docNumber');
		return <span>{docNumber && docNumber !== 'undefined' ? docNumber : 'Draft'}</span>;
	};

	const SettingsCrumb = () => {
		let appName = new URLSearchParams(location.search).get('appName');
		return <span>{appName !== 'undefined' ? makeFirstCharUpper(appName) : ''}</span>;
	};

	const HomeCrumb = () => {
		return <HomeSimpleIcon iconClass='breadcrumbIcon' />;
	};

	const routes = [
		{ path: '/', breadcrumb: HomeCrumb },
		{ path: '/team-registration', breadcrumb: 'Team Registration' },
		{ path: '/invoices/:invoiceUuid', breadcrumb: InvoiceCrumb },
		{ path: '/suppliers/:uuid', breadcrumb: SupplierCrumb },
		{ path: '/orders/:orderUuid', breadcrumb: OrderCrumb },
		{ path: '/manage/apps/settings', breadcrumb: SettingsCrumb },
	];

	const breadcrumbs = useBreadcrumbs(routes);

	let blacklist = [
		'/',
		'/manage',
		'/features',
		'/features/dentists',
		'/features/dental_labs',
		'/features/dental_practices',
	];

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			let blacklisted = false;
			for (let i = 0; i < blacklist.length; i++) {
				if (blacklist[i] === location.pathname) {
					blacklisted = true;
				}
			}
			setLoaded(!blacklisted);
		}
		return () => (mounted = false);
	}, [location]);

	return (
		<>
			{loaded ? (
				<div className='breadcrumbContainer'>
					{breadcrumbs.map(({ match, breadcrumb }, i) => (
						<React.Fragment key={match.pathname}>
							<NavLink to={match.pathname}>
								{breadcrumb}{' '}
								{breadcrumbs.length > 1 && breadcrumbs.length !== i + 1 ? (
									<PipeDividerIcon iconClass='pipeDividerIcon' />
								) : (
									''
								)}{' '}
							</NavLink>
						</React.Fragment>
					))}
				</div>
			) : (
				<div className='breadcrumbContainer'></div>
			)}
		</>
	);
}

export default Breadcrumbs;

import React, { useContext } from 'react';
import { useEffect } from 'react';
import Loading from '../../components/Loading/Loading';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import Axios from 'axios';
import config from '../../config';

export default function InvitesList(props) {
	const { fetchInvitations, loading, invitations, handleFadeOutModal, setInvitations } = props || {};

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	function checkStatusStyle(status) {
		switch (status) {
			case 'accepted':
				return 'statusGreen';
			case 'declined':
				return 'statusRed';
			case 'pending':
				return 'statusYellow';
			default:
				return '';
		}
	}

	async function removeInvite(invitationUuid) {
		let res = await Axios({
			url: `${config.apiv1}/invitation/invitation.delete/${invitationUuid}`,
			method: 'DELETE',
		});

		if (res?.data?.ok) {
			let filtered = invitations.filter((invitation) => invitation.invitationUuid !== invitationUuid);
			setInvitations(() => [...filtered]);
		}
	}

	async function resendInvite(invitation) {
		let userExists = await Axios.get(`${config.auth}/v1/user/user.read/email/${invitation.email}`, false, true);
		Axios({
			url: `${config.apiv1}/email/member.invite`,
			method: 'POST',
			data: {
				recipientName: invitation.recipientName,
				senderName: teamState.currentTeam.teamInfo.teamName,
				senderTeamUuid: teamState.currentTeam.teamUuid,
				invitations: [
					{
						recipientName: invitation.recipientName,
						recipientEmail: invitation.email,
						role: invitation.role,
						permissions: invitation.permissions,
						customText: invitation.customText,
						resend: !userExists ? true : false,
					},
				],
			},
		})
			.then((res) => {
				if (res.data.ok) {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'Invitation successfully resent',
					});
					setTimeout(() => {
						fetchInvitations();
					}, 1000);
				} else {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'Something went wrong when resending the invitation',
					});
				}
			})
			.catch((err) => {
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Something went wrong when resending the invitation',
				});
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Something went wrong when resending the invitation',
				});
				return err;
			});
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			fetchInvitations();
		}
		return () => (mounted = false);
	}, [teamState, userState]);

	return (
		<>
			{invitations && invitations.length > 0 && (
				<div className='invitationsListContainer'>
					<h2>Team invites</h2>
					<div className='colHeaders'>
						<h4>Name</h4>
						<h4>Email</h4>
						<h4>Status</h4>
						<h4></h4>
					</div>
					{!loading ? (
						<div className='invitationsList'>
							{invitations &&
								invitations.map((invitation, key) => {
									if (invitation.status !== 'accepted') {
										return (
											<div key={key} className='invite'>
												<h3>{invitation.recipientName || 'N/A'}</h3>
												<h3>{invitation.email}</h3>
												<h3 className={checkStatusStyle(invitation.status)}>
													{invitation.status}
												</h3>
												<div className='btnCont'>
													<button
														className='btn3-primary team'
														onClick={() => {
															resendInvite(invitation);
														}}>
														Resend
													</button>
													<button
														className='btn3-secondary'
														onClick={() => {
															removeInvite(invitation.invitationUuid);
														}}>
														Remove
													</button>
												</div>
											</div>
										);
									} else {
										return (
											<div key={key} className='invite'>
												<h3>{invitation.recipientName || 'N/A'}</h3>
												<h3>{invitation.email}</h3>
												<h3 className={checkStatusStyle(invitation.status)}>
													{invitation.status}
												</h3>
												<div className='btnCont'></div>
											</div>
										);
									}
								})}
						</div>
					) : (
						<Loading type='circle' />
					)}
				</div>
			)}
		</>
	);
}

import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useForms } from '../../hooks/useForms';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';
import Button from '../Button';

export default function ProductForm({ hideModal, options }) {
	const { callback, submitUrl, method, data } = options || {};

	const formValidationSchema = yup.object().shape({
		name: yup.string().required(),
	});

	const { teamState } = useContext(CurrentTeamContext);

	let productMap = (formData) => {
		const STATUS = {
			DRAFT: 1,
		};
		return {
			teamUuid: teamState?.currentTeam?.teamUuid,
			channel: formData?.channel,
			name: formData?.name,
			status: formData?.status || STATUS.DRAFT,
			taxCode: formData?.taxCode,
			type: formData?.type || 'CMD',
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'product',
		formValidationSchema,
		productMap,
		submitUrl,
		method
	);

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async () => {
		let success = await submitForm(null, `Product created successfully`, callback);
		if (success === true) {
			hideModal();
		}
	};

	useEffect(() => {
		if (data) {
			updateForm({ ...data });
		}
	}, []);
	return (
		<>
			<form id='product'>
				<h4>Add invoice line item</h4>
				<TextInput
					text='Name'
					name={'name'}
					value={formData?.name}
					controlled
					callback={handleFormData}
					required={true}
					customError={customErrors?.name}
				/>
				<TextInput
					text='Channel'
					type='text'
					name={'channel'}
					value={formData?.channel}
					controlled
					callback={handleFormData}
					customError={customErrors?.channel}
				/>
				<TextInput
					text='Tax code'
					type='text'
					name={'taxCode'}
					value={formData?.taxCode}
					controlled
					callback={handleFormData}
					customError={customErrors?.taxCode}
				/>
				<TextInput
					text='Type'
					type='text'
					name={'type'}
					value={formData?.type}
					controlled
					callback={handleFormData}
					customError={customErrors?.type}
				/>
			</form>
			<div className='btnCont'>
				<Button
					size='md'
					style='primary'
					text='Submit'
					onClick={handleSubmit}
					loading={reqLoading}
					minWidth={135}
				/>
			</div>
		</>
	);
}

let holdReasonOptions = [
	<option value='buyer_fitting'>Buyer fitting</option>,
	<option value='buyer_testing'>Buyer testing</option>,
	<option value='buyer_decision'>Buyer decision</option>,
	<option value='buyer_request'>Buyer request</option>,
	<option value='seller_awaiting_parts'>Seller awaiting parts</option>,
	<option value='buyer_other'>Buyer other</option>,
	<option value='seller_other'>Seller other</option>,
];

export default holdReasonOptions;

import React from 'react';
import TabGroup from './Tabs/TabGroup';

export default function FilterTabs(props) {
	const { leftGroup, rightGroup, selectedTab } = props || {};

	return (
		<div className='tabRow'>
			{leftGroup && <TabGroup selectedTab={selectedTab?.left} tabs={leftGroup} />}
			{rightGroup && <TabGroup selectedTab={selectedTab?.right} tabs={rightGroup} />}
		</div>
	);
}

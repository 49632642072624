import Axios from 'axios';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Loading from '../../components/Loading/Loading';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { splitUserId } from '../../helpers';

export default function MemberConfirm() {
	const [loaded, setLoaded] = useState('');
	const [pageMessage, setPageMessage] = useState('');

	const { userState, userDispatch } = useContext(CurrentUserContext);
	const { teamDispatch } = useContext(CurrentTeamContext);
	let location = useLocation();

	useEffect(() => {
		let teamUuid = new URLSearchParams(location.search).get('teamUuid');
		let invitationUuid = new URLSearchParams(location.search).get('invitationUuid');
		let redirectAfterAuth = Cookies.get('redirectAfterAuth');

		if (redirectAfterAuth) {
			redirectAfterAuth = JSON.parse(redirectAfterAuth);
		}

		if (!teamUuid || !invitationUuid) {
			teamUuid = redirectAfterAuth?.params.teamUuid;
			invitationUuid = redirectAfterAuth?.params.invitationUuid;
			Cookies.remove('redirectAfterAuth');
		}

		Axios(`${config.apiv1}/team/team.update/member.add`, {
			method: 'PATCH',
			data: {
				invitationUuid: invitationUuid,
				userUuid: splitUserId(userState.currUser.sub),
				teamUuid: teamUuid,
				name: userState.currUser.name,
				userEmail: userState.currUser.email,
			},
		})
			.catch((err) => {
				Cookies.remove('redirectAfterAuth');
				userDispatch({ type: 'LOGIN' });
			})
			.then((res) => {
				const twoSeconds = 2000;
				if (res?.data?.ok) {
					setPageMessage(`You have successfully joined the team!`);
					setLoaded(true);
					setTimeout(() => {
						teamDispatch({ type: 'SET_TEAM', payload: { teamUuid: res.data.data.teamUuid, reload: true } });
					}, twoSeconds);
				} else {
					const foundUser = userState?.users?.find((user) => user?.email === res?.data?.data);
					if (foundUser) {
						userDispatch({ type: 'SET_CURR_USER', payload: foundUser.sub });
						teamDispatch({ type: 'RELOAD' });
					} else {
						if (res.data.message === 'MEMBER_ALREADY_EXISTS') {
							window.location.href = '/';
							Cookies.remove('redirectAfterAuth');
						} else if (res.data.message === 'NO_INVITATION_FOUND') {
							window.location.href = '/';
							Cookies.remove('redirectAfterAuth');
						} else {
							userDispatch({
								type: 'SET_REDIRECT_AFTER_AUTH',
								payload: {
									isRedirect: true,
									redirectUri: '/confirm-member',
									redirectCount: redirectAfterAuth ? redirectAfterAuth.redirectCount + 1 : 1,
									params: { invitationUuid: invitationUuid, teamUuid: teamUuid },
								},
							});
							userDispatch({ type: 'LOGIN' });
						}
					}
				}
			});
	}, []); // eslint-disable-next-line

	if (!loaded) {
		return <Loading type='circle' />;
	}

	return <div>{loaded && <h1>{pageMessage}</h1>}</div>;
}

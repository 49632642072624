import { Line } from 'rc-progress';
import React from 'react';

export default function ProgressBar({ percent }) {
	return (
		<div className='progressBar'>
			<Line
				percent={percent || 0}
				strokeWidth={4}
				trailWidth={4}
				style={{ maxWidth: '85%' }}
				strokeColor='#008c99'
			/>
			<h4>{percent || 0}%</h4>
		</div>
	);
}

import React from 'react';
import { cloudServiceAttachmentLabels, cloudServices, dateConverter } from '../../../helpers';
import { PlusIcon } from '../../../components/SVGIcons/SVGIcons';

export default function OrderExternalAttachments(props) {
	const { externalAttachments, handleModal } = props?.props || {};

	return (
		<div className='gridItemOrder external'>
			<div className='row highlight'>
				<h4 className='gridHeader'>External Attachments</h4>
				<div
					className='plus discIcon'
					onClick={() => {
						handleModal('externalAttachmentModal', true);
					}}>
					<PlusIcon />
				</div>
			</div>
			<div className='col'>
				{externalAttachments && externalAttachments?.length > 0 ? (
					externalAttachments.map((attachment, key) => {
						if (attachment && attachment?.fields) {
							return (
								<div key={key} className='borderBottom marginBottom-10 marginLeft-10'>
									{Object.values(cloudServices)?.map((service, key) => {
										if (service?.serviceUuid === attachment.serviceUuid) {
											return <h4 key={key}>{service?.displayName}</h4>;
										} else {
											return <React.Fragment key={key}></React.Fragment>;
										}
									})}
									{Object.keys(attachment.fields)?.map((field, key) => {
										return (
											<div className='row' key={key}>
												<p className='text'>
													{cloudServiceAttachmentLabels(attachment.serviceUuid)[field]}:{' '}
												</p>
												<div className='data bold'>
													{dateConverter(attachment.fields[field])}
												</div>
											</div>
										);
									})}
								</div>
							);
						}
					})
				) : (
					<div className='marginLeft-10'>No external attachments</div>
				)}
			</div>
		</div>
	);
}

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForms } from '../../hooks/useForms';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';
import * as yup from 'yup';
import Button from '../Button';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import config from '../../config';
import useFetch from '../../hooks/useFetch';

let recipientTypes = {
	SELLER: 1,
	BUYER: 2,
};

export default function InviteTeamFormNEW({ hideModal, options }) {
	const formValidationSchema = yup.object().shape({
		email: yup.string().email().required(),
		recipientName: yup.string().required(),
		// Define your schema here
		// Example: email: yup.string().email().required()
	});

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	let navigate = useNavigate();
	const { fetch } = useFetch();

	const [formVis, setFormVis] = useState(options?.invitationType || null);

	let inviteFieldMap = (formData) => {
		let senderNameString = `${
			userState.currUser.name || `${userState.currUser.given_name} ${userState.currUser.family_name}`
		}${teamState.currentTeam?.teamInfo?.teamName ? ` from ${teamState.currentTeam?.teamInfo?.teamName}` : ''}`;

		return {
			senderName: senderNameString,
			senderTeamUuid: teamState.currentTeam.teamUuid,
			invitations: [
				{
					recipientEmail: formData.email,
					recipientName: formData.recipientName,
					customText: formData.customText,
					recipientType: formData.recipientType || recipientTypes.SELLER,
				},
			],
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'inviteTeam',
		formValidationSchema,
		inviteFieldMap,
		`${config.apiv1}/email/team.invite`
	);

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	async function extraValidation(formData) {
		let exists = await fetch(`${config.auth}/v1/user/user.read/email/${formData.email}`, false, true);
		if (exists?.user) {
			return { valid: false, message: 'A user with that email address is already a part of Procuur' };
		} else {
			return { valid: true };
		}
	}

	const handleSubmit = async () => {
		let success = await submitForm(
			async (formData) => extraValidation(formData),
			`Invite was successfully sent to ${formData?.recipientName}`
		);
		if (success === true) {
			hideModal();
		}
	};

	return (
		<div className='invitationContainer' id='inviteForm'>
			{!formVis && !teamState?.isTeamOfOne ? (
				<>
					<h4>Who would you like to invite?</h4>
					<div className='btnCont'>
						<button
							className='btn3-secondary'
							onClick={() => {
								navigate('/manage/members');
								hideModal();
							}}>
							Team Member
						</button>
						<button
							className='btn3-secondary'
							onClick={() => {
								setFormVis(recipientTypes.SELLER);
								updateForm({ recipientType: recipientTypes.SELLER });
							}}>
							Supplier
						</button>
						<button
							className='btn3-secondary'
							onClick={() => {
								setFormVis(recipientTypes.BUYER);
								updateForm({ recipientType: recipientTypes.BUYER });
							}}>
							Customer
						</button>
					</div>
				</>
			) : (
				<>
					<h4>
						{formVis === 1 || teamState?.isTeamOfOne
							? 'Invite a supplier to Procuur'
							: 'Invite a customer to Procuur'}
					</h4>
					<form>
						<TextInput
							text={'Name of recipient: '}
							name={'recipientName'}
							type={'text'}
							value={formData?.recipientName}
							controlled
							callback={handleFormData}
							required={true}
							customError={customErrors?.recipientName}
						/>
						<TextInput
							text={'Email: '}
							name={'email'}
							type={'email'}
							value={formData?.email}
							callback={handleFormData}
							controlled
							required={true}
							customError={customErrors?.email}
						/>
						<TextareaInput
							text={'Custom message (optional):'}
							name={'customText'}
							value={formData?.customText}
							controlled
							callback={handleFormData}
						/>
					</form>
					<div className='btnCont'>
						<Button
							size='md'
							style='primary'
							text='Submit'
							onClick={handleSubmit}
							loading={reqLoading}
							minWidth={135}
						/>
					</div>
				</>
			)}
		</div>
	);
}

import React from 'react';
import CheckboxInput from '../Inputs/CheckboxInput';
import SelectInput from '../Inputs/SelectInput';
import sortOptions from '../Options/sortOptions';
import Button from '../Button';

export default function InvoiceFilterForm(props) {
	const { filter, handleFilterInput, handleFilterSelect, applyFilter, clearFilter } = props || {};

	return (
		<div className='orderFilter'>
			<h3>STATUS</h3>
			<h4>Only show:</h4>
			<CheckboxInput
				checkboxText={'Draft'}
				name={'draft'}
				checked={filter?.draft === undefined && filter.status === 'draft' ? true : filter?.draft}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Sent'}
				name={'sent'}
				checked={filter?.sent === undefined && filter.status === 'sent' ? true : filter?.sent}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Paid'}
				name={'paid'}
				checked={filter?.paid === undefined && filter.status === 'paid' ? true : filter?.paid}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Refunded'}
				name={'refunded'}
				checked={filter?.refunded === undefined && filter.status === 'refunded' ? true : filter?.refunded}
				callback={handleFilterInput}
				onlyInput
			/>
			<CheckboxInput
				checkboxText={'Disputed'}
				name={'disputed'}
				checked={filter?.disputed === undefined && filter.status === 'disputed' ? true : filter?.disputed}
				callback={handleFilterInput}
				onlyInput
			/>
			{/* <h3>DATE</h3>
			<DateInput
				text={'Date from'}
				name={'dateFrom'}
				callback={handleFilterInput}
				value={filter.dateFrom}
				focused={false}
				dateFormat='Pp'
				timeSelect
				max={new Date()}
			/>
			<DateInput
				text={'Date to'}
				name={'dateTo'}
				callback={handleFilterInput}
				value={filter.dateTo}
				focused={false}
				dateFormat='Pp'
				timeSelect
				max={new Date()}
			/> */}
			<h3>SORT</h3>
			<SelectInput
				htmlOptions={sortOptions}
				callback={handleFilterSelect}
				value={filter?.sort}
				text={'Sort by'}
				name={'sort'}
				reverse
			/>
			<div className='btnCont'>
				<Button text='Clear filter' size='sm' style='secondary' onClick={clearFilter} minWidth={135} />
				<Button
					text='Apply filter'
					size='sm'
					style='primary'
					onClick={() => {
						applyFilter(filter);
					}}
					minWidth={135}
				/>
			</div>
		</div>
	);
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function DashboardWidgetLinks(props) {
	const { links } = props || {};

	return (
		<div>
			{links.length > 0 &&
				links.map((link, key) => {
					return (
						<div className='cardLink' key={key}>
							{link.linkType === 'sidebarRight' && (
								<Link
									to='#'
									onClick={() => {
										link.callback();
									}}>
									<h4>{link.name}</h4>
									<h4>{link.count}</h4>
								</Link>
							)}
							{link.linkType === 'link' && (
								<Link
									to={`/${link.path}?type=${link.type}${
										typeof link.status === 'number' ? `&status=${link.status}` : ''
									}${link.hold ? `&hold=true` : ''}`}>
									<h4>{link.name}</h4>
									<h4>{link.count}</h4>
								</Link>
							)}
						</div>
					);
				})}
		</div>
	);
}

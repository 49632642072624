import React from 'react';
import Button from '../Button';
import { PlusIcon } from '../SVGIcons/SVGIcons';

export default function PaymentList({ status }) {
	return (
		<div className='invoiceItemsSection'>
			<table>
				<thead className='topBar'>
					<tr>
						<th>
							<h4 className='header'>Date</h4>
						</th>
						<th>
							<h4 className='header'>Currency</h4>
						</th>
						<th>
							<h4 className='header'>Amount received</h4>
						</th>
						<th>
							<h4 className='header'>Balance</h4>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
			{/* {!details && ( */}
			{status !== 'paid' && (
				<div className='btnAddCont'>
					<Button
						text='Add received payment'
						style='secondary'
						size='md'
						iconLeft={<PlusIcon />}
						color='sale'
						// onClick={addInvoiceItem}
					/>
					<Button
						text='Create and finish'
						style='primary'
						size='md'
						iconLeft={<PlusIcon />}
						// onClick={addInvoiceItem}
					/>
				</div>
			)}
			{/* )} */}
		</div>
	);
}

import React from 'react';
import TextInput from '../Inputs/TextInput';
import DateInput from '../Inputs/DateInput';

export default function OrderEditForm(props) {
	const { handleData, status, formData, hint, dueDate, requiredDate, type } = props || {};

	return (
		<>
			{type === 1 && (
				<TextInput
					text={'Hint'}
					name={'sellerHint'}
					defaultValue={formData?.sellerHint || hint}
					callback={handleData}
					required
				/>
			)}
			{type === 2 && (
				<>
					<DateInput
						text={'Dispatch by'}
						name={'dispatchDueDateTime'}
						callback={handleData}
						defaultValue={formData?.dispatchDueDateTime || dueDate}
						focused={false}
						dateFormat='P'
						disabled={parseInt(status) >= 4}
						min={new Date()}
					/>
				</>
			)}
		</>
	);
}

import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import LocationPin from './LocationPin';
import Loading from '../../components/Loading/Loading';
import config from '../../config';

export default function GoogleMap(props) {
	const { address, parsedAddress } = props || {};

	const [location, setLocation] = useState(null);
	const [loaded, setLoaded] = useState(false);

	async function fetchGeo() {
		const filter = ['addressUuid', 'isDefault', 'addressType', 'street'];
		try {
			let addressGoogle = '';
			for (let field in address) {
				if (!filter.includes(field)) {
					addressGoogle = addressGoogle + ` ${address[field]}`;
				}
			}
			let url = `${config.apiv1}/map/map.geolocate?addressString=${addressGoogle}`;
			let res = await fetch(url, {
				method: 'GET',
			});
			res = await res.json();

			if (res?.results && res.results.length > 0) {
				setLocation(res.results[0].geometry.location);
			}
			setLoaded(true);
		} catch (err) {
			console.log(err);
			return err;
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			fetchGeo();
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			{address && parsedAddress && location && (
				<>
					{loaded ? (
						<GoogleMapReact
							bootstrapURLKeys={{ key: config.apiKeyMaps }}
							defaultCenter={location}
							defaultZoom={15}>
							<LocationPin address={parsedAddress} lat={location?.lat} lng={location?.lng} />
						</GoogleMapReact>
					) : (
						<Loading type='circle' />
					)}
				</>
			)}
		</>
	);
}

import React, { useRef } from 'react';
import { ReactComponent as UploadCloudTurqSVG } from '../../assets/svg/upload-cloud-turq.svg';
import { CheckIcon, CloseIcon, CloseXIcon, PlusIcon, UploadCloudIcon } from '../SVGIcons/SVGIcons';
import SelectInput from '../Inputs/SelectInput';
import { Line, Circle } from 'rc-progress';
import ProgressBar from '../Progress/ProgressBar';
import { checkFilenameLength, parseFileSize } from '../../helpers';
import FileMimeIcon from '../SVGIcons/FileIconMime';
import Button from '../Button';

export default function Upload(props) {
	const {
		handleModal,
		handleNewFiles,
		completedFiles,
		files,
		removeFile,
		fileLocationOptions,
		fileLocation,
		handleFileLocation,
		maxFileSize,
		uploadProgress,
		uploading,
	} = props?.props || {};

	const { errorMessage, handleError, interactType } = props || {};

	let inputRef = useRef(null);

	return (
		<>
			{interactType === 'form' ? (
				<div className='uploadInputCont'>
					<input
						type='file'
						id='uploadInput'
						name='uploadInput'
						multiple={true}
						value=''
						onChange={(e) => {
							if (e?.target?.files[0]?.size < maxFileSize || !maxFileSize) {
								handleNewFiles(e.target.files);
								handleError ? handleError('') : null;
							} else {
								handleError(`File size too large. Max size allowed is ${maxFileSize / 1000 / 1000} MB`);
							}
						}}
						ref={inputRef}
					/>
					<label htmlFor='uploadInput'>
						<Button
							text='Add file'
							style='primary'
							iconLeft={<PlusIcon />}
							type='button'
							onClick={() => inputRef?.current?.click()}
						/>
					</label>
				</div>
			) : (
				<>
					<div className='dragFileElement'>
						<CloseXIcon iconClass='closeIcon' onClick={() => handleModal('uploadModal', false)} />
						<input
							type='file'
							id='uploadInput'
							multiple={true}
							value=''
							onChange={(e) => {
								if (e?.target?.files[0]?.size < maxFileSize || !maxFileSize) {
									handleNewFiles(e.target.files);
									handleError('');
								} else {
									handleError(
										`File size too large. Max size allowed is ${maxFileSize / 1000 / 1000}MB`
									);
								}
							}}
						/>
						<label id='uploadLabel' htmlFor='uploadInput'>
							<UploadCloudTurqSVG className='cloudIcon' />
							{errorMessage ? <h4 className='error'>{errorMessage}</h4> : <></>}
							<div className='flexCol'>
								<p>
									<span className='highlight cursor-pointer'>Click to choose file</span>
								</p>
								<p>or drag and drop</p>
							</div>
						</label>
					</div>
					{fileLocationOptions && (
						<SelectInput
							htmlOptions={fileLocationOptions}
							callback={handleFileLocation}
							value={fileLocation}
							text={'Location'}
							name={'fileLocation'}
						/>
					)}
				</>
			)}
			{(files?.length !== 0 || completedFiles?.length !== 0) && interactType !== 'form' ? (
				<div className='chosenFileList'>
					<div className='listCont'>
						{files &&
							files.map((file, key) => {
								if (file) {
									return (
										<div className='listedFile' key={key}>
											<FileMimeIcon mimetype={`${file?.name?.split('.')[1]}`} />
											<div className='col'>
												<h4>{checkFilenameLength(file?.name, 20)}</h4>
												<p>{parseFileSize(file?.size)}</p>
												<ProgressBar
													percent={uploadProgress ? uploadProgress[file?.name]?.percent : 0}
												/>
											</div>
											{/* {!uploading ? (
													<CloseIcon
														iconClass='closeIcon'
														onClick={() => {
															removeFile(file);
														}}
													/>
												) : (
													<></>
												)} */}
										</div>
									);
								}
							})}
						{completedFiles &&
							completedFiles.map((file, key) => {
								if (file) {
									return (
										<div className='listedFile' key={key}>
											<FileMimeIcon mimetype={`${file?.name?.split('.')[1]}`} />
											<div className='col'>
												<div className='row'>
													<h4>{checkFilenameLength(file?.name, 20)}</h4>
													<CheckIcon iconClass='checkIcon' />
												</div>
												<p>{parseFileSize(file.size)}</p>
												<ProgressBar percent={100} />
											</div>
											{/* {!uploading && uploadProgress[file?.name]?.complete === false ? (
													<CloseIcon
														iconClass='closeIcon'
														onClick={() => {
															removeFile(file);
														}}
													/>
												) : (
													<></>
												)} */}
										</div>
									);
								}
							})}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
}

import Axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function useFetch(url) {
	const [data, setData] = useState(null);
	const [fullData, setFullData] = useState(null);
	const [customUrl, setCustomUrl] = useState('');
	const [reqLoading, setReqLoading] = useState(true);
	const [error, setError] = useState('');

	async function fetch(paramUrl, controller, returnFull) {
		if (paramUrl || url || customUrl) {
			try {
				let res = await Axios.get(paramUrl || customUrl || url, {
					signal: controller?.signal,
				});
				setError(res?.data?.error);
				setData(res?.data?.data);
				setFullData(res?.data);
				setReqLoading(false);
				return returnFull ? res.data : res.data.data;
			} catch (err) {
				setError(err);
				setReqLoading(false);
			}
		}
	}

	useEffect(() => {
		let mounted = true;
		const controller = new AbortController();
		if (mounted) {
			fetch(null, controller);
		}
		return () => {
			controller.abort();
			mounted = false;
		};
	}, [url, customUrl]);

	return { data, fullData, reqLoading, error, fetch, setCustomUrl, setData };
}

import React from 'react';
import Button from '../Button';

export default function ImgModal(props) {
	const { src, downloadImg, setShowOverlay, setShow, file } = props;
	return (
		<div className='flexModal'>
			{file ? (
				<>
					<h3>{file.caption || file.name}</h3>
					<img src={src} alt='Modal image' />
					<div className='btnCont modal reverse'>
						<Button
							text='Download'
							style='primary'
							type='lg'
							onClick={() => {
								downloadImg(file);
							}}
						/>
						<Button
							text='Close'
							style='secondary'
							type='lg'
							onClick={() => {
								setShow('imgModal', { open: false, file: null });
								setShowOverlay ? setShowOverlay(false) : null;
							}}
						/>
					</div>
				</>
			) : (
				<h3>No file</h3>
			)}
		</div>
	);
}

import * as moment from 'moment-timezone';
import 'moment/locale/uk';
import Decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import React from 'react';
import {
	CustomerIcon,
	DocketCalendarIcon,
	DocketCheckboxIcon,
	DocketFileIcon,
	DocketGroupIcon,
	DocketHIcon,
	DocketImageIcon,
	DocketPhoneIcon,
	DocketRadioIcon,
	DocketRichtextIcon,
	DocketScribbleIcon,
	DocketTextareaIcon,
	DocketTextIcon,
	DocketTriDownIcon,
	FlagIcon,
	FlagSolidIcon,
	ProgressAcceptedIcon,
	ProgressAcceptedInverseIcon,
	ProgressCompletedIcon,
	ProgressCompletedInverseIcon,
	ProgressDeliveredIcon,
	ProgressDeliveredInverseIcon,
	ProgressDispatchedIcon,
	ProgressDispatchedInverseIcon,
	ProgressDisputedIcon,
	ProgressDisputedInverseIcon,
	ProgressDraftIcon,
	ProgressDraftInverseIcon,
	ProgressPendingIcon,
	ProgressPendingInverseIcon,
	ProgressProcessingIcon,
	ProgressProcessingInverseIcon,
	ProgressRejectedIcon,
	ProgressRejectedInverseIcon,
	ProgressVoidIcon,
	ProgressVoidInverseIcon,
	TeamIcon,
	TruckLeftIcon,
} from './components/SVGIcons/SVGIcons';
import Chart1 from './assets/SVG_teeth-charts/FDI-ISO-3950.svg';

export function getCurrTimestamp() {
	moment.locale('uk');
	return moment(Date.now()).tz('Europe/London').format('h:mm:ss');
}

export function getFutureDate(days) {
	moment.locale('uk');
	let date = moment(Date.now()).tz('Europe/London').add(days, 'd');
	return date;
}

export function getCurrDate(format) {
	moment.locale('uk');
	return moment(Date.now())
		.tz('Europe/London')
		.format(format || 'DD-MM-YYYY');
}

export function timestampConverter(timestamp, format, timezone) {
	if (typeof timestamp === 'string' || typeof timestamp === 'object' || typeof timestamp === 'number') {
		timestamp = new Date(timestamp);
		timestamp = new Intl.DateTimeFormat('en-UK', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		}).format(timestamp);
		return timestamp;
	} else {
		return timestamp;
	}
}

export function getDateDiffMonths(months, type) {
	const date = new Date();
	if (type === 'plus') {
		date.setMonth(date.getMonth() + months);
	}
	if (type === 'minus') {
		date.setMonth(date.getMonth() - months);
	}
	return date;
}

export function clarifyDate(date) {
	moment.locale('uk');
	let REFERENCE = moment();
	let TODAY = REFERENCE.clone().startOf('day');
	let YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

	if (moment(date).isSame(TODAY, 'd')) {
		return 'Today';
	} else if (moment(date).isSame(YESTERDAY, 'd')) {
		return 'Yesterday';
	} else {
		return timestampConverter(date, 'DD/MM/YYYY', 'Europe/London');
	}
}

export function parseExactDate(date) {
	if (!date) {
		return '';
	}
	moment.locale('uk');
	const dayInMill = 86400000;
	const year = 365;
	const month = 30;
	const week = 7;

	date = new Date(date);

	function calcDays(date) {
		let diff = Date.now() - date.getTime();
		let days = diff / dayInMill;
		days = Math.floor(days);
		return days;
	}

	let days = calcDays(date);

	if (days === 0) {
		if (moment() - moment(date).valueOf() < dayInMill) {
			return (date = new Intl.DateTimeFormat('en-UK', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			}).format(date));
		} else {
			return `${days} ${days < 2 ? 'day' : 'days'} ago`;
		}
	} else if (days < week) {
		return `${days} ${days < 2 ? 'day' : 'days'} ago`;
	} else if (days < month && days >= week) {
		return `${Math.floor(days / week)} ${days < week * 2 ? 'week' : 'weeks'} ago`;
	} else if (days < year && days >= month) {
		return `${Math.floor(days / month)} ${days < month * 2 ? 'month' : 'months'} ago`;
	} else {
		return (date = new Intl.DateTimeFormat('en-UK', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour12: true,
		}).format(date));
	}
}

export function parseKanbanDate(date) {
	moment.locale('uk');
	const dayInMill = 86400000;
	const year = 365;
	const month = 30;
	const week = 7;

	if (!date || date === 'null') {
		return {};
	}

	let warningYellow = '#F79009';
	let errorRed = '#D92D20';

	date = new Date(date);

	let parsedDateObj = { date: date };

	function calcDays(date) {
		let diff = Date.now() - date.getTime();
		let days = diff / dayInMill;
		days = Math.floor(days);
		return -days;
	}

	let days = calcDays(date);

	if (days < 7) {
		parsedDateObj.color = warningYellow;
		parsedDateObj.icon = <FlagIcon />;
	}
	if (days < 3) {
		parsedDateObj.color = errorRed;
		parsedDateObj.icon = <FlagSolidIcon />;
	}
	if (days < 0) {
		parsedDateObj.date = `due ${-days} ${days < 2 ? 'day' : 'days'} ago`;
	} else if (days === 0) {
		if (moment() - moment(date).valueOf() < dayInMill) {
			parsedDateObj.date = 'due today';
		} else {
			parsedDateObj.date = `due in ${days} ${days > 2 ? 'day' : 'days'}`;
		}
	} else if (days < week) {
		parsedDateObj.date = `due in ${days} ${days < 2 ? 'day' : 'days'}`;
	} else {
		parsedDateObj.date = date = new Intl.DateTimeFormat('en-UK', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour12: true,
			timeZone: 'Europe/London',
		}).format(date);
	}
	return parsedDateObj;
}

export function areDifferentDays(date1, date2) {
	moment.locale('uk');
	return moment(date1).format('DD') !== moment(date2).format('DD');
}

export function dateConverter(date, format) {
	moment.locale('en-UK');
	const filter = ['-', ':'];
	if (typeof date === 'string' || typeof date === 'date') {
		const isDate = filter.some(function (v) {
			return date?.indexOf(v) >= 0;
		});
		if (moment(date).isValid() && isDate) {
			return moment(date).format(format || 'DD/MM/YYYY');
		} else {
			return date;
		}
	} else {
		return date;
	}
}

export function docketDataConverter(data, time) {
	if (!data) {
		return data;
	}
	function checkValidDate(data) {
		let dateSequence = [4, 2, 16];
		let checks = {
			0: false,
			1: false,
			2: false,
		};
		let splitString = data?.toString()?.split('-');
		for (let i = 0; i < splitString.length; i++) {
			if (dateSequence[i] === splitString[i].length) {
				checks[i] = true;
			}
		}
		if (checks[0] && checks[1] && checks[2] && data.length === 24) {
			return true;
		} else {
			return false;
		}
	}
	moment.locale('en-UK');
	if (isNaN(data) && checkValidDate(data)) {
		return moment(data).format(time ? 'DD/MM/YYYY hh:mm A' : 'DD/MM/YYYY');
	} else if (typeof data === 'boolean') {
		if (data === true) {
			return 'Yes';
		} else {
			return 'No';
		}
	} else {
		return data;
	}
}

export function decodeJwt(token) {
	if (token !== null && token !== undefined) {
		return Decode(token);
	}
}

export function handleRedirect(isAuthenticated, path) {
	if (!isAuthenticated) {
		return <Navigate to={path} />;
	}
}

export function splitUserId(id) {
	if (id) {
		return id.split('|')[1];
	}
}

export function parseFieldIcon(type) {
	const iconClass = 'docketIcon large';
	switch (type) {
		case 'select':
			return <DocketTriDownIcon iconClass={iconClass} />;
		case 'input.text':
			return <DocketTextIcon iconClass={iconClass} />;
		case 'input.phone':
			return <DocketPhoneIcon iconClass={iconClass} />;
		case 'textarea':
			return <DocketTextareaIcon iconClass={iconClass} />;
		case 'input.date':
			return <DocketCalendarIcon iconClass={iconClass} />;
		case 'input.file':
			return <DocketFileIcon iconClass={iconClass} />;
		case 'input.checkbox':
			return <DocketCheckboxIcon iconClass={iconClass} />;
		case 'input.radio':
			return <DocketRadioIcon iconClass={iconClass} />;
		case 'header':
			return <DocketHIcon iconClass={iconClass} />;
		case 'paragraph':
			return <DocketRichtextIcon iconClass={iconClass} />;
		case 'drawingTool':
			return <DocketScribbleIcon iconClass={iconClass} />;
		case 'container':
			return <DocketGroupIcon iconClass={iconClass} />;
		case 'graphic':
			return <DocketImageIcon iconClass={iconClass} />;
		default:
			return <div></div>;
	}
}

export function parseProcessingStatus(status, className) {
	status = Number(status);
	const iconClass = className || 'orderPageIcon marginLeft-10';
	switch (status) {
		case 0:
			return {
				text: 'Draft',
				color: '#808080',
				style: '',
				icon: <ProgressDraftIcon iconClass={iconClass} />,
				iconInverted: <ProgressDraftInverseIcon iconClass={iconClass} />,
			};
		case 1:
			return {
				text: 'Pending',
				color: '#F3890F',
				style: 'waiting',
				icon: <ProgressPendingIcon iconClass={iconClass} />,
				iconInverted: <ProgressPendingInverseIcon iconClass={iconClass} />,
			};
		case 2:
			return {
				text: 'Accepted',
				color: '#F3890F',
				style: 'waiting',
				icon: <ProgressAcceptedIcon iconClass={iconClass} />,
				iconInverted: <ProgressAcceptedInverseIcon iconClass={iconClass} />,
			};
		case 3:
			return {
				text: 'Processing',
				color: '#F3890F',
				style: 'waiting',
				icon: <ProgressProcessingIcon iconClass={iconClass} />,
				iconInverted: <ProgressProcessingInverseIcon iconClass={iconClass} />,
			};
		case 4:
			return {
				text: 'Completed',
				color: '#1EB840',
				style: 'success',
				icon: <ProgressCompletedIcon iconClass={iconClass} />,
				iconInverted: <ProgressCompletedInverseIcon iconClass={iconClass} />,
			};
		case 5:
			return {
				text: 'Dispatched',
				color: '#1EB840',
				style: 'success',
				icon: <ProgressDispatchedIcon iconClass={iconClass} />,
				iconInverted: <ProgressDispatchedInverseIcon iconClass={iconClass} />,
			};
		case 6:
			return {
				text: 'Delivered',
				color: '#1EB840',
				style: 'success',
				icon: <ProgressDeliveredIcon iconClass={iconClass} />,
				iconInverted: <ProgressDeliveredInverseIcon iconClass={iconClass} />,
			};
		case 7:
			return {
				text: 'Disputed',
				color: '#DF2D21',
				style: 'error',
				icon: <ProgressDisputedIcon iconClass={iconClass} />,
				iconInverted: <ProgressDisputedInverseIcon iconClass={iconClass} />,
			};
		case 8:
			return {
				text: 'Void',
				color: '#DF2D21',
				style: 'error',
				icon: <ProgressVoidIcon iconClass={iconClass} />,
				iconInverted: <ProgressVoidInverseIcon iconClass={iconClass} />,
			};
		case 9:
			return {
				text: 'Rejected',
				color: '#DF2D21',
				style: 'error',
				icon: <ProgressRejectedIcon iconClass={iconClass} />,
				iconInverted: <ProgressRejectedInverseIcon iconClass={iconClass} />,
			};
		case 10:
			return {
				text: 'Cancelled',
				color: '#808080',
				style: 'error',
				icon: <ProgressRejectedIcon iconClass={iconClass} />,
				iconInverted: <ProgressRejectedInverseIcon iconClass={iconClass} />,
			};
		default:
			return {
				text: 'Unknown',
				style: '',
			};
	}
}

export function parseGrade(grade) {
	grade = Number(grade);
	const iconClass = 'orderPageIcon marginLeft-10';
	switch (grade) {
		case 1:
			return {
				text: 'High',
			};
		case 2:
			return {
				text: 'Medium',
			};
		case 3:
			return {
				text: 'Low',
			};
		default:
			return {
				text: 'Unset',
			};
	}
}

export function parseTrackingStatus(status) {
	if (!status) {
		return {};
	}
	status = Number(status);
	const iconClass = 'trackItemIcon';
	switch (status) {
		case 1:
			return {
				text: 'Buyer',
				style: 'neutral',
				icon: <CustomerIcon iconClass={iconClass} />,
			};
		case 2:
			return {
				text: 'Courier',
				style: 'waiting',
				icon: <TruckLeftIcon iconClass={iconClass} />,
			};
		case 3:
			return {
				text: 'Seller',
				style: 'neutral',
				icon: <TeamIcon iconClass={iconClass} />,
			};
		default:
			return {
				text: 'Unknown',
				style: '',
				icon: <CustomerIcon iconClass={iconClass} />,
			};
	}
}

export function parseOrderPriority(priority) {
	switch (priority) {
		case 1:
			return 'Standard';
		case 2:
			return 'Express';
		default:
			return 'Unknown';
	}
}

export function splitNotificationType(type) {
	if (type) {
		return type.split('.');
	}
}

export const toBase64 = async (file) => {
	let res = new Promise((resolve, reject) => {
		const reader = new FileReader();
		if (typeof file !== 'string') {
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => {
				reject(error);
			};
		} else {
			resolve(file);
		}
	});
	return res;
};

export const getUnreadNotificationCount = (notifications, notificationType, status, teamUuid, isBuyer) => {
	let unread = 0;
	if (status && notificationType) {
		for (let i = 0; i < notifications.length; i++) {
			if (
				notifications[i]?.notificationData?.orderProcessingStatus === status &&
				notifications[i]?.notificationData[isBuyer ? 'buyerTeamUuid' : 'sellerTeamUuid'] === teamUuid &&
				!notifications[i].isRead
			) {
				unread++;
			}
		}
	}
	return unread;
};

export const checkUnreadMessages = (notifications, notificationType, orderUuid) => {
	let unread = false;
	if (notificationType) {
		for (let i = 0; i < notifications.length; i++) {
			if (
				notifications[i].notificationType === notificationType &&
				!notifications[i].isRead &&
				notifications[i]?.notificationData?.orderUuid === orderUuid
			) {
				return (unread = true);
			}
		}
	}
	return unread;
};

export const parseToOptions = (data, type) => {
	let options = [];
	if (data && type) {
		switch (type) {
			case 'trustedTeams':
				for (let i = 0; i < data.length; i++) {
					options.push({ name: data[i]?.teamName, value: data[i]?.teamUuid });
				}
				return options;
			case 'suppliers':
				for (let i = 0; i < data.length; i++) {
					options.push({ name: data[i]?.teamInfo?.teamName, value: data[i]?.teamUuid });
				}
				return options;
			case 'packages':
				for (let i = 0; i < data.length; i++) {
					let selectText = `${
						data[i]?.quotedCollectionDateTime
							? dateConverter(data[i]?.quotedCollectionDateTime, 'DD/MM/YYYY-hh:mm')
							: ''
					}${data[i]?.address?.teamName ? `_${data[i]?.address?.teamName}` : `_${data[i]?.address?.name}`}`;

					options.push({
						name: selectText,
						value: data[i]?.packageUuid,
					});
				}
				return options;
			case 'members':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: `${data[i]?.prefix ? `${data[i]?.prefix} ` : ''}${data[i]?.firstname} ${
							data[i]?.lastname
						}`,
						value: data[i]?.userUuid,
					});
				}
				return options;
			case 'addresses':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: data[i]?.addressLine1,
						value: {
							name: data[i]?.name,
							addressLine1: data[i]?.addressLine1,
							city: data[i]?.city,
							postalCode: data[i]?.postalCode,
							country: data[i]?.country,
							addressUuid: data[i]?.addressUuid,
						},
					});
				}
				return options;
			case 'supplierAddresses':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: data[i]?.supplierName,
						value: {
							name: data[i]?.name,
							addressLine1: data[i]?.addressLine1,
							city: data[i]?.city,
							postalCode: data[i]?.postalCode,
							country: data[i]?.country,
							addressUuid: data[i]?.addressUuid,
						},
					});
				}
				return options;
			case 'couriers':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: data[i]?.companyName,
						value: data[i]?.courierUuid,
					});
				}
				return options;
			case 'docketSelect':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: data[i]?.value,
						value: data[i]?.value,
					});
				}
				return options;
			case 'docketFields':
				let filter = ['drawingTool'];
				for (let i = 0; i < data.length; i++) {
					if (data[i]?.data?.fieldText && data[i]?.data?.fieldName && !filter.includes(data[i]?.type)) {
						options.push({
							name: data[i]?.data?.fieldText,
							value: data[i]?.data?.fieldName,
						});
					}
				}
				return options;
			case 'dockets':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: data[i]?.name,
						value: data[i]?.docketUuid,
					});
				}
				return options;
			case 'cloudServices':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: data[i]?.displayName,
						value: data[i]?.name,
					});
				}
				return options;
			case 'products':
				for (let i = 0; i < data.length; i++) {
					options.push({
						name: data[i]?.name,
						value: data[i]?.productUuid,
					});
				}
				return options;
			default:
				return options;
		}
	}
	return options;
};

export const sliceString = (string, start, end, dots) => {
	if (!string || typeof string !== 'string') return '';

	if (string?.length < end) {
		return string;
	} else {
		if (dots) {
			return `${string?.slice(start, end)}...`;
		} else {
			return string?.slice(start, end);
		}
	}
};

export const checkFileType = (filetype) => {
	filetype = filetype?.toLowerCase();
	switch (filetype) {
		case 'png':
			return 1;
		case 'jpg':
			return 1;
		case 'stl':
			return 2;
		case 'docx':
			return 2;
		case 'pdf':
			return 2;
		default:
			return 2;
	}
};

export const handleIconClass = (valid, focus) => {
	if (valid) {
		return 'inputIconSuccess';
	} else if (!valid && focus) {
		return 'inputIconError';
	} else {
		return 'hidden';
	}
};

export const checkFilenameLength = (filename, maxLength) => {
	if (maxLength && filename && filename.length > maxLength) {
		return `${sliceString(filename.split('.')[0], 0, maxLength)}...${
			filename.split('.')[1] ? filename.split('.')[1] : ''
		}`;
	} else {
		return filename;
	}
};

export const parseFileSize = (size) => {
	const t = 1000;
	const kb = t;
	const mb = kb * t;
	const gb = mb * t;
	if (typeof size !== 'number') {
		size = Math.floor(size);
	}
	let length = size.toString().length;
	if (length > 9) {
		size = size / gb;
		return `${Math.floor(size)} GB`;
	} else if (length > 6) {
		size = size / mb;
		return `${Math.floor(size)} MB`;
	} else if (length > 3) {
		size = size / kb;
		return `${Math.floor(size)} KB`;
	} else {
		return `${size}B`;
	}
};

export const filterMember = (members, userUuid) => {
	let filtered = members?.filter((member) => member.userUuid === userUuid);
	if (filtered.length !== 0) {
		return filtered[0]?.firstname + ' ' + filtered[0]?.lastname;
	}
};

export const filterMemberAvatar = (members, userUuid) => {
	let filtered = members?.filter((member) => member.userUuid === userUuid);
	if (filtered.length !== 0) {
		return filtered[0]?.avatar;
	}
};

export const checkPackDelivered = (pack) => {
	if (pack && Array.isArray(pack.locations)) {
		if (pack.locations[0]?.origin === 1 && pack.locations[0]?.location === 3) {
			return true;
		} else if (pack.locations[0]?.origin === 3 && pack.locations[0]?.location === 1) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const checkStatusIfDisable = (status, func) => {
	let blockedFuncs = {
		draft: ['editOrder', 'chat', 'addFile', 'addComment', 'addItem'],
		pending: ['suborder', 'assign'],
		completed: ['addFile', 'suborder', 'setPrice', 'assign'],
		dispatched: ['editOrder', 'addFile', 'addItem', 'suborder', 'setPrice', 'assign'],
		delivered: ['editOrder', 'addFile', 'addItem', 'suborder', 'setPrice', 'assign'],
		rejected: ['editOrder', 'addFile', 'addItem', 'suborder', 'setPrice', 'addComment', 'assign'],
	};

	status = parseInt(status);

	switch (status) {
		case 0:
			if (blockedFuncs.draft.includes(func)) {
				return true;
			}
			break;
		case 1:
			if (blockedFuncs.pending.includes(func)) {
				return true;
			}
			break;
		case 4:
			if (blockedFuncs.completed.includes(func)) {
				return true;
			}
			break;
		case 5:
			if (blockedFuncs.dispatched.includes(func)) {
				return true;
			}
			break;
		case 6:
			if (blockedFuncs.delivered.includes(func)) {
				return true;
			}
			break;
		case 9:
			if (blockedFuncs.rejected.includes(func)) {
				return true;
			}
			break;
	}
};

export const makeFirstCharUpper = (string) => {
	if (string && typeof string === 'string') {
		return string.slice(0, 1).toUpperCase() + string.slice(1);
	} else {
		return string;
	}
};

export const integrationDataLabels = (integration) => {
	switch (integration) {
		case 'dentally':
			return {
				id: 'Patient ID',
				ni_number: 'National Insurance Number',
				address_line_1: 'Address Line 1',
				address_line_2: 'Address Line 2',
				address_line_3: 'Address Line 3',
				mobile_phone: 'Mobile Phone',
				middle_name: 'Middle Name',
				postcode: 'Postcode/Zip',
				home_phone: 'Home Phone',
				work_phone: 'Work Phone',
				first_name: 'First Name',
				nhs_number: 'NHS Number',
				county: 'County/State',
				last_name: 'Last Name',
				email_address: 'Email',
				date_of_birth: 'DOB',
				title: 'Title',
				town: 'Town',
			};
		default:
			return {};
	}
};

// export const externalFileLabels = () => {
// 			return {
// 				folderPathName: 'Folder name',
// 				recipientEmail:
// 				senderEmail
// 				accountName: 'Name of Account',
// 				scanDate
// 				fileName: 'Name of file',
// 				subject
// 				date: 'The Date of the Scan',
// 				note
// 			};

// };

export const cloudServiceAttachmentLabels = (serviceUuid) => {
	const DROPBOX = 'c92242a1-62db-4842-98b7-d820f62a5bf3';
	const WETRANSFER = 'fe1fcd14-77f7-41e3-8ef7-23c1800d2a4c';
	const ITERO = '6a52a119-4e3c-4245-aa75-da57085aa9e6';
	const THREESHAPE = '057eb4c4-463a-4c42-88aa-dc6240f3547a';
	const TRIOS = '537e541c-4ab2-483c-a629-4f98175a2973';
	const IMETRIC = '7bb3ca80-54a6-420d-9dac-b3670d67bce9';
	const MEDIT = 'ad61389b-024d-4527-a208-21c172591d9a';
	const VIVASCAN = 'ed874d1f-5c7f-4162-ac42-a46d6730d64d';
	const THREEDISC = 'b002cb24-20f9-43d2-9763-c1a58f0b3430';
	const DEXIS = '26c9127d-4641-4186-946e-0eaf8a3d88dc';
	const STRAUSMANN = '099e3784-7fd0-4ae8-ad5b-34177171fe9a';
	const PLANMECA = '9c527bf7-65fd-406d-be28-7a183b1d5d44';
	const LAUNCA = '3da125e0-cfcb-446d-9136-4d7518a820ab';
	const EMAIL = '3d3d41ac-e399-4e5f-b729-f0cf60404621';
	const OTHER = '494af86c-0bdc-4ce0-a574-45fc4b9daff5';

	switch (serviceUuid) {
		case DROPBOX:
			return {
				folderPathName: 'Folder name',
				fileName: 'Name of file',
			};
		case WETRANSFER:
			return {
				recipientEmail: 'Recipient Email Address',
				senderEmail: 'Sender Email Address',
			};
		case ITERO:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case THREESHAPE:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case TRIOS:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case IMETRIC:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case MEDIT:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case VIVASCAN:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case THREEDISC:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case DEXIS:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case STRAUSMANN:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case PLANMECA:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case LAUNCA:
			return {
				scanDate: 'The Date of the Scan',
				accountName: 'Name of Account',
			};
		case EMAIL:
			return {
				senderEmail: "Sender's Email Address",
				subject: 'Email Subject line',
				date: 'Date email was sent',
			};
		case OTHER:
			return {
				folderPathName: 'Folder name',
				fileName: 'Name of file',
			};
		default:
			return {};
	}
};

export const parseDentallyFields = (fields, filter) => {
	let defaultFilter = [
		'id',
		'title',
		'first_name',
		'middle_name',
		'last_name',
		'date_of_birth',
		'email_address',
		'nhs_number',
		'ni_number',
		'address_line_1',
		'address_line_2',
		'address_line_3',
		'town',
		'county',
		'postcode',
		'home_phone',
		'mobile_phone',
		'work_phone',
	];

	let parsedFields = {};
	if (filter) {
		for (let field in fields) {
			if (filter[field] && fields[field]) {
				parsedFields[field] = fields[field];
			}
		}
	} else {
		for (let field in fields) {
			if (defaultFilter.includes(field) && fields[field]) {
				parsedFields[field] = fields[field];
			}
		}
	}
	return parsedFields || fields;
};

export const calcSkels = (width, items, settings, skeleton) => {
	const maxCount = 4;
	const tripleCount = 3;
	const dualCount = 2;
	let skelCount = 0;
	let skelList = [];

	if (!items || !width) {
		return;
	}
	if (items.length === 0) {
		return [skeleton, skeleton, skeleton, skeleton];
	}

	switch (width) {
		case settings.max.carouselWidth:
			skelCount = maxCount - (items.length % 4);
			break;
		case settings.triple.carouselWidth:
			skelCount = tripleCount - (items.length % 3);
			break;
		case settings.dual.carouselWidth:
			skelCount = dualCount - (items.length % 2);
			break;
	}

	for (let i = 0; i < skelCount; i++) {
		skelList.push(skeleton);
	}
	return skelList;
};

export const getRolePermissions = (role) => {
	switch (role) {
		case 'administrator':
			return {
				isAdmin: true,
				isBuyer: true,
				manageOrders: true,
				authorizedOnBehalf: true,
				canInvite: true,
				canAddTrusted: true,
			};
		case 'manager':
			return {
				isAdmin: false,
				isBuyer: true,
				manageOrders: true,
				authorizedOnBehalf: false,
				canInvite: true,
				canAddTrusted: true,
			};
		case 'buyer':
			return {
				isAdmin: false,
				isBuyer: true,
				manageOrders: false,
				authorizedOnBehalf: false,
				canInvite: false,
				canAddTrusted: false,
			};
		case 'officer':
			return {
				isAdmin: false,
				isBuyer: false,
				manageOrders: false,
				authorizedOnBehalf: true,
				canInvite: false,
				canAddTrusted: false,
			};
		case 'member':
			return {
				isAdmin: false,
				isBuyer: false,
				manageOrders: false,
				authorizedOnBehalf: false,
				canInvite: false,
				canAddTrusted: false,
			};
		default:
			return {
				isAdmin: false,
				isBuyer: false,
				manageOrders: false,
				authorizedOnBehalf: false,
				canInvite: false,
				canAddTrusted: false,
			};
	}
};

export const cloudServices = {
	['3disc']: {
		serviceUuid: 'b002cb24-20f9-43d2-9763-c1a58f0b3430',
		name: '3disc',
		displayName: '3DISC',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	['3shape']: {
		serviceUuid: '057eb4c4-463a-4c42-88aa-dc6240f3547a',
		name: '3shape',
		displayName: '3Shape',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	trios: {
		serviceUuid: '537e541c-4ab2-483c-a629-4f98175a2973',
		name: 'trios',
		displayName: 'Trios',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	['iMetric']: {
		serviceUuid: '7bb3ca80-54a6-420d-9dac-b3670d67bce9',
		name: 'iMetric',
		displayName: 'iMetric',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	dexis: {
		serviceUuid: '26c9127d-4641-4186-946e-0eaf8a3d88dc',
		name: 'dexis',
		displayName: 'Dexis',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	dropbox: {
		serviceUuid: 'c92242a1-62db-4842-98b7-d820f62a5bf3',
		name: 'dropbox',
		displayName: 'Dropbox',
		fields: [
			{ name: 'folderPathName', label: 'Folder name', type: 'text' },
			{ name: 'fileName', label: 'Name of file', type: 'text' },
		],
	},

	email: {
		serviceUuid: '3d3d41ac-e399-4e5f-b729-f0cf60404621',
		name: 'email',
		displayName: 'Email',
		fields: [
			{ name: 'senderEmail', label: "Sender's Email Address" },
			{ name: 'subject', label: 'Email Subject line', type: 'text' },
			{ name: 'date', label: 'Date email was sent', type: 'date' },
		],
	},
	itero: {
		serviceUuid: '6a52a119-4e3c-4245-aa75-da57085aa9e6',
		name: 'itero',
		displayName: 'iTero',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	launca: {
		serviceUuid: '3da125e0-cfcb-446d-9136-4d7518a820ab',
		name: 'launca',
		displayName: 'Launca',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	medit: {
		serviceUuid: 'ad61389b-024d-4527-a208-21c172591d9a',
		name: 'medit',
		displayName: 'Medit',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	planmeca: {
		serviceUuid: '9c527bf7-65fd-406d-be28-7a183b1d5d44',
		name: 'planmeca',
		displayName: 'Planmeca',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	strausmann: {
		serviceUuid: '099e3784-7fd0-4ae8-ad5b-34177171fe9a',
		name: 'strausmann',
		displayName: 'Strausmann',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	vivascan: {
		serviceUuid: 'ed874d1f-5c7f-4162-ac42-a46d6730d64d',
		name: 'vivascan',
		displayName: 'VivaScan',
		fields: [
			{ name: 'scanDate', label: 'The Date of the Scan', type: 'date' },
			{ name: 'accountName', label: 'Name of Account', type: 'text' },
		],
	},
	wetransfer: {
		serviceUuid: 'fe1fcd14-77f7-41e3-8ef7-23c1800d2a4c',
		name: 'wetransfer',
		displayName: 'WeTransfer',
		fields: [
			{ name: 'recipientEmail', label: 'Recipient Email Address', type: 'text' },
			{ name: 'senderEmail', label: 'Sender Email Address', type: 'text' },
		],
	},
	other: {
		serviceUuid: '494af86c-0bdc-4ce0-a574-45fc4b9daff5',
		name: 'other',
		displayName: 'Other',
		fields: [
			{ name: 'folderPathName', label: 'Folder name', type: 'text' },
			{ name: 'fileName', label: 'Name of file', type: 'text' },
		],
	},
};

export const breakpoints = {
	mobile: { min: 320, max: 480 },
	tablet: { min: 481, max: 768 },
	laptop: { min: 769, max: 1024 },
	desktop: { min: 1025, max: 1200 },
	desktop: { min: 1201 },
};

export const defaultDocketTemp = (team) => {
	return {
		fields: [
			{
				id: 'field_9_a5b77587-a5a0-4783-813c-15eee727092f',
				name: 'Header text',
				type: 'header',
				data: {
					header: 'Lab Sheet',
				},
			},
			{
				id: 'field_2_c4cb9e4f-d961-4671-a10c-dd7cfa670b84',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Patients Name',
					placeholder: 'Surname, Firstname, Title',
					required: true,
					fieldName: 'patientsname',
				},
			},
			{
				id: 'widget_1_52f1dbc9-93d0-4a00-ae3b-99d4a3eac25f',
				name: 'FDI-ISO-3950',
				type: 'drawingTool',
				data: {
					fieldName: 'FDI-ISO-3950',
					fieldText: 'FDI-ISO-3950',
					drawToolImg: {
						name: 'FDI-ISO-3950',
						data: Chart1,
					},
				},
			},
			{
				id: 'field_2_1a5a2fa5-2fd3-4e2c-9b1f-ba4346dec6cb',
				name: 'Single-line text',
				type: 'input.text',
				data: {
					fieldText: 'Shade',
					fieldName: 'shade',
				},
			},
			{
				id: 'field_4_f46fb590-98f8-42b5-bb9d-5d425d21200f',
				name: 'Multi-line text',
				type: 'textarea',
				data: {
					fieldText: 'Further instructions',
					fieldName: 'furtherinstructions',
				},
			},
			{
				id: 'field_5_940bb8ed-4b94-48ca-b4b9-21d5454ee930',
				name: 'Date',
				type: 'input.date',
				data: {
					fieldText: 'Return date',
					minDays: '5',
					fieldName: 'returndate',
				},
			},
		],
		docketType: 1,
		teamUuid: team,
		docketUuid: 'af09a8a6-7ed6-4db5-b28f-be1c7006c8f5',
		name: 'Basic Lab Sheet',
		description: 'Simple Lab Sheet example. Transactor Systems Ltd Template.',
		color: 'yellow',
	};
};

export const replaceSpecialCharacters = (str) => {
	const sanitizedStr = str.replace(/[^a-zA-Z0-9 ]/g, '');
	const replacedStr = sanitizedStr.replace(/ /g, '_');
	const finalStr = replacedStr.replace(/_+/g, '_');
	const trimmedStr = finalStr.replace(/^_|_$/g, '');

	return trimmedStr;
};

export const getCurrencySymbolFromTeam = (team) => {
	let getCurrencySymbol = (locale, currency) =>
		(0)
			.toLocaleString(locale, {
				style: 'currency',
				currency,
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
			.replace(/\d/g, '')
			.trim();
	if (team?.teamInfo?.currency && team?.teamInfo?.spokenLanguage) {
		return getCurrencySymbol(team?.teamInfo?.spokenLanguage, team?.teamInfo?.currency);
	} else {
		return '£';
	}
};

export const addCurrencySpace = (formatted) => {
	return (formatted = formatted.replace(/(\D)(\d)/, '$1 $2'));
};

export const calculatePercentage = (percentage, total) => {
	return (percentage / 100) * total;
};

export const createWrapperAndAppendToBody = (wrapperId) => {
	const wrapperElement = document.createElement('div');
	wrapperElement.setAttribute('id', wrapperId);
	document.body.appendChild(wrapperElement);
	return wrapperElement;
};

export const timeDifference = (date1, date2) => {
	const time1 = new Date(date1).getTime();
	const time2 = new Date(date2).getTime();

	if (time1 > time2) {
		return '00:00:00';
	}

	const differenceInMs = time2 - time1;

	const totalMinutes = Math.floor(differenceInMs / (1000 * 60));
	const totalHours = Math.floor(totalMinutes / 60);
	const days = Math.floor(totalHours / 24);
	const hours = totalHours % 24;
	const minutes = totalMinutes % 60;

	const formattedDays = String(days).padStart(2, '0');
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutes).padStart(2, '0');

	return `${formattedDays}:${formattedHours}:${formattedMinutes}`;
};

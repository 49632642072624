import React from 'react';

export default function ManageWrap(props) {
	const { children, backColor, style, header } = props || {};
	return (
		<main className={`pageInnerCont${backColor ? ` ${backColor}` : ''}`} style={style || null}>
			{header && (
				<div className='mainHeader'>
					<h1 className='headerText'>
						{header.icon}
						{header.text}
					</h1>
				</div>
			)}
			{children}
		</main>
	);
}

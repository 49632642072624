import React from 'react';
import { useContext } from 'react';
import {
	AnalyticsIcon,
	BellIcon,
	CartBuyIcon,
	CartSellIcon,
	SkeletonAnalyticsIcon,
	TagIcon,
} from '../../components/SVGIcons/SVGIcons';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import DashboardWidget from './DashboardWidget';
import DashboardWidgetLinks from './DashboardWidgetLinks';

export default function DashboardNotifications(props) {
	const { ordersCount, notificationsCount } = props || {};

	let { rightSidebarState, rightSidebarDispatch } = useContext(RightSidebarContext);

	function openSwitchRight() {
		rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
		rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: !rightSidebarState.sidebarOpen });
		rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: 4 });
	}

	return (
		<>
			<div className='overviewSection'>
				<DashboardWidget
					color={'purchase'}
					icon={<CartBuyIcon />}
					header={'Purchase Orders'}
					body={
						<DashboardWidgetLinks
							links={[
								{
									name: 'Drafts',
									count: ordersCount?.drafts,
									type: 1,
									status: 0,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Pending',
									count: ordersCount?.purchasesPending,
									type: 1,
									status: 1,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Accepted',
									count: ordersCount?.purchasesAccepted,
									type: 1,
									status: 2,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Processing',
									count: ordersCount?.purchasesProcessing,
									type: 1,
									status: 3,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Dispatched',
									count: ordersCount?.purchasesDispatched,
									type: 1,
									status: 5,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Held',
									count: ordersCount?.purchasesHeld,
									type: 1,
									hold: true,
									path: 'orders',
									linkType: 'link',
								},
							]}
							isBuyer={true}
						/>
					}
					url={'orders'}
					type={1}
					linkBtn={'See all'}
					linkType={'link'}
				/>
				<DashboardWidget
					color={'sale'}
					icon={<TagIcon />}
					header={'Sales'}
					body={
						<DashboardWidgetLinks
							links={[
								{
									name: 'New',
									count: ordersCount?.salesPending,
									type: 2,
									status: 1,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Accepted',
									count: ordersCount?.salesAccepted,
									type: 2,
									status: 2,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Processing',
									count: ordersCount?.salesProcessing,
									type: 2,
									status: 3,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Awaiting suborder',
									count: ordersCount?.awaitingSuborder,
									type: 2,
									status: 12,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Awaiting dispatch',
									count: ordersCount?.awaitingDispatch,
									type: 2,
									status: 4,
									path: 'orders',
									linkType: 'link',
								},
								{
									name: 'Held',
									count: ordersCount?.salesHeld,
									type: 2,
									hold: true,
									path: 'orders',
									linkType: 'link',
								},
							]}
							isBuyer={false}
						/>
					}
					url={'orders'}
					url2={'progress'}
					type={2}
					linkBtn={'See all'}
					linkBtn2={'Progress'}
					linkType={'link'}
				/>
				<DashboardWidget
					color={'notification'}
					icon={<BellIcon />}
					header={'Notifications'}
					body={
						<DashboardWidgetLinks
							links={[
								{
									name: 'New notifications',
									count: notificationsCount?.messages,
									linkType: 'sidebarRight',
									callback: openSwitchRight,
								},
								{
									name: 'Pricing reviews',
									count: notificationsCount?.pricingReviews,
									linkType: 'sidebarRight',
									callback: openSwitchRight,
								},
								{
									name: 'Unread notifications',
									count: notificationsCount?.unreadNotifications,
									linkType: 'sidebarRight',
									callback: openSwitchRight,
								},
							]}
						/>
					}
					url={'notifications'}
					linkBtn={'See all'}
					linkType={'sidebarRight'}
					callback={openSwitchRight}
					disabledBtn
				/>
				<DashboardWidget
					color={'analytics'}
					icon={<AnalyticsIcon />}
					header={'Analytics'}
					linkBtn={'Coming soon'}
					url={'tutorials/en/tutorial'}
					body={<SkeletonAnalyticsIcon iconClass='analyticsIcon' />}
				/>
			</div>
		</>
	);
}

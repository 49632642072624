import React from 'react';
import { CloseIcon } from '../SVGIcons/SVGIcons';

export default function DefaultBanner(props) {
	const { textThick, textThin, callback } = props || {};
	return (
		<div className='bannerCont' onClick={callback}>
			<p className='thickText'>{textThick}</p>
			<p className='thinText'>{textThin}</p>
			<CloseIcon iconClass='closeIcon' />
		</div>
	);
}

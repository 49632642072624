import Axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function usePost(url, callback, formId, method) {
	const [data, setData] = useState(null);
	const [formData, setFormData] = useState(null);
	const [reqLoading, setReqLoading] = useState(false);
	const [error, setError] = useState('');
	const [customUrl, setCustomUrl] = useState(null);
	const [customMethod, setCustomMethod] = useState(null);
	const [customFormId, setCustomFormId] = useState(null);
	const [customErrors, setCustomErrors] = useState({});

	async function post(paramData, paramUrl, checkForm = true, paramMethod) {
		function checkInvalidFields(data) {
			const formCont = document.getElementById(customFormId || formId);
			const elements = formCont?.querySelectorAll('[isrequired]');

			if (!data) return false;
			if (!checkForm) return false;
			if (!customFormId && !formId) return false;

			if (elements?.length > 0) {
				for (let i = 0; i < elements?.length; i++) {
					if (!data[elements[i].getAttribute('isrequired')]) {
						let newCustomErrors = {
							...customErrors,
							[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
						};
						setCustomErrors(newCustomErrors);
						elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
						return { invalid: true, customErrors: newCustomErrors };
					}
				}
			} else {
				return { invalid: false, customErrors: null };
			}
		}

		if (!formData && !paramData) {
			setError('Form empty');
			return;
		}

		let checkRes = checkInvalidFields(paramData || formData);

		if (checkRes?.invalid && callback) {
			return callback({
				ok: false,
				customErrors: checkRes?.customErrors,
				id: customFormId || formId,
			});
		}

		setReqLoading(true);

		try {
			const res = await Axios({
				url: paramUrl || customUrl || url,
				method: paramMethod || method || customMethod || 'POST',
				data: paramData || formData,
			});

			if (res.data?.ok) {
				setTimeout(() => {
					setReqLoading(false);
					callback &&
						callback({
							ok: true,
							data: res.data.data,
							customErrors: customErrors,
							id: customFormId || formId,
						});
					setData({ ok: true, data: res.data.data, customErrors: customErrors });
					setFormData(null);
				}, 500);
				return { data: res.data.data, customErrors: customErrors };
			}
			return res;
		} catch (err) {
			setReqLoading(false);
			callback && callback({ ok: false, customErrors, error: err, id: customFormId || formId });
			setData({ ok: false, customErrors, error: err });
			setFormData(null);
			return err;
		}
	}

	const handleFormData = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	const handleNestedFormData = (data, objKey) => {
		const { name, value } = data || {};
		setCustomErrors({});
		let newData = { [name]: value };
		if (formData[objKey] && data.name !== 'service') {
			newData = { ...formData[objKey], [name]: value };
		}
		setFormData((formData) => ({
			...formData,
			[objKey]: newData,
		}));
	};

	const handleFormSelectData = (data, fieldName) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[fieldName]: data,
		}));
	};

	return {
		data,
		formData,
		post,
		setCustomUrl,
		setCustomFormId,
		reqLoading,
		error,
		customErrors,
		setCustomErrors,
		setCustomMethod,
		handleFormData,
		handleNestedFormData,
		handleFormSelectData,
	};
}

import React from 'react';
import { CircleSolidIcon } from '../SVGIcons/SVGIcons';

export default function Toggle({ label, name, value, onClick, disabled }) {
	return (
		<div className={`toggle${disabled ? ' disabledDiv' : ''}`}>
			<label className='toggleLabel' htmlFor={name}>
				<p>{label}</p>
			</label>
			<div
				className={`toggleButton${value === true ? ' checked' : ''}`}
				onClick={() => {
					!disabled ? onClick(name, !value) : null;
				}}>
				<div className='circle'>
					<CircleSolidIcon />
				</div>
			</div>
		</div>
	);
}

import React from 'react';
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import DateInput from '../Inputs/DateInput';
import { cloudServices, dateConverter, parseToOptions } from '../../helpers';
import Button from '../Button';

export default function ExternalAttachmentForm({
	formData,
	handleFormData,
	customErrors,
	orderExternalAttachments,
	deleteExternalAttachment,
	addExternalAttachment,
}) {
	return (
		<div id='externalAdd'>
			<SelectInput
				options={parseToOptions(Object.values(cloudServices), 'cloudServices')}
				callback={(value, name) => {
					handleFormData(cloudServices[value?.value], name);
				}}
				text={'Service'}
				name={'service'}
				value={formData?.service?.serviceUuid}
				customError={customErrors.service}
				required
			/>
			{formData?.service &&
				cloudServices[formData?.service?.name].fields.map((field, key) => {
					if (!field || !field.name) {
						return <React.Fragment key={key}></React.Fragment>;
					}
					return (
						<div key={key}>
							{field.type === 'text' && (
								<TextInput
									text={field.label}
									name={field.name}
									callback={(value, name) => {
										handleFormData(value, name);
									}}
									value={formData[field.name]}
									customError={customErrors[field.name]}
									controlled
								/>
							)}
							{field.type === 'date' && (
								<DateInput
									text={field.label}
									name={field.name}
									callback={(value, name) => {
										handleFormData(value.toString(), name);
									}}
									defaultValue={formData[field.name]}
									focused={false}
									dateFormat='P'
								/>
							)}
						</div>
					);
				})}
			<div className='orderItemList'>
				{Object.keys(orderExternalAttachments).length > 0 && <h4>Added external attachments</h4>}
				{orderExternalAttachments &&
					Object.keys(orderExternalAttachments).length > 0 &&
					Object.values(orderExternalAttachments).map((file, key) => {
						return (
							<div className='flexRow' key={key}>
								{file.service?.fields?.map((field, key) => {
									if (file[field.name]) {
										return <h4 key={key}>{dateConverter(file[field?.name])}</h4>;
									} else {
										return <React.Fragment key={key}></React.Fragment>;
									}
								})}
								<h4>{file.caption}</h4>
								<Button
									text='Remove'
									style='secondary'
									size='sm'
									type='button'
									minWidth={164}
									onClick={() => {
										deleteExternalAttachment(file.extFileUuid);
									}}
								/>
							</div>
						);
					})}
			</div>
			{formData?.service && (
				<div className='btnCont borderBottom paddingBottom-20'>
					<Button
						text='Add'
						style='primary'
						size='md'
						type='button'
						minWidth={164}
						onClick={addExternalAttachment}
					/>
				</div>
			)}
		</div>
	);
}

import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

let TutorialContext = React.createContext();

function TutorialContextProvider(props) {
	const location = useLocation();

	let initialState = {
		loading: false,
		reload: false,
		tutorials: {},
		signposting: true,
		lastLocation: location,
	};

	const reducer = (tutorialState, action) => {
		switch (action.type) {
			case 'RESET':
				tutorialState.tutorials[action.payload.page] = action.payload.data;
				Cookies.remove(`tutorial.${action.payload.page}`);
				return {
					...tutorialState,
					loading: false,
					reload: true,
				};
			case 'SET_TUTORIAL_ACTIVE':
				tutorialState.tutorials[action.payload.page] = action.payload.data;
				return {
					...tutorialState,
					loading: false,
					reload: false,
				};
			case 'SET_TUTORIAL_INACTIVE':
				tutorialState.tutorials[action.payload.page] = action.payload.data;
				Cookies.set(`tutorial.${action.payload.page}`, JSON.stringify(action.payload.data), {
					expires: 365 * 5,
				});
				return {
					...tutorialState,
					loading: false,
					reload: false,
				};
			case 'SET_TUTORIAL_PAGE':
				tutorialState.tutorials[action.payload.page].curr = action.payload.order;
				return {
					...tutorialState,
					loading: false,
					reload: false,
				};
			case 'ADD_NEW_PROMPT':
				tutorialState.tutorials[action.payload] = { open: false, prompt: true, curr: 1 };
				return {
					...tutorialState,
					loading: false,
					reload: false,
				};
			case 'START_FIRST_TOUR':
				let data = { open: true, prompt: false, curr: 1 };
				tutorialState.tutorials[action.payload] = data;
				Cookies.set(`tutorial.${action.payload.page}`, JSON.stringify(data), {
					expires: 365 * 5,
				});
				return {
					...tutorialState,
					loading: false,
					reload: false,
				};
			case 'SET_SIGNPOSTING':
				localStorage.setItem('tutorial.dashboard.signposting', action?.payload?.enabled);

				return {
					...tutorialState,
					signposting: action?.payload?.enabled || false,
					loading: false,
					reload: false,
				};
			case 'CLOSE_ALL':
				return {
					...tutorialState,
					loading: false,
					reload: false,
					tutorials: {},
				};
			default:
				return {
					...tutorialState,
				};
		}
	};

	let [tutorialState, tutorialDispatch] = React.useReducer(reducer, initialState);
	let value = { tutorialState, tutorialDispatch };

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			let cookies = Cookies.get();
			for (let cookie in cookies) {
				if (cookie.split('.')[0] === 'tutorial') {
					tutorialState.tutorials[cookie.split('.')[1]] = JSON.parse(cookies[cookie]);
				}
			}
			tutorialState.signposting =
				localStorage.getItem('tutorial.dashboard.signposting') === 'false' ? false : true;
		}
		return () => (mounted = false);
	}, []);

	useEffect(() => {
		let mounted = true;
		if (mounted && location !== tutorialState.lastLocation) {
			tutorialDispatch({ type: 'CLOSE_ALL' });
		}
		return () => (mounted = false);
	}, [location]);

	return <TutorialContext.Provider value={value}>{props.children}</TutorialContext.Provider>;
}

let TutorialContextConsumer = TutorialContext.Consumer;

export { TutorialContext, TutorialContextProvider, TutorialContextConsumer };

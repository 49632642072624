import React, { useContext } from 'react';
import SignpostItem from './SignpostItem';
import { CartBuyIcon, CloseIcon, CustomerIcon, PenFrameIcon } from '../SVGIcons/SVGIcons';
import { useNavigate } from 'react-router';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import { TutorialContext } from '../../contexts/TutorialContext';
import Loading from '../Loading/Loading';

export default function Signposts() {
	const { showModal } = useModalsGlobal();
	const { teamState } = useContext(CurrentTeamContext);
	const { tutorialState, tutorialDispatch } = useContext(TutorialContext);
	let navigate = useNavigate();

	const IS_DEV = process.env.REACT_APP_ENV === 'development-local' || process.env.REACT_APP_ENV === 'development';

	const signpostItems = [];

	const { data, reqLoading } = useFetch(
		`${config.apiv1}/order/orders.read/${teamState?.currentTeam?.teamUuid}?teamType=1&limit=1`
	);

	function constructSignpostItems() {
		const showSigns = {
			QUICK_ORDER: !data || data?.length === 0,
			REGISTER_TEAM: teamState?.userTeams?.length === 1 && teamState?.currentTeam?.isTeamOfOne === true,
			INVITE_SUPPLIERS:
				!teamState?.currentTeam?.trustedSuppliers || teamState?.currentTeam?.trustedSuppliers?.length < 1,
			INVITE_CUSTOMERS:
				Object.keys(teamState?.currentTeam?.trustedBy || {})?.length < 1 && !teamState?.isTeamOfOne,
		};
		if (IS_DEV || showSigns.QUICK_ORDER) {
			signpostItems.push(
				<SignpostItem
					type='purchase'
					title='Quick order'
					paragraph='Place your first order'
					onClick={() => navigate('/order')}
					icon={<CartBuyIcon />}
					key={'quickOrder'}
				/>
			);
		}
		if (IS_DEV || showSigns.REGISTER_TEAM) {
			signpostItems.push(
				<SignpostItem
					type='team'
					title='Register a team'
					paragraph='Create your team'
					onClick={() => navigate('/team-registration')}
					icon={<PenFrameIcon />}
					key={'registerTeam'}
				/>
			);
		}
		if (IS_DEV || showSigns.INVITE_SUPPLIERS) {
			signpostItems.push(
				<SignpostItem
					type='purchase'
					title='Invite suppliers'
					paragraph='Add trusted suppliers'
					onClick={() => showModal('inviteTeam', { invitationType: 1 })}
					icon={<CartBuyIcon />}
					key={'inviteSuppliers'}
				/>
			);
		}
		if (IS_DEV || showSigns.INVITE_CUSTOMERS) {
			signpostItems.push(
				<SignpostItem
					type='sale'
					title='Invite customers'
					paragraph='Add your first customer'
					onClick={() => showModal('inviteTeam', { invitationType: 2 })}
					icon={<CustomerIcon />}
					key={'inviteCustomers'}
				/>
			);
		}
	}

	constructSignpostItems();

	return (
		<>
			{tutorialState.signposting === false || signpostItems?.length === 0 ? (
				<></>
			) : (
				<div className='overviewSection signposts'>
					<div className='signposts'>
						{/* // UPDATE CLOSE ICON, DOWNLOAD NEW */}
						<CloseIcon
							iconClass='signpostCloseIcon'
							onClick={() => {
								showModal('confirmChoice', {
									callback: () => {
										tutorialDispatch({
											type: 'SET_SIGNPOSTING',
											payload: {
												page: 'dashboard',
												enabled: false,
											},
										});
									},
									header: 'Are you sure you want to disable signposting?',
									body: 'To re-enable these again you can do so by clicking "Enable signposting" through the tutorial icon in the bottom right',
								});
							}}
						/>
						{reqLoading ? (
							<Loading type='circle' size='small' />
						) : (
							<div className='signpostsList'>{signpostItems.map((item, key) => item)}</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}

import React from 'react';
import { dateConverter } from '../../../helpers';

export default function BannerSection({ type, data }) {
	return (
		<div className={`bannerSection${type ? ` ${type}` : ''}`}>
			{type === 'draft' && (
				<>
					<h4>Draft</h4>
					<h4>Draft</h4>
				</>
			)}
			{type === 'sent' && (
				<>
					<h4>Unpaid</h4>
					<div className='midSection'>
						<p>sent: {dateConverter(data.sent)}</p>•<p>due: {dateConverter(data.due)}</p>
					</div>
					<h4>Unpaid</h4>
				</>
			)}
			{type === 'paid' && (
				<>
					<h4>Paid</h4>
					<h4>Paid</h4>
				</>
			)}
		</div>
	);
}

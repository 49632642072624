import { createPortal } from 'react-dom';
import { createWrapperAndAppendToBody } from '../helpers';

function ReactPortal({ children, wrapperId }) {
	let element = document.getElementById(wrapperId);
	if (!element) {
		element = createWrapperAndAppendToBody(wrapperId);
	}

	return createPortal(children, element);
}

export default ReactPortal;

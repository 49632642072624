import React, { useRef } from 'react';
import TextInput from '../Inputs/TextInput';
import Button from '../Button';
import {
	ArrowNarrowLeftIcon,
	ArrowNarrowRightIcon,
	CloseIcon,
	PlusIcon,
	TeamPlusIcon,
	UploadIcon,
} from '../SVGIcons/SVGIcons';
import TextareaInput from '../Inputs/TextareaInput';
import CheckboxInput from '../Inputs/CheckboxInput';
import Loading from '../Loading/Loading';
import { replaceSpecialCharacters, toBase64 } from '../../helpers';
import SelectInput from '../Inputs/SelectInput';
import taxOptions from '../Options/taxOptions';
import currencyOptions from '../Options/currencyOptions';
import roleOptions from '../Options/roleOptions';
import { Link } from 'react-router-dom';
import AddressForm from './AddressForm';
import DefaultBanner from '../Banners/DefaultBanner';
import TextSplitInput from '../Inputs/TextSplitInput';
import config from '../../config';
import countryOptions from '../Options/countryOptions';
import langOptions from '../Options/langOptions';

const TeamRegisterForm = React.forwardRef((props, ref) => {
	const {
		slideWidth,
		slideOffset,
		formData,
		address,
		handleInviteSelectData,
		handleInviteData,
		handleAddressInput,
		handleFormData,
		customErrors,
		isNameOK,
		checkNameTaken,
		postTeam,
		isTeamRegistered,
		disableNextBtn,
		handleFormSelectData,
		reqLoading,
		nextPage,
		skippedSteps,
		previousPage,
		handleModal,
		disableNext,
		removeMember,
		regTeamUuid,
		page,
		setSkippedSteps,
		removeSupplier,
		addSupplier,
		addMember,
		setShowBanner,
		showBanner,
	} = props || {};

	const avatarInputRef = useRef(null);
	const pageStart = useRef(null);
	const pageEnd = useRef(null);

	let readOnly = {
		shopUrl: `${config.host?.split('://')[1]}/shop/`,
		teamInitials: 'Abbreviation',
	};

	return (
		<div className='teamRegisterForm'>
			<h3 className='pageHeader'>Create your Team in a few simple steps</h3>
			<div className='pageStart' ref={pageStart}></div>
			<div className='overflowCont'>
				{isTeamRegistered && showBanner && (
					<DefaultBanner
						textThick={`Congratulations, you created the team ${formData?.teamName}!`}
						textThin='Please continue setting it up.'
						callback={() => setShowBanner(false)}
					/>
				)}
				<div
					ref={ref}
					className='innerCont'
					style={{
						transform: `translateX(${slideOffset + slideWidth}px)`,
						transition: 'transform 0.6s ease-in-out',
						maxWidth: '100%',
					}}>
					<div
						className={`formStep${slideOffset !== -slideWidth ? ' overflowYHide' : ''}`}
						style={{
							minWidth: `${slideWidth}px`,
						}}
						id='teamFirstSetup'>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h4>Team name</h4>
									<p className='fieldDesc'>
										Please give your team a name and check it for uniqueness. Click Register Team
										when you are happy with it.
									</p>
									<p className='fieldDesc marginTop-10'>This will be displayed on your profile.</p>
								</div>
								<div className='innerCol'>
									<TextInput
										type='text'
										name={'teamName'}
										defaultValue={formData?.teamName}
										callback={handleFormData}
										customError={customErrors?.teamName}
										disabled={isTeamRegistered}
										required={true}
										onlyInput
									/>
									{isNameOK === true && (
										<>
											<TextSplitInput
												type='text'
												name={'shopUrl'}
												value={formData?.shopUrl}
												customError={customErrors?.shopUrl}
												readOnly={readOnly.shopUrl}
												callback={(value, name) => {
													let normalTextRegex = /^[A-Za-z-]+$/;
													if (normalTextRegex.test(value)) {
														handleFormData(value, name);
													}
												}}
												required={true}
												controlled
												tooltip={
													"This is the direct URL (a 'web link') to your shop. The shop can be used to sell or as a company profile/website. You cannot edit this, but you can change your Team name after the URL is set."
												}
												disabled
												onlyInput
											/>
											<TextSplitInput
												type='text'
												name={'teamInitials'}
												value={formData?.teamInitials}
												callback={handleFormData}
												customError={customErrors?.teamInitials}
												readOnly={readOnly.teamInitials}
												disabled={isTeamRegistered}
												maxLength={3}
												tooltip={
													'If you do not upload a Team avatar we display a small piece of text (usually two or three characters long) ' +
													"in place of the missing avatar. It will help you identify which Team you are using. This small piece of text is known as the 'abbreviation'."
												}
												required={true}
												controlled
												onlyInput
											/>
										</>
									)}
									{isNameOK === false && <p>This team name is already taken</p>}
								</div>
								<div className='innerCol'>
									<div className='btnCont'>
										<Button
											text='Check name'
											size='sm'
											style='primary'
											onClick={checkNameTaken}
											disabled={isTeamRegistered || !formData.teamName}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h4>Register</h4>
									<p className='fieldDesc'>
										By clicking Register Team you agree to Procuur's{' '}
										<Link target='_blank' rel='noopener noreferrer' to={'/terms'}>
											Terms and Conditions
										</Link>
									</p>
								</div>
								<div className='innerCol'>
									<div className='btnCont'>
										{reqLoading ? (
											<Loading type='circle' />
										) : (
											<Button
												iconLeft={<TeamPlusIcon />}
												text='Register team'
												onClick={postTeam}
												style='primary'
												size='lg'
												disabled={!isNameOK || isTeamRegistered}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={`formStep${slideOffset !== -slideWidth * 2 ? ' overflowYHide' : ''}`}
						style={{
							minWidth: `${slideWidth}px`,
						}}
						id='teamFirstSetup'>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h3>Set up your team</h3>
									<p className='fieldDesc'>Make your team recognizable.</p>
								</div>
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h4>Tagline</h4>
									<p className='fieldDesc'>A quick snapshot of your company.</p>
								</div>
								<div className='innerCol'>
									<TextareaInput
										type='text'
										style='new'
										name={'companyDescription'}
										defaultValue={formData?.companyDescription}
										callback={handleFormData}
										customError={customErrors?.companyDescription}
										onlyInput
										short
									/>
								</div>
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h4>Team avatar</h4>
									<input
										type='file'
										name='teamIcon'
										value=''
										onChange={async (e) => {
											handleFormData(await toBase64(e.target.files[0]), 'teamIcon');
										}}
										ref={avatarInputRef}
										hidden
									/>
									<p className='fieldDesc'>
										Choose a team avatar or{' '}
										<span className='underline' onClick={() => avatarInputRef?.current?.click()}>
											upload your own
										</span>
										.
									</p>
								</div>
								{isNameOK && (
									<>
										{formData.teamIcon ? (
											<div className='flexRow'>
												<img
													src={formData.teamIcon}
													className='previewIcon'
													alt='Team icon image'
												/>
												<Button
													style='secondary'
													size='sm'
													text='Remove'
													onClick={() => {
														handleFormData(null, 'teamIcon');
													}}
												/>
											</div>
										) : (
											<div className='avatarRow'>
												<div
													className={`teamChoicesIcon purple${
														formData.avatarColor === 'purple' ? ' selected' : ''
													}`}
													onClick={() => handleFormData('purple', 'avatarColor')}>
													{formData.teamInitials?.slice(0, 2)}
												</div>
												<div
													className={`teamChoicesIcon pink${
														formData.avatarColor === 'pink' ? ' selected' : ''
													}`}
													onClick={() => handleFormData('pink', 'avatarColor')}>
													{formData.teamInitials?.slice(0, 2)}
												</div>
												<div
													className={`teamChoicesIcon green${
														formData.avatarColor === 'green' ? ' selected' : ''
													}`}
													onClick={() => handleFormData('green', 'avatarColor')}>
													{formData.teamInitials?.slice(0, 2)}
												</div>
												<div
													className={`teamChoicesIcon blue${
														formData.avatarColor === 'blue' ? ' selected' : ''
													}`}
													onClick={() => handleFormData('blue', 'avatarColor')}>
													{formData.teamInitials?.slice(0, 2)}
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h4>Language</h4>
									<p className='fieldDesc'>
										Choose which is the main language spoken within your company
									</p>
								</div>
								<div className='innerCol'>
									<SelectInput
										htmlOptions={langOptions}
										callback={handleFormSelectData}
										value={formData.spokenLanguage}
										name={'spokenLanguage'}
										required
									/>
								</div>
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='flexCol'>
									<div className='checkRow'>
										<p>Is your Team a registered company?</p>
										<CheckboxInput
											customError={customErrors?.isCompany}
											checked={formData.isCompany}
											callback={handleFormData}
											name='isCompany'
											onlyInput
										/>
									</div>
									<div className={`checkRow${formData.isCompany ? '' : ' disabledDiv'}`}>
										<p>Will you allow collection?</p>
										<CheckboxInput
											customError={customErrors?.acceptCollect}
											checked={formData.acceptCollect}
											callback={handleFormData}
											name='acceptCollect'
											onlyInput
										/>
									</div>
									<div className='checkRow'>
										<p>Do you aim to sell in Procuur?</p>
										<CheckboxInput
											customError={customErrors?.isSeller}
											checked={formData.isSeller}
											callback={handleFormData}
											name='isSeller'
											onlyInput
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					{formData.isCompany && (
						<div
							className={`formStep${
								!formData.isCompany || slideOffset !== -slideWidth * 3 ? ' overflowYHide' : ''
							}`}
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='stepHeader'>
										<h3>Company information</h3>
										<p>Name your team, add a logo and provide information about your company</p>
									</div>
								</div>
							</div>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Registered company details</h4>
									</div>
									<div className='innerCol'>
										<TextInput
											type='text'
											text='Company name'
											name={'companyName'}
											defaultValue={formData?.companyName}
											callback={handleFormData}
											customError={customErrors?.companyName}
											required={true}
										/>
										<TextInput
											type='text'
											text='Company no.'
											name={'companyNumber'}
											defaultValue={formData?.companyNumber}
											callback={handleFormData}
											customError={customErrors?.companyNumber}
											required={true}
										/>
										<TextInput
											type='text'
											text='MHRA number'
											name={'mhra_registration_number'}
											defaultValue={formData?.mhra_registration_number}
											callback={handleFormData}
											customError={customErrors?.mhra_registration_number}
										/>
										<SelectInput
											htmlOptions={taxOptions}
											callback={handleFormSelectData}
											value={formData.tax}
											name={'tax'}
											text={'Tax jurisdiction'}
											required
										/>
										<SelectInput
											htmlOptions={currencyOptions}
											callback={handleFormSelectData}
											value={formData.currency}
											name={'currency'}
											text={'Currency'}
											required
										/>
										<SelectInput
											htmlOptions={countryOptions}
											callback={(value, name) => {
												handleFormSelectData(value, name);
												handleAddressInput(value?.value, 'country');
											}}
											value={formData.companyCountry}
											name={'companyCountry'}
											text={'Country of registration'}
											required
										/>
									</div>
								</div>
							</div>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Company address</h4>
										<p>Enter your registered company address</p>
									</div>
									<div className='innerCol'>
										<TextInput
											text={'Name'}
											name={'name'}
											callback={handleAddressInput}
											defaultValue={address?.name}
											required
										/>
										<TextInput
											text={'Address line 1'}
											name={'addressLine1'}
											callback={handleAddressInput}
											defaultValue={address?.addressLine1}
											required
										/>
										<TextInput
											text={'Address line 2 (optional)'}
											name={'addressLine2'}
											callback={handleAddressInput}
											defaultValue={address?.addressLine2}
										/>
										<TextInput
											text={'Address line 3 (optional)'}
											name={'addressLine3'}
											callback={handleAddressInput}
											defaultValue={address?.addressLine3}
										/>
										<TextInput
											text={'City'}
											name={'city'}
											callback={handleAddressInput}
											defaultValue={address?.city}
											required
										/>
										<TextInput
											text={'Postcode'}
											name={'postalCode'}
											callback={handleAddressInput}
											defaultValue={address?.postalCode}
											required
										/>
										<TextInput
											text={'County / State / Province'}
											name={'county'}
											callback={handleAddressInput}
											defaultValue={address?.county}
											required
										/>
										<SelectInput
											htmlOptions={countryOptions}
											callback={(value, name) => {
												handleAddressInput(value?.value, name);
											}}
											value={address?.country}
											name={'country'}
											text={'Country'}
											required
										/>
										{!formData.acceptCollect && (
											<TextareaInput
												type='text'
												style='new'
												text='Delivery instructions'
												name={'instructions'}
												defaultValue={address?.instructions}
												callback={handleAddressInput}
												customError={customErrors?.instructions}
												short
											/>
										)}
									</div>
								</div>
							</div>
							{formData.acceptCollect && (
								<div className='formSection'>
									<div className='sectionRow'>
										<div className='innerCol'>
											<h4>Collection</h4>
										</div>
										<div className='innerCol'>
											<>
												<div className='checkRow'>
													<p>Your address for collection is different to the above?</p>
													<CheckboxInput
														customError={customErrors?.notSameAsRegAddr}
														checked={formData.notSameAsRegAddr}
														callback={handleFormData}
														name='notSameAsRegAddr'
														onlyInput
														required
													/>
												</div>
												{formData.notSameAsRegAddr && (
													<>
														<h4 className='marginTop-20 marginBottom-20'>
															Enter your collection address below
														</h4>
														<>
															<TextInput
																text={'Address line 1'}
																name={'collectionAddressLine1'}
																callback={handleAddressInput}
																defaultValue={address?.collectionAddressLine1}
															/>
															<TextInput
																text={'Address line 2 (optional)'}
																name={'collectionAddressLine2'}
																callback={handleAddressInput}
																defaultValue={address?.collectionAddressLine2}
															/>
															<TextInput
																text={'Address line 3 (optional)'}
																name={'collectionAddressLine3'}
																callback={handleAddressInput}
																defaultValue={address?.collectionAddressLine3}
															/>
															<TextInput
																text={'City'}
																name={'collectionCity'}
																callback={handleAddressInput}
																defaultValue={address?.collectionCity}
															/>
															<TextInput
																text={'Postcode'}
																name={'collectionPostalCode'}
																callback={handleAddressInput}
																defaultValue={address?.collectionPostalCode}
															/>
															<TextInput
																text={'County / State / Province'}
																name={'collectionCounty'}
																callback={handleAddressInput}
																defaultValue={address?.collectionCounty}
															/>
															<SelectInput
																htmlOptions={countryOptions}
																callback={(value, name) => {
																	handleAddressInput(value?.value, name);
																}}
																value={address?.collectionCountry}
																name={'collectionCountry'}
																text={'Country'}
																required
															/>
														</>
													</>
												)}
											</>
											<TextInput
												text={'Collection hours'}
												name={'collectionHours'}
												callback={handleAddressInput}
												defaultValue={address?.collectionHours}
											/>
											<TextInput
												text={'Collection point'}
												name={'collectionPoint'}
												callback={handleAddressInput}
												defaultValue={address?.collectionPoint}
											/>
											<TextareaInput
												type='text'
												style='new'
												text='Collection instructions'
												name={'collectionInstructions'}
												defaultValue={address?.collectionInstructions}
												callback={handleAddressInput}
												customError={customErrors?.collectionInstructions}
												short
											/>
										</div>
									</div>
								</div>
							)}
						</div>
					)}
					{formData.isCompany && (
						<div
							className={`formStep${slideOffset !== -slideWidth * 4 ? ' overflowYHide' : ''}`}
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='stepHeader'>
										<h3>Company information (continued)</h3>
										<p>Name your team, add a logo and provide information about your company</p>
									</div>
								</div>
							</div>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Contact details</h4>
									</div>
									<div className='innerCol'>
										<TextInput
											text={'Phone number'}
											name={'phoneNumber'}
											callback={handleFormData}
											defaultValue={formData.phoneNumber}
											type='phone'
											required
										/>
										<TextInput
											text={'Email'}
											name={'businessEmail'}
											callback={handleFormData}
											defaultValue={formData.businessEmail}
											type='email'
										/>
										<TextInput
											text={'Website'}
											name={'websiteUrl'}
											callback={handleFormData}
											defaultValue={formData.websiteUrl}
											type='text'
										/>
									</div>
								</div>
							</div>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='flexCol'>
										<div className='checkRow'>
											<p>Do you wish to make your Team public?</p>
											<CheckboxInput
												customError={customErrors?.isPublic}
												checked={formData.isPublic}
												callback={handleFormData}
												name='isPublic'
												onlyInput
												required
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='formSection'>
								<div className='sectionRow'>
									<div className='innerCol'>
										<h4>Company logo</h4>
										<p className='fieldDesc'>
											Update your company logo and then choose where you want it to display.
										</p>
									</div>
									{formData.teamPicture?.file ? (
										<>
											<img
												className='teamPicture'
												src={formData.teamPicture.base64}
												alt='Company logo'
											/>
											<Button
												size='md'
												style='secondary'
												text='Re-upload logo'
												iconLeft={<UploadIcon />}
												onClick={() => {
													handleModal('uploadModal', { open: true, name: 'teamPicture' });
												}}
											/>
										</>
									) : (
										<Button
											size='md'
											style='primary'
											text='Upload logo'
											iconLeft={<UploadIcon />}
											onClick={() => {
												handleModal('uploadModal', { open: true, name: 'teamPicture' });
											}}
										/>
									)}
								</div>
							</div>
						</div>
					)}
					<div
						id='inviteMembers'
						className={`formStep${
							slideOffset !== -slideWidth * 3 && slideOffset !== -slideWidth * 5 ? ' overflowYHide' : ''
						}`}
						style={{
							minWidth: `${slideWidth}px`,
						}}>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='stepHeader'>
									<h3>Invite your team members</h3>
									<p>This can be done later by pressing 'Invite' at the very top of all pages.</p>
								</div>
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h4>Enter name and email address of your team members</h4>
								</div>
								<div className='wideCol'>
									{formData.memberInvites &&
										Object.keys(formData.memberInvites)?.map((invite, key) => {
											return (
												<div className='sectionRow' key={key}>
													<div className='innerCol'>
														<TextInput
															text={'Name'}
															name={'externalName'}
															callback={(e) =>
																handleInviteData(
																	'memberInvites',
																	e,
																	invite,
																	'externalName'
																)
															}
															defaultValue={formData.memberInvites[invite]?.externalName}
															type='text'
															required
														/>
													</div>
													<div className='innerCol'>
														<TextInput
															text={'Email'}
															name={'externalEmail'}
															callback={(e) =>
																handleInviteData(
																	'memberInvites',
																	e,
																	invite,
																	'externalEmail'
																)
															}
															defaultValue={formData.memberInvites[invite]?.externalEmail}
															type='email'
															required
														/>
													</div>
													<div className='innerCol'>
														<SelectInput
															htmlOptions={roleOptions}
															callback={(e) =>
																handleInviteSelectData(
																	'memberInvites',
																	e,
																	invite,
																	'role'
																)
															}
															value={formData.memberInvites[invite]?.role}
															name={'role'}
															text={'Role'}
															required
														/>
													</div>
													<div className='thinCol'>
														{key !== 0 && (
															<div
																className='iconCont'
																onClick={() => removeMember(invite)}>
																<CloseIcon />
															</div>
														)}
													</div>
												</div>
											);
										})}
								</div>
							</div>
							<div className='addBtn'>
								<Button
									style='tertiary-secondary'
									size='md'
									text='Add another'
									iconLeft={<PlusIcon />}
									onClick={addMember}
								/>
							</div>
						</div>
					</div>
					<div
						id='inviteSuppliers'
						className={`formStep${
							slideOffset !== -slideWidth * 4 && slideOffset !== -slideWidth * 6 ? ' overflowYHide' : ''
						}`}
						style={{
							minWidth: `${slideWidth}px`,
						}}>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='stepHeader'>
									<h3>Invite your suppliers</h3>
									<p>This can be done later by pressing 'Invite' at the very top of all pages.</p>
								</div>
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									<h4>Enter name and email address of your suppliers</h4>
								</div>
								<div className='wideCol'>
									{formData.supplierInvites &&
										Object.keys(formData.supplierInvites).map((invite, key) => {
											return (
												<div className='sectionRow' key={key}>
													<div className='innerCol'>
														<TextInput
															text={'Name'}
															name={'externalName'}
															callback={(e) =>
																handleInviteData(
																	'supplierInvites',
																	e,
																	invite,
																	'externalName'
																)
															}
															defaultValue={
																formData.supplierInvites[invite]?.externalName
															}
															type='text'
															required
														/>
													</div>
													<div className='innerCol'>
														<TextInput
															text={'Email'}
															name={'externalEmail'}
															callback={(e) =>
																handleInviteData(
																	'supplierInvites',
																	e,
																	invite,
																	'externalEmail'
																)
															}
															defaultValue={
																formData.supplierInvites[invite]?.externalEmail
															}
															type='email'
															required
														/>
													</div>
													<div className='thinCol'>
														{key !== 0 && (
															<div
																className='iconCont'
																onClick={() => removeSupplier(invite)}>
																<CloseIcon />
															</div>
														)}
													</div>
												</div>
											);
										})}
								</div>
							</div>
							<div className='addBtn'>
								<Button
									style='tertiary-secondary'
									size='md'
									text='Add another'
									iconLeft={<PlusIcon />}
									onClick={() => {
										addSupplier();
									}}
								/>
							</div>
						</div>
					</div>

					<div
						className={`formStep${
							slideOffset !== -slideWidth * 5 && slideOffset !== -slideWidth * 7 ? ' overflowYHide' : ''
						}`}
						style={{
							minWidth: `${slideWidth}px`,
						}}>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='stepHeader'>
									<h2>All done!</h2>
								</div>
							</div>
						</div>
						<div className='formSection'>
							<div className='sectionRow'>
								<div className='innerCol'>
									{formData.isPublic === true && formData.isSeller === true && (
										<Link className='linkText pointer' to={`/suppliers/${regTeamUuid}`}>
											Visit your shop
										</Link>
									)}
									<Link className='linkText pointer' to={`/manage/members`}>
										Manage your team
									</Link>
									<Link className='linkText pointer' to={`/manage/apps`}>
										Add apps
									</Link>
									<Link className='linkText pointer' to={`/`}>
										Go to your dashboard
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				{!disableNext && isTeamRegistered && (
					<div className='formNav'>
						{slideOffset < -slideWidth ? (
							<Button
								text='Go back'
								size='md'
								style='tertiary-primary'
								iconLeft={<ArrowNarrowLeftIcon />}
								onClick={previousPage}
							/>
						) : (
							<div className='filler'></div>
						)}
						<div className='flexRow'>
							{page === 'inviteMembers' || page === 'inviteSuppliers' ? (
								<Button
									text='Skip'
									size='md'
									style='secondary'
									iconRight={<ArrowNarrowRightIcon />}
									onClick={() => {
										setSkippedSteps({ ...skippedSteps, [page]: true });
										nextPage(false, { ...skippedSteps, [page]: true });
									}}
									disabled={!isNameOK || !isTeamRegistered}
								/>
							) : (
								<></>
							)}
							<Button
								text='Next'
								size='md'
								style='primary'
								iconRight={<ArrowNarrowRightIcon />}
								onClick={nextPage}
								disabled={!isNameOK || !isTeamRegistered || disableNextBtn}
							/>
						</div>
						<div className='filler'></div>
					</div>
				)}
				<div className='pageEnd' ref={pageEnd}></div>
			</div>
		</div>
	);
});

export default TeamRegisterForm;

import React from 'react';

export default function Overlay(props) {
	const { loadOverlay, showOverlay, setShowOverlay, zIndex, dim } = props || {};
	return (
		<>
			{loadOverlay ? (
				<div
					className={showOverlay ? 'overlay fadeIn' : 'overlay fadeOut'}
					style={{ zIndex: zIndex ? zIndex - 1 : 98 }}
					onClick={() => {
						setShowOverlay ? setShowOverlay(false) : null;
					}}></div>
			) : (
				<></>
			)}
		</>
	);
}

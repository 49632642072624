import React, { useEffect, useState } from 'react';
import { timeDifference } from '../../helpers';
import { ClockStopwatchIcon } from '../SVGIcons/SVGIcons';
import { Tooltip } from 'react-tooltip';

export default function StopwatchCont({ pack }) {
	const [countdownTimer, setCountdownTimer] = useState('');

	function millisecondsUntilNextMinute(date) {
		const seconds = date.getSeconds();
		const milliseconds = date.getMilliseconds();

		const millisecondsLeft = (60 - seconds) * 1000 - milliseconds;

		return millisecondsLeft;
	}

	useEffect(() => {
		let mounted = true;
		let intervalId;
		let minute = 60000;
		let timeUntilFullMinute = millisecondsUntilNextMinute(new Date());
		if (mounted && pack) {
			setCountdownTimer(timeDifference(new Date(), new Date(pack?.quotedCollectionDateTime)));
			setTimeout(() => {
				setCountdownTimer(timeDifference(new Date(), new Date(pack?.quotedCollectionDateTime)));
				intervalId = setInterval(() => {
					setCountdownTimer(timeDifference(new Date(), new Date(pack?.quotedCollectionDateTime)));
				}, minute);
			}, timeUntilFullMinute);
		}

		return () => {
			mounted = false;
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, []);
	return (
		<div
			className='timestampCont'
			data-tooltip-id={`stopwatch_${pack?.packageUuid}`}
			data-tooltip-content='Time until the package will be dispatched (days:hours:minutes)'
			data-tooltip-delay-show={500}>
			<ClockStopwatchIcon iconClass='stopwatchIcon' />
			<h2>{countdownTimer}</h2>
			<Tooltip id={`stopwatch_${pack?.packageUuid}`} />
		</div>
	);
}

import React, { useState } from 'react';
import SupportDropdown from '../Dropdowns/SupportDropdown';
import { QuestionIcon } from '../SVGIcons/SVGIcons';

export default function SupportIcon({ spaceBottom }) {
	const [open, setOpen] = useState(false);
	return (
		<>
			{open && <SupportDropdown setOpen={setOpen} spaceBottom={spaceBottom} />}
			<QuestionIcon
				iconClass={`supportIconFixed${spaceBottom ? ' spaceBottom' : ''}`}
				onClick={() => {
					setOpen(!open);
				}}
			/>
		</>
	);
}

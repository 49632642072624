import React, { useEffect, useRef, useState } from 'react';
import { DocketTemplateIcon, LibraryIcon, PlusIcon } from '../../../components/SVGIcons/SVGIcons';
import useCompVis from '../../../hooks/useCompVis';
import TemplateItem from './TemplateItem';
import EmptyState from '../../../components/EmptyState/EmptyState';
import TemplateItemExt from './TemplateItemExt';

export default function Library(props) {
	const {
		handleChosenDocket,
		handleDocketData,
		handleDropdown,
		externalTemps,
		dropdownOpen,
		setIsEditing,
		deleteDocket,
		patchDocket,
		handleModal,
		postDocket,
		teamTemps,
		setColor,
		color,
	} = props || {};

	const [compVis, setCompVis] = useState('');
	const ref = useRef(null);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target) && compVis) {
			setCompVis(false);
			cb();
		}
	};

	function handleDropVis(docketUuid) {
		if (compVis !== docketUuid) {
			setCompVis(docketUuid);
		} else {
			setCompVis('');
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return (
		<>
			<div className='libraryCont'>
				<div className='libraryHeader'>
					<h3>
						<LibraryIcon iconClass='libraryIcon' />
						Library
					</h3>
					<p className='headerDesc'>
						Build your own Custom Order Form. <a href='/tutorials/en/getting-started'>Learn more</a>
					</p>
				</div>
				<div className='librarySection'>
					<div className='libraryRow'>
						<div className='rowCol'>
							<h4>Team Order Forms</h4>
							<p className='tempDesc'>
								A Custom Order Form is a customized form that is added into the order process. It allows
								you to gather specific information required. Learn more about Custom Order Forms.
							</p>
						</div>
						<div className='rowItems' ref={ref}>
							{teamTemps ? (
								teamTemps
									.filter((temp) => temp.docketType === 1)
									?.map((temp, key) => (
										<TemplateItem
											handleChosenDocket={handleChosenDocket}
											handleDocketData={handleDocketData}
											handleDropdown={handleDropdown}
											handleDropVis={handleDropVis}
											setIsEditing={setIsEditing}
											dropdownOpen={dropdownOpen}
											deleteDocket={deleteDocket}
											patchDocket={patchDocket}
											handleModal={handleModal}
											postDocket={postDocket}
											setColor={setColor}
											compVis={compVis}
											template={temp}
											color={color}
											key={key}
										/>
									))
							) : (
								<>No templates</>
							)}
							<div className='templateItem' style={{ cursor: 'pointer' }}>
								<PlusIcon
									iconClass='addIcon'
									onClick={() => {
										postDocket({
											name: `Form-New#${teamTemps.length + 1}`,
											docketType: 1,
											default: false,
											published: false,
											fields: [],
										});
									}}
								/>
							</div>
						</div>
					</div>
					<div className='libraryRow'>
						<div className='rowCol'>
							<h4>Team Change Requests</h4>
							<p className='tempDesc'>
								A Change Request Form is a form that can be presented from within an active order when
								you want to ask questions pertaining to a change in the order.
							</p>
						</div>
						<div className='rowItems' ref={ref}>
							{teamTemps ? (
								teamTemps
									.filter((temp) => temp.docketType === 2)
									?.map((temp, key) => (
										<TemplateItem
											handleChosenDocket={handleChosenDocket}
											handleDocketData={handleDocketData}
											handleDropdown={handleDropdown}
											handleDropVis={handleDropVis}
											setIsEditing={setIsEditing}
											dropdownOpen={dropdownOpen}
											deleteDocket={deleteDocket}
											patchDocket={patchDocket}
											handleModal={handleModal}
											postDocket={postDocket}
											setColor={setColor}
											compVis={compVis}
											template={temp}
											color={color}
											key={key}
										/>
									))
							) : (
								<EmptyState header='No templates found' thin />
							)}
							<div className='templateItem' style={{ cursor: 'pointer' }}>
								<PlusIcon
									iconClass='addIcon'
									onClick={() => {
										postDocket({
											name: `Form-New#${teamTemps.length + 1}`,
											docketType: 2,
											default: false,
											published: false,
											fields: [],
										});
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='libraryHeader small'>
					<h3>
						<DocketTemplateIcon iconClass='libraryIcon' />
						Templates
					</h3>
				</div>
				<div className='libraryRow'>
					<div className='rowCol'>
						<h4>Transactor</h4>
						<p className='tempDesc'>
							Transactor Systems have supplied these Lab Dockets for you to use. You can either use them
							as is or customize them to your specific requirements. To use one click on it.
						</p>
					</div>
					{externalTemps ? (
						externalTemps.map((temp, key) => (
							<TemplateItemExt teamTemps={teamTemps} postDocket={postDocket} template={temp} key={key} />
						))
					) : (
						<EmptyState header='No templates found' thin />
					)}
				</div>
			</div>
		</>
	);
}

import React from 'react';
import TextareaInput from '../Inputs/TextareaInput';

export default function FeedbackForm(props) {
	const { responseMsg, callback, feedback } = props || {};

	return (
		<div className='invitationContainer' id='feedbackForm'>
			<h2>Feedback</h2>
			<h4>Send us feedback to our support team with the form below</h4>
			{responseMsg ? <h4>{responseMsg}</h4> : null}
			<form>
				<TextareaInput name={'feedbackBody'} callback={callback} defaultValue={feedback} required />
			</form>
		</div>
	);
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import authConfig from './authConfig.json';
import { BrowserRouter } from 'react-router-dom';
import { CurrentTeamContextProvider } from './contexts/CurrentTeamContext';
import { CurrentUserContextProvider } from './contexts/CurrentUserContext';
import { RightSidebarContextProvider } from './contexts/RightSidebarContext';
import { LeftSidebarContextProvider } from './contexts/LeftSidebarContext';
import { TutorialContextProvider } from './contexts/TutorialContext';
import { BotLeftModalContextProvider } from './contexts/BotLeftModalContext';
import './i18n';

ReactDOM.render(
	<BrowserRouter>
		<CurrentUserContextProvider
			clientId={authConfig.clientId}
			audience={authConfig.audience}
			redirectUri={window.location.origin}
			scope={'openid profile email offline_access'}>
			<CurrentTeamContextProvider>
				<TutorialContextProvider>
					<RightSidebarContextProvider>
						<LeftSidebarContextProvider>
							<BotLeftModalContextProvider>
								<App />
							</BotLeftModalContextProvider>
						</LeftSidebarContextProvider>
					</RightSidebarContextProvider>
				</TutorialContextProvider>
			</CurrentTeamContextProvider>
		</CurrentUserContextProvider>
	</BrowserRouter>,
	document.getElementById('root')
);

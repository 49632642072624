import React, { useContext, useEffect, useState } from 'react';
import ManageAppForm from '../../components/Forms/ManageAppForm';
import useForm from '../../hooks/useForm';
import usePost from '../../hooks/usePost';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import useModals from '../../hooks/useModals';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import useFetch from '../../hooks/useFetch';
import { useLocation, useNavigate, useParams } from 'react-router';
import { makeFirstCharUpper } from '../../helpers';
import Button from '../../components/Button';
import Axios from 'axios';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

export default function TeamAppManage() {
	const { teamState, teamDispatch } = useContext(CurrentTeamContext);

	let filter = [
		'title',
		'first_name',
		'middle_name',
		'last_name',
		'date_of_birth',
		'email_address',
		'nhs_number',
		'ni_number',
		'address_line_1',
		'address_line_2',
		'address_line_3',
		'town',
		'county',
		'postcode',
		'home_phone',
		'mobile_phone',
		'work_phone',
	];

	let location = useLocation();
	let appName = new URLSearchParams(location.search).get('appName');
	let fetchSuccess = new URLSearchParams(location.search).get('fetchSuccess');

	let integrationUuid = teamState.currentTeam?.integrations[appName];

	let navigate = useNavigate();
	const { modalsState, flashFadeModal, handleModal } = useModals();
	const { formData, handleFormInput, handleFormSelect, setFormData } = useForm(null, true);
	const { data, fetch } = useFetch(`${config.apiv1}/integration/integration.read/${integrationUuid}`);
	const { post } = usePost(
		`${config.apiv1}/integration/integration.update/${integrationUuid}`,
		handleUpdateRes,
		'',
		'PATCH'
	);

	const [checkAll, setCheckAll] = useState(false);

	function handleUpdateRes(res) {
		if (res.ok) {
			flashFadeModal('Integration options updated successfully');
		} else {
			flashFadeModal('There was an error while updating integration');
		}
	}

	function toggleAllCheck() {
		handleFormInput(!checkAll, 'title');
		handleFormInput(!checkAll, 'first_name');
		handleFormInput(!checkAll, 'middle_name');
		handleFormInput(!checkAll, 'last_name');
		handleFormInput(!checkAll, 'date_of_birth');
		handleFormInput(!checkAll, 'email_address');
		handleFormInput(!checkAll, 'nhs_number');
		handleFormInput(!checkAll, 'ni_number');
		handleFormInput(!checkAll, 'address_line_1');
		handleFormInput(!checkAll, 'address_line_2');
		handleFormInput(!checkAll, 'address_line_3');
		handleFormInput(!checkAll, 'town');
		handleFormInput(!checkAll, 'county');
		handleFormInput(!checkAll, 'postcode');
		handleFormInput(!checkAll, 'home_phone');
		handleFormInput(!checkAll, 'mobile_phone');
		handleFormInput(!checkAll, 'work_phone');
		setCheckAll(!checkAll);
	}

	function uninstallApp() {
		handleModal('deleteModal', {
			open: true,
			header: 'Are you sure you want to uninstall this app?',
			callback: () => {
				uninstall();
			},
		});

		async function uninstall() {
			let res = await Axios({
				url: `${config.apiv1}/integration/integration.delete/${integrationUuid}`,
				method: 'DELETE',
				data: {
					teamUuid: teamState.currentTeam.teamUuid,
				},
			});
			if (res.data?.ok) {
				navigate('/manage/apps');
				teamDispatch({ type: 'RE-FETCH_TEAM' });
			} else {
				handleModal('deleteModal', {
					open: false,
				});
				flashFadeModal('There was an error uninstalling the app');
			}
		}
	}

	async function refreshToken() {
		const lastState = uuidv4();
		Cookies.set(`lastState`, lastState, { expires: 0.2 });

		//!TEMP
		Cookies.set(`savedSecret`, data.clientSecret, { expires: 1 });
		Cookies.set(`savedID`, data.clientID, { expires: 1 });
		//!TEMP

		let newUrl = new URL(
			`${config.dentallyAPI}/oauth/authorize?` +
				`redirect_uri=${config.host}/redirects/dentally&scope=appointment patient:read ` +
				`patient:update user:read&response_type=code`
		);
		newUrl.searchParams.set('state', lastState);

		//!TEMP
		if (data.clientID) {
			newUrl.searchParams.set('client_id', data.clientID);
		}
		//!TEMP

		window.location.href = newUrl.href;
	}

	useEffect(() => {
		if (data?.settings && Object.keys(data?.settings)?.length > 0) {
			handleFormInput(data?.settings?.hintFormat, 'hintFormat');
			for (let field in data?.settings) {
				if (filter.includes(`{{${field}}}`) && data.settings[field]) {
					return setCheckAll(true);
				}
			}
		}
	}, [data]);

	useEffect(() => {
		if (fetchSuccess === 'true') {
			flashFadeModal('New dentally token fetched successfully!');
		} else if (fetchSuccess === 'false') {
			flashFadeModal('Error when trying to fetch new token, please reinstall integration');
		}
	}, []);

	return (
		<div className='pageInnerCont white'>
			<div className='teamAppsCont'>
				<FadeOutModal
					show={modalsState.modalFading?.show}
					isFading={modalsState.modalFading?.isFading}
					message={modalsState.modalFading?.message}
				/>
				{modalsState.deleteModal?.open && (
					<>
						<ConfirmModal
							value={modalsState.deleteModal.value}
							header={modalsState.deleteModal.header}
							callback={modalsState.deleteModal.callback}
							handleModal={handleModal}
							modalType={'deleteModal'}
							zIndex={999}
						/>
					</>
				)}
				<div className='appManageCont'>
					{appName === 'dentally' && (
						<>
							<h2>{makeFirstCharUpper(appName)} Options</h2>
							<div className='marginBottom-20'>
								<Button
									text='Refresh token'
									size='sm'
									style='secondary'
									onClick={() => {
										refreshToken();
									}}
									minWidth={135}
								/>
							</div>
							<div className='marginBottom-20'>
								<Button
									text='Uninstall'
									size='sm'
									style='secondary'
									onClick={() => {
										uninstallApp();
									}}
									minWidth={135}
								/>
							</div>
							<ManageAppForm
								handleFormInput={handleFormInput}
								toggleAllCheck={toggleAllCheck}
								formData={formData}
								setFormData={setFormData}
								checkAll={checkAll}
								integration={data}
								suggestions={filter}
								appName={appName}
							/>
							<button
								className='btn3-primary marginTop-40'
								onClick={() => {
									post({ settings: formData });
								}}>
								Save changes
							</button>
						</>
					)}
					{appName === 'labmanager' && (
						<>
							<h2>{makeFirstCharUpper(appName)} Options</h2>
							<div className='marginBottom-20'>
								<Button
									text='Uninstall'
									size='sm'
									style='secondary'
									onClick={() => {
										uninstallApp();
									}}
									minWidth={135}
								/>
							</div>
							<ManageAppForm
								handleFormInput={handleFormInput}
								handleFormSelect={handleFormSelect}
								toggleAllCheck={toggleAllCheck}
								formData={formData}
								checkAll={checkAll}
								post={post}
								reFetchIntegration={fetch}
								integration={data}
								setFormData={setFormData}
								suggestions={filter}
								appName={appName}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';
import {
	ArrowNarrowRightIcon,
	GotoDiscBlack40Icon,
	Learning1Icon,
	Learning2Icon,
	Learning3Icon,
	Learning4Icon,
} from '../../SVGIcons/SVGIcons';
import useFetch from '../../../hooks/useFetch';
import Loading from '../../Loading/Loading';
import LearningZoneSkeleton from '../carouselSkeletons/LearningZoneSkeleton';
import { calcSkels, sliceString } from '../../../helpers';

export default function LearningZoneCarousel(props) {
	const { settings, currOffset, carouselWidth, setTotalWidth } = props || {};

	const [skeletons, setSkeletons] = useState(0);

	const { data, reqLoading, error, fetch, setCustomUrl } = useFetch(
		`${config.apiv1}/tutorial/listing.read?featured=1&lang=en`
	);

	let { userState } = useContext(CurrentUserContext);

	useEffect(() => {
		let mounted = true;
		if (mounted && data) {
			setTotalWidth(-(data.length / 4) * settings.max.carouselWidth);
			setSkeletons(() => calcSkels(carouselWidth, data, settings, <LearningZoneSkeleton />));
		}
		return () => (mounted = false);
	}, [settings, userState, data]);

	let learningIcons = [
		<Learning1Icon iconClass='learningIcon' />,
		<Learning2Icon iconClass='learningIcon' />,
		<Learning3Icon iconClass='learningIcon' />,
		<Learning4Icon iconClass='learningIcon' />,
	];

	return (
		<div
			className='learningZoneCont'
			style={{
				transform: `translateX(${currOffset + carouselWidth}px)`,
				transition: '0.6s ease-in-out',
				width: `${carouselWidth}px`,
			}}>
			{Array.isArray(data) && !reqLoading ? (
				<>
					{data.map((learningCard, key) => {
						if (learningCard) {
							return (
								<React.Fragment key={key}>
									<Link to={`/tutorials/en/${learningCard.uri}`}>
										<div className='learningCard'>
											<div className='cardImg'>{learningIcons[key <= 3 ? key : key % 4]}</div>
											<div className='cardFooter'>
												<h4>
													{learningCard.title.charAt(0).toUpperCase() +
														learningCard.title.slice(1)}
												</h4>
												<p>{sliceString(learningCard?.synopsis, 0, 75, true)}</p>
												<h4 className='linkText'>
													Read Post <ArrowNarrowRightIcon />
												</h4>
											</div>
										</div>
									</Link>
								</React.Fragment>
							);
						} else {
							return <React.Fragment key={key}></React.Fragment>;
						}
					})}
					{skeletons.map((skeleton, key) => {
						return <React.Fragment key={key}>{skeleton}</React.Fragment>;
					})}
				</>
			) : (
				<Loading type='circle' />
			)}
		</div>
	);
}

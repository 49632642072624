import React from 'react';
import { parseToOptions } from '../../helpers';
import SelectInput from '../Inputs/SelectInput';

export default function AddToPackForm(props) {
	const { handleSelectData, chosenPack, packages } = props || {};
	return (
		<>
			<h2>Add order item to package</h2>
			<SelectInput
				options={parseToOptions(Object.values(packages), 'packages')}
				callback={handleSelectData}
				value={chosenPack}
				text={'Package'}
				name={'chosenPackage'}
			/>
		</>
	);
}

import React from 'react';
import OrderComments from './GridItems/OrderComments';
import OrderFiles from './GridItems/OrderFiles';
import OrderFinancial from './GridItems/OrderFinancial';
import OrderItems from './GridItems/OrderItems';
import OrderLMInfo from './GridItems/OrderLMInfo';
import OrderProgress from './GridItems/OrderProgress';
import OrderReferences from './GridItems/OrderReferences';
import OrderSuborders from './GridItems/OrderSuborders';
import OrderTracking from './GridItems/OrderTracking';
import OrderDtlyInfo from './GridItems/OrderDtlyInfo';
import OrderExternalAttachments from './GridItems/OrderExternalAttachments';
import GridItemSkeleton from './GridItemSkeleton';

export default function OrderGrid(props) {
	return (
		<div className='orderGridCont'>
			<OrderReferences props={props} />
			<OrderProgress props={props} />
			<OrderFinancial props={props} />
			<OrderItems props={props} />
			<OrderFiles props={props} />
			<OrderTracking props={props} />
			<OrderComments props={props} />
			<OrderSuborders props={props} />
			{props?.isLMMember && props.isSeller && <OrderLMInfo props={props} />}
			{props?.order?.integrations?.soe?.dentally && <OrderDtlyInfo props={props} />}
			<OrderExternalAttachments props={props} />
		</div>
	);
}

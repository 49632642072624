import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../components/Invoice/PageWrapper';
import {
	ArrowNarrowLeftIcon,
	BoxesStackedIcon,
	FileIcon,
	PenIcon,
	PlusIcon,
	TrashIcon,
	UploadCloudIcon,
} from '../../components/SVGIcons/SVGIcons';
import { checkFilenameLength, dateConverter } from '../../helpers';
import EmptyState from '../../components/EmptyState/EmptyState';
import useFetch from '../../hooks/useFetch';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import config from '../../config';
import SelectCustom from '../../components/Select/SelectCustom';
import Button from '../../components/Button';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import { ModalContext } from '../../contexts/ModalContext';
import Axios from 'axios';

export default function TeamInventory() {
	const { teamState } = useContext(CurrentTeamContext);

	const { data, fullData, reqLoading, fetch } = useFetch();

	const { showModal, hideModal } = useModalsGlobal();
	let { flashFadeModal } = useContext(ModalContext);

	const [products, setProducts] = useState([]);

	async function voidProduct(productUuid) {
		let res = await Axios({
			url: `${config.apiv1}/product/product.void/${productUuid}`,
			method: 'PATCH',
		});
		if (res.data.ok) {
			let filtered = [...products].filter((file) => file.productUuid !== productUuid);
			setProducts(() => filtered);
			flashFadeModal('File successfully deleted');
		}
	}

	function confirmVoid(productUuid) {
		showModal('confirmChoice', {
			open: true,
			header: 'Are you sure you want to delete this product?',
			callback: () => {
				voidProduct(productUuid);
			},
		});
	}

	async function fetchProducts() {
		let res = await fetch(`${config.apiv1}/product/products.read/${teamState.currentTeam.teamUuid}`);
		if (res && res?.length > 0) {
			setProducts(res);
		}
	}

	useEffect(() => {
		fetchProducts();
	}, []);

	return (
		<PageWrapper
			backColor='white'
			style={window.innerWidth > 768 ? { padding: '28px 60px 60px 60px' } : null}
			header={{
				text: 'Inventory',
				icon: <BoxesStackedIcon iconClass='headerIcon' />,
				theme: 'sale',
				button1: {
					text: 'Add product',
					cb: null,
					style: 'primary',
					width: 164,
					iconLeft: <PlusIcon />,
					cb: () =>
						showModal('product', {
							callback: fetchProducts,
							submitUrl: `${config.apiv1}/product/product.create`,
							method: 'POST',
							data,
						}),
				},
			}}>
			{products && products.length > 0 ? (
				<div className='filesList'>
					{products.map((product, key) => {
						return (
							<div key={key} className='fileCont'>
								<div className='col'>
									<div className='thumbPlaceholder'>
										<BoxesStackedIcon />
									</div>
								</div>
								<div className='col'>
									<div className='btnCont'>
										<div
											className='iconCont'
											onClick={() => {
												showModal('product', {
													callback: fetchProducts,
													submitUrl: `${config.apiv1}/product/product.update/${product?.productUuid}`,
													method: 'PATCH',
													data,
												});
											}}>
											<PenIcon />
										</div>
										<div
											className='iconCont'
											onClick={() => {
												confirmVoid(product.productUuid);
											}}>
											<TrashIcon />
										</div>
									</div>
									<div className='textCont'>
										<h4>{checkFilenameLength(product.name, 20)}</h4>
										<div className='textRow'>
											<p>Type: </p>
											<p>
												<span className='spanBold'>{product.type}</span>
											</p>
										</div>
										<div className='textRow'>
											<p>Channel: </p>
											<p className='spanBold'>{product.channel}</p>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<div className='pageInnerCont gray'>
					<EmptyState
						type='files'
						body='You have no files. Start by uploading files to ...'
						header='No files found'
					/>
				</div>
			)}
		</PageWrapper>
	);
}

import React, { useContext } from 'react';
import { CaretDownIcon, CaretUpIcon, PlusIcon } from '../../../components/SVGIcons/SVGIcons';
import { checkPackDelivered, checkStatusIfDisable, dateConverter, parseTrackingStatus } from '../../../helpers';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import TooltipIcon from '../../../components/TooltipIcon';
import { useNavigate } from 'react-router';

export default function OrderTracking(props) {
	const { order, expandItem, expandedItems, removeItem, handleModal, packages, setShowOverlay } = props?.props || {};

	const { teamState } = useContext(CurrentTeamContext);

	let navigate = useNavigate();

	return (
		<div className='gridItemOrder tracking'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Tracking</h4>
				<div className='marginLeft-auto marginRight-20'>
					<TooltipIcon
						id='orderTracking'
						tooltip='Order items and their accompaniments can be tracked at the touch of a button.'
					/>
				</div>
				<div
					className='plus discIcon'
					onClick={() => {
						handleModal('orderItemNewModal', true);
						setShowOverlay(true);
					}}>
					<PlusIcon />
				</div>
			</div>
			<div className='colCont'>
				<div className='row'>
					<div className='rowItem'>
						<h4 className='subHeader'>ITEM</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>PACKAGE</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>ORIGIN</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>LOCATION</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>TRACKING STATUS</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'></h4>
					</div>
				</div>
				<div className='col'>
					{order?.items && order?.items?.length !== 0 ? (
						order?.items?.map((item, key) => {
							// if (!packages[item.packageUuid]) {
							// 	return <React.Fragment key={key}></React.Fragment>;
							// }
							return (
								<div className='itemCont borderBottom' key={key}>
									<div className='row'>
										<div className='rowItem'>
											<p className='bodyText bold'>{item?.itemDesc || '...'}</p>
										</div>
										{packages[item.packageUuid] ? (
											<>
												<div className='rowItem'>{`${
													packages[item.packageUuid]?.quotedCollectionDateTime
														? dateConverter(
																packages[item.packageUuid]?.quotedCollectionDateTime,
																'DD/MM/YYYY-hh:mm'
														  )
														: ''
												}${
													packages[item.packageUuid]?.address?.teamName
														? `_${packages[item.packageUuid]?.address?.teamName}`
														: `_${packages[item.packageUuid]?.address?.name}`
												}`}</div>
												<div className='rowItem'>
													{parseTrackingStatus(packages[item.packageUuid]?.origin).icon}
												</div>
												<div className='rowItem'>
													{parseTrackingStatus(packages[item.packageUuid]?.location).icon}
												</div>
												<div className='rowItem'>
													Due:{' '}
													{dateConverter(
														packages[item.packageUuid]?.quotedCollectionDateTime
													)}
												</div>
											</>
										) : (
											<>
												<div className='rowItem'>Not assigned to package</div>
												<div className='rowItem'></div>
												<div className='rowItem'></div>
												<div className='rowItem'></div>
											</>
										)}
										{packages[item.packageUuid]?.senderTeamUuid ===
										teamState.currentTeam.teamUuid ? (
											<>
												{expandedItems && expandedItems[item?.itemUuid] ? (
													<div className='rowItem'>
														<CaretUpIcon
															iconClass='arrowExpand'
															onClick={() => {
																expandItem({
																	...expandedItems,
																	[item?.itemUuid]: !expandedItems[item?.itemUuid],
																});
															}}
														/>
													</div>
												) : (
													<div className='rowItem'>
														<CaretDownIcon
															iconClass='arrowExpand'
															onClick={() => {
																expandItem({
																	...expandedItems,
																	[item?.itemUuid]: !expandedItems[item?.itemUuid],
																});
															}}
														/>
													</div>
												)}
											</>
										) : (
											<div className='rowItem'></div>
										)}
									</div>
									{expandedItems && (
										<>
											{expandedItems[item?.itemUuid] && (
												<div className='expandedRow'>
													{!checkPackDelivered(packages[item.packageUuid]) && (
														<>
															{!item.packageUuid ? (
																<button
																	className='btn3-primary marginRight-20'
																	onClick={() => {
																		handleModal('addToPackModal', {
																			open: true,
																			item: item,
																		});
																	}}>
																	Add to package
																</button>
															) : (
																<></>
															)}
															<button
																className='btn3-primary'
																onClick={() => {
																	removeItem(item?.itemUuid);
																}}>
																Remove item
															</button>
															<button
																className='btn3-primary marginLeft-10'
																onClick={() => {
																	navigate(`/packages/${item.packageUuid}`);
																}}>
																View package
															</button>
														</>
													)}
												</div>
											)}
										</>
									)}
								</div>
							);
						})
					) : (
						<>No packages to track</>
					)}
				</div>
			</div>
		</div>
	);
}

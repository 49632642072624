import React, { useState } from 'react';
import AutofillInput from './AutofillInput';
import TooltipIcon from '../TooltipIcon';
import Loading from '../Loading/Loading';
import { CloseIcon } from '../SVGIcons/SVGIcons';

export default function TextareaInput(props) {
	const {
		text,
		name,
		callback,
		customCallbackValue,
		disabled,
		autofill,
		required,
		customError,
		noSpace,
		preview,
		tooltipExtendedText,
		defaultValue,
		value,
		onKeyDown,
		onBlur,
		loading,
		short,
		onlyInput,
		placeholder,
		controlled,
		autoFocus,
		tooltip,
	} = props || {};

	const [tooltipExtended, setTooltipExtended] = useState(
		localStorage.getItem('tooltip.order-details-private-notes') ? false : true
	);

	const toggleTooltipExtended = () => {
		setTooltipExtended(!tooltipExtended);
		if (!localStorage.getItem('tooltip.order-details-private-notes')) {
			localStorage.setItem('tooltip.order-details-private-notes', true);
		}
	};

	return (
		<>
			{!onlyInput ? (
				<div className='formColumnCenter'>
					{(required && defaultValue === null) ||
						(required && defaultValue === undefined && <div isrequired={name}></div>)}
					<div className='fieldTextContainer'>
						<p className='extraBold' htmlFor={name}>
							{text}
						</p>
						{tooltip && (
							<>
								<TooltipIcon id={name} tooltip={tooltip} delay={500} onClick={toggleTooltipExtended} />
							</>
						)}
					</div>
					{tooltipExtended === true && tooltipExtendedText ? (
						<p className='tooltipExtendedText'>
							{tooltipExtendedText}
							<CloseIcon onClick={toggleTooltipExtended} />
						</p>
					) : (
						<></>
					)}
					{controlled ? (
						<textarea
							onChange={(e) => (callback ? callback(e.target.value, e.target.name, noSpace) : null)}
							className={`formTextarea${short ? ' short' : ''}`}
							style={preview ? { resize: 'none' } : null}
							disabled={disabled || preview}
							value={value || ''}
							placeholder={placeholder}
							onKeyDown={onKeyDown}
							autoFocus={autoFocus}
							onBlur={onBlur}
							name={name}
							type='text'
							id={name}
						/>
					) : (
						<textarea
							onChange={(e) => (callback ? callback(e.target.value, e.target.name, noSpace) : null)}
							className={`formTextarea${short ? ' short' : ''}`}
							style={preview ? { resize: 'none' } : null}
							autoFocus={autoFocus}
							disabled={disabled || preview}
							defaultValue={defaultValue}
							placeholder={placeholder}
							onKeyDown={onKeyDown}
							onBlur={onBlur}
							name={name}
							type='text'
							id={name}
						/>
					)}
					{autofill && customCallbackValue && (
						<AutofillInput
							customCallbackValue={customCallbackValue}
							defaultValue={defaultValue || ''}
							callback={callback}
							name={name}
						/>
					)}
					{loading && (
						<div className='absoluteLoading'>
							<Loading type='circle' size='small' center />
						</div>
					)}
					{customError && <h4 className='tooltipError'>{customError}</h4>}
				</div>
			) : (
				<>
					{(required && defaultValue === null) ||
						(required && defaultValue === undefined && <div isrequired={name}></div>)}
					{text && (
						<div className='fieldTextContainer'>
							<p className='extraBold' htmlFor={name}>
								{text}
							</p>
						</div>
					)}
					{controlled ? (
						<textarea
							onChange={(e) => (callback ? callback(e.target.value, e.target.name, noSpace) : null)}
							className={`formTextarea${short ? ' short' : ''}`}
							style={preview ? { resize: 'none' } : null}
							disabled={disabled || preview}
							value={value || ''}
							placeholder={placeholder}
							onKeyDown={onKeyDown}
							autoFocus={autoFocus}
							onBlur={onBlur}
							name={name}
							type='text'
							id={name}
						/>
					) : (
						<textarea
							onChange={(e) => (callback ? callback(e.target.value, e.target.name, noSpace) : null)}
							className={`formTextarea${short ? ' short' : ''}`}
							style={preview ? { resize: 'none' } : null}
							disabled={disabled || preview}
							defaultValue={defaultValue}
							autoFocus={autoFocus}
							placeholder={placeholder}
							onKeyDown={onKeyDown}
							onBlur={onBlur}
							name={name}
							type='text'
							id={name}
						/>
					)}
					{autofill && customCallbackValue && (
						<AutofillInput
							customCallbackValue={customCallbackValue}
							defaultValue={defaultValue || ''}
							callback={callback}
							name={name}
						/>
					)}
					{loading && (
						<div className='absoluteLoading'>
							<Loading type='circle' size='small' center />
						</div>
					)}
					{customError && <h4 className='tooltipError'>{customError}</h4>}
				</>
			)}
		</>
	);
}

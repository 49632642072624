import React, { useEffect, useState } from 'react';
import { CheckIcon, SupplierTrustedIcon } from '../../SVGIcons/SVGIcons';
import SupplierSkeleton from '../carouselSkeletons/SupplierSkeleton';
import { useNavigate } from 'react-router';
import { calcSkels } from '../../../helpers';

export default function (props) {
	const { settings, currOffset, carouselWidth, setTotalWidth, trustedSuggested, patchSuggested, loaded } =
		props || {};

	let navigate = useNavigate();

	const [skeletons, setSkeletons] = useState(0);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			setTotalWidth(-(trustedSuggested.length / 4) * settings.max.carouselWidth);
			setSkeletons(() => calcSkels(carouselWidth, trustedSuggested, settings, <SupplierSkeleton />));
		}
		return () => (mounted = false);
	}, [settings]);

	return (
		<div
			className='suppliersCont'
			style={{
				transform: `translateX(${currOffset + carouselWidth}px)`,
				transition: '0.6s ease-in-out',
				width: `${carouselWidth}`,
			}}>
			{loaded &&
				trustedSuggested.map((suggested, key) => {
					return (
						<div
							className='supplier'
							key={key}
							onClick={() => {
								navigate(
									`/suppliers/${suggested?.notificationData?.teamUuid}?name=${suggested?.notificationData?.teamInfo?.teamName}`
								);
							}}>
							<div className='supplierHeader'>
								<SupplierTrustedIcon iconClass='headerIcon' />
							</div>
							<div className='supplierBody'>
								<h3>{suggested?.notificationData?.teamName}</h3>
								<p>
									{suggested?.notificationData?.teamDesc?.length > 100
										? suggested?.notificationData?.teamDesc.slice(0, 97) + '...'
										: suggested?.notificationData?.teamDesc}
								</p>
							</div>
							<div className='supplierFooter'>
								<button
									className='btn3-secondary'
									onClick={(e) => {
										e.stopPropagation();
										patchSuggested(suggested, 'false');
									}}>
									Decline
								</button>
								<button
									className='btn3-primary'
									onClick={(e) => {
										e.stopPropagation();
										patchSuggested(suggested, 'true');
									}}>
									<CheckIcon iconClass='btnIcon white' />
									Trust
								</button>
							</div>
						</div>
					);
				})}
			{skeletons &&
				skeletons.map((skeleton, key) => {
					if (skeleton) {
						return <React.Fragment key={key}>{skeleton}</React.Fragment>;
					}
				})}
		</div>
	);
}

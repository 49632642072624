import React, { useState, useContext, useEffect } from 'react';
import { TutorialContext } from '../../contexts/TutorialContext';
import Cookies from 'js-cookie';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import usePost from '../../hooks/usePost';

export default function TutorialPrompt(props) {
	const { page } = props || {};

	let { tutorialState, tutorialDispatch } = useContext(TutorialContext);
	let { userState } = useContext(CurrentUserContext);

	let { post } = usePost();

	function setActive(params) {
		tutorialDispatch({ type: 'SET_TUTORIAL_ACTIVE', payload: params });
	}
	function setInactive(params) {
		tutorialDispatch({ type: 'SET_TUTORIAL_INACTIVE', payload: params });
	}

	useEffect(() => {
		let mounted = true;
		if (
			mounted &&
			userState?.currUser?.metadata?.firstLogin === true &&
			!tutorialState?.tutorials[page] &&
			!Cookies.get(`tutorial.${page}`)
		) {
			tutorialDispatch({ type: 'START_FIRST_TOUR', payload: page });
			post({ firstLogin: false }, `${config.auth}/v1/user/user.update`);
		} else if (mounted && !tutorialState?.tutorials[page] && !Cookies.get(`tutorial.${page}`)) {
			tutorialDispatch({ type: 'ADD_NEW_PROMPT', payload: page });
		}
		return () => (mounted = false);
	}, []);

	return (
		<>
			{tutorialState?.tutorials[page]?.prompt && (
				<div className='tutorialPrompt'>
					<h3>Take a tour of Procuur</h3>
					<p>Walk through Procuur's main interface and features.</p>
					<button
						className='btn1'
						onClick={() => {
							setActive({ page: page, data: { open: true, prompt: false, curr: 1 } });
						}}>
						Show me around
					</button>
					<button
						className='btn2'
						onClick={() => {
							setInactive({ page: page, data: { open: false, prompt: false } });
						}}>
						No thanks
					</button>
				</div>
			)}
		</>
	);
}

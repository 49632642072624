import React, { useEffect, useState } from 'react';

export default function useForm(preData, saveInStore) {
	const [formData, setFormData] = useState(preData || {});
	const [customErrors, setCustomErrors] = useState({});
	const [files, setFiles] = useState([]);

	const handleFormInput = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	const handleFormSelect = (data, fieldName) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[fieldName]: data,
		}));
	};

	const handleFormFiles = (e) => {
		setFiles(e.target.files);
	};

	function createFormDataObj() {
		let formDataObj = new FormData();
		for (let field in formData) {
			formDataObj.append(formData[field]);
		}
		return formDataObj;
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (saveInStore) {
				let savedFormData = JSON.parse(localStorage.getItem('savedFormData'));
				setFormData(savedFormData);
			}
		}
		return () => {
			if (saveInStore) {
				localStorage.setItem('savedFormData', JSON.stringify(formData));
			}
			mounted = false;
		};
	}, []);

	return {
		formData,
		customErrors,
		files,
		handleFormInput,
		handleFormSelect,
		handleFormFiles,
		createFormDataObj,
		setFormData,
	};
}

import React, { useContext } from 'react';
import ChatBox from './functionalComponents/ChatBox';

export default function SidebarOrder(props) {
	const { contentReference, textboxesOpen, openTextbox, open } = props || {};

	return (
		<>
			<ChatBox
				contentReference={contentReference}
				textboxesOpen={textboxesOpen}
				openTextbox={openTextbox}
				open={open}
			/>
		</>
	);
}

import React, { useEffect, useState, useRef } from 'react';
import ArrowLeft from '../Arrows/ArrowLeft';
import ArrowRight from '../Arrows/ArrowRight';
import SupplierCarousel from './carouselTypes/SupplierCarousel';
import LearningZoneCarousel from './carouselTypes/LearningZoneCarousel';
import LearningZoneHeader from './carouselHeaders/LearningZoneHeader';
import SupplierHeader from './carouselHeaders/SupplierHeader';
import TrustedSettingsHeader from './carouselHeaders/TrustedSettingsHeader';
import SuggestedCarousel from './carouselTypes/SuggestedCarousel';
import config from '../../config';

export default function Carousel(props) {
	const {
		type,
		headerType,
		backColor,
		mode,
		noHeader,
		leftDescription,
		cardOptions,
		customList,
		patchSuggested,
		loaded,
	} = props || {};

	const [currOffset, setCurrOffset] = useState(-360);
	const [carouselWidth, setCarouselWidth] = useState(360);
	const [totalWidth, setTotalWidth] = useState(0);

	const [lastMousePress, setLastMousePress] = useState(null);
	const [newMousePress, setNewMousePress] = useState(null);
	const [mouseEventLoading, setMouseEventLoading] = useState(false);

	const innerRef = useRef(null);

	const [settings] = useState({
		single: { innerContWidth: 864, carouselWidth: 360 },
		dual: { innerContWidth: 1200, carouselWidth: 720 },
		triple: { innerContWidth: 1540, carouselWidth: 1080 },
		max: { carouselWidth: 1440 },
	});
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	function nextPage() {
		setCurrOffset(currOffset - carouselWidth);
	}

	function previousPage() {
		setCurrOffset(currOffset + carouselWidth);
	}

	function carouselSwipe(e) {
		setNewMousePress(e.touches[0].clientX);
	}

	function onTouchEnd(e) {
		let swipeMinDetectPixels = 50;
		if (mouseEventLoading) {
			if (newMousePress + swipeMinDetectPixels < lastMousePress) {
				if (currOffset === totalWidth || currOffset < totalWidth) {
					return;
				} else {
					nextPage();
				}
			} else if (newMousePress - swipeMinDetectPixels > lastMousePress) {
				if (currOffset === -carouselWidth) {
					return;
				} else {
					previousPage();
				}
			}
		}
	}

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const checkAndSetCarSize = () => {
			if (
				innerRef.current.offsetWidth >= settings.triple.innerContWidth &&
				carouselWidth !== settings.max.carouselWidth
			) {
				setCarouselWidth(settings.max.carouselWidth);
				setCurrOffset(-settings.max.carouselWidth);
			} else if (
				innerRef.current.offsetWidth < settings.single.innerContWidth &&
				carouselWidth !== settings.single.carouselWidth
			) {
				setCarouselWidth(settings.single.carouselWidth);
				setCurrOffset(-settings.single.carouselWidth);
			} else if (
				innerRef.current.offsetWidth < settings.dual.innerContWidth &&
				innerRef.current.offsetWidth > settings.single.innerContWidth &&
				carouselWidth !== settings.dual.carouselWidth
			) {
				setCarouselWidth(settings.dual.carouselWidth);
				setCurrOffset(-settings.dual.carouselWidth);
			} else if (
				innerRef.current.offsetWidth < settings.triple.innerContWidth &&
				innerRef.current.offsetWidth > settings.dual.innerContWidth &&
				carouselWidth !== settings.triple.carouselWidth
			) {
				setCarouselWidth(settings.triple.carouselWidth);
				setCurrOffset(-settings.triple.carouselWidth);
			}
		};
		checkAndSetCarSize();
	}, [windowSize, carouselWidth, settings]);

	return (
		<div className='carousel'>
			{headerType === 'trustedSettings' && !noHeader && <TrustedSettingsHeader />}
			{headerType === 'suppliers' && !noHeader && <SupplierHeader />}
			{headerType === 'learningZone' && !noHeader && <LearningZoneHeader />}
			<div
				className={`carouselInnerContainer ${type}`}
				ref={innerRef}
				style={{
					backgroundColor: `var(--${backColor})`,
					color: `var(--${mode === 'dark' ? 'white' : 'black'})`,
					padding: `${leftDescription ? '16px 0px' : '36px 0px'}`,
					margin: `${leftDescription ? '0px 0px 24px 0px' : '0px 0px 50px 0px'}`,
				}}>
				{type === 'suppliers' && leftDescription && (
					<div className='leftDesc'>
						<h4>Trusted</h4>
						<p>
							A Trusted Supplier is a business that you trust and would regularly purchase from.{' '}
							<a className='underline' href={`${config.host}/tutorials/en/how-to-trusted-suppliers`}>
								Learn more about Trusted Suppliers
							</a>
							.
						</p>
					</div>
				)}
				{type === 'suggested' && leftDescription && (
					<div className='leftDesc'>
						<h4>Suggested</h4>
						<p>Trusted suppliers suggested by your team.</p>
					</div>
				)}
				<ArrowLeft
					previous={previousPage}
					disabled={currOffset === -carouselWidth}
					color={mode === 'dark' ? 'white' : 'gray'}
				/>
				<div
					className='carouselItems'
					style={{
						width: `${carouselWidth}px`,
						transition: '0s',
					}}
					onTouchStart={(e) => {
						setMouseEventLoading(true);
						setLastMousePress(e.touches[0].clientX);
					}}
					onTouchMove={(e) => {
						carouselSwipe(e);
					}}
					onTouchEnd={onTouchEnd}>
					{type === 'suppliers' && (
						<SupplierCarousel
							settings={settings}
							currOffset={currOffset}
							carouselWidth={carouselWidth}
							setTotalWidth={setTotalWidth}
							suppliers={customList}
							cardOptions={cardOptions}
							loaded={loaded}
						/>
					)}
					{type === 'suggested' && (
						<SuggestedCarousel
							settings={settings}
							currOffset={currOffset}
							carouselWidth={carouselWidth}
							setTotalWidth={setTotalWidth}
							cardOptions={cardOptions}
							trustedSuggested={customList}
							patchSuggested={patchSuggested}
							loaded={loaded}
						/>
					)}
					{type === 'learningZone' && (
						<LearningZoneCarousel
							settings={settings}
							currOffset={currOffset}
							carouselWidth={carouselWidth}
							setTotalWidth={setTotalWidth}
						/>
					)}
				</div>
				<ArrowRight
					next={nextPage}
					disabled={currOffset === totalWidth || currOffset < totalWidth}
					color={mode === 'dark' ? 'white' : 'gray'}
				/>
			</div>
		</div>
	);
}

import React from 'react';
import Button from '../../Button';
import SelectCustom from '../../Select/SelectCustom';
import { ArrowNarrowRightIcon } from '../../SVGIcons/SVGIcons';

export default function InvoicesFilterBar(props) {
	const { filter, handleFilter, handleSearch, openFilter } = props || {};
	return (
		<div className='filterBar'>
			<div>
				<h4 className='label'>Search for invoice</h4>
				<input
					className='textFieldInput'
					onChange={(e) => {
						handleSearch(e.target.value);
					}}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Status</h4>
				<SelectCustom
					htmlOptions={[
						<option value='all'>All</option>,
						<option value='draft'>Draft</option>,
						<option value='sent'>Sent</option>,
						<option value='paid'>Paid</option>,
						<option value='refunded'>Refunded</option>,
						<option value='disputed'>Disputed</option>,
					]}
					fieldName='status'
					callback={(e) => {
						handleFilter(e.value, 'status');
					}}
					placeholder={'Select...'}
					value={filter?.status || 'all'}
					width={300}
				/>
			</div>
			<div className='field'>
				<h4 className='label'>Due date</h4>
				<SelectCustom
					htmlOptions={[
						<option value=''></option>,
						<option value='dueDate.desc'>Newest to oldest</option>,
						<option value='dueDate.asc'>Oldest to newest</option>,
					]}
					placeholder={'Select...'}
					fieldName='sort'
					callback={(e) => handleFilter(e.value, 'sort')}
					value={filter?.sort?.split('.')[0] === 'dueDate' ? filter?.sort : ''}
					width={300}
				/>
			</div>
			<Button
				text='All filters'
				size='md'
				style='secondary'
				iconRight={<ArrowNarrowRightIcon />}
				onClick={openFilter}
			/>
		</div>
	);
}

import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PenIcon } from '../../../components/SVGIcons/SVGIcons';
import ListFieldPre from './ListFieldPre';

export default function ContainerField(props) {
	const { field, column, handleModal, fieldIndex, dropsDisabled, showDropZones } = props || {};
	return (
		<div className='mainFormCont'>
			<Draggable draggableId={field.id} index={fieldIndex}>
				{(provided1) => (
					<div className={'containerField'} {...provided1.draggableProps} ref={provided1.innerRef}>
						<h4 {...provided1.dragHandleProps}>{column.fields[fieldIndex].droppable.name}</h4>
						<Droppable droppableId={field.droppable.id} isDropDisabled={dropsDisabled === true}>
							{(provided2) => (
								<div
									className={`${
										showDropZones === true
											? 'dropSection container dropZone'
											: 'dropSection container'
									}`}
									ref={provided2.innerRef}
									{...provided2.droppableProps}>
									{field?.droppable?.fields?.map((innerField, key) => {
										return (
											<React.Fragment key={innerField.id}>
												<ListFieldPre
													index={key}
													field={innerField}
													handleModal={handleModal}
													droppableId={field.droppable.id}
													showTools={column.type === 'docket'}
													editIcon={
														<div
															className='toolIcon discIcon'
															onClick={() => {
																handleModal(
																	'editModal',
																	{
																		open: true,
																		data: {
																			field: innerField,
																			colId: field.droppable.id,
																		},
																	},
																	true
																);
															}}>
															<PenIcon />
														</div>
													}
												/>
											</React.Fragment>
										);
									})}
									{column.id !== 'column_1' && (
										<>
											{column.fields[fieldIndex]?.droppable?.fields?.length === 0 && (
												<>
													<div className='placeholder'>Drop components here</div>
												</>
											)}
										</>
									)}
									{provided2.placeholder}
								</div>
							)}
						</Droppable>
					</div>
				)}
			</Draggable>
		</div>
	);
}

import React from 'react';

let BotLeftModalContext = React.createContext();

function BotLeftModalContextProvider(props) {
	let initialState = {
		loading: true,
		reload: false,
		sidebarOpen: false,
		contentType: null,
		textboxesOpen: {},
		contentReference: null,
		show: false,
	};

	const reducer = (modalState, action) => {
		switch (action.type) {
			case 'ANIMATE_MODAL_DEFAULT':
				return {
					...modalState,
					show: action.show,
					isFading: action.isFading,
					message: action.message,
					loading: false,
					reload: false,
				};
			default:
				break;
		}
	};

	let [modalState, modalDispatch] = React.useReducer(reducer, initialState);
	let value = { modalState, modalDispatch };

	return <BotLeftModalContext.Provider value={value}>{props.children}</BotLeftModalContext.Provider>;
}

let BotLeftModalContextConsumer = BotLeftModalContext.Consumer;

export { BotLeftModalContext, BotLeftModalContextProvider, BotLeftModalContextConsumer };

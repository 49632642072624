import React, { useEffect } from 'react';
import Canvas from '../../components/Canvas/Canvas';
import CheckboxInput from '../../components/Inputs/CheckboxInput';
import DateInput from '../../components/Inputs/DateInput';
import FileInput from '../../components/Inputs/FileInput';
import RadioInput from '../../components/Inputs/RadioInput';
import SelectInput from '../../components/Inputs/SelectInput';
import TextareaInput from '../../components/Inputs/TextareaInput';
import TextInput from '../../components/Inputs/TextInput';
import { checkFilenameLength, getFutureDate, parseDentallyFields, parseFileSize, parseToOptions } from '../../helpers';
import { FileIcon, TrashIcon } from '../../components/SVGIcons/SVGIcons';

export default function DocketSwitch(props) {
	const {
		field,
		handleFormSelectData,
		handleFormData,
		customErrors,
		formData,
		formFiles,
		handleFocused,
		focused,
		handleFiles,
		parentOrder,
		autofill,
		dentallyData,
	} = props || {};
	return (
		<>
			{field?.data && (
				<>
					{(() => {
						switch (field?.type) {
							case 'select':
								return (
									<SelectInput
										options={parseToOptions(field?.data?.selectOptions, 'docketSelect')}
										callback={handleFormSelectData}
										text={field?.data?.fieldText || field?.data?.fieldName}
										name={field?.data?.fieldName}
										value={formData[field?.data?.fieldName]}
										customError={customErrors[field?.data?.fieldName]}
										required={field?.data?.required}
										customCallbackValue={
											parentOrder.customFields && parentOrder.customFields[field?.data?.fieldName]
										}
										autofill={autofill}
									/>
								);
							case 'input.text':
								return (
									<TextInput
										text={field?.data?.fieldText || field?.data?.fieldName}
										name={field?.data?.fieldName}
										placeholder={field?.data?.placeholder}
										defaultValue={formData[field?.data?.fieldName]}
										value={formData[field?.data?.fieldName]}
										type='text'
										callback={handleFormData}
										required={field?.data?.required}
										customError={customErrors[field?.data?.fieldName]}
										customCallbackValue={
											parentOrder.customFields && parentOrder.customFields[field?.data?.fieldName]
										}
										autofill={autofill}
										options={dentallyData}
										controlled={dentallyData ? true : false}
									/>
								);
							case 'input.file':
								return (
									<>
										<FileInput
											text={field?.data?.fieldText || field?.data?.fieldName}
											name={field?.data?.fieldName}
											customError={customErrors[field?.data?.fieldName]}
											btnText='Choose file'
											callback={handleFiles}
										/>
										{formFiles && formFiles[field?.data?.fieldName] ? (
											<div
												className='fileCont marginBottom-20'
												style={{ width: '100%', maxWidth: '480px' }}>
												<FileIcon iconClass='placeholder' />
												<div className='fileRow'>
													<div>
														<h4 className='text'>
															{checkFilenameLength(
																formFiles[field?.data?.fieldName]?.file?.name,
																20
															)}
														</h4>
														<h4 className='text'>
															{parseFileSize(
																formFiles[field?.data?.fieldName]?.file?.size
															)}
														</h4>
													</div>
													<TrashIcon
														iconClass='trashIcon'
														onClick={(value, name) =>
															handleFiles(null, field?.data?.fieldName)
														}
													/>
												</div>
											</div>
										) : (
											<></>
										)}
									</>
								);
							case 'input.phone':
								return (
									<TextInput
										text={field?.data?.fieldText || field?.data?.fieldName}
										name={field?.data?.fieldName}
										placeholder={field?.data?.placeholder}
										defaultValue={formData[field?.data?.fieldName]}
										type='phone'
										callback={handleFormData}
										required={field?.data?.required}
										customError={customErrors[field?.data?.fieldName]}
										customCallbackValue={
											parentOrder.customFields && parentOrder.customFields[field?.data?.fieldName]
										}
										autofill={autofill}
									/>
								);
							case 'textarea':
								return (
									<TextareaInput
										text={field?.data?.fieldText || field?.data?.fieldName}
										name={field?.data?.fieldName}
										placeholder={field?.data?.placeholder}
										defaultValue={formData[field?.data?.fieldName]}
										callback={handleFormData}
										required={field?.data?.required}
										customError={customErrors[field?.data?.fieldName]}
										customCallbackValue={
											parentOrder.customFields && parentOrder.customFields[field?.data?.fieldName]
										}
										autofill={autofill}
									/>
								);
							case 'input.date':
								return (
									<DateInput
										text={field?.data?.fieldText || field?.data?.fieldName}
										name={field?.data?.fieldName}
										callback={(value, name) => {
											handleFormData(value, name);
										}}
										handleFocused={handleFocused}
										required={field?.data?.required}
										customError={customErrors[field?.data?.fieldName]}
										focused={focused}
										defaultValue={formData[field?.data?.fieldName]}
										min={new Date(getFutureDate(field?.data?.minDays))}
										customCallbackValue={
											parentOrder.customFields && parentOrder.customFields[field?.data?.fieldName]
										}
										autofill={autofill}
										dateFormat={
											field?.data?.timeSelect === true ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'
										}
										timeSelect={field?.data?.timeSelect}
									/>
								);
							case 'input.checkbox':
								return (
									<CheckboxInput
										text={field?.data?.fieldText || field?.data?.fieldName}
										name={field?.data?.fieldName}
										checked={formData[field?.data?.fieldName]}
										callback={handleFormData}
										required={field?.data?.required}
										customError={customErrors[field?.data?.fieldName]}
										customCallbackValue={
											parentOrder.customFields && parentOrder.customFields[field?.data?.fieldName]
										}
										autofill={autofill}
									/>
								);
							case 'input.radio':
								let radios = [];
								for (let radio in field?.data) {
									if (radio !== 'extraRadios' && radio !== 'fieldName' && radio !== 'fieldText') {
										radios.push({ text: field?.data[radio], value: field?.data[radio] });
									}
								}
								return (
									<RadioInput
										text={field?.data?.fieldText || field?.data?.fieldName}
										name={field?.data?.fieldName}
										defaultValue={formData && formData[field?.data?.fieldName]}
										callback={handleFormData}
										customError={customErrors[field?.data?.fieldName]}
										customCallbackValue={
											parentOrder.customFields && parentOrder.customFields[field?.data?.fieldName]
										}
										radios={radios}
									/>
								);
							case 'header':
								return <h3>{field?.data?.header}</h3>;
							case 'paragraph':
								return <p>{field?.data?.paragraph}</p>;
							case 'drawingTool':
								return (
									<div className='formRowCenter'>
										<h3 className='header'>{field?.data?.fieldText || field?.data?.fieldName}</h3>
										<Canvas
											cWidth={500}
											cHeight={700}
											handleFile={handleFiles}
											name={field?.data?.fieldName}
											img={field?.data?.drawToolImg?.data}
										/>
									</div>
								);
							case 'graphic':
								return <img className='graphic' src={field?.data?.graphic?.data} alt='Graphic'></img>;
							default:
								return <></>;
						}
					})()}
				</>
			)}
		</>
	);
}

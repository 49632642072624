import React, { useContext } from 'react';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { dateConverter, filterMember, filterMemberAvatar, parseKanbanDate, sliceString } from '../../helpers';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { ProgressHoldIcon } from '../../components/SVGIcons/SVGIcons';

export default function KanbanItem(props) {
	const { item } = props || {};

	const { teamState } = useContext(CurrentTeamContext);
	let navigate = useNavigate();

	const { date, color, icon } = parseKanbanDate(item.dispatchDueDateTime, 'DD/MM/YYYY');

	return (
		<div
			className='item'
			onClick={() => {
				navigate(`/orders/${item.orderUuid}?orderRef=${item.orderRef}`);
			}}>
			<div className='row'>
				<h4 className={`channel`}>{sliceString(item.customFields?.docketName, 0, 15, true) || 'Other'}</h4>
			</div>
			<div className='row'>
				<h4 className='blackHeader'>{item.orderRef}</h4>
				<h4 className='grayHeader'>{sliceString(item.sellerTeamName || '', 0, 20, true)}</h4>
			</div>
			<div className='row'>
				<h4>{item.hold ? <ProgressHoldIcon iconClass='dateIcon icon warning' /> : ''}</h4>
				<h4
					className='dateText'
					style={{ color: color ? color : '' }}
					data-tooltip-id={`tbd_${item.orderUuid}`}
					data-tooltip-content='TBD = to be determined'
					data-tooltip-delay-show={500}>
					{date ? `${date}` : 'TBD'}
					{icon && (
						<div className='dateIcon' style={{ fill: color ? color : '' }}>
							{icon}
						</div>
					)}
					{!date && <Tooltip id={`tbd_${item.orderUuid}`} />}
				</h4>
			</div>
			<div className='row'>
				<h4 className='smallHeader'>
					{filterMemberAvatar(teamState?.currentTeam?.members, item.sellerUserUuid)?.url && (
						<img
							className='avatar'
							src={filterMemberAvatar(teamState?.currentTeam?.members, item.sellerUserUuid)?.url}
							alt='User avatar'
						/>
					)}
					{filterMember(teamState?.currentTeam?.members, item.sellerUserUuid) || 'None assigned'}
				</h4>
			</div>
		</div>
	);
}

import React, { useEffect } from 'react';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';

export default function STLViewer(props) {
	const { file, hidden, close } = props || {};
	let renderer = null;

	useEffect(() => {
		let mounted = true;
		if (mounted && !hidden) {
			const scene = new THREE.Scene();
			scene.background = new THREE.Color('#333');

			const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 1000);
			camera.position.z = 0;
			camera.position.y = 0;
			camera.position.x = 50;

			const canvas = document.getElementById('sceneCanvas');
			renderer = new THREE.WebGLRenderer({
				canvas,
				antialias: true,
			});
			renderer.setSize(window.innerWidth - 200, window.innerHeight - 200);
			document.body.appendChild(renderer.domElement);

			const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
			ambientLight.castShadow = true;
			scene.add(ambientLight);

			let spotLight1 = new THREE.SpotLight(0xffffff, 1);
			spotLight1.castShadow = true;
			spotLight1.position.set(-100, -100, -100);
			scene.add(spotLight1);

			let spotLight2 = new THREE.SpotLight(0xffffff, 1);
			spotLight2.castShadow = true;
			spotLight2.position.set(100, 100, 100);
			scene.add(spotLight2);

			// Load in the supplier file
			let loader = new STLLoader();
			loader.load(file.url, function (geometry) {
				geometry.center();
				let material = new THREE.MeshPhongMaterial({ color: 0xaaaaaa, specular: 0x111111, shininess: 200 });
				let meshMaterial = material;
				if (geometry.hasColors) {
					meshMaterial = new THREE.MeshPhongMaterial({ opacity: geometry.alpha, vertexColors: true });
				}
				const mesh = new THREE.Mesh(geometry, meshMaterial);
				scene.add(mesh);
				animate();
			});

			const controls = new OrbitControls(camera, renderer.domElement);

			const animate = () => {
				controls.update();
				renderer.render(scene, camera);
				window.requestAnimationFrame(animate);
			};
		}
		return () => {
			mounted = false;
			renderer.dispose();
			renderer.forceContextLoss();
			document.getElementsByClassName('threeJsCont')[0]?.remove();
			document.getElementById('sceneCanvas')?.remove();
		};
	}, [hidden]);

	return (
		<div className='threeJsCont' style={{ height: window.innerHeight - 100, width: window.innerWidth - 200 }}>
			<h2 className='stlHeader'>{file?.name}</h2>
			<button className='closeModel' onClick={close}>
				Close
			</button>
			<canvas id='sceneCanvas' className='threeJsModel' />
		</div>
	);
}

import React from 'react';
import { Link } from 'react-router-dom';
import { SupplierTrustedIcon } from '../../SVGIcons/SVGIcons';

export default function SupplierHeader() {
	return (
		<div className='carouselHeader'>
			<div>
				<div className='flexRowStart'>
					<SupplierTrustedIcon iconClass='supplierTrustedIcon' />
					<h2 className='marginLeft-20'>Trusted Suppliers</h2>
				</div>
				<p>A Trusted Supplier is a business that you trust and would regularly purchase from.</p>
				<Link to='/tutorials/en/how-to-trusted-suppliers' className='underline'>
					Learn more about Trusted Suppliers.
				</Link>
			</div>
			<div className='carouselHeaderBtnCont'>
				<Link to='/suppliers'>
					<button className='dashboardBtnSeeAll'>Find a supplier</button>
				</Link>
			</div>
		</div>
	);
}

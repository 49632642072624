import React from 'react';

export default function LearningZoneSkeleton() {
	return (
		<div className='learningCardSkel'>
			<div className='cardImg'></div>
			<div className='cardFooter'>
				<h4></h4>
			</div>
		</div>
	);
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import FormWrap from '../../components/Wrappers/FormWrap';
import {
	ArchiveIcon,
	ChevronDownIcon,
	ChevronIconRight,
	CoinsStackedIcon,
	InvoiceGBPIcon,
	InvoiceInfoIcon,
	PaperPlaneIcon,
	PenIcon,
	PlusIcon,
	SendIcon,
	TrashIcon,
} from '../../components/SVGIcons/SVGIcons';
import Loading from '../../components/Loading/Loading';
import ItemList from './ItemList';
import RadioInput from '../../components/Inputs/RadioInput';
import usePost from '../../hooks/usePost';
import Button from '../../components/Button';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import Axios from 'axios';
import { ModalContext } from '../../contexts/ModalContext';
import { getCurrencySymbolFromTeam } from '../../helpers';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import PageWrapper from '../../components/Invoice/PageWrapper';
import SectionWrapper from '../../components/Invoice/sections/SectionWrapper';
import BuyerInfoSection from '../../components/Invoice/sections/BuyerInfoSection';
import InfoSection from '../../components/Invoice/sections/InfoSection';
import ItemsSection from '../../components/Invoice/sections/ItemsSection';
import AmountSection from '../../components/Invoice/sections/AmountSection';
import NotesSection from '../../components/Invoice/sections/NotesSection';
import TextareaInput from '../../components/Inputs/TextareaInput';
import BannerSection from '../../components/Invoice/sections/BannerSection';
import InvoicePayments from '../../components/InvoicePayments';

export default function InvoiceDetails() {
	let location = useLocation();
	let params = useParams();
	let navigate = useNavigate();

	const { teamState } = useContext(CurrentTeamContext);

	const [order, setOrder] = useState(null);
	const [invoice, setInvoice] = useState(null);
	const [buyerTeam, setBuyerTeam] = useState({});
	const [sellerTeam, setSellerTeam] = useState({});
	const [currencySymbol, setCurrencySymbol] = useState('');
	const [updateLoading, setUpdateLoading] = useState(false);

	const [formData, setFormData] = useState({});

	const { modals, showModal } = useModalsGlobal();

	let { flashFadeModal } = useContext(ModalContext);

	const { post, reqLoading, customErrors } = usePost();

	const { fetch } = useFetch(`${config.apiv1}/invoice/invoice.read/${params.invoiceUuid}`);

	const headerTypes = {
		draft: {
			text: 'Invoice',
			icon: <InvoiceGBPIcon iconClass='headerIcon' />,
			theme: 'sale',
			button1: {
				text: 'Edit',
				cb: () => navigate(`/invoice-edit?invoiceUuid=${invoice.invoiceUuid}`),
				iconLeft: <PenIcon />,
				style: 'secondary',
				width: 164,
			},
			button2: {
				text: 'Send',
				iconLeft: <SendIcon />,
				cb: () => {
					patchInvoice({ status: 'sent' });
				},
				style: 'primary',
				width: 164,
			},
			button3: {
				text: 'Delete',
				iconLeft: <TrashIcon />,
				cb: () =>
					showModal('confirmChoice', {
						header: `Are you sure you want to delete this invoice?`,
						body: 'You cannot undo this action',
						callback: deleteInvoice,
					}),
				style: 'secondary',
				width: 164,
			},
		},
		sent: {
			text: 'Invoice',
			icon: <InvoiceGBPIcon iconClass='headerIcon' />,
			theme: 'sale',
			button1: {
				text: 'Amend',
				cb: null,
				iconLeft: <PenIcon />,
				style: 'secondary',
				width: 164,
			},
			button2: {
				text: 'Mark as paid',
				iconLeft: <CoinsStackedIcon />,
				cb: () => {
					patchInvoice({ status: 'paid' });
				},
				style: 'primary',
				width: 164,
			},
			button3: { text: 'More', iconRight: <ChevronDownIcon />, cb: null, style: 'secondary' },
		},
		paid: {
			text: 'Invoice',
			icon: <InvoiceGBPIcon iconClass='headerIcon' />,
			theme: 'sale',
			button1: {
				text: 'Archive',
				cb: null,
				iconLeft: <ArchiveIcon />,
				style: 'primary',
				width: 164,
			},
			button2: {
				text: 'More',
				iconRight: <ChevronDownIcon />,
				cb: null,
				style: 'secondary',
				width: 164,
			},
		},
	};

	async function fetchInitData() {
		let invoice = await fetch(`${config.apiv1}/invoice/invoice.read/${params.invoiceUuid}`);
		let order = await fetch(`${config.apiv1}/order/order.read/${invoice?.orderUuid}`);
		let buyerTeam = await fetch(`${config.apiv1}/team/team.read/${order?.buyerTeamUuid}`);
		let sellerTeam = await fetch(`${config.apiv1}/team/team.read/${order?.sellerTeamUuid}`);

		setOrder(order || {});
		setInvoice(invoice);
		setBuyerTeam(buyerTeam);
		setSellerTeam(sellerTeam);

		setFormData(invoice);
	}

	function calcInvoiceTotal(invoice) {
		let reduced = invoice?.invoiceItems?.reduce((totalVal, item) => {
			return totalVal + item?.totalAmount;
		}, 0);
		return reduced;
	}

	function confirmChoice(e, choiceType) {
		switch (choiceType) {
			case 'status':
				showModal('confirmChoice', {
					header: `Are you sure you want to set the invoice as ${e}?`,
					callback: () => patchInvoice({ status: e }),
				});
				break;
		}
	}

	function handleFormData(value, name) {
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	}

	// async function addInvoice(data) {
	// 	let res = await post(
	// 		{
	// 			...data,
	// 		},
	// 		`${config.apiv1}/invoice/invoice.update/${params.invoiceUuid}`,
	// 		true,
	// 		'PATCH'
	// 	);
	// }

	function handleNewItem(data) {
		let invoiceTotal = calcInvoiceTotal({ invoiceItems: [data, ...invoice?.invoiceItems] });

		patchInvoice({ totalAmount: invoiceTotal, invoiceItems: [...invoice?.invoiceItems, data] });
	}

	function handleEditedItem(data, item) {
		let itemMap = invoice.invoiceItems.map((invoiceItem) => {
			if (invoiceItem.invoiceItemUuid === item.invoiceItemUuid) {
				return data?.data || invoiceItem;
			} else {
				return invoiceItem;
			}
		});

		let invoiceTotal = calcInvoiceTotal({ invoiceItems: itemMap });

		patchInvoice({ totalAmount: invoiceTotal, invoiceItems: [...itemMap] });
	}

	function editItem(item) {
		showModal('editInvoiceItem', {
			submitUrl: `${config.apiv1}/invoice/invoice-item.update/${item.invoiceItemUuid}`,
			method: 'PATCH',
			callback: (data) => handleEditedItem(data, item),
			data: item,
		});
	}

	function deleteItem(item) {
		showModal('confirmChoice', {
			header: `Are you sure you want to delete this invoice item?`,
			callback: () => deleteInvoiceItem(item),
		});
	}

	async function deleteInvoiceItem(item) {
		try {
			let res = await Axios({
				url: `${config.apiv1}/invoice/invoice-item.delete/${item.invoiceItemUuid}`,
				method: 'DELETE',
			});
			if (res?.data?.ok) {
				let filtered = invoice.invoiceItems?.filter(
					(invoiceItem) => invoiceItem.invoiceItemUuid !== item.invoiceItemUuid
				);

				let invoiceTotal = calcInvoiceTotal({ invoiceItems: filtered });

				patchInvoice({ totalAmount: invoiceTotal, invoiceItems: [...filtered] });
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function deleteInvoice() {
		try {
			let res = await post({}, `${config.apiv1}/invoice/invoice.delete/${params.invoiceUuid}`, true, 'DELETE');
			flashFadeModal(`Invoice was successfully deleted`);
			navigate('/invoices');
		} catch (err) {
			console.log(err);
		}
	}

	async function patchInvoice(data, noFlash) {
		try {
			data = { ...data, sellerTeamUuid: order?.sellerTeamUuid };

			let res = await post(
				{
					...data,
				},
				`${config.apiv1}/invoice/invoice.update/${params.invoiceUuid}`,
				true,
				'PATCH'
			);
			!noFlash ? flashFadeModal(`Invoice was successfully updated`) : null;
			setFormData((formData) => ({
				...formData,
				...data,
			}));
			setInvoice((invoice) => ({
				...invoice,
				...data,
			}));
		} catch (err) {
			console.log(err);
		}
	}

	const updateDebounceSearch = useCallback(
		debounce((value, name) => {
			setUpdateLoading(false);
			patchInvoice({ [name]: value }, true);
		}),
		[]
	);

	function debounce(cb, delay = 1000) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	const formatter = new Intl.NumberFormat(teamState?.currentTeam?.teamInfo?.spokenLanguage, {
		style: 'currency',
		currency: invoice?.currencyCode || 'GBP',
		currencyDisplay: 'narrowSymbol',
	});

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			fetchInitData();
			setCurrencySymbol(getCurrencySymbolFromTeam(teamState.currentTeam));
		}
		return () => (mounted = false);
	}, []);

	return (
		<PageWrapper
			backColor='gray'
			style={window.innerWidth > 768 ? { padding: '0px 0px 32px 0px', gap: '24px' } : { gap: '24px' }}
			header={headerTypes[invoice?.status]}
			subHeader={invoice?.docReference || 'Draft'}
			innerWidthMax={1000}
			innerCont={
				<InvoicePayments
					invoice={invoice}
					setUpdateLoading={setUpdateLoading}
					updateLoading={updateLoading}
					updateDebounceSearch={updateDebounceSearch}
				/>
			}
			innerWrap>
			{order && invoice ? (
				<>
					{/* <div className='innerHeader'>
						<h2>{order.buyerTeamName || order.buyerUserName}</h2>
						<h2>{order.sellerTeamName}</h2>
					</div> */}
					<SectionWrapper>
						<BannerSection
							type={invoice?.status}
							data={{ sent: invoice?.txnDate, due: invoice?.dueDate }}
						/>
					</SectionWrapper>
					<SectionWrapper>
						<BuyerInfoSection order={order} formData={invoice} details />
					</SectionWrapper>
					<SectionWrapper>
						<InfoSection formData={invoice} details />
					</SectionWrapper>
					<SectionWrapper>
						<ItemsSection
							currencySymbol={currencySymbol}
							invoiceItems={invoice?.invoiceItems}
							formatter={formatter}
							details
						/>
					</SectionWrapper>
					<SectionWrapper>
						<AmountSection
							formData={invoice}
							customErrors={customErrors}
							currencySymbol={currencySymbol}
							formatter={formatter}
							details
						/>
					</SectionWrapper>
					<SectionWrapper>
						<NotesSection formData={invoice} details />
					</SectionWrapper>
				</>
			) : (
				<Loading type='circle' />
			)}
		</PageWrapper>
	);
}

import React, { useEffect, useRef, useState } from 'react';
import { AppIcon } from '../SVGIcons/SVGIcons';
import AutofillInput from './AutofillInput';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { integrationDataLabels, parseDentallyFields } from '../../helpers';
import TooltipIcon from '../TooltipIcon';

const TextInput = React.forwardRef((props, ref) => {
	const {
		customCallbackValue,
		focusRightOnNewVal,
		tooltipExtendedText,
		defaultValue,
		placeholder,
		customError,
		autoFocus,
		onlyInput,
		maxLength,
		onKeyDown,
		callback,
		disabled,
		controlled,
		required,
		iconLeft,
		autofill,
		noSpace,
		preview,
		options,
		tooltip,
		onBlur,
		value,
		type,
		name,
		thin,
		text,
	} = props || {};

	const [optionsOpen, setOptionsOpen] = useState(false);
	const [tooltipExtended, setTooltipExtended] = useState(false);

	let inputRef = useRef(null);

	let labels = integrationDataLabels('dentally');

	const toggleTooltipExtended = () => {
		setTooltipExtended(!tooltipExtended);
	};

	useEffect(() => {
		if (inputRef.current && focusRightOnNewVal) {
			inputRef.current.focus();
			inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
		}
	}, [value]);

	return (
		<>
			{type === 'phone' ? (
				<div className={`formRowCenter${thin ? ' thin' : ''}`} ref={ref}>
					{required && defaultValue === undefined && <div isrequired={name}></div>}
					{required && defaultValue === null && <div isrequired={name}></div>}
					{required && defaultValue === '' && <div isrequired={name}></div>}
					<div className='fieldTextContainer'>
						{iconLeft}
						<p className='inputLabel' htmlFor={name}>
							{text}
						</p>
						{tooltip && (
							<TooltipIcon id={name} tooltip={tooltip} onClick={toggleTooltipExtended} delay={500} />
						)}
					</div>
					{tooltipExtended === true && tooltipExtendedText ? { tooltipExtendedText } : <></>}
					<PhoneInput
						placeholder={placeholder}
						value={defaultValue}
						required={required}
						disabled={disabled}
						onChange={(e) => {
							e && callback ? callback(e, name, noSpace) : null;
						}}
					/>
					{customError && <h4 className='tooltipError'>{customError}</h4>}
				</div>
			) : (
				<>
					{!onlyInput ? (
						<div className={`formRowCenter${thin ? ' thin' : ''}`} ref={ref}>
							{required && defaultValue === undefined && <div isrequired={name}></div>}
							{required && defaultValue === null && <div isrequired={name}></div>}
							{required && defaultValue === '' && <div isrequired={name}></div>}
							<div className='fieldTextContainer'>
								{iconLeft}
								<p className='inputLabel' htmlFor={name}>
									{text}
								</p>
								{tooltip && <TooltipIcon id={name} tooltip={tooltip} delay={500} />}
							</div>
							<div className='inputContainer'>
								{controlled ? (
									<input
										name={name}
										type={type || 'text'}
										className={`textFieldInput${preview ? ' cursor-text' : ''}`}
										onChange={(e) =>
											callback ? callback(e.target.value, e.target.name, noSpace) : null
										}
										value={value || ''}
										placeholder={placeholder}
										onKeyDown={onKeyDown}
										maxLength={maxLength}
										required={required}
										disabled={disabled}
										autoFocus={autoFocus}
										onBlur={(e) => {
											onBlur ? onBlur(e) : null;
										}}
										ref={inputRef}
										id={name}
										min={0}
									/>
								) : (
									<input
										name={name}
										type={type || 'text'}
										className={`textFieldInput${preview ? ' cursor-text' : ''}`}
										onChange={(e) =>
											callback ? callback(e.target.value, e.target.name, noSpace) : null
										}
										defaultValue={defaultValue || ''}
										placeholder={placeholder}
										onKeyDown={onKeyDown}
										maxLength={maxLength}
										autoFocus={autoFocus}
										required={required}
										disabled={disabled}
										onBlur={(e) => {
											onBlur ? onBlur(e) : null;
										}}
										ref={inputRef}
										id={name}
										min={0}
									/>
								)}
								{options && (
									<div
										tabIndex={0}
										className='optionsIconCont'
										onMouseDown={(e) => {
											setOptionsOpen(!optionsOpen);
											optionsOpen === false ? e.target.focus() : null;
										}}
										onBlur={() => {
											setOptionsOpen(false);
										}}>
										<AppIcon iconClass='optionsIcon' />
									</div>
								)}
							</div>
							{options && optionsOpen && (
								<>
									<div className='options textInput'>
										{Object.keys(parseDentallyFields(options))?.map((option, key) => {
											if (option && typeof option === 'string') {
												return (
													<div
														className='option'
														key={key}
														onMouseDown={() => {
															callback
																? callback(
																		`${defaultValue ? `${defaultValue} ` : ''}${
																			options[option]
																		}`,
																		name
																  )
																: null;
															setOptionsOpen(false);
														}}>
														{`${labels[option]}: ${options[option]}`}
													</div>
												);
											} else {
												return <React.Fragment key={key}></React.Fragment>;
											}
										})}
									</div>
								</>
							)}
							{customError && <h4 className='tooltipError'>{customError}</h4>}
							{autofill && customCallbackValue && (
								<AutofillInput
									callback={callback}
									customCallbackValue={customCallbackValue}
									name={name}
									defaultValue={defaultValue || ''}
								/>
							)}
						</div>
					) : (
						<div className='inputContainer'>
							{controlled ? (
								<input
									name={name}
									type={type || 'text'}
									className={`textFieldInput${preview ? ' cursor-text' : ''}`}
									onChange={(e) =>
										callback ? callback(e.target.value, e.target.name, noSpace) : null
									}
									value={value || ''}
									placeholder={placeholder}
									onKeyDown={onKeyDown}
									maxLength={maxLength}
									autoFocus={autoFocus}
									required={required}
									disabled={disabled}
									onBlur={onBlur}
									ref={inputRef}
									id={name}
									min={0}
								/>
							) : (
								<input
									name={name}
									type={type || 'text'}
									className={`textFieldInput${preview ? ' cursor-text' : ''}`}
									onChange={(e) =>
										callback ? callback(e.target.value, e.target.name, noSpace) : null
									}
									defaultValue={defaultValue || ''}
									placeholder={placeholder}
									onKeyDown={onKeyDown}
									maxLength={maxLength}
									autoFocus={autoFocus}
									required={required}
									disabled={disabled}
									onBlur={onBlur}
									ref={inputRef}
									id={name}
									min={0}
								/>
							)}
						</div>
					)}
				</>
			)}
		</>
	);
});

export default TextInput;
